import Header from "../../layouts/header";
import VerificationContent from "../../components/admin/verification";
import Cookies from 'js-cookie';
import { useHistory } from "react-router-dom";

export default function Verification() {
  const kyc_required = Cookies.get('kyc_required');
  const history = useHistory();
  if(kyc_required==0){
    history.push("/");
  }
  
    return (
      <>
        <section className="verification">
        <div className="wrapper">
             {/* <Header /> */}
            <VerificationContent />
            </div>
        </section>
      </>
    );
  }