import React, { useState } from "react";
import CallToAction from "../../components/contractors/callToAction";
import Content from "../../components/tutorial/content";
import Footer from "../../layouts/footer";
import Header from "../../layouts/header";
import Cookies from 'js-cookie';
import { useHistory ,useLocation } from 'react-router-dom';

export default function Listing() {
	
	const userToken = Cookies.get('token');
	//const userType = Cookies.get('user_type');

	const search = window.location.search;

	const history = useHistory()


	const userType =history.location.pathname.replace('/tutorial/','')



	return (
		<>
			 {/* <Header /> */}
			<Content userType={userType} />
			<CallToAction />
			{/* <Footer /> */}
		</>
	);
}
