import { useState } from 'react';
import Cookies from 'js-cookie';

export default function useToken() {
  const getToken = () => {
  //  const userToken = sessionStorage.getItem('token');
    const userToken =  Cookies.get('token');
  
    return (userToken) ? true :false;
   
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    //sessionStorage.setItem('token', JSON.stringify(userToken));
    Cookies.set('token', JSON.stringify(userToken));

    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token
  }
}