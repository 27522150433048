import React from "react";
import ReactStars from "react-rating-stars-component";
import { apartmentCardContent } from "../../utils/constant";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {currencyFormat} from "../../utils/functions";
export default function ApartmentCard({ detail }) {
	// const { detail } = props;
	const ratingChanged = (newRating) => {
	
	};
	

	return (
		<>
			<div className="apartment-card">
				<div className="feed-card-img">
				{ (detail.images!='' && detail.images!=null)?
					<img className="related-prop-img" src={process.env.REACT_APP_IMAGES_URL + JSON.parse(detail.images).path[0]}	alt=""/>
				:
					<img src={apartmentCardContent.feedCardImg} alt="" />
				}

					{/* <span>{apartmentCardContent.feedCardTag}</span> */}
				</div>
				<div className="feed-card-description">
					<p className="price-info">{detail.demand} USD</p>
					<h4 className="name-clint"><Link target="_blank" to={"/property/" + detail.slug + " "}>
											{detail.title}
										</Link></h4>
					{/* <div className="feed-rating mb-3">
						<ReactStars
							count={5}
							onChange={ratingChanged}
							size={24}
							activeColor="#d7524d"
						/>
					</div> */}
					<div className="feed-card-info">
						<div>
							<span>
								<img src={apartmentCardContent.feedCardBedIcon} alt="BedIcon" />
							</span>
							<span>{detail.beds}</span>
						</div>
						<div>
							<span>
								<img src={apartmentCardContent.feedCardSpaceIcon} alt="" />
							</span>
							<span>{detail.size}</span>
						</div>
						<div>
							<span>
								<img src={apartmentCardContent.feedCardBathIcon} alt="" />
							</span>
							<span>{detail.baths}</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
