import React, { useState , useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { BsPeopleFill } from 'react-icons/bs'
import { FiSearch } from 'react-icons/fi'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { BsEmojiSmile } from 'react-icons/bs'
import { MdAttachFile } from 'react-icons/md'
import { Avatar, OurSpecialFeatureBuildingImage , communityDarkIcon } from "../../utils/images";
import LeftSidebar from "../../layouts/leftSider";
import { MdOutlineSend } from 'react-icons/md';
import Cookies from 'js-cookie';
import moment from 'moment';
import Picker from 'emoji-picker-react';
import axios from 'axios';
import {Modal , Button} from 'react-bootstrap';
import Alert from "react-bootstrap/Alert";
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck , faBars  , faPlus } from '@fortawesome/free-solid-svg-icons'

const selected = [];
const aquaticCreatures = [];
const customStyles = {
  container: provided => ({
    ...provided,
    width: 'auto'
  })
};
async function addGroup(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/chatroom/add/group', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

async function addUserToGroup(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/chatroom/add/user/to/group', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}


export default function MessagesContent() {
  const [showLeftSideMenu, setShowLeftSideMenu] = useState(false);
  const handleCloseLeftSideMenu = () => setShowLeftSideMenu(false);
  const handleShowLeftSideMenu = () => setShowLeftSideMenu(true);

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [successMessageAddMember, setSuccessMessageAddMember] = useState('');
    const [name, setName] = useState('');
    const userToken = Cookies.get('token');
    const userid = Cookies.get('user_id');
    const [friends, setFriends] = useState();
    const [rooms, setRooms] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState();
    const [selectCurrentSelectedRoom, setSelectCurrentSelectedRoom] = useState();
    const [selectCurrentSelectedRoomArray, setSelectCurrentSelectedRoomArray] = useState();
    const [chats, setChats] = useState([]);
    const [message, setMessage] = useState("");
    const [url, setUrl] = useState();
    const [reloadboard, setReloadBoard] = useState();
    const [filteredRooms, setFilteredRooms] = useState([]);
    const [chosenEmoji, setChosenEmoji] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [showNoti, setShowNoti] = useState(false);
    const handleCloseNoti = () => setShowNoti(false);
    const handleShowNoti = () => setShowNoti(true);
    const [memberSelected, setMemberSelected] = useState([]);
    const [showNotiUpdate, setShowNotiUpdate] = useState(false);
    const handleCloseNotiUpdate = () => setShowNotiUpdate(false);
    const handleShowNotiUpdate = () => setShowNotiUpdate(true);
    const [reload, setReload] = useState();

    const [images, setFrontFiles] = useState();

    const [members, setMembers] = useState();
    const [addMembers, setAddMembers] = useState();
    const [selecteForUpdateMembers, setSelecteForUpdateMembers] = useState();


    const user_package = Cookies.get("package");
    const [all_members, setAllMembers] = useState([]);
    const [groupImage, setGroupImage] = useState();

    const handleRemoveImage = (i) => { 
	
      const imgarrayrealname = images.realname;
      imgarrayrealname.splice(i, 1);
      const imgarraypath = images.path;
      imgarraypath.splice(i, 1);
      setFrontFiles({ 'path' : imgarraypath ,  'realname'  : imgarrayrealname});
      
     } 


    const ref = useRef(null);
    const onEmojiClick = (event, emojiObject) => {
      const cursor = ref.current.selectionStart;
      const text =
        message.slice(0, cursor) + emojiObject.emoji + message.slice(cursor);
        setMessage(text);
    };

    const [show, toggleShow] = React.useState(false);

    const [image, setImage] = useState('');
    const [frontfilename, setFrontfilename] = useState();
    const refrbtn = useRef(null);
    

    const [dropdownSearchValue, setDropdownSearchValue] = useState("")

    useEffect(() => {
      
      if(rooms.length>0){

      const filteredSkills = rooms.filter(skill  =>
    
  (skill.type=='personal')?
     (userid==skill.first_user)?
        skill.second_author.first_name.match(new RegExp(dropdownSearchValue, "i"))
     :
        skill.first_author.first_name.match(new RegExp(dropdownSearchValue, "i"))
  
      :  skill.name.match(new RegExp(dropdownSearchValue, "i"))
      
      )
      

    



     
      setFilteredRooms(filteredSkills);

      //console.log('filteredSkills', filteredSkills.length);
 
      if(filteredSkills.length>0){
        setSelectedRoom(filteredSkills[0].room_id);
        setSelectCurrentSelectedRoomArray(filteredSkills[0]);  
      }
      
                setReloadBoard('3122');


      }
  }, [rooms ,dropdownSearchValue]);

  useEffect(() => {
  
    (filteredRooms && 
      filteredRooms.map((room, index) => (
        //aquaticCreatures[index] = {label: 'one'  , item :'1'}
        (room.type=='personal')? aquaticCreatures[index] = {label:(userid==room.first_user)?room.second_author.first_name+' '+room.second_author.last_name :room.first_author.first_name +' '+room.first_author.last_name,  value: (userid==room.first_user)?room.second_user :room.first_user} :null
      ))
      )
      setAllMembers(aquaticCreatures);
     // console.log("aquaticCreatures" , aquaticCreatures);

 }, [filteredRooms]);
    const handleChange =  e => {
      //setFiles(e.target.files)
      setErrorMessage(null);
      const url = process.env.REACT_APP_API_URL+'api/uploadFile';
      const formData = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        setFrontfilename(e.target.files[i].name);
        formData.append(`files[${i}]`, e.target.files[i])
        }
          formData.append('directory' , 'messages');
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
          axios.post(url, formData, config).then((response) => {
          
            let  path = [];
		let  realname = [];
			 if(images){
				 path =	images.path
				 realname =	images.realname
			}
			if(response.data.files.path){
				response.data.files.path.map(function(val, index){
					path = [...path ,val ];
				})
				response.data.files.realname.map(function(val, index){
			
					realname = [...realname ,val ];
				})
			}

			setFrontFiles({ 'path' : path ,  'realname'  : realname })


           // setImage(JSON.stringify(response.data.files));
         
            //setMessage(message + ' <img src="'+process.env.REACT_APP_IMAGES_URL +response.data.files.path[0]+'" >');
            //refrbtn.current.click();

            
          });
  
        };

    const checkImage = (msg) => {
  
      var m;
      var str = msg;
   var urls = []; 
    var rex = /<img[^>]+src="https:\/\/([^">]+)/g;
 var ret ='';
    while ( m = rex.exec( str ) ) {
      urls.push( m[1] );
      ret = 'data';
      //ret += " https://"+m[1];
  }
  
      if(ret!=''){
       
          return  urls;
      }else{
        return false;
      }
    }



    async function sendmessage(credentials) {




        return fetch(process.env.REACT_APP_API_URL+'api/chat/send', {
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin' : 'http://localhost:3000/',
            'Access-Control-Allow-Credentials' : 'true/',
            'Content-Type': 'application/json' ,
            'Authorization': 'Bearer '+credentials.userToken+'',
          },
          body: JSON.stringify(credentials)
        })
          .then(data => data.json())
      }


    React.useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'api/chats/room' , {
            method: 'POST',
            headers: {
              'Access-Control-Allow-Origin' : 'http://localhost:3000/',
              'Access-Control-Allow-Credentials' : 'true/',
              'Content-Type': 'application/json' ,
              'Authorization': 'Bearer '+userToken+'',
            },
            body: JSON.stringify({'room_id':selectedRoom})
          }
            )
            .then(results => results.json())
            .then(data => {
                setChats(data.data);
             
            });
        
    }, [reloadboard ]); // <-- Have to pass in [] here!

  
      React.useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'api/chatrooms' , {
          method: 'GET',
          headers: {
            'Access-Control-Allow-Origin' : 'http://localhost:3000/',
            'Access-Control-Allow-Credentials' : 'true/',
            'Content-Type': 'application/json' ,
            'Authorization': 'Bearer '+userToken+'',
          },
        }
          )
          .then(results => results.json())
          .then(data => {
              if(data.status){
                setRooms(data.data);
                setFilteredRooms(data.data);
                


              }
           
          });
      }, [reload]); // <-- Have to pass in [] here!

    function selectCurrent(s) {
    
     setSelectCurrentSelectedRoomArray(filteredRooms[s]);
     let newarray = [];

   //  console.log(' filteredRooms' ,  filteredRooms );


     if(filteredRooms[s].type=='group'){
     
      filteredRooms.map((item, index) => (
              
        
        (filteredRooms[s].members.find(x =>     x.user_id === item.second_user ||  x.user_id === item.first_user    )) ? 
          
        null
          :
          
           (item.type=='personal')? newarray[index] = { label: (userid==item.first_user)?item.second_author.first_name+' '+item.second_author.last_name :item.first_author.first_name +' '+item.first_author.last_name,  value: (userid==item.first_user)?item.second_user :item.first_user} :null
         
         

      ))
      
      setAddMembers(newarray);
      
     }
   
     

     }

     React.useEffect(() => {
      //console.log('addMembers' , addMembers );
    }, [addMembers ]); // <-- Have to pass in [] here!
  


      const handleSendMessage = async e => {
        e.preventDefault();
        setErrorMessage(null);
     
       let imghtml = ''; 


       if(images){
         images.path.map(function(img, index){
          imghtml += '<img key="'+index+'" src="'+process.env.REACT_APP_IMAGES_URL +img+'" />';
        })
        imghtml = imghtml + message;
       
         }else{
          imghtml =  message;
         }


       
        
        

          const res = await sendmessage({
            'is_admin': 'no',
            'room_id' : selectedRoom, 
            "message" : imghtml,
            userToken
          } );
          if(res.status){
            setMessage('');
            setUrl(message);
            setFrontFiles({ 'path' : [] ,  'realname'  : [] })
           setReloadBoard(Date.now);
          //  sessionStorage.setItem("token", token.author.api_token);
          //  history.push("/login");
          
          }
  
        }


       const handleKeyPress = (e) => {
          if (e.key === 'Enter') {
            refrbtn.current.click();
          }
        }
        
       

        const handleChangeImages =  e => {
          //setFiles(e.target.files)
          setErrorMessage(null);
          setSuccessMessage(null);
          setShowLoader(true);
          const url = process.env.REACT_APP_API_URL+'api/uploadFile';
          const formData = new FormData();
          for (let i = 0; i < e.target.files.length; i++) {
            formData.append(`files[${i}]`, e.target.files[i])
            }
              formData.append('directory' , 'profile');
              const config = {
                headers: {
                  'content-type': 'multipart/form-data',
                },
              };
              axios.post(url, formData, config).then((response) => {
                let  path = [];
                let  realname = [];
                  // if(groupImage){
                  //   path =	groupImage.path
                  //   realname =	groupImage.realname
                  // }
                  if(response.data.files.path){
                    response.data.files.path.map(function(val, index){
                      path = [...path ,val ];
                    })
                    response.data.files.realname.map(function(val, index){
                  
                      realname = [...realname ,val ];
    
                    })
                  }
    
                  setGroupImage({ 'path' : path ,  'realname'  : realname })
    
                  setShowLoader(false);
              });
      
            };
            const handleRemoveImages = (i) => { 
	
              const imgarrayrealname = groupImage.realname;
              imgarrayrealname.splice(i, 1);
              const imgarraypath = groupImage.path;
              imgarraypath.splice(i, 1);
              setGroupImage({ 'path' : imgarraypath ,  'realname'  : imgarrayrealname});
              
             }


             const handleSubmit = async e => {
              e.preventDefault();
              setSuccessMessage(null);
              const res = await addGroup({
                'members' : JSON.stringify(members),
                name,
                'image' : JSON.stringify(groupImage),
                userToken
              });
              if(res.success){
              //  sessionStorage.setItem("token", token.author.api_token);
              setSuccessMessage('Group has been added successfully.');
              setName('');
              //setGroupImage([]);
             setReload(Date.now);
              }else{
              
                setErrorMessage(res.errors);
              }
          
            }


            const handleSubmitUser = async e => {
              e.preventDefault();
              setSuccessMessageAddMember(null);
              const res = await addUserToGroup({
                'members' : JSON.stringify(selecteForUpdateMembers),
                'room_id': selectCurrentSelectedRoomArray.room_id ,
                userToken
              });
              if(res.success){
              //  sessionStorage.setItem("token", token.author.api_token);
              setSuccessMessageAddMember('User has been successfully add to the group.');
              
               // console.log("selecteForUpdateMembers" , selecteForUpdateMembers);
               //setMemberSelected(selecteForUpdateMembers);
              let newarray = [];

              addMembers.map((item, index) => (
              
                (selecteForUpdateMembers.find(x =>     x.value === item.value   )) ? 
                  
                null
                  :
                  
                    newarray[index] = { label: item.label , value:item.value } 
                 
        
              ))
              
              setAddMembers(newarray);
              setMemberSelected([]);


              }else{
              
                //setErrorMessage(res.errors);
              }
          
            }

            
            

    return (

      
      <>
      {(showLoader)&& 
		<div id="loading">
			<div className="loader"></div>
 		</div>
		}
        <section className="edit-profile-content row">
        <Modal show={showLeftSideMenu} onHide={handleCloseLeftSideMenu} size="md">
					
					<Modal.Body>
					<div style={{     width:'25px' }} className="cursor-pointer bg-primary px-2 py-1 mb-2 " onClick={handleCloseLeftSideMenu}>x</div>
					<div className='' style={{     backgroundColor:' #f6f9fe' }}>    <LeftSidebar /></div>

					</Modal.Body>
					
				</Modal>

        <div  className='message-pages-top  w-100'>
            <div className="d-flex mx-4 mt-4 justify-content-between">

              <div className=" d-lg-none   ">
              <FontAwesomeIcon  onClick={handleShowLeftSideMenu} icon={faBars} />
                  
                </div>
            
            </div>

          </div>



      

<div className="side-bar d-none d-lg-block h-auto  ">
				<LeftSidebar />
			</div>


          <div className="messages-list col-md-4">
              <form>
                  <span><BsPeopleFill className="search-icon" /></span>
                  <input type="search" placeholder="Conversation" 
                   onChange={e => setDropdownSearchValue(e.target.value)}
                   value={dropdownSearchValue}
                  />
                  <button><FiSearch className="search-icon" /></button>

                 

                  <FontAwesomeIcon  onClick={handleShowNoti}  className="display-4-custom"  icon={faPlus} />


              </form>
              {filteredRooms && (
              <ul className="users-list m-2">
                  {filteredRooms.map((room,index)=> (
                    // (index==0)?setSelectedRoom(skill.room_id):null,
        // (index==0)?selectCurrent(index):null,
        // (index==0)?setReloadBoard(Date.now):null,
<div  key={index} >
            {(room.type=='personal')? 
          //personal
          <li  onClick={(e) => { setSelectedRoom(e.currentTarget.dataset.id) ; selectCurrent(e.currentTarget.dataset.mainid); setReloadBoard(Date.now) }   }  data-id={room.room_id} data-mainid={index} >
                        
                        <div className="user-left">
                            <div className="user-img">
                            
                            {(userid==room.first_user)?
                                  <>
                                {(room.second_author.image!='' && room.second_author.image!=null)?
       
                                <img  className={  `social-media-post-profile-image user_${room.second_author.user_type}` } src={process.env.REACT_APP_IMAGES_URL + JSON.parse(room.second_author.image).path[0]} alt="" title={room.second_author.user_type} />
                                :
                                <img  className={  `social-media-post-profile-image user_${room.second_author.user_type}` } src={Avatar} alt="" title={room.second_author.user_type} />
                                    }
                                    </>
                              :
<>
                              {(room.first_author.image!='' && room.first_author.image!=null)?
       
                              <img  className={  `social-media-post-profile-image user_${room.first_author.user_type}` } src={process.env.REACT_APP_IMAGES_URL + JSON.parse(room.first_author.image).path[0]} alt="" title={room.first_author.user_type} />
                              :
                              <img className={  `social-media-post-profile-image user_${room.first_author.user_type}` }  src={Avatar} alt="" title={room.first_author.user_type} />
                             
                                  }
                                   </>
                            }

                                <div className="user-online"></div>
                            </div>
                            <h4>{(userid==room.first_user)?room.second_author.first_name+' '+room.second_author.last_name.substring(0, 1)+'.' :room.first_author.first_name +' '+room.first_author.last_name.substring(0, 1)+'.'} </h4>
                        </div>
                        <span className="user-right">{(userid==room.first_user)?(room.second_author.is_admin)?'USHX Admin':room.second_author.user_type:(room.first_author.is_admin)?'USHX Admin':room.first_author.user_type}</span>
                        </li>
            :
            //groups 
            <li key={index} onClick={(e) => { setSelectedRoom(e.currentTarget.dataset.id) ; selectCurrent(e.currentTarget.dataset.mainid); setReloadBoard(Date.now) }   }  data-id={room.room_id} data-mainid={index} >
                        
            <div className="user-left">
                <div className="user-img">
                
                <img  className={  `social-media-post-profile-image ` } src={process.env.REACT_APP_IMAGES_URL + JSON.parse(room.image).path[0]} alt="" />

                    <div className="user-online"></div>
                </div>
                <h4>{room.name} </h4>
            </div>
            <span className="user-right">Group</span>
            </li>
                    
          }


</div>
                    
                  
                


                        
                  ))}
                
                
              </ul>
                )}
          </div>


          <div className="chat col-md-8">

            {selectCurrentSelectedRoomArray && (
            <div className="chat-top">

                {(selectCurrentSelectedRoomArray.type=='personal')?
               <div className="chat-top-left">
              {(userid==selectCurrentSelectedRoomArray.first_user)?
                                  <>
                                {(selectCurrentSelectedRoomArray.second_author.image!='' && selectCurrentSelectedRoomArray.second_author.image!=null)?
       
                                <img  className="social-media-post-profile-image" src={process.env.REACT_APP_IMAGES_URL + JSON.parse(selectCurrentSelectedRoomArray.second_author.image).path[0]} alt="" />
                                :
                                <img src={Avatar} alt="" />
                                    }
                                    </>
                              :
<>
                              {(selectCurrentSelectedRoomArray.first_author.image!='' && selectCurrentSelectedRoomArray.first_author.image!=null)?
       
                              <img  className="social-media-post-profile-image" src={process.env.REACT_APP_IMAGES_URL + JSON.parse(selectCurrentSelectedRoomArray.first_author.image).path[0]} alt="" />
                              :
                              <img src={Avatar} alt="" />
                                  }
                                   </>
                            }



                    <div className="chat-top-name">
                        <h3>{(userid==selectCurrentSelectedRoomArray.first_user)?selectCurrentSelectedRoomArray.second_author.first_name:selectCurrentSelectedRoomArray.first_author.first_name}</h3>
                        {/* <span>3 People in conversation</span> */}
                    </div>
                </div>
                :
                // group
                <div className="chat-top-left d-flex  justify-content-between w-100">
                  <div className='w-50 d-flex  justify-content-start'>
                <img  className="social-media-post-profile-image" src={process.env.REACT_APP_IMAGES_URL + JSON.parse(selectCurrentSelectedRoomArray.image).path[0]} alt="" />
<div className="chat-top-name my-auto">
                        <h3>{selectCurrentSelectedRoomArray.name}</h3>
                        {/* <span>3 People in conversation</span> */}
                    </div>
                    </div>
                    {(userid==selectCurrentSelectedRoomArray.first_user)&&
                       <div className='cursor-pointer' onClick={handleShowNotiUpdate} >  
                        <FontAwesomeIcon  onClick={handleShowNotiUpdate}  className="display-4-custom-new"  icon={faPlus} />
                         
                       
                       <img className="dark-icon display-4-custom-new adduserimg" src={communityDarkIcon} alt="" /></div>
                      
}
                    </div>
              }
                
               
                
                {/* <BiDotsVerticalRounded className="chat-top-right" />  */}
            </div>
            
            )}


            {chats && (
            <div className="chat-messages">

              

{chats.map((chat, index) => 
             (
               
                <div key={index} className="chat-msg-container ml-2">
                     {chat.user_id==userid?

                    <div className="chat-msg">
                        <h5>You</h5>
                        <p>{checkImage(chat.message)? 
                          <>
                         
                          {
                          checkImage(chat.message).map((img, index) =>(

                          <img key={index} width={100}  src={"https://"+img} />

                          ))}  
<br></br>
{ chat.message.replace(/<img[^>]*>/g,"")}


                          </>
                          :
                          chat.message

                      }</p>
                        <h6> {moment(chat.created_at ).format("H:mm") } </h6>
                    </div>
                    :
                    <>
                    <div className="chat-msg-avatar">
                   
                                {(chat.user.image!='' && chat.user.image!=null)?
       
                                <img  className={  ` user_${chat.user.user_type}` } src={process.env.REACT_APP_IMAGES_URL + JSON.parse(chat.user.image).path[0]} alt="" />
                                :
                                <img  className={  ` user_${chat.user.user_type}` } src={Avatar} alt="" />
                                    }
                              
                              

                        <div className="user-online"></div>
                    </div>
                    <div className="chat-msg">
                    <h5>{chat.user.first_name}</h5>
                    <p>{checkImage(chat.message)? 
                          <>
                         
                          {
                          checkImage(chat.message).map((img, index) =>(

                          <img key={index} width={100}  src={"https://"+img} />

                          ))}  
<br></br>
{ chat.message.replace(/<img[^>]*>/g,"")}


                          </>
                          :
                          chat.message

                      }</p>
                      <h6> {moment(chat.created_at ).format("H:mm") } </h6>
                    </div>
                    </>
                        }
                </div>
  ))}

                {/* <div className="chat-msg-container images-msg">
                    <div className="chat-msg-avatar">
                        <img src={Avatar} alt="" />
                        <div className="user-online"></div>
                    </div>
                    <div className="msg-images">
                        <div className="img-row one-img">
                            <img src={OurSpecialFeatureBuildingImage} alt="" />
                        </div>
                        <div className="img-row two-img">
                            <img src={OurSpecialFeatureBuildingImage} alt="" />
                            <img src={OurSpecialFeatureBuildingImage} alt="" />
                        </div>
                    </div>
                </div>
                <div className="chat-msg-container">
                    <div className="chat-msg-avatar">
                        <img src={Avatar} alt="" />
                        <div className="user-online"></div>
                    </div>
                    <div className="chat-msg bg-primary">
                        <p>Hello guys, we have discussed about post-corona vacation plan and our decision is to go to Bali. We will have a very big party after this corona ends! These are some images about our destination</p>
                    </div>
                </div> */}
                {/* <div className="chat-msg-container">
                    <span className="msg-typing">. . .</span>
                </div> */}
            </div>
            )}
{
(images) && images.path.map((img , index) => (
										<div key={index}>
											
											   {(index==0)?
											   <>
												<br></br>
											   Selected Images:
												</>
											   :null}
												<span key={index} onClick={ ()=> handleRemoveImage(index)} className="closecustom"  aria-hidden="true">&times;</span>
											 
												<img width="75" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 
										</div>
									
									))}
{(user_package!='standard') &&
            <div className="chat-form">
                <span className="chat-icon">
                    <BsEmojiSmile   onClick={() => toggleShow(!show)}/> 
                   

                </span>
                <input ref={ref} type="text"  onKeyPress={handleKeyPress} placeholder="Write a message..." value={message} onChange={e => setMessage(e.target.value)} />
                <span className="chat-icon">
                
                    
                   
                  <label htmlFor="file">
                    <MdAttachFile className='edit-icon' /> 
                  <input  key="front_file" type="file"  className="hiddenfileinput" onChange={handleChange}  multiple/>
                  </label>

                   
          
                  
                </span>
                {/* <span className="chat-icon text-primary" >
                    <BiDotsVerticalRounded /> 
                </span> */}
                <button ref={refrbtn} type="button" className='sendbtn'   onClick={handleSendMessage} ><MdOutlineSend className="sendIcon"  /></button>
               
            </div>
	}

           {show && <Picker   onEmojiClick={onEmojiClick} /> 
  }
          </div>


          <Modal show={showNotiUpdate} onHide={handleCloseNotiUpdate} size="md">
        <Modal.Header >
          <Modal.Title>Add Members to  Group</Modal.Title>
        </Modal.Header>
					<Modal.Body>


        


          <div className="row edit-form">
          {successMessageAddMember && (
                            <Alert className="m-auto" variant="success">
                              {successMessageAddMember && <p> {successMessageAddMember} </p>}
                            </Alert>
                          )}
              <div className="signup-input  mt-3 col-md-12">
                <label>Members</label>
                {(addMembers  ) &&
                 <Select
                 
                 components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                 placeholder="Search"
                 isMulti 
                 value={memberSelected}
                 styles={customStyles}
                 options={addMembers}
                 onChange={ (opt) => { setSelecteForUpdateMembers(opt);   setMemberSelected(opt) }  }

               />
                }
               
              </div>
          </div>

					</Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseNotiUpdate}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmitUser}>
            Add To Group
          </Button>
          </Modal.Footer>
					
				</Modal>


          
				<Modal show={showNoti} onHide={handleCloseNoti} size="md">
        <Modal.Header >
          <Modal.Title>Add Group</Modal.Title>
        </Modal.Header>
					<Modal.Body>

        
          {successMessage && (
                            <Alert variant="success">
                              {successMessage && <p> {successMessage} </p>}
                            </Alert>
                          )}

          {errorMessage && (
            <Alert variant="danger" >
                 {errorMessage.name && (
                <p>  {errorMessage.name} </p>
                )}
                {errorMessage.image && (
                <p>  {errorMessage.image} </p>
                )}
                {errorMessage.members && (
                <p>  {errorMessage.members} </p>
                )}
                
                 
                  
              
                 
                </Alert>

          )}


          <div className="row edit-form">
              <div className="signup-input col-md-12">
                <label>Name</label>
                <input type="text" value={name}  onChange={e => setName(e.target.value)}  />
              </div>
              <div className="signup-input col-md-12">
                <label>Members</label>
                {(all_members) &&
                 <Select
                 components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                 placeholder="Search"
                 isMulti
                 styles={customStyles}
                 options={all_members}
                 onChange={opt => setMembers(opt)}

               />
                }
               
              </div>

              <div className="col-md-6 file-field d-flex  justify-content-between ">
                <input type="file"  onChange={handleChangeImages}/>
                <h3>
             
                  
                Image <br /> Click  to upload</h3>

                  {
									
									(groupImage) && groupImage.path.map((img , index) => (
										<div  key={index}>
											
											  
												<p key={index} onClick={ ()=> handleRemoveImages(index)} className="closecustom text-center"  aria-hidden="true">&times;</p>
                        <div  key={index} className='d-flex justify-content-center'>
												<img  key={index} width="75" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 
                    </div>
                		</div>
									
									))}

              </div>

          </div>

                     
       

					</Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseNoti}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Add Group
          </Button>
          </Modal.Footer>
					
				</Modal>


        </section>
      </>
    );
  }