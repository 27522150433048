import ActivityContent from "../../components/admin/activity";
import Header from "../../layouts/header";

export default function Activity() {
    return (
      <>
        <section className="activity">
        <div className="wrapper">
             {/* <Header /> */}
            <ActivityContent />
          </div>
        </section>
      </>
    );
  }