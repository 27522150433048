import React, { useState } from "react";
import CallToAction from "../../components/contractors/callToAction";
import RealtorsResults from "../../components/realtors/realtorsResults";
import Footer from "../../layouts/footer";
import Header from "../../layouts/header";

export default function Realtors() {
	const [feedCardContent, setFeedCardContent] = useState([]);
	const [feedCardContentmeta, setFeedCardContentMeta] = useState([]);
	const [reloaddata, setSetReloadData] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const fetchData = async () => {
		fetch(process.env.REACT_APP_API_URL + `api/realtors`, {
			method: "GET",
			headers: {
				"Access-Control-Allow-Origin": "http://localhost:3000/",
				"Access-Control-Allow-Credentials": "true/",
				"Content-Type": "application/json",
			},
		})
			.then((results) => results.json())
			.then((data) => {
				if (data) {
					setFeedCardContent(data.data);
					setFeedCardContentMeta(data.meta);
				}
			});
	};

	React.useEffect(() => {
		fetchData();
	}, [reloaddata]);
	return (
		<>
			<section className="reacltors">
				 {/* <Header /> */}
				<RealtorsResults
					feedCardContent={feedCardContent}
					feedCardContentmeta={feedCardContentmeta}
					fetchData={fetchData}
					setPageNumber={setPageNumber}
					setSetReloadData={setSetReloadData}
				/>
				<CallToAction />
				{/* <Footer /> */}
			</section>
		</>
	);
}
