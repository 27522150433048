import React, { useState , useEffect } from 'react';
import { Link } from "react-router-dom";
import { MdOutlineSend } from 'react-icons/md';
import SettingSider from "../../layouts/settingSider";
import LeftSidebar from "../../layouts/leftSider";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import useToken from '../App/useToken';
import Alert from "react-bootstrap/Alert";
import Cookies from 'js-cookie';

import {Modal, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck , faBars  , faPlus } from '@fortawesome/free-solid-svg-icons'

async function veirifyKyc(credentials) {

  return fetch(process.env.REACT_APP_API_URL+'api/author/kyc/update', {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
      'Access-Control-Allow-Credentials' : 'true/',
      'Content-Type': 'application/json' ,
      'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

async function sendmessage(credentials) {

  return fetch(process.env.REACT_APP_API_URL+'api/chat/send', {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
      'Access-Control-Allow-Credentials' : 'true/',
      'Content-Type': 'application/json' ,
      'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}


export default function VerificationContent() {
  const [showLoader, setShowLoader] = useState(false);
  const [showLeftSideMenu, setShowLeftSideMenu] = useState(false);
  const handleCloseLeftSideMenu = () => setShowLeftSideMenu(false);
  const handleShowLeftSideMenu = () => setShowLeftSideMenu(true);
  const { token, setToken } = useToken();
  const history = useHistory();

  if(!token) {
     history.push("/login");
  }
  const userToken = Cookies.get('token');
  const userid = Cookies.get('user_id');
  

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [first_name, setFirstName] = useState();
  const [last_name, setLastName] = useState();
  const [company, setCompany] = useState();
  const [frontfilename, setFrontfilename] = useState();
  const [backfilename, setBackfilename] = useState();
  const [companyid, setIdCompany] = useState();
  const [document_type, setDoctype] = useState();
  const [image_front, setFrontFiles] = useState();
  const [image_back, setBackFiles] = useState();
  const [board, setBoard] = useState([]);
  const [message, setMessage] = useState();
  const [url, setUrl] = useState();
  const [checkkyc, setCheckKyc] = useState([]);
  const [reloadboard, setReloadBoard] = useState();
  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+'api/chats/messageboard' , {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+userToken+'',
      },
    }
      )
      .then(results => results.json())
      .then(data => {
       
        setBoard(data.chat);
      });
  }, [url]); // <-- Have to pass in [] here!


  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+'api/author/kyc' , {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+userToken+'',
      },
    }
      )
      .then(results => results.json())
      .then(data => {
       
        setCheckKyc(data);
      });
  }, [reloadboard]); // <-- Have to pass in [] here!


  const handleChange =  e => {
    //setFiles(e.target.files)
    setShowLoader(true);
    setErrorMessage(null);
    const url = process.env.REACT_APP_API_URL+'api/uploadFile';
    const formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      setFrontfilename(e.target.files[i].name);
      formData.append(`files[${i}]`, e.target.files[i])
      }
        formData.append('directory' , 'kyc');
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        axios.post(url, formData, config).then((response) => {
          setShowLoader(false);
          setFrontFiles(JSON.stringify(response.data.files))
        });

      };

      const handleChangeBack =  e => {
        //setFiles(e.target.files)
        setErrorMessage(null);
        setShowLoader(true);
        const url = process.env.REACT_APP_API_URL+'api/uploadFile';
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
          setBackfilename(e.target.files[i].name);
          formData.append(`files[${i}]`, e.target.files[i])
          }
            formData.append('directory' , 'kyc');
            const config = {
              headers: {
                'content-type': 'multipart/form-data',
              },
            };
            axios.post(url, formData, config).then((response) => {
              setShowLoader(false);
              setBackFiles(JSON.stringify(response.data.files));
            });
          };

          const handleSubmit = async e => {
            e.preventDefault();
            setErrorMessage(null);
            setShowLoader(true);
             
              const res = await veirifyKyc({
                first_name,
                last_name,
                company,
                companyid,
                document_type,
                image_front,
                image_back,
                userToken
              } );
              if(res.success){
                setSuccessMessage('Your details are submitted successfully.');
                setReloadBoard(Date.now());
                setShowLoader(false);
              }else{
              
               setErrorMessage(res.errors);
               setShowLoader(false);
              }
      
            }

            const handleSendMessage = async e => {
              e.preventDefault();
              setErrorMessage(null);
           
               
                const res = await sendmessage({
                  'is_admin': 'yes',
                  message,
                  userToken
                } );
                if(res.status){
                
                  setMessage('');
                  setUrl(message);
                  setReloadBoard(message)
                //  sessionStorage.setItem("token", token.author.api_token);
                //  history.push("/login");
                
                }
        
              }
var     hidekyc = false;
if(checkkyc.status==true){
  
     if(checkkyc.data.status=='Accepted' || checkkyc.data.status=='Pending' ){
      hidekyc = true;
    
     }
}

    return (
     
        <section className="edit-profile-content row">
          {(showLoader)&& 
		<div id="loading">
			<div className="loader"></div>
 		</div>
		}


       <div  className='message-pages-top  w-100'>
            <div className="d-flex mx-4 mt-4 justify-content-between">

              <div className=" d-lg-none   ">
              <FontAwesomeIcon  onClick={handleShowLeftSideMenu} icon={faBars} />
                  
                </div>
            
            </div>

          </div>

        <div className="sidebar col-md-6 col-lg-2 col-0 d-none d-lg-block h-auto">  
            <LeftSidebar />
          </div>
     
          <div className="sidebar search-sidebar col-md-6 col-lg-2 col-0 d-none d-lg-block h-auto ">
            <SettingSider />
          </div>



          {/* <div  className='side-bar d-none d-lg-block h-auto'>
             <div className='' style={{     backgroundColor:' #f6f9fe' }}>   <LeftSidebar /></div>
             <div className='' style={{     backgroundColor:' #f6f9fe' }}><SettingSider /></div>

          </div> */}


         <Modal    dialogClassName='custom-dialog' show={showLeftSideMenu} onHide={handleCloseLeftSideMenu} size="lg"  >
					
					<Modal.Body>
					<div style={{     width:'25px' }} className="cursor-pointer bg-primary px-2 py-1 mb-2 " onClick={handleCloseLeftSideMenu}>x</div>
          
          <div className='row'>
          <div className="sidebar col-6  ">  
            <LeftSidebar />
          </div>
     
          <div className="sidebar search-sidebar col-6   ">
            <SettingSider />
          </div>
          </div>
         

					</Modal.Body>
					
				</Modal>

       
          <div className="edit-container ol-xsm-12 col-sm-12   col-md-12 col-lg-11 col-12">
         
          {hidekyc ?
            <Alert variant="success" >
                
                <p>  KYC Verification Submitted </p>
               
                </Alert>
               
           :
          <form onSubmit={handleSubmit}>
            <h3>KYC Verification</h3>

            {errorMessage && (
            <Alert variant="danger" >
                 {errorMessage.first_name && (
                <p>  {errorMessage.first_name} </p>
                )}

                {errorMessage.last_name && (
                <p>  {errorMessage.last_name} </p>
                )}
              
              {errorMessage.company && (
                <p>  {errorMessage.company} </p>
                )}

                {errorMessage.companyid && (
                <p>  {errorMessage.companyid} </p>
                )}
                 {errorMessage.document_type && (
                <p>  {errorMessage.document_type} </p>
                )}
                 {errorMessage.image_back && (
                <p>  {errorMessage.image_back} </p>
                )}
                 {errorMessage.image_front && (
                <p>  {errorMessage.image_front} </p>
                )}
              
                 
                </Alert>

          )}


            <div className="row edit-form">
              <div className="signup-input col-md-6">
                <label>First Name</label>
                <input type="text"   onChange={e => setFirstName(e.target.value)}/>
              </div>
              <div className="signup-input col-md-6">
                  <label>Last Name</label>
                  <input type="text" onChange={e => setLastName(e.target.value)} />
              </div>
              <div className="signup-input col-md-6">
                <label>Company Name</label>
                <input type="text" onChange={e => setCompany(e.target.value)} />
              </div>
              <div className="signup-input col-md-6">
                  <label>Company ID</label>
                  <input type="text" onChange={e => setIdCompany(e.target.value)} />
              </div>
              <div className="signup-input col-md-6">
                <label>Document Type</label>
                <select className="form-select type-select" aria-label="Default select example" onChange={e => setDoctype(e.target.value)}>
                    <option  value="" >Select Document Type</option>
                    <option value="driving_license">Driving License</option>
                    <option value="cnic">Government-issued ID</option>
                </select>
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-6 file-field">
                <input  key="front_file" type="file" onChange={handleChange} />
                <h3>Front Side <br /> Click  to upload</h3>
                <span>{frontfilename}</span>
              </div>
              <div className="col-md-6 file-field">
                <input  key="back_file" type="file"   onChange={handleChangeBack} />
                <h3>Back Side <br /> Click  to upload</h3>
                <span>{backfilename}</span>
              </div>
              <div className="col-md-6">
            

                  {successMessage && (
            <Alert variant="success" >
                 {successMessage && (
                <p>  {successMessage} </p>
                )}
                </Alert>

          )}


              </div>
            </div>
            <div className="signup-submit">
              <button type="submit" className="submit-btn">Submit for Revision</button>
            </div>
            </form>
              }

            <h3 className="board-title">Message Board</h3>

        
        
 
             {board.map((user , index) => 
             (
              <div key={index} className="board-msg col-12">
                <h4>{user.user_id==userid?'You':'Admin'}
                </h4>
                <p>{user.message}</p>
                <span>{user.formatted_date}</span>
            </div>
            
      ))}
        

          
            <form className="board-form">
                <input type="text"  value={message} onChange={e => setMessage(e.target.value)}/>
                <button type="button"  onClick={handleSendMessage} ><MdOutlineSend className="sendIcon"  /></button>
            </form>
          </div>
        </section>
    
    );
  }