import Home from "../views/home";
import Listing from "../views/listing";
import Detail from "../views/detail";
import Login from "../views/login";
import Forgot from "../views/forgot";
import Signup from "../views/signup";
import Completion from "../views/completion";
import EditProfile from "../views/editProfile";
import Security from "../views/security";
import Mobile from "../views/mobile";
import Questionnairs from "../views/questionnairs";
import Document from "../views/documents";
import Verification from "../views/verification";
import Notifications from "../views/notifications";
import Listings from "../views/listings";
import Activity from "../views/activity";
import Referrals from "../views/referrals";
import Messages from "../views/messages";
import SocialMedia from "../views/socialMedia";
import AddProperty from "../views/addProperty";
import Contractors from "../views/contractors";
import ContractorDetails from "../views/contractorDetails";
import Investors from "../views/investors";
import InvestorsDetails from "../views/investorDetails";
import Realtors from "../views/realtors";
import RealtorDetails from "../views/realtorDetails";
import PropertyDetails from "../views/propertyDetails";
import Token from "../views/token";
import Community from "../views/community";
import Post from "../views/postDetails";
import Profile from '../views/profile';
import Tutorial from '../views/tutorial';
import Terms from '../views/terms';
import Privacy from '../views/privacy';
import {
	home,
	token,
	listing,
	detail,
	login,
	community,
	forgot,
	signup,
	completion,
	notifications,
	editProfile,
	passwordupdate,
	questionnairs,
	verification,
	listings,
	activity,
	referrals,
	messages,
	socialMedia,
	addProperty,
	contractors,
	contractorDetails,
	realtors,
	realtorDetails,
	propertyDetails,
	investors,
	investorDetails,
	post,
	mobile,
	profile,
	tutorial,
	terms,
	privacy,
	documents
} from "./routePath";

const AuthRouter = [
	{ path: home, component: Home },
	{ path: token, component: Token },
	{ path: listing, component: Listing },
	{ path: notifications, component: Notifications },
	{ path: detail, component: Detail },
	{ path: login, component: Login },
	{ path: forgot, component: Forgot },
	{ path: signup, component: Signup },
	{ path: completion, component: Completion },
	{ path: editProfile, component: EditProfile },
	{ path: passwordupdate, component: Security },
	{ path: mobile, component: Mobile },
	{ path: questionnairs, component: Questionnairs },
	{ path: documents, component: Document },
	{ path: verification, component: Verification },
	{ path: listings, component: Listings },
	{ path: activity, component: Activity },
	{ path: referrals, component: Referrals },
	{ path: community, component: Community },
	{ path: messages, component: Messages },
	
	{ path: socialMedia, component: SocialMedia },
	{ path: addProperty, component: AddProperty },
	{ path: contractors, component: Contractors },
	{ path: contractorDetails, component: ContractorDetails },
	{ path: realtors, component: Realtors },
	{ path: realtorDetails, component: RealtorDetails },
	{ path: propertyDetails, component: PropertyDetails },
	{ path: investors, component: Investors },
	{ path: investorDetails, component: InvestorsDetails },
	{ path: post, component: Post },
	{ path: profile, component: Profile },	
	{ path: tutorial, component: Tutorial },
	{ path: privacy, component: Privacy },
	{ path: terms, component: Terms },	
];

export default AuthRouter;
