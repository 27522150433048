import React, { useState } from 'react';
import Alert from "react-bootstrap/Alert";


async function subscribeAction(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/subscription/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}


export default function CallToAction() {
  const [email, setEmail] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async e => {
    e.preventDefault();
    setErrorMessage(null);
    setSuccessMessage(null);
    const token = await subscribeAction({
      email
    });
    if(token.success){
      setSuccessMessage("Subscription has been added successfully.");
      setEmail('');
    }else{
      setErrorMessage(token.errors);
    }

  }




  return (
    <></>
//     <section className="call-to-action">
//       <div className="container">
//         <div className="row call-to-action-main">
//           <div className="col-md-6">
//             <div className="call-to-action-content">
//               <h2>Get Great News from Us</h2>
//               <p>We value privacy and would never spam you!</p>
//             </div>
//           </div>
//           <div className="col-md-6">
//             <div className="call-subscribe">
//             <form onSubmit={handleSubmit}>
//               <input required type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="E.g. ziggy@space.com" />
//               <button type='submit'>Subscribe</button>
//               </form>
//               {errorMessage && (
//             <Alert variant="danger" >
//                   <Alert.Heading>
//                   {errorMessage}
//                   </Alert.Heading>
//                 </Alert>

//           )}

// {successMessage && (
// 									<Alert variant="success">
// 										{successMessage && <p> {successMessage} </p>}
// 									</Alert>
// 								)}


//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
  );
}
