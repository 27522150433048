import SignupForm from "../../components/signup/signupForm";
import SignupLogo from "../../components/signup/signupLogo";
import React, { useState } from 'react';
import AddProperty from "../../components/completion/addProperty";
import Phone from "../../components/completion/phone"
import Kyc from "../../components/completion/kyc";
import Basicinfo from "../../components/completion/basicInfo";
import Interests from "../../components/completion/basicquestions";
import Alert from "react-bootstrap/Alert";
import { useHistory } from "react-router-dom";
import Header from "../../layouts/header";
import Cookies from 'js-cookie';


async function registerUser(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}
async function checkEmail(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/author/check/email', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

export default function Signup() {
  const [count, setCount] = React.useState(0);
  const [details, setDetails] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [phone, setPhone] = useState('');
    const user_type = Cookies.get('user_type');
    const userToken = Cookies.get('token');
  const  kyc_required =   Cookies.get('kyc_required');
  const history = useHistory();
//  console.log("userToken" , userToken);

  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+'api/user/detail' , {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+userToken+'',
      },
    }
      )
      .then(results => results.json())
      .then(data => {
     
        setDetails(data.user);
        setPhone(data.user.phone);

      });
  }, []); // <-- Have to pass in [] here!


    const normalizeInput = (value, previousValue) => {
      if (!value) return value;
      const currentValue = value.replace(/[^\d]/g, '');
      const cvLength = currentValue.length;
      
      if (!previousValue || value.length > previousValue.length) {
        if (cvLength < 4) return currentValue;
        if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
      }
    };

  const handlePhone = (val) => {
    setPhone(normalizeInput(val, phone));
    };


  const handleStepsForword = () => {
    let  isphonevarified = Cookies.get('isphonevarified');
    let kycsubmitted = Cookies.get('kycsubmitted');
    let error = 0;

    console.log('user_type' ,user_type);
    console.log('count' ,count);
    setErrorMessage(null);
    if(user_type=='seller'){
     
      if(count==3){
        
      if( isphonevarified=="undefined" || isphonevarified==0){
        console.log("error" , count);
        let checkerror = {vairfyPhone:"Please Verify your phone number"};
        setErrorMessage(checkerror);
        error = 1;
        console.log('should through error' ,"yes");
      }
      }
    }else{

      if(count==2){
        
        if(isphonevarified=="undefined" || isphonevarified==0){
        
          let checkerror = {vairfyPhone:"Please Verify your phone number"};
          setErrorMessage(checkerror);
          error = 1;
        }
        }
    }
  
    if(error==0){
      setCount(count + 1);
    }
    
  }
  
    let components = [];
    if(user_type=='seller'){

        if(kyc_required==1){
          components = [

            <div><Basicinfo  
            handleStepsForword={handleStepsForword}
            details = {details}
        
            className="mt-5 " /></div>,

            <div><Interests  
            handleStepsForword={handleStepsForword}
            details = {details}
        
            className="mt-5 " /></div>,

            <div><AddProperty  
            handleStepsForword={handleStepsForword}
        
        
            className="mt-5 " /></div>,
            <div><Phone  
            phone={phone}
            setPhone={handlePhone}
            setErrorMessage={setErrorMessage}
            className="mt-5 " /></div>,
            <div><Kyc  
        
            setErrorMessage={setErrorMessage}
        
            className="mt-5 " /></div>,
            
        ]
        }else{
          components = [

            <div><Basicinfo  
            handleStepsForword={handleStepsForword}
            details = {details}
        
            className="mt-5 " /></div>,
            <div><Interests  
            handleStepsForword={handleStepsForword}
            details = {details}
        
            className="mt-5 " /></div>,
            <div><AddProperty  
            handleStepsForword={handleStepsForword}
        
        
            className="mt-5 " /></div>,
            <div><Phone  
            setPhone={handlePhone}
            phone={phone}
            setErrorMessage={setErrorMessage}
            className="mt-5 " /></div>,
           
            
        ]
        }
     


    }else{

      if(kyc_required==1){
       components = [
    
        <div><Basicinfo  
        handleStepsForword={handleStepsForword}
        details = {details}
    
        className="mt-5 " /></div>,
        <div><Interests  
        handleStepsForword={handleStepsForword}
        details = {details}
    
        className="mt-5 " /></div>,
        <div><Phone  
        setPhone={handlePhone}
        phone={phone}
        setErrorMessage={setErrorMessage}
        className="mt-5 " /></div>,
        <div><Kyc  
    
        setErrorMessage={setErrorMessage}
    
        className="mt-5 " /></div>,
        
    ]
  }else{
    components = [
    
      <div><Basicinfo  
      handleStepsForword={handleStepsForword}
      details = {details}
  
      className="mt-5 " /></div>,
      <div><Interests  
      handleStepsForword={handleStepsForword}
      details = {details}
  
      className="mt-5 " /></div>,
      <div><Phone  
      setPhone={handlePhone}
      phone={phone}
      setErrorMessage={setErrorMessage}
      className="mt-5 " /></div>,
      
      
  ]
  }
    }
  

    let  isphonevarified = Cookies.get('isphonevarified');
    if( isphonevarified=='undefined'){
      isphonevarified = 0 ;
    }
    let kycsubmitted = Cookies.get('kycsubmitted');
    if( kycsubmitted=='undefined'){
      kycsubmitted = 0 ;
    }
    const handleStepsFinish = () => {
      let kycsubmitted = Cookies.get('kycsubmitted');
      let  isphonevarified = Cookies.get('isphonevarified');
    //  if( fonvari !="undefined" && fonvari !=0 ){
    //    history.push('/congrates')
      
    //   }else{

    //   }

      setErrorMessage(null);
      if(user_type=='seller'){

        if( kycsubmitted==0){
         
          let checkerror = {vairfyPhone:"Please submit KYC"};
          setErrorMessage(checkerror);
        }else{
          history.push('/edit-profile')
        }
      }else{
       
        if(isphonevarified=="undefined" || isphonevarified==0){
        
          let checkerror = {vairfyPhone:"Please Verify your phone number"};
          setErrorMessage(checkerror);
        }else{
            history.push('/edit-profile')
          }
          
      }


     
    }

const handleStepsBackword = () => {
  setCount(count - 1);
}
  return (
    <>
      <section className="login-section  mx-3">
       {/* <Header /> */}

      <div className="mt-5 container ">
      <section className="signup-form mt-5 row" >
        <div className="row  mt-2">
          <h2 className="signup-form-title col-md-12 ">Let's setup your profile quickly</h2>
         </div>
          <div className="row pt-5 h-100 mt-5">
{errorMessage && (
            <Alert variant="danger" >
                 {errorMessage.first_name && (
                <p>  {errorMessage.first_name} </p>
                )}
                {errorMessage.last_name && (
                <p>  {errorMessage.last_name} </p>
                )}
                {errorMessage.email && (
                <p>  {errorMessage.email} </p>
                )}
                {errorMessage.password && (
                <p>  {errorMessage.password} </p>
                )}
                 {errorMessage.vairfyPhone && (
                <p>  {errorMessage.vairfyPhone} </p>
                )}
                   {errorMessage.password_confirmation && (
                <p>  {errorMessage.password_confirmation} </p>
                )}
                     {errorMessage.phone && (
                <p>  {errorMessage.phone} </p>
                )}
                  {errorMessage.code && (
                <p>  {errorMessage.code} </p>
                )}
                   {errorMessage.company && (
                <p>  {errorMessage.company} </p>
                )}
                   {errorMessage.user_type && (
                <p>  {errorMessage.user_type} </p>
                )}

          {errorMessage.terms && (
                          <p>  {errorMessage.terms} </p>
                          )}
              
                 
                </Alert>

          )}

          
        </div>
      
       
      
      {
            // render component from our components array
            components[count]
        }

</section>
    

      <div className="signup-submit">
      
        {/* show previous button if we are not on first element */}
        {count > 0 && <button type="button" className="signup-btn" onClick={handleStepsBackword}>Previous</button>}

        {/* hide next button if we are at the last element */}
        {count < components.length - 1 && 
        <button type="button"  className="signup-btn " onClick={handleStepsForword}>Next</button>}

{count == components.length - 1 && 
        <button type="button"  className="signup-btn" onClick={handleStepsFinish} >Finish</button>}


      {/* <TypeForm  className="mt-5" /> */}
        {/* <SignupForm  className="mt-5" /> */}
        </div>
        </div>
      </section>
    </>
  );
}
