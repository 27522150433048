import React, { useState, useRef } from 'react'

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'
import {Modal, Button} from 'react-bootstrap';
import 'react-image-crop/dist/ReactCrop.css'
import Cookies from 'js-cookie';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck , faBars ,faPenAlt , faPenSquare } from '@fortawesome/free-solid-svg-icons'


// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

async function profileGalleryUpdate(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/profile/upload-gallery', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}


export default function ImageCrop(props) {

  const userToken =  Cookies.get('token');


  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(9 / 9)
  const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showLoader, setShowLoader] = useState(false);
    const [images, setFrontFiles] = useState();
    const handleRemoveImage = (i) => { 
	
        const imgarrayrealname = images.realname;
        imgarrayrealname.splice(i, 1);
        const imgarraypath = images.path;
        imgarraypath.splice(i, 1);
        setFrontFiles({ 'path' : imgarraypath ,  'realname'  : imgarrayrealname});
        
       } 
    const handleChange =  e => {
        var image = new Image();

       
          
        image.src = previewCanvasRef.current.toDataURL();
        console.log ("previewCanvasRef" , image);

          
        const url = process.env.REACT_APP_API_URL+'api/uploadFileCanvas';
        const formData = new FormData();
      
          formData.append(`files`, image.src)
          
            formData.append('directory' , 'profile');
            const config = {
              headers: {
                'content-type': 'multipart/form-data',
              },
            };
            axios.post(url, formData, config).then((response) => {
            
              props.setImage(JSON.stringify(response.data.files))
              setShow(false);


              let  path = [];
              let  realname = [];
                if(images){
                  path =	images.path
                  realname =	images.realname
                }
                if(response.data.files.path){
                  response.data.files.path.map(function(val, index){
                    path = [...path ,val ];
                  })
                  response.data.files.realname.map(function(val, index){
                
                    realname = [...realname ,val ];

                  })
                }

                setFrontFiles({ 'path' : path ,  'realname'  : realname })



                const res =  profileGalleryUpdate({
    
                  userToken,
                  'image' : JSON.stringify({ 'path' : path ,  'realname'  : realname })
            
                  
                });
                if(res){
                  Cookies.set('profile_image',  path)
                  window.location.reload();
                  setShowLoader(false);
                }


               


            });


        //setFiles(e.target.files)
        // setErrorMessage(null);
        // const url = process.env.REACT_APP_API_URL+'api/uploadFile';
        // const formData = new FormData();
        // for (let i = 0; i < e.target.files.length; i++) {
        //   setFrontfilename(e.target.files[i].name);
        //   formData.append(`files[${i}]`, e.target.files[i])
        //   }
        //     formData.append('directory' , 'profile');
        //     const config = {
        //       headers: {
        //         'content-type': 'multipart/form-data',
        //       },
        //     };
        //     axios.post(url, formData, config).then((response) => {
            
        //       setImage(JSON.stringify(response.data.files))
    
        //     });
    
          };

          

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
      setShow(true);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined)
    } else if (imgRef.current) {
      const { width, height } = imgRef.current
      setAspect(9 / 9)
      setCrop(centerAspectCrop(width, height, 9 / 9))
    }
  }

  return (


    
    <div className="App">
        {(showLoader)&& 
		<div id="loading">
			<div className="loader"></div>
 		</div>
		}
      <div className="Crop-Controls">
      <div className="col-md-12  file-field file-field-image-container-profile" >
        <input type="file" accept="image/*" onChange={onSelectFile} />
        {/* <FontAwesomeIcon  icon={faPenSquare} style={{ fontSize: '16px' , marginBottom: '42px'}} /> */}
      {(props.profile_image!='null')?
      
                          <img className={  `social-media-post-profile-image  user_${props.details.user_type}` }   src={process.env.REACT_APP_IMAGES_URL + props.profile_image} alt="" />
                          
                        


                        :
                        null
                        }   
          
    </div>
        <div className='d-none'>
          <label htmlFor="scale-input">Scale: </label>
          <input
            id="scale-input"
            type="number"
            step="0.1"
            value={scale}
            disabled={!imgSrc}
            onChange={(e) => setScale(Number(e.target.value))}
          />
        </div>
        <div className='d-none'>
          <label htmlFor="rotate-input">Rotate: </label>
          <input
            id="rotate-input"
            type="number"
            value={rotate}
            disabled={!imgSrc}
            onChange={(e) =>
              setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
            }
          />
        </div>
        <div>
          
        </div>
      </div>
      


      <Modal show={show} onHide={handleClose} size="lg">
                    <Modal.Header >
                    <Modal.Title>Crop Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <button className='btn btn-primary rounded mb-2 bg-primary text-white ' onClick={handleToggleAspectClick}>
            Toggle aspect {aspect ? 'off' : 'on'}
          </button>
                    <div className='row'>
                    
                        <div className='col-md-6'> 
                        
                        {Boolean(imgSrc) && (
                        <ReactCrop

                        crop={crop}
                        minWidth={'200'}
                        maxWidth={'200'}
                        minHeight={'200'}
                        maxHeight={'200'}
                        circularCrop
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={aspect}
                        >
                        <img
                            ref={imgRef}
                            alt="Crop me"
                            src={imgSrc}
                            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                            onLoad={onImageLoad}
                        />
                        </ReactCrop>
                    )}
                        </div>
                        <div className='col-md-6'> 
                        {Boolean(completedCrop) && (
                        <canvas
                            id="canvas"
                            ref={previewCanvasRef}
                            style={{
                            border: '1px solid black',
                            objectFit: 'contain',
                            width: completedCrop.width,
                            height: completedCrop.height,
                            }}
                        />
                        )}
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="Primary" onClick={handleChange}>
                        Save
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>
     
    </div>
  )
}
