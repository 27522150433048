import { Link } from "react-router-dom";
import LeftSidebar from "../../layouts/leftSider";
import SettingSider from "../../layouts/settingSider";
import { FiSearch } from 'react-icons/fi'
import { RiSendPlaneFill } from 'react-icons/ri'
import { Avatar } from "../../utils/images";
import React, { useState ,useEffect , useCallback } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment';
import Select from 'react-select';
import Alert from "react-bootstrap/Alert";


const aquaticCreatures = [];
const customStyles = {
  container: provided => ({
    ...provided,
    width: 400
  })
};

async function addDealer(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/property/add/dealer', {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}


async function sendmessage(credentials) {
	


  return fetch(process.env.REACT_APP_API_URL+'api/appointments/status', {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
      'Access-Control-Allow-Credentials' : 'true/',
      'Content-Type': 'application/json' ,
      'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())


  }


  

  async function sendNote(credentials) {

    return fetch(process.env.REACT_APP_API_URL+'api/property/notes/add', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
  
  
    }
export default function Views(props) {
 
  const userToken = Cookies.get('token');
  const userid = Cookies.get('user_id');
  const [myValues, setMyValues] = useState(props.appointments);
  const [notes, setNotes] = useState(props.notes);
  const [message, setMessage] = useState();
  const [propertyid, setPropertyid] = useState(props.property);
  const [checked, setChecked] = useState(false);
  const [dealer, setDealer] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedDealer, setSelectedDealer] = useState([]);
  const [checkIfDeal, setCheckIfDeal] = useState('');
  const [shareonsocialmedia, setshareonsocialmedia] = useState(false);
  const [notifyinvestor, setnotifyinvestor] = useState(false);
  
  const [views, setViews] = useState('');


  const checkNotifyinsetor =  e => {
    if(e){
      setnotifyinvestor(true);
    
    }else{
      setnotifyinvestor(false);
      
    }

    
}


  const checksetShareProperty =  e => {
    if(e){
      setshareonsocialmedia(true);
  
    }else{
      setshareonsocialmedia(false);
      
    }

    
}


  const handleSubmit = async e => {
    e.preventDefault();
    setErrorMessage(null);
    setSuccessMessage(null);
    const token = await addDealer({
      notifyinvestor,
      shareonsocialmedia,
      dealer,
      'property_id':propertyid , 
      userToken,
    });
    if(token.success){
      setSuccessMessage("Dealer has been added successfully.");
      setDealer('');
      setCheckIfDeal('YES');
    }else{
      setErrorMessage(token.errors);
    }

  }


  (myValues && 
    myValues.map((item, index) => (
      aquaticCreatures[index] = {label: item.name,  value: item.id}
    ))
    )
 


    React.useEffect(() => {
      fetch(process.env.REACT_APP_API_URL + "api/property/get/dealer", {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000/",
          "Access-Control-Allow-Credentials": "true/",
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken + "",
        },
        body: JSON.stringify({
          'property_id':propertyid , 
        }),
      })
        .then((results) => results.json())
        .then((data) => {
          if(data.status){
            setSelectedDealer(data.deal);
          }
          
        });
    }, [myValues ,checkIfDeal ]); // <-- Have to pass in [] here!




const handleAppointment = useCallback(
  (id ,status) => async () => {
    const res = await sendmessage({
      'appointmentid' : id,
      'status' :  String(status), 
      userToken
    } );
    
    if(res.appointments){

   
    setMyValues(res.appointments);
     
    }

  },
  [props.appointments],
)


useEffect(() => {
  setMyValues(props.appointments);
}, [props.appointments]);

useEffect(() => {
  setViews(props.total_views);
}, [props.total_views]);

useEffect(() => {
  setNotes(props.notes);
}, [props.notes]);

useEffect(() => {
  setPropertyid(props.property);
}, [props.property]);

const handleSendNote = async e => {
  e.preventDefault();
    const res = await sendNote({
      'note': message,
      'visibility_status' :  (checked)? 'private' : 'public', 
      'property_id' : propertyid,
      userToken
    } );
    if(res){
      setMessage('');
      setNotes(res.note);
    }

  }
  


  
    return (
      <>
        <section className="edit-profile-content views-container row">

          <div className="edit-container views-container">
           
          <div className="activity-msg col-11 p-4 m-auto">
                <p>This property was viewed {views} time.  {views} members viewed this property. </p>
            </div>

            { props.author== userid && 
<>
            { myValues && 
            

            <div className="views-table col-11">
              <table className="table">
                <thead>
                  <tr>
                    <td>People who wants to see this property</td>
                  </tr>
                </thead>
                <tbody>

                {myValues.map((item, index) => (
                  <tr>
                    <td>Name: {item.name}
                      {/* <br /> <span className="text-secondary small">Realtor</span> */}
                    </td>
                    <td>Email:{item.email}</td>
                    <td>Phone: {item.phone}</td>
                    <td>
                  {
                    
                
                   (item.status==0)?
                     <>
                      <button className="btn reject-btn"  onClick={handleAppointment( item.id, 2)} >Reject</button>
                      <button className="btn accept-btn" onClick={handleAppointment( item.id, 1)}>Accept</button>
                      </>
                   :(item.status==1)?
                      <>
                      <button className="btn reject-btn" onClick={handleAppointment( item.id, 2)}>Reject</button>
                      </>
                   :
                    <>
                    <button className="btn accept-btn" onClick={handleAppointment( item.id, 1)}>Accept</button>
                    </>
                    }
                      

                    </td>
                  </tr>
                  	))}
                </tbody>
              </table>
            </div>
            }
 



            <div className="deal col-11">
              <h2 className="deal-title">Is the deal made? With whom?</h2>
              {errorMessage && (
            <Alert variant="danger" >
                  <Alert.Heading>
                  {errorMessage}
                  </Alert.Heading>
                </Alert>

          )}

          {successMessage && (
                            <Alert variant="success">
                              {successMessage && <p> {successMessage} </p>}
                            </Alert>
                          )}

              <div className="row">

              {selectedDealer && (
                <>
                {(selectedDealer.appointment)?
               <div className="col-lg-6  deal-input">
                 
                  Name: {selectedDealer.appointment.name}  <br></br>
                  Email:  {selectedDealer.appointment.email}

               </div>

:
                <form  onSubmit={handleSubmit} className="col-lg-12">
               
                <div className="col-lg-6  deal-input">
               


                  {/* <div className="deal-icon">
                    <FiSearch />
                  </div> */}
                  {/* <input type="text" placeholder="Search Users"/> */}
                  <Select
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    placeholder="Search"
                    styles={customStyles}
                    options={aquaticCreatures}
                    onChange={opt => setDealer(opt.value)}

                  />
                  </div>
          
                <div className="col-12 mx-0 my-2">
                  <div class="cbox yes-no-container views-choices">
                      <span>
                          <input type="checkbox" name="choice1" id="choice1" onChange={e => checksetShareProperty(e.target.checked)}/>
                          <label className="views-choice" for="choice1">Share on Social Media?</label>
                      </span>
                      <span>
                          <input type="checkbox" name="choice2" id="choice2"  onChange={e => checkNotifyinsetor(e.target.checked)} /> 
                          <label className="views-choice" for="choice2">Notify this to potential investors</label>
                      </span>
                  </div>
                </div>
                <div className="col-12 text-right">
                  <button type="submit" className="btn accept-btn">Deal</button>
                </div>
                </form>
                
                  
              } 
</>
              )}   
         
              </div>
            
            </div>

            </>
}     
  
            <div className="col-11 m-auto p-0 notes">
              
              <h2>Notes Related to the property</h2>

              { notes && 
              <>
                
                {notes.map((item, index) => (
              <div className="note">
                <h6 className="note-name">{item.author.first_name}</h6>
                <p>{item.note}</p>
                <span className="note-time">{moment(item.created_at ).format("DD MMMM YYYY ")}</span>
              </div>    
                ))}
                </>
          }

              <div className="notes-bottom">
                <form className="notes-form">
                  <input type="text"  value={message} onChange={e => setMessage(e.target.value)}/>
                  <button type="button" className="notes-form-icon"  onClick={handleSendNote} >
                    <RiSendPlaneFill />
                  </button>
                </form>
                <div class="cbox yes-no-container notes-checkbox">
                  <span>
                      <input type="checkbox" name="private" id="private"  defaultChecked={checked}
        onChange={() => setChecked(!checked)} />
                      <label className="views-choice" for="private">Private</label>
                  </span>
                </div>
              </div>
            </div>

            {/* <div className="col-11 m-auto">
              <div className="row">
                <div className="col-lg-6 pl-0">
                  <h4 className="my-4">Interested Investors</h4>
                  <div className="investors-container">

                    <div className="investor">
                      <div className="investor-img">
                        <img src={Avatar} alt="" />
                        <div className="table-img-text">
                            Angela Jhonny <br /> <span className="text-secondary small">Brooklyn</span>
                        </div>
                      </div>
                      <div className="investor-btns">
                        <button className="btn reject-btn">Message</button>
                        <button className="btn accept-btn">Follow</button>
                      </div>
                    </div>

                    <div className="investor">
                      <div className="investor-img">
                        <img src={Avatar} alt="" />
                        <div className="table-img-text">
                            Angela Jhonny <br /> <span className="text-secondary small">Brooklyn</span>
                        </div>
                      </div>
                      <div className="investor-btns">
                        <button className="btn reject-btn">Message</button>
                        <button className="btn accept-btn">Follow</button>
                      </div>
                    </div>

                    <div className="investor">
                      <div className="investor-img">
                        <img src={Avatar} alt="" />
                        <div className="table-img-text">
                            Angela Jhonny <br /> <span className="text-secondary small">Brooklyn</span>
                        </div>
                      </div>
                      <div className="investor-btns">
                        <button className="btn reject-btn">Message</button>
                        <button className="btn accept-btn">Follow</button>
                      </div>
                    </div>

                    <div className="investor">
                      <div className="investor-img">
                        <img src={Avatar} alt="" />
                        <div className="table-img-text">
                            Angela Jhonny <br /> <span className="text-secondary small">Brooklyn</span>
                        </div>
                      </div>
                      <div className="investor-btns">
                        <button className="btn reject-btn">Message</button>
                        <button className="btn accept-btn">Follow</button>
                      </div>
                    </div>

                  </div>
                </div>
                
                <div className="col-lg-6 pr-0">
                  <h4 className="my-4">Recommended Contractors</h4>
                  <div className="investors-container">

                    <div className="investor">
                      <div className="investor-img">
                        <img src={Avatar} alt="" />
                        <div className="table-img-text">
                            Angela Jhonny <br /> <span className="text-secondary small">Brooklyn</span>
                        </div>
                      </div>
                      <div className="investor-btns">
                        <button className="btn reject-btn">Message</button>
                        <button className="btn accept-btn">Follow</button>
                      </div>
                    </div>

                    <div className="investor">
                      <div className="investor-img">
                        <img src={Avatar} alt="" />
                        <div className="table-img-text">
                            Angela Jhonny <br /> <span className="text-secondary small">Brooklyn</span>
                        </div>
                      </div>
                      <div className="investor-btns">
                        <button className="btn reject-btn">Message</button>
                        <button className="btn accept-btn">Follow</button>
                      </div>
                    </div>

                    <div className="investor">
                      <div className="investor-img">
                        <img src={Avatar} alt="" />
                        <div className="table-img-text">
                            Angela Jhonny <br /> <span className="text-secondary small">Brooklyn</span>
                        </div>
                      </div>
                      <div className="investor-btns">
                        <button className="btn reject-btn">Message</button>
                        <button className="btn accept-btn">Follow</button>
                      </div>
                    </div>

                    <div className="investor">
                      <div className="investor-img">
                        <img src={Avatar} alt="" />
                        <div className="table-img-text">
                            Angela Jhonny <br /> <span className="text-secondary small">Brooklyn</span>
                        </div>
                      </div>
                      <div className="investor-btns">
                        <button className="btn reject-btn">Message</button>
                        <button className="btn accept-btn">Follow</button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div> */}

          </div>
        </section>
      </>
    );
  }