import React, { useState } from 'react';
import { Link } from "react-router-dom";
import LeftSidebar from "../../layouts/leftSider";
import SettingSider from "../../layouts/settingSider";
import Cookies from 'js-cookie';
import Alert from "react-bootstrap/Alert";
import axios from 'axios';
import {Modal, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck , faBars  , faPlus } from '@fortawesome/free-solid-svg-icons'
async function profileUpdate(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/profile/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}


async function profilePasswordUpdate(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/profile/password/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}



export default function EditProfileContent({ onEdit }) {


  const [showLeftSideMenu, setShowLeftSideMenu] = useState(false);
  const handleCloseLeftSideMenu = () => setShowLeftSideMenu(false);
  const handleShowLeftSideMenu = () => setShowLeftSideMenu(true);
  const [old_password, setOldPassword] = useState();
  const [password, setPassword] = useState();
  const [password_confirmation, setConfirmPassword] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [url, setUrl] = useState();
  const [errorPasswordMessage, setErrorPasswordMessage] = useState('');
  const [successPasswordMessage, setSuccessPasswordMessage] = useState('');
  const [image, setImage] = useState('');
  const [frontfilename, setFrontfilename] = useState();
  const [item, setItem] = React.useState([]);
  const userToken = Cookies.get('token');
  const userid = Cookies.get('user_id');
	const [usStates, setUsStates] = useState(false);


  const profile_image = Cookies.get('profile_image');
 


  const handleSubmitpassword = async e => {
    e.preventDefault();
    setErrorPasswordMessage(null);
 
     
      const res = await profilePasswordUpdate({
        old_password,
        password,
        password_confirmation,
        userToken
      });
      if(res.success){
      //  sessionStorage.setItem("token", token.author.api_token);
      setSuccessPasswordMessage('Your Profile password has been updated successfully.');
      }else{
      
        setErrorPasswordMessage(res.errors);
      }

    }

      

    




    

    return (
      
        <section className="edit-profile-content row">
        <div  className='message-pages-top  w-100'>
            <div className="d-flex mx-4 mt-4 justify-content-between">

              <div className=" d-lg-none   ">
              <FontAwesomeIcon  onClick={handleShowLeftSideMenu} icon={faBars} />
                  
                </div>
            
            </div>

          </div>

        <div className="sidebar col-md-6 col-lg-2 col-0 d-none d-lg-block h-auto">  
            <LeftSidebar />
          </div>
     
          <div className="sidebar search-sidebar col-md-6 col-lg-2 col-0 d-none d-lg-block h-auto ">
            <SettingSider />
          </div>



          {/* <div  className='side-bar d-none d-lg-block h-auto'>
             <div className='' style={{     backgroundColor:' #f6f9fe' }}>   <LeftSidebar /></div>
             <div className='' style={{     backgroundColor:' #f6f9fe' }}><SettingSider /></div>

          </div> */}


         <Modal    dialogClassName='custom-dialog' show={showLeftSideMenu} onHide={handleCloseLeftSideMenu} size="lg"  >
					
					<Modal.Body>
					<div style={{     width:'25px' }} className="cursor-pointer bg-primary px-2 py-1 mb-2 " onClick={handleCloseLeftSideMenu}>x</div>
          
          <div className='row'>
          <div className="sidebar col-6  ">  
            <LeftSidebar />
          </div>
     
          <div className="sidebar search-sidebar col-6   ">
            <SettingSider />
          </div>
          </div>
         

					</Modal.Body>
					
				</Modal>

       
          <div className="edit-container ol-xsm-12 col-sm-12   col-md-12 col-lg-11 col-12">
         

            <form onSubmit={handleSubmitpassword}>
            {successPasswordMessage && (
            <Alert variant="success" >
                 {successPasswordMessage && (
                <p>  {successPasswordMessage} </p>
                )}
                </Alert>
              )}


          {errorPasswordMessage && (
            <Alert variant="danger" >
              {errorPasswordMessage.old_password && (
                <p>  {errorPasswordMessage.old_password} </p>
                )}
                {errorPasswordMessage.password && (
                <p>  {errorPasswordMessage.password} </p>
                )}
                   {errorPasswordMessage.password_confirmation && (
                <p>  {errorPasswordMessage.password_confirmation} </p>
                )}
                  
              
                 
                </Alert>

          )}

            <h3>Change Password </h3>
            <div className="row edit-form">
            <div className="signup-input col-md-6">
                  <label>Old Password</label>
                  <input type="password"  onChange={e => setOldPassword(e.target.value)} />
              </div>
              <div className="signup-input col-md-6">
                  <label>New Password (At least 8 characters)</label>
                  <input type="password"  onChange={e => setPassword(e.target.value)} />
              </div>
              <div className="signup-input col-md-6">
                  <label>Retype Password</label>
                  <input type="password"   onChange={e => setConfirmPassword(e.target.value)} />
              </div>
            </div>
            <div className="signup-submit">
              <button type="submit"  className="edit-btn">Update</button>
            </div>
            </form>
          </div>
        </section>
      
    );
  }