import { realtorsContent } from "../../utils/constant";
import ReactStars from "react-rating-stars-component";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { realtorDetails } from "../../routers/routePath";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
	locationSelectIcon,
	propertyTypeIcon,
	Avatar,
    propertylistingpagescreenshot,
    socialmediapagetoprightcornerscreenshot,
    socialmediapagetopscreenshot,
    kycvarificationscreenshot,
} from "../../utils/images";
import {Modal, Button , ProgressBar } from 'react-bootstrap';
import { useTour } from '@reactour/tour'
import React, { useState } from 'react';
import Cookies from 'js-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck } from '@fortawesome/free-solid-svg-icons'

export default function Content(props) {
	const ratingChanged = (newRating) => {

	};
    const { setIsOpen } = useTour()
    const [url, setUrl] = useState();
    const userToken = Cookies.get('token');
    const user_type = Cookies.get('user_type');
    const [personalInfo, setPersonalInfo] = useState(false);
    const [mobileAuthenication, setMobileAuthenication] = useState(false);
    const [emailVerifcation, setEmailVerifcation] = useState(true);
    const [kycVerifcation, setKycVerifcation] = useState(false);
    const [hasProperty, setHasProperty] = useState(false);
    const [packageSelection, setPackageSelection] = useState(true);
    const [questionnaire, setQuestionnaire] = useState(false);
    const [tourCompleted, setTourCompleted] = useState(false);
    React.useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'api/user/detail' , {
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin' : 'http://localhost:3000/',
            'Access-Control-Allow-Credentials' : 'true/',
            'Content-Type': 'application/json' ,
            'Authorization': 'Bearer '+userToken+'',
          },
        }
          )
          .then(results => results.json())
          .then(data => {
         
            console.log(data);

            if(data.user.first_name!='' 
            && data.user.first_name!=null
            && data.user.last_name!=''
            && data.user.last_name!=null 
            && data.user.company!=''
            && data.user.company!=null 
            && data.user.phone!=''
            && data.user.phone!=null 
            && data.user.county!=''
            && data.user.county!=null 
            && data.user.image!=''
            && data.user.image!=null 
            && data.user.location!=''
            && data.user.location!=null 
            && data.user.city!=''
            && data.user.city!=null 
            && data.user.state!=''
            && data.user.state!=null 
            && data.user.zipcode!=''
            && data.user.zipcode!=null 
            && data.user.years_in_business!=''
            && data.user.years_in_business!=null 
            ){
                setPersonalInfo(true);
            }

            if(data.user.isphonevarified==1){
                setMobileAuthenication(true);
            }

            if(data.user.kyc_required==1 ){
                if(data.user.kycsubmitted==1 ){
                    setKycVerifcation(true);
                }

            }else{
                setKycVerifcation(true);
            }

            if(data.user.property.length > 0){
                setHasProperty(true);
            }
            if(data.user.questions.length > 0){
                setQuestionnaire(true);
            }
            if(data.user.madetour==1){
                setTourCompleted(true);
            }

           

          });
      }, [url]); // <-- Have to pass in [] here!

var total = 0;
    if(personalInfo){
          total ++ 
    }
    if(mobileAuthenication){
        total ++ 
    }
    if(emailVerifcation){
        total ++
    }
    if(kycVerifcation){
        total ++
    }
    if(user_type=='seller'){
    if(hasProperty){
        total ++;
    }
}
    if(packageSelection){
        total ++;
    }
    if(questionnaire){
        total ++;
    }

    if(tourCompleted){
        total ++;
    }
   
    let now = 0;
    if(user_type=='seller'){

 now =  (total  * 100 /8 ).toFixed(0);
    }else{
         now =  (total  * 100 /7 ).toFixed(0);
    }
var vari = 'success';
if(now < 25){
    vari = 'danger';
}else if(now < 50){
    vari = 'warning';
}
else if(now < 75){
    vari = 'info';
}else{
    vari = 'success';
}

const progressInstance = <ProgressBar striped={true} animated={true}  variant={vari} now={now} label={`${now}%`} />;

const handleNewFun = (val) => {
    document.querySelector('.navbar-toggler').click();
   
  }
    // setIsOpen(true)
	return (
		<section className="listing-filters">
			<div className="container">
				<h2>Congratulations !!! </h2>
                <h3>You have completed all of the basic steps.</h3>
                    <br></br>
                   
        <h3>Here is your checklist to complete the profile and get started.   </h3>
                
            <div className="card p-3 mt-2 mb-4">
                <div className="card p-3 shadow  ">
                {progressInstance}
                </div>

                    <div className="row">

                   
                        <div className="col-md-d col-lg-6">
                        <ul className="checklist mt-3">
                        {(personalInfo)?
                        <li>
                            <div className="d-flex">
                            <FontAwesomeIcon className=" p-3 border border-success rounded-circle text-success" icon={faCircleCheck} />
                            <span className="mx-3 my-3">  Personal Information </span>
                            </div>
                           
                        </li>
                    :
                    <li className="">
                        <div className="d-flex">
                         <FontAwesomeIcon className=" p-3 border border-danger rounded-circle text-danger" icon={faXmark} />
                           <span className="mx-3 my-3">Personal Information  </span>
                           
                            <a href="/edit-profile"> <button  className="btn mb-2 mt-2 tutorialbtn " type="button">Submit</button> </a>
                           </div>
                        </li>

                    }
                        
                        {(packageSelection) ?
                          <li>
                            <div className="d-flex">
                          <FontAwesomeIcon className=" p-3 border border-success rounded-circle text-success" icon={faCircleCheck} /> 
                          <span className="mx-3 my-3"> Package Selection</span>
                          </div>
                      </li>
                        :

                        <li>
                            <div className="d-flex">
                            <FontAwesomeIcon className=" p-3 border border-danger rounded-circle text-danger" icon={faXmark} /> 
                            
                            <span className="mx-3 my-3"> Package Selection </span>
                            
                            <button  className="btn mb-2 mt-2 tutorialbtn " type="button">Submit</button>
                            </div>
                        </li>
                        }
                        {(questionnaire)?
                         <li>
                             <div className="d-flex">
                          <FontAwesomeIcon className=" p-3 border border-success rounded-circle text-success" icon={faCircleCheck} />  
                          
                          <span className="mx-3 my-3">Questionnaire</span>
                          </div>
                     </li>
                            :
                            <li>
                                <div className="d-flex">
                            <FontAwesomeIcon className=" p-3 border border-danger rounded-circle text-danger" icon={faXmark} />
                            <span className="mx-3 my-3">Questionnaire</span>
                               <a href="/questionnairs"> <button  className="btn mb-2 mt-2 tutorialbtn " type="button">Submit</button> </a>
                            </div>
                        </li>
                    }
                       
                        {(mobileAuthenication)?
                            <li>
                                <div className="d-flex">
                              <FontAwesomeIcon className=" p-3 border border-success rounded-circle text-success" icon={faCircleCheck} />
                              
                              <span className="mx-3 my-3"> Mobile Authentication</span>
                              </div>
                            </li>
                        :
                        <li>
                                <div className="d-flex">
                             <FontAwesomeIcon className=" p-3 border border-danger rounded-circle text-danger" icon={faXmark} />
                             <span className="mx-3 my-3"> Mobile Authentication </span>
                               <a href="/mobile-verification"> <button  className="btn mb-2 mt-2 tutorialbtn " type="button">Submit</button> </a>
                             </div>
                        </li>
                        }
                       
                      
                        
                    </ul>
                        </div>
                        <div className="col-md-d col-lg-6">
                        <ul className="checklist mt-3">
                        {(emailVerifcation)?
                        <li>
                            <div className="d-flex">
                          <FontAwesomeIcon className=" p-3 border border-success rounded-circle text-success" icon={faCircleCheck} />
                          <span className="mx-3 my-3"> Email Verification</span>
                          </div>
                        </li>
                        :
                            <li>
                                <div className="d-flex">
                          <FontAwesomeIcon className=" p-3 border border-danger rounded-circle text-danger" icon={faXmark} /> 
                          <span className="mx-3 my-3">Email Verification</span> 
                          <a href="/edit-profile"> <button  className="btn mb-2 mt-2 tutorialbtn " type="button">Submit</button> </a>
                          </div>
                        </li>
                    }
                        
                        {(kycVerifcation)?
                         <li>
                            <div className="d-flex">
                             <FontAwesomeIcon className=" p-3 border border-success rounded-circle text-success" icon={faCircleCheck} />  <span className="mx-3 my-3"> KYC  Verification </span>
                             </div>
                         </li>
                    
                            :
                            <li>
                                <div className="d-flex">
                            <FontAwesomeIcon className="  p-3 border border-danger rounded-circle text-danger" icon={faXmark} />  <span className="mx-3 my-3">KYC Verification</span> <a href="/verification"> <button  className="btn mb-2 mt-2 tutorialbtn " type="button">Submit</button> </a>
                            </div>
                        </li>
                    }
                        

{(user_type=='seller') && 
<>
{(hasProperty)?
                        <li>
                           <div className="d-flex">
                             <FontAwesomeIcon className=" p-3 border border-success rounded-circle text-success" icon={faCircleCheck} />  <span className="mx-3 my-3"> Submit property
                             </span>
                             </div>
                             </li>
                            :
<li>
                           
<div className="d-flex">
                            <FontAwesomeIcon className="  p-3 border border-danger rounded-circle text-danger" icon={faXmark} />  <span className="mx-3 my-3">Submit property </span> <a href="/addProperty"> <button  className="btn mb-2 mt-2 tutorialbtn " type="button">Submit</button> </a>
                            </div>
                        </li>

                    }
</>

}
                       
                        
                        {(tourCompleted)?
                          <li>
                            <div className="d-none d-md-flex">
                           <FontAwesomeIcon className=" p-3 border border-success rounded-circle text-success" icon={faCircleCheck} /> 
                           <span className="mx-3 my-3">  Explore platform </span>
                           </div>
                         </li>
                            :
                                <li>
                                      <div className="d-none d-md-flex">
                            <FontAwesomeIcon className="  p-3 border border-danger rounded-circle text-danger" icon={faXmark} /> 
                            <span className="mx-3 my-3">    Explore platform  </span> <button className="btn mb-2 mt-2 tutorialbtn" type={'button'} h="4" onClick={() =>  setIsOpen(true) }>
                             <span className="mx-3 my-3">  Get Started </span>
                                </button>
                                </div>
                        </li>
                    }
                        
                    </ul>
                        </div>
                    </div>
                    
            </div>
			</div>
		</section>
	);
}
