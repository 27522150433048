import { latestNewsContent } from "../../utils/constant";
import React, { useState } from "react";
import VideoModal from "../../modal/videoModal";
import {
	newsPlayIcon,
  whyChooseUsBuildingImage,
} from "../../utils/images";
import { Video } from "../../utils/videos";

export default function LatestNews(props) {
  const [news, SetNews] = useState(props.news);
  const [modalShow, setModalShow] = React.useState(false);
  const [video, SetVideo] = useState('');
  const handleClickVideo = (video)=>{
    
    SetVideo(video);
    setModalShow(true);
  }
  React.useEffect(() => {
		SetNews(props.news);
	}, [props.news]); // <-- Have to pass in [] here!
  return (
    <section  className="latest-news-section">
      <div className="container">
        <h5 className="feature-title">News and Update</h5>
        <div className="row">
          {news.map((val, key) => (
            <div key={key} className="col-md-4">
              <div className="suitable-card">
                <div className="suitable-card-img news-card-top position-relative">
                
{(val.type=='video')? 
<>
  <div className="overlay-news-image"></div> 
                  <video width="100%" height="100%" controls>
            <source src={process.env.REACT_APP_IMAGES_URL + val.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
                  <a  onClick={() => handleClickVideo(process.env.REACT_APP_IMAGES_URL + val.video)} href="javascript:void(0)"
                   
                  >
                    <img   src={newsPlayIcon} alt="" />
                  </a> 
                  </>
:
<iframe width="330" height="200"
src={val.url}>
</iframe>
}
                 
                
                </div>
                <h3>{val.title}</h3>
                <p>{val.description.substring(0, 200)+"..."}</p>
              </div>
            </div>
          ))}
         
         <VideoModal source={video} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
        <div className="view-more-feed">
          {/* <button>View More</button> */}
        </div>
      </div>
    </section>
  );
}
