import {
  kitchenImage,
  houseTwoImage,
  houseView,
  parkView,
  diningView,
  locationTick
} from "../../utils/images";

export default function ContractorDetails(props) {
  return (
    <>
      <section className="contractor-details col-11">
        <div className="contractor-details-container">
          <h2 className="main-title-head mt-0">{props.details.first_name}</h2>
          <p>
            <img src={locationTick} alt="" />
            {props.details.location}

            { 
									<>
									{(props.details.location)? props.details.location +",":'USA ,'}
									{(props.details.city)?  props.details.city+",":null}
									{(props.details.state)?  props.details.state +",":null}
									{(props.details.zipcode)?  props.details.zipcode :null}
									</>
										}

          </p>
        </div>
      </section>
    </>
  );
}
