import {
  kitchenImage,
  houseTwoImage,
  houseView,
  parkView,
  diningView,
  locationTick,
  messagesDarkIcon,
  messagesLightIcon,
  User
} from "../../utils/images";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck , faBars ,faPenAlt , faPenSquare } from '@fortawesome/free-solid-svg-icons'
import ImageCrop from "../ImageCropFeed/imageCrop";
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import axios from 'axios';
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

async function profileGalleryUpdate(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/profile/upload-gallery', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}
export default function ProfileDetails(props) {
  const [image, setImage] = useState('');
  const profile_image = Cookies.get('profile_image');
  const userToken =  Cookies.get('token');
  const user_id =  Cookies.get('user_id');
  const [imagesCoverPhoto, setImagesCoverPhoto] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const handleChangeCoverPhoto =  e => {
    //setFiles(e.target.files)
   
    setShowLoader(true);
    const url = process.env.REACT_APP_API_URL+'api/uploadFile';
    const formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append(`files[${i}]`, e.target.files[i])
      }
        formData.append('directory' , 'profile');
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        axios.post(url, formData, config).then((response) => {
          let  path = [];
          let  realname = [];
            if(imagesCoverPhoto){
              path =	imagesCoverPhoto.path
              realname =	imagesCoverPhoto.realname
            }
            if(response.data.files.path){
              response.data.files.path.map(function(val, index){
                path = [...path ,val ];
              })
              response.data.files.realname.map(function(val, index){
            
                realname = [...realname ,val ];

              })
            }


            
            setImagesCoverPhoto({ 'path' : path ,  'realname'  : realname })

            const res =  profileGalleryUpdate({
    
              userToken,
              'coverPhoto' : JSON.stringify({ 'path' : path ,  'realname'  : realname })
              
            });
            if(res){
              
              setTimeout(
                function() {
                  window.location.reload();
                }, 2000);


              
              setShowLoader(false);
            }
        });

      };
  return (
    <>


      <section class="contractor-details contractor-details-socail col-12 d-flex justify-content-between" 
     
        style={{ backgroundImage: props.details.coverPhoto ? "url("+process.env.REACT_APP_IMAGES_URL + JSON.parse(props.details.coverPhoto).path[0]+")" :'' }}
      
       
       
       
       >
       
        <div  className={  `contractor-details-container user_border_${props.details.user_type}` }>
        <div className="d-flex justify-content-center" style={{ height:'60px' }}>
            

            {(user_id!=props.details.id)?
  <>
            {(props.details.image!='' && props.details.image!=null)?
                              <>
                                  <img  className={  `social-media-post-profile-image user_${props.details.user_type}` }  src={process.env.REACT_APP_IMAGES_URL + JSON.parse(props.details.image).path[0]} alt={props.details.user_type} title={props.details.user_type} style={{ marginBottom: '-18px' }}  />
                                  <div className="position-absolute mt-5">
                                    <span className={  `badge user_bg_${props.details.user_type}` } >{(props.details.user_type=='Real_Estate_Professional')?'REP':(props.details.user_type=='affiliates')?'Crypto User':props.details.user_type}</span>
                                  </div>
                                  </>
                                  :
                                  <>
                                  <img  className={  `social-media-post-profile-image user_${props.details.user_type}` }  src={User} alt={props.details.user_type}  title={props.details.user_type} style={{ marginBottom: '-18px' }}  />
                                  <div className="position-absolute mt-5">
                                  <span className={  `badge user_bg_${props.details.user_type}` } >{(props.details.user_type=='Real_Estate_Professional')?'REP':(props.details.user_type=='affiliates')?'Crypto User':props.details.user_type}</span>
                                  </div>
                                  </>
  
  
                                      }
                                </>
                                :
                                profile_image &&
                                <>
                                  <ImageCrop   profile_image={profile_image} setImage={setImage} details={props.details}/>
                                  <div className="position-absolute mt-5">
                                  <span className={  `badge user_bg_${props.details.user_type}` } >{(props.details.user_type=='Real_Estate_Professional')?'REP':(props.details.user_type=='affiliates')?'Crypto User':props.details.user_type}</span>
                                  </div>
                                  </>
                                  
                                  
                          }
                                      
  
                             
                                     
  
                                      </div>
          <h2 className="main-title-head mt-3">
            <div>
              {props.details.first_name}   {props.details.last_name} 
            </div>
           
          </h2>
         
          <p>
            <img src={locationTick} alt="" />
            {props.details.state} ,  {props.details.city} 
          </p>
        </div>

        {(user_id==props.details.id)?
        <div className="ml-5" >
        <div className="col-md-12  file-field file-field-image-container-cover" >
           <input type="file" accept="image/*"  onChange={handleChangeCoverPhoto}/>
           <FontAwesomeIcon  icon={faPenSquare} style={{ fontSize: '20px'}} /> Edit Cover Photo
        </div>

        

        
        </div>
        :
        <NavLink to="/messages" activeClassName="active">

       <button className="btn details-img-btn m-2"><img style={{ width: '20px' }} className="dark-icon" src={messagesLightIcon} alt="" /></button> 
     
     
      </NavLink>
}
       
      </section>
    </>
  );
}
