import React, { useState } from "react";
//import { feedCardContent } from "../../utils/constant";
import ReactStars from "react-rating-stars-component";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { detail } from "../../routers/routePath";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "react-js-pagination";

import {
	chooseUsIconOne,
	chooseUsIconThree,
	chooseUsIconTwo,
	featureElectricImage,
	featureManImage,
	feedcardBathImage,
	feedcardBedImage,
	feedcardImage,
	feedcardSpaceImage,
	newsPlayIcon,
	suitablecardImageFour,
	suitablecardImageOne,
	suitablecardImageThree,
	suitablecardImageTwo,
	testimonialClientImage,
	testimonialQuoteIcon,
} from "../../utils/images";
import {currencyFormat} from "../../utils/functions";

export default function FiltersResults(props) {
	const ratingChanged = (newRating) => {
		
	};




	return (
		<section className="filter-results">
			<div className="container">
				<h2>Properties </h2> 
				{/* <h6 className="filter-description">
					11700 washington street, new york, USA
				</h6> */}
				<div className="row">

					{
					(props.feedCardContent.length > 0)?
					
					props.feedCardContent.map((val, key) => (
						<div key={key} className="col-md-6 mt-4">
							<div className="feed-card">
								<div className="feed-card-img">
									{(val.images!=null && val.images!="")?
									<img
									src={process.env.REACT_APP_IMAGES_URL + val.images.path[0]}
									alt=""
								/>
									:
										<img src={feedcardImage} alt="" />
								}
									


									{/* <span>rent</span> */}
								</div>
								<div className="feed-card-description">
								
									<h4>
										<Link to={"/property/" + val.slug + " "}>
											{val.title}
										</Link>
									</h4>
									<h5 className="reducedescription">
									{val.about}
									</h5>
									<p className="pt-2">Price: {val.demand} USD</p>
									{/* <div className="feed-rating mb-3">
										<ReactStars
											count={5}
											onChange={ratingChanged}
											size={24}
											activeColor="#d7524d"
										/>
									</div> */}
									<div className="feed-card-info">
										<div>
											<span>
												<img src={feedcardBedImage} alt="BedIcon" />
											</span>
											<span>{val.beds}</span>
										</div>
										<div>
											<span>
												<img src={feedcardSpaceImage} alt="" />
											</span>
											<span>{val.size} sq ft</span>
										</div>
										<div>
											<span>
												<img src={feedcardBathImage} alt="" />
											</span>
											<span>{val.baths}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					))
				
				:
				<p>Thank you for your interest, USHX will have thousands of properties on our platform but for now, please fill out a request of a zip code and we will match you with one of our realtors and prequalify you with our mortgage team</p>
				}
				</div>
				<div className="result-pagination mt-5">
					<Pagination
						activePage={
							props.feedCardContentmeta
								? props.feedCardContentmeta.current_page
								: 0
						}
						itemsCountPerPage={
							props.feedCardContentmeta ? props.feedCardContentmeta.per_page : 0
						}
						totalItemsCount={
							props.feedCardContentmeta.total ? props.feedCardContentmeta.total : 0
						}
						onChange={(pageNumber) => {
							props.setPageNumber(pageNumber);
							props.setSetReloadData(pageNumber);
						}}
						pageRangeDisplayed={8}
						itemClass="page-item"
						linkClass="page-link"
						firstPageText="<<"
						lastPageText=">>"
					/>

					{/* <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end">
              <li className="page-item disabled">
                <a className="page-link" href="#" tabindex="-1">
                  {" "}
                  <FaChevronLeft />
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  {" "}
                  <FaChevronRight />{" "}
                </a>
              </li>
            </ul>
          </nav> */}
				</div>
			</div>
		</section>
	);
}
