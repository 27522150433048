import React, { useState } from "react";
import SimpleMap from "../../components/detail/addressSection";
import Apartment from "../../components/detail/apartmentSection";
import ApartmentCard from "../../components/detail/appartmentCard";
import DetailBanner from "../../components/detail/detailbanner";
import Discription from "../../components/detail/discriptionSection";
import Gallery from "../../components/detail/gallerySection";
import GrandFamily from "../../components/detail/grandfamilySection";
import PropertyMain from "../../components/detail/propertySection";
import SpecialFeature from "../../components/detail/specialFeatureSection";
import SaleAgent from "../../components/detail/saleagentSection";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import Views from "../../components/detail/views";
import Cookies from 'js-cookie';
import { useHistory ,useLocation } from 'react-router-dom';

export default function Detail() {
	const [details, setDetails] = useState([]);
	
	const [item, setItem]  = useState('');
	const search = window.location.search;
	const history = useHistory()

	const user_package = Cookies.get("package");


	const slug =history.location.pathname.replace('/property/','')
	const [author, setAuthor] = useState(0);
	const userToken = Cookies.get('token');
	const fetchData = async () => {

		if(userToken){
			fetch(process.env.REACT_APP_API_URL + `api/properties/${slug}/show`, {
				method: "GET",
				headers: {
					"Access-Control-Allow-Origin": "http://localhost:3000/",
					"Access-Control-Allow-Credentials": "true",
					"Content-Type": "application/json",
					'Authorization': 'Bearer '+userToken+'',
				},
			})
				.then((results) => results.json())
				.then((data) => {
					if (data) {
						setDetails(data.data);
						setAuthor(data.data.user.id);
					}
				});
		}else{
			fetch(process.env.REACT_APP_API_URL + `api/properties/${slug}/showpublic`, {
				method: "GET",
				headers: {
					"Access-Control-Allow-Origin": "http://localhost:3000/",
					"Content-Type": "application/json",
				},
			})
				.then((results) => results.json())
				.then((data) => {
					if (data) {
						setDetails(data.data);
						setAuthor(data.data.user.id);
					}
				});
		}
		


	};

	React.useEffect(() => {
		fetchData();
	}, [item]); // <-- Have to pass in [] here!

	const handleEdit = (slug) => {
		setItem(slug);
	  };
	return (
		<>
			 {/* <Header /> */}
			<DetailBanner images={details.images} />
			<GrandFamily zipcode={details.zipcode}    city={details.city}  state={details.state}  title={details.title} />
			
			{/* <PropertyMain title={"Property Overview"} /> */}
			{/* <SpecialFeature title={"Special Features"} /> */}
			{/* <SpecialFeature title={"Bedrooms"} /> */}

           {(userToken)?
				<>
						<Discription about={details.about} title={"Discription"} />
						<SpecialFeature amenities={details.amenities} title={"Amenities"} />
						</>
						:

						<div className="  row mx-lg-5 px-lg-4 mx-md-4 px-md-3 " >
							<div className="  col-md-6">
								<Discription  about={details.about} title={"Discription"} />
								<SpecialFeature amenities={details.amenities} title={"Amenities"} />
							</div>
							<div className=" col-md-6">
							<section className="choose-us-section ">
							<div className="container">
								<h2>
								<span>What are we</span> offering
								</h2>
								<div className="row">
								<div className="col-md-12">
									<div className="choose-us-content choose-us-content-property-details">
									<h4>US Housing Exchange</h4>
									<h5>
									A private site for individual buyers, sellers, investors, contractors and crypto users to engage with one another and have properties listed for free as an alternative to traditional listing methods.  This site’s purpose is to take back “The American Dream” of homeownership for the average American from large corporate and institutional buyers!
									</h5>
									</div>
								</div>
								<div className="col-md-12">
									<div className="choose-us-content choose-us-content-property-details">
									<h4>Master Classes</h4>
									<h5>
									Within our social media platform there will be experts that will offer classes based on their expertise, sign up and enjoy. 
									</h5>
									</div>
								</div>
								<div className="col-md-12">
									<div className="choose-us-content choose-us-content-property-details">
									<h4>NFTs </h4>
									<h5>
									Buy our NFTs as we fight to end homelessness, find out how you can adopt a veteran, thru the purchase of our cryptocurrency platform
									</h5>
									</div>
								</div>
								</div>
							</div>
							</section>
							</div>
						</div>
			}

		
{(!userToken)?
<section className="call-to-action m-2">
      <div className="container">
        <div className="row call-to-action-main">
          <div className="col-md-6">
            <div className="call-to-action-content">
              <h2>Join Us</h2>
              <p>Join our top social media platform and start connecting with people!</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="call-subscribe">
            <a href='/signup' > <button type='button'>Join Now</button></a>
              
            


            </div>
          </div>
        </div>
      </div>
    </section>
	:
	<section className="call-to-action m-2">
      <div className="container">
        <div className="row call-to-action-main">
          <div className="col-md-6">
            <div className="call-to-action-content">
              <h2>Contact Us</h2>
              <p>Contact us to know more about the property</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="call-subscribe">
            <a href='/messages' > <button type='button'>Chat</button></a>
              
            


            </div>
          </div>
        </div>
      </div>
    </section>
}


{(details.images)&&
	<Gallery images={details.images} title={"Gallery"} />
}
		


			<Apartment   onEdit={handleEdit} title={"Related properties"} detail={details.relatedProperties} />
			{/* <ApartmentCard /> */}
			{(userToken) &&
				<SimpleMap title={details.address} />
			}

			{(userToken  && user_package!='standard' ) && 

			<SaleAgent
			property={details.id}
			title={"Take a Tour of the property"}
			detail={details}
		/>
			}
			


			{/* <Footer /> */}
		</>
	);
}
