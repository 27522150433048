import { Link } from "react-router-dom";
import { eventImageOne, houseTwoImage, userLikeImage } from "../../utils/images";
import { NavDropdown } from "react-bootstrap";
import React, { useState } from "react";
import { detail  } from "../../routers/routePath";
export default function AssociatedProperties(props) {
  const [properties, setProperties] = useState(props.properties);

  React.useEffect(() => {
		setProperties(props.properties);
	}, [props.properties]); // <-- Have to pass in [] here!


  return (
    <div className="notification-sub">
      <div className="notification-card">
        <div className="notify-head">
          <h2>Associated properties</h2>
          <Link to="/listing">See All</Link>
        </div>


        {properties && 
          <>
                    {properties.map((value) => (
 <div className="event-notify">
 <div className="user-action-detail">
   <div className="notify-user">
   { (value.images!='' && value.images!=null)?
					<img className="associated-property-img" src={process.env.REACT_APP_IMAGES_URL + JSON.parse(value.images).path[0]}	alt=""/>
				:
					<img className="associated-property-img" src={houseTwoImage} alt="" />
				}


   </div>
   <div className="notify-user-action">
    
     <a
												href={detail + "?property=" + value.slug + " "}
											>
								 <h2>{value.title}</h2>
											</a>

     <h4>Starting From ${value.demand} USD</h4>
   </div>
 </div>
</div>
))}
</>
}

       



      </div>
    </div>
  );
}
