import LoginForm from "../../components/forgot/forgotForm";
import LoginLogo from "../../components/forgot/loginLogo";

export default function Forgot() {
  return (
    <>
      <section className="login-section">
        <LoginLogo />
        <LoginForm />
      </section>
    </>
  );
}
