import {
	addedPostImage,
	Avatar,
	commentIcon,
	LikeIcon,
	locationIcon,
	mapImage,
	photoIcon,
	postImageOne,
	postImageThree,
	postImageTwo,
	propertiesTypeIcon,
	shareIcon,
	submitCommentIcon,
	User,
	footerFbIcon,
	copyIcon,
	wattsapIcon,
  footerInstagramIcon,
  footerLinkedinIcon,
  footerTwitterIcon,
  googlePlayStoreImage,
  diamond,
	userLikeImage,
} from "../../utils/images";
import {Modal, Button} from 'react-bootstrap';
import {
	
	FacebookShareButton,
	FacebookIcon,
	LinkedinShareButton,
	LinkedinIcon,
	TwitterShareButton,
	TwitterIcon,
	WhatsappShareButton,
	WhatsappIcon,
  } from "react-share";


import React, { useState , useCallback } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { NavDropdown, Form } from "react-bootstrap";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { detail , profile } from "../../routers/routePath";
import Lightbox from 'react-image-lightbox';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck , faBars  , faPlus , faCheck, faHeart as fsHeart } from '@fortawesome/free-solid-svg-icons'
import {  faHeart  } from '@fortawesome/free-regular-svg-icons'
async function hidePost(credentials) {
	


	return fetch(process.env.REACT_APP_API_URL + "api/posts/hideforme", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "http://localhost:3000/",
			"Access-Control-Allow-Credentials": "true/",
			"Content-Type": "application/json",
			Authorization: "Bearer " + credentials.userToken + "",
		},
		body: JSON.stringify(credentials),
	}).then((data) => data.json());
}

async function deletePost(credentials) {
	return fetch(process.env.REACT_APP_API_URL + "api/posts/remove", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "http://localhost:3000/",
			"Access-Control-Allow-Credentials": "true/",
			"Content-Type": "application/json",
			Authorization: "Bearer " + credentials.userToken + "",
		},
		body: JSON.stringify(credentials),
	}).then((data) => data.json());
}


async function toogleLike(credentials) {
	return fetch(process.env.REACT_APP_API_URL + "api/posts/likeDislike", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "http://localhost:3000/",
			"Access-Control-Allow-Credentials": "true/",
			"Content-Type": "application/json",
			Authorization: "Bearer " + credentials.userToken + "",
		},
		body: JSON.stringify(credentials),
	}).then((data) => data.json());
}


async function addComment(credentials) {
	return fetch(process.env.REACT_APP_API_URL + "api/comment/store", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "http://localhost:3000/",
			"Access-Control-Allow-Credentials": "true/",
			"Content-Type": "application/json",
			Authorization: "Bearer " + credentials.userToken + "",
		},
		body: JSON.stringify(credentials),
	}).then((data) => data.json());
}


export default function Post(props) {
	const shareUrl = process.env.REACT_APP_URL+'post/'+props.postlink;
    const title = props.post.body;
	const [post, setPosts] = useState(props.post);
    const [comment, setComment] = useState('');
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [images, setImages] = useState([]);
	const [initCount, setInitCount] = useState(0);
	const [linktodetil, setLinktodetail] = useState('https://api.whatsapp.com/send?text='+process.env.REACT_APP_URL+'post?details='+props.postlink);
	
// 	if(props.post.body){
// setLinktodetail('https://api.whatsapp.com/send?text='+props.post.body);
// 	}
React.useEffect(() => {

if(post.images){
	setImages(post.images.path);

}

}, [post.images]); // <-- Have to pass in [] here!


const [current , setCurrent] = useState('');
const [prev , setPrev] = useState('');
const [next , setNext] = useState('');


	const handleClickImage = (e, count) => {
		e && e.preventDefault();
		setInitCount(count);
		setCurrent(process.env.REACT_APP_IMAGES_URL + images[count]);
		if(count > 0){
			setPrev(process.env.REACT_APP_IMAGES_URL + images[count-1]);
		}else{
			setPrev('');
		}

		if(count + 1 == images.length  ){
			
			setNext('');
		}else{
			
			setNext(process.env.REACT_APP_IMAGES_URL + images[count+1]);
			
		}
	// console.log('images.length' , images.length);
		// console.log('current' , current);
		// console.log('next' , next);
		// console.log('prev' , prev);
	   }

	   const handleNextImage = () => {
		
		
		setCurrent(process.env.REACT_APP_IMAGES_URL + images[initCount + 1]);
		
		
			
			if(images[initCount]){
			
				setPrev(process.env.REACT_APP_IMAGES_URL + images[initCount]);
			}else{
				
				setPrev('');
			}
			
		

		if(initCount +1 == images.length  ){
			setNext('');
		}else{
			if(images[initCount + 2]){
				setNext(process.env.REACT_APP_IMAGES_URL + images[initCount + 2]);
			}else{
				setNext('');
			}
			
		
		}
		setInitCount(initCount+1);

	   }

	   const handlePrevImage = () => {
	

		setCurrent(process.env.REACT_APP_IMAGES_URL + images[initCount - 1 ]);
		if(initCount > 0){
			if(images[initCount-2]){
				setPrev(process.env.REACT_APP_IMAGES_URL + images[initCount-2]);
			}else{
				setPrev('');
			}
			
		}else{
			setPrev('');
		}

		
			if(images[initCount]){
				setNext(process.env.REACT_APP_IMAGES_URL + images[initCount]);
			}else{
				setNext('');
			}
			
		setInitCount(initCount-1);
	   }
	 
	const   handleCloseModal = (e) => {
		e && e.preventDefault();
	 
		setCurrent('');
	   }
	
React.useEffect(() => {
	setLinktodetail('https://api.whatsapp.com/send?text=http://localhost:3000/post?details='+props.postlink);
	}, [post]); // <-- Have to pass in [] here!

	const userToken = Cookies.get("token");
	const userid = Cookies.get("user_id");
	const user_package = Cookies.get("package");
	
	const handleHideComments = useCallback(
        (id) => async () => {
        
			// var element = document.getElementById('comments_'+id);
			// element.classList.toggle("hidecomments");
    
// 			var top = document.getElementById( 'comments_'+id ).documentOffsetTop() - ( window.innerHeight / 2 );
// window.scrollTo( 0, top );

document.getElementById('comments_'+id).scrollIntoView({block: "center"});


      
        },
        [],
      )

    const handleHidepost = useCallback(
        (id) => async () => {
          const res = await hidePost({
            'id' : id,
            userToken
          } );
          
          if(res){
      
            document.getElementById('post_'+id).remove();
           
          }
      
        },
        [],
      )

	  const handleDeletepost = useCallback(
        (id) => async () => {
          const res = await deletePost({
            'id' : id,
            userToken
          } );
          
          if(res){
      
            document.getElementById('post_'+id).remove();
           
          }
      
        },
        [],
      )

      const handleLikePost =  async e => {
        e.preventDefault();
		let total = 0; 
		if(post.is_liked_by_me){
			 total = post.total_likes -  1;
			 setPosts({...post ,is_liked_by_me:0 ,total_likes: total   });
		}else{
			total = post.total_likes +  1;
			setPosts({...post ,is_liked_by_me:1 ,total_likes: total   });
		}
		

	
        
		const res = await toogleLike({
           
            'id' : post.id,
            userToken
          } );
          
          if(res){
           // setPosts(res.post[0]);
          }

      }

      const handleAddComment =  async e => {
        e.preventDefault();
        const res = await addComment({
            'comment' : comment,
            'post' : post.id,
            userToken
          } );
          
          if(res){
            setPosts(res.post[0]);
            setComment('');
          }

      }

    return (
        <div  id={"post_"+post.id} className="added-post">
               
							<div className="added-post-user-info">
								<div className="user-info">
								
								

									<div className="user-info-profile">
									
									<Link to={"/profile/" + post.author.slug + " "}
											>
												{(post.author.packageSelected=='founder')&&
								<div className="d-flex justify-content-end"><img src={diamond}  className="diamond-icon"/></div>
								}

                  {(post.author.image!='' && post.author.image!=null)?
       
                    <img  className={  `social-media-post-profile-image user_${post.author.user_type}` } src={process.env.REACT_APP_IMAGES_URL + JSON.parse(post.author.image).path[0]} alt="" title={post.author.user_type} />
                    :
                    <img src={User} alt="" />
                      }
					  </Link>
					
										

									</div>
									<div className="user-info-detail">
										<h2>
										<Link to={"/profile/" + post.author.slug + " "}>{post.author.first_name} {post.author.last_name.substring(0, 1)}.</Link>	
										</h2>
										<h5> {moment(post.created_at ).fromNow()  }</h5>
									</div>
								</div>

								<div className="added-post-options">
									<NavDropdown
										id="nav-dropdown-dark-example"
										title=""
										menuVariant="light"
									>
										{(userid==post.author.id)?

										<NavDropdown.Item href="#" onClick={handleDeletepost( post.id)}>Remove post</NavDropdown.Item>
										:
										<NavDropdown.Item href="#" onClick={handleHidepost( post.id)}>Report post</NavDropdown.Item>
										}

										
									</NavDropdown>
								</div>
							</div>
							<div className="added-post-description">
								<h3>
									{post.body}
									{post.property && (
										<div>
											<Link className="Ralewayfamily"
												to={"/property/" + post.property.slug + " "}
											>
												Property: {post.property.title}
											</Link>
										</div>
									)}
									{post.location && (
										<div>
											<span className="Ralewayfamily">Location: {post.location} </span>
										</div>
									)}
								</h3>
							</div>
							<div className="added-post-image-more-grid row">
								{post.images && (
									<>
									{(post.images.path.length==1)?


									<div className="col-md-12">
										{post.images.path.map((img , index) => (
											<img key={index} src={process.env.REACT_APP_IMAGES_URL +img}  onClick={ e => handleClickImage(e, index)} alt="" style={{ height: 300 }} />
										))}
									</div>
									:(post.images.path.length==2)?
									<div className="row col-md-12">
										{post.images.path.map((img , index) => (
											<div key={index}  className="col-md-6"> 
											<img src={process.env.REACT_APP_IMAGES_URL +img}  onClick={ e => handleClickImage(e, index)} alt="" style={{ height: 300 }} />
											</div>
										))}
									</div>
									:
										<>	
									<div className="col-md-6">
											<div className="pb-4">
												<img src={process.env.REACT_APP_IMAGES_URL +post.images.path[0]} alt="" onClick={ e => handleClickImage(e, 0)}  style={{ height: 150 }}  />
											</div>
											<div>
												<img src={process.env.REACT_APP_IMAGES_URL +post.images.path[1]} alt="" onClick={ e => handleClickImage(e,1)} style={{ height: 150 }} />
											</div>
									</div>
									<div className="col-md-6">
										<img src={process.env.REACT_APP_IMAGES_URL +post.images.path[2]} alt="2"  onClick={ e => handleClickImage(e, 2)}/>
									</div>

									<div className="row col-md-12 m-2">
										{post.images.path.map((img , index) => (
											(index > 2) &&
											<div key={index} className="col-md-3 pl-1 pr-1"> 
											<img  src={process.env.REACT_APP_IMAGES_URL +img}  onClick={ e => handleClickImage(e, index)} alt={index} style={{ height: 150 }} />
											</div>
										))}
									</div>




									</>
										}
									</>

								)}
							</div>
							{current &&
					<Lightbox
						mainSrc={current}
						nextSrc={next}
						prevSrc={prev}
						onMovePrevRequest={handlePrevImage}
						  onMoveNextRequest={handleNextImage}
						onCloseRequest={handleCloseModal}
						/>
				}
							{/* <div className="like-comment-info">
              
              
								<div className="total-likes">
                {(post.total_likes>0)?
									<Link to="/">
                  {post.likers.map((value,index) => 
                  (
                    <>
                {(value.image!='' && value.image!=null )? 
                 <span key={index}>
                  <img className="social-media-post-like-image" src={process.env.REACT_APP_IMAGES_URL + JSON.parse(value.image).path[0]} alt="" />
                  </span> 
                :
                <span>
                <img src={userLikeImage} alt="" />
              </span>
                }
                </>
                ))} 
									
										<span className="total-counts">+{post.total_likes}</span>
									</Link>
                  :null  
                }
								</div>

								
								
							</div> */}
							<div className="do-like-comment">
								<div className="do-like-innerdiv">
								

								<Link to="/"  onClick={ handleLikePost}>
									<span>
									
									{(post.is_liked_by_me)?
									<FontAwesomeIcon  className=" fontawosomiconcustom"  icon={fsHeart} />
									:
									<FontAwesomeIcon  className=" fontawosomiconcustom"  icon={faHeart} />
									}	
									</span>
									

									<span> {(post.total_likes>0)?
									post.total_likes:null }  
									{(post.total_likes>0)?' Likes' : ' Like'}</span>


								</Link>
								<a href="#!" onClick={handleHideComments( post.id)}>
									<span>
										<img src={commentIcon} alt="" />
									</span>
									<span> {(post.total_comments>0)?post.total_comments:null} Comment</span>
								</a>
								</div>
								<div>
								<a  href="#!" onClick={handleShow}>
									<span>
										<img src={shareIcon} alt="" />
									</span>
									<span>Share</span>
								</a>
								</div>
							</div>

	<Modal show={show} onHide={handleClose} size="md">
        <Modal.Header >
          <Modal.Title>Share on social media  </Modal.Title>
        </Modal.Header>
        <Modal.Body>
		<FacebookShareButton
            url={shareUrl}
            quote={title}
          >
             <img src={footerFbIcon} alt="icon" />
			
          </FacebookShareButton>

		  <TwitterShareButton
            url={shareUrl}
            title={title}
          >
            <img src={footerTwitterIcon} alt="icon" />
          </TwitterShareButton>

		  <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: "
          >
           
		   <img src={wattsapIcon} alt="icon" />
          </WhatsappShareButton>
		  <LinkedinShareButton url={shareUrl}  >
			
		  <img src={footerLinkedinIcon} alt="icon" />
          </LinkedinShareButton>

		  <button className="my-2 social-btn"
			onClick={() =>  navigator.clipboard.writeText(shareUrl)}
			>
			
			<img src={copyIcon} alt="icon" />
			
			</button>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
							
                            {(post.total_comments>0)?
							
                            <div  className="postcomments" id={"comments_"+post.id}>
                            {post.comments.map((value,index) => 
                                    (
										
                                        <div key={index} className="comment-list row">

                                        {(value.author.image!='' && value.author.image!=null )? 
                                            <div className=" col-sm-2 col-md-1 col-2 text-center">
                                          <Link to={"/profile/" + value.author.slug + " "} > <img  className={  `social-media-post-like-image user_${value.author.user_type}` } src={process.env.REACT_APP_IMAGES_URL + JSON.parse(value.author.image).path[0]} alt=""  title={value.author.user_type}/> </Link>
                                            </div> 
                                       :
                                            <div className=" col-sm-2 col-md-1 col-2 text-center">
                                            <Link to={"/profile/" + value.author.slug + " "} > <img className={  `social-media-post-like-image user_${value.author.user_type}` } src={Avatar} alt="" title={value.author.user_type} /> </Link>
                                            </div>

                                       }
                                        <div className=" col-sm-10 col-md-10 col-9 p-0">
                                        <span className="Ralewayfamily username"> <Link to={"/profile/" + value.author.slug + " "} > {value.author.first_name} {value.author.last_name.substring(0, 1)}.  </Link> </span> 
                                      
                                        <span className="comment-text Ralewayfamily"> 
                                        {value.comment}
                                        </span>
                                        </div>
                                        </div>
                                    ))}
                        
                           </div>
                            :null

                            }

							
							<div className="add-comment">
								<div className="comment-input">
									<input type="text" value={comment} onChange={e => setComment(e.target.value)} placeholder="Write a comment" />
								</div>
								<div className="submit-comment">
									<button type="button" onClick={handleAddComment}>
										<img src={submitCommentIcon} alt="" />
									</button>
								</div>
							</div>
							
							


						</div>
    )

}