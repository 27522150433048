import {
	benefitsImageFour,
	chooseUsIconOne,
	chooseUsIconThree,
	chooseUsIconTwo,
	featureElectricImage,
	featureManImage,
	feedcardBathImage,
	feedcardBedImage,
	feedcardImage,
	feedcardSpaceImage,
	newsPlayIcon,
	suitablecardImageFour,
	suitablecardImageOne,
	suitablecardImageThree,
	suitablecardImageTwo,
	testimonialClientImage,
	testimonialQuoteIcon,
	saveIcon,
	universityIcon,
	starIcon,
	clockIcon,
	Ushxtoken,
	BusinessMan,
} from "./images";

export const suitableCardContent = [
	{
		suitableCardImg: suitablecardImageOne,
		suitableCardHeading: "Buyers",
		suitableCardDesc:
			"Tired of paying rent and someone else's mortgage? Let us buy a house for you even if you have bad credit!",
	},
	{
		suitableCardImg: suitablecardImageTwo,
		suitableCardHeading: "Sellers",
		suitableCardDesc:
			"Need to find a buyer for your property? Register your property for free with us and get top sales price!",
	},
	{
		suitableCardImg: suitablecardImageThree,
		suitableCardHeading: "Real Estate Investor",
		suitableCardDesc:
			"Partner with us to utilize our innovated house flipping system!",
	},
	{
		suitableCardImg: suitablecardImageFour,
		suitableCardHeading: "Contractors",
		suitableCardDesc:
			"Utilize our revolutionary construction management system to earn consistent jobs through our USHX mobile app!",
	},
	{
		suitableCardImg: Ushxtoken,
		suitableCardHeading: "Crypto User",
		suitableCardDesc:
			"Utilize our revolutionary construction management system to earn consistent jobs through our USHX mobile app!",
	},
	{
		suitableCardImg: BusinessMan,
		suitableCardHeading: "Real Estate Professional",
		suitableCardDesc:
			"Utilize our revolutionary construction management system to earn consistent jobs through our USHX mobile app!",
	},


];

export const feedCardContent = [
	{
		feedCardImg: feedcardImage,
		feedCardTag: "Rent",
		feedCardStartPrice: "Starting From $1000",
		feedCardHeading: "Michal Jhone Home",
		feedCardSubHeading:
			"Utilize our revolutionary construction management system to earn consistent jobs through our USHX mobile app!",
		feedCardBedInfo: "2",
		feedCardBedIcon: feedcardBedImage,
		feedCardSpaceInfo: "2561 sq ft",
		feedCardSpaceIcon: feedcardSpaceImage,
		feedCardBathInfo: "2",
		feedCardBathIcon: feedcardBathImage,
	},
	{
		feedCardImg: feedcardImage,
		feedCardTag: "Rent",
		feedCardStartPrice: "Starting From $1000",
		feedCardHeading: "Michal Jhone Home",
		feedCardSubHeading:
			"Utilize our revolutionary construction management system to earn consistent jobs through our USHX mobile app!",
		feedCardBedInfo: "2",
		feedCardBedIcon: feedcardBedImage,
		feedCardSpaceInfo: "2561 sq ft",
		feedCardSpaceIcon: feedcardSpaceImage,
		feedCardBathInfo: "2",
		feedCardBathIcon: feedcardBathImage,
	},
	{
		feedCardImg: feedcardImage,
		feedCardTag: "Rent",
		feedCardStartPrice: "Starting From $1000",
		feedCardHeading: "Michal Jhone Home",
		feedCardSubHeading:
			"Utilize our revolutionary construction management system to earn consistent jobs through our USHX mobile app!",
		feedCardBedInfo: "2",
		feedCardBedIcon: feedcardBedImage,
		feedCardSpaceInfo: "2561 sq ft",
		feedCardSpaceIcon: feedcardSpaceImage,
		feedCardBathInfo: "2",
		feedCardBathIcon: feedcardBathImage,
	},
	{
		feedCardImg: feedcardImage,
		feedCardTag: "Rent",
		feedCardStartPrice: "Starting From $1000",
		feedCardHeading: "Michal Jhone Home",
		feedCardSubHeading:
			"Utilize our revolutionary construction management system to earn consistent jobs through our USHX mobile app!",
		feedCardBedInfo: "2",
		feedCardBedIcon: feedcardBedImage,
		feedCardSpaceInfo: "2561 sq ft",
		feedCardSpaceIcon: feedcardSpaceImage,
		feedCardBathInfo: "2",
		feedCardBathIcon: feedcardBathImage,
	},
];

export const realtorsContent = {
	feedCardImg: benefitsImageFour,
	feedCardTag: "Featured",
	feedCardStartPrice: "Starting From $1000",
	feedCardHeading: "Remax Edge",
	feedCardSubHeading:
		"Utilize our revolutionary construction management system to earn consistent jobs through our USHX mobile app!",
	feedCardBedInfo: "2",
	cardSaveIcon: saveIcon,
	cardUniversityIcon: universityIcon,
	cardStarIcon: starIcon,
	cardClockIcon: clockIcon,
	feedCardSpaceInfo: "2561 sq ft",
	feedCardSpaceIcon: feedcardSpaceImage,
	feedCardBathInfo: "2",
	feedCardBathIcon: feedcardBathImage,
};
// export const chooseUsContent = [
//   {
//     chooseUSIcon: chooseUsIconOne,
//     chooseUSHeading: "Social Media Platform",
//     chooseUSDesc:
//       "For buyers, investors, sellers, contractors, realtors to all have a meeting ground.",
//   },
//   {
//     chooseUSIcon: chooseUsIconTwo,
//     chooseUSHeading: "USHX Marketplace",
//     chooseUSDesc:
//       "Bring stakeholders together where actual deals will take place.",
//   },
//   {
//     chooseUSIcon: chooseUsIconThree,
//     chooseUSHeading: "USHX Token",
//     chooseUSDesc: "USHX token for the members of the Club.",
//   },
// ];

export const featuresContent = [
	{
		featuresIcon: featureElectricImage,
		featuresHeading: "USHX Members Club",
		featuresDesc: "See fixed prices before you book.",
	},
	{
		featuresIcon: featureManImage,
		featuresHeading: "USHX Marketplace",
		featuresDesc: "See fixed prices before you book.",
	},
	{
		featuresIcon: featureManImage,
		featuresHeading: "USHX Social Media Platform",
		featuresDesc: "See fixed prices before you book.",
	},
	{
		featuresIcon: featureElectricImage,
		featuresHeading: "USHX Token",
		featuresDesc: "See fixed prices before you book.",
	},
];

export const latestNewsContent = [
	{
		latestNewsImage: suitablecardImageOne,
		latestNewsIcon: newsPlayIcon,
		latestNewsHeading: "An Ugly Myspace Profile Will Sure",
		latestNewsDesc:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
	},
	{
		latestNewsImage: suitablecardImageTwo,
		latestNewsIcon: newsPlayIcon,
		latestNewsHeading: "An Ugly Myspace Profile Will Sure",
		latestNewsDesc:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
	},
	{
		latestNewsImage: suitablecardImageThree,
		latestNewsIcon: newsPlayIcon,
		latestNewsHeading: "An Ugly Myspace Profile Will Sure",
		latestNewsDesc:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
	},
];

export const testimonialContent = [
	{
		testimonialIcon: testimonialQuoteIcon,
		testimonialDesc:
			"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua",
		testimonialClientImage: testimonialClientImage,
		testimonialClientName: "Chuan Allen",
		testimonialClientLink: "@chuanAllen",
	},
	{
		testimonialIcon: testimonialQuoteIcon,
		latestNewsDesc:
			"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua",
		testimonialClientImage: testimonialClientImage,
		testimonialClientName: "Chuan Allen",
		testimonialClientLink: "@chuanAllen",
	},
	{
		testimonialIcon: testimonialQuoteIcon,
		latestNewsDesc:
			"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua",
		testimonialClientImage: testimonialClientImage,
		testimonialClientName: "Chuan Allen",
		testimonialClientLink: "@chuanAllen",
	},
	{
		testimonialIcon: testimonialQuoteIcon,
		latestNewsDesc:
			"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua",
		testimonialClientImage: testimonialClientImage,
		testimonialClientName: "Chuan Allen",
		testimonialClientLink: "@chuanAllen",
	},
];

export const apartmentCardContent = {
	feedCardImg: feedcardImage,
	feedCardTag: "Rent",
	feedCardStartPrice: "Starting From $1000",
	feedCardHeading: "Michal Jhone Home",
	feedCardBedInfo: "2",
	feedCardBedIcon: feedcardBedImage,
	feedCardSpaceInfo: "2561 sq ft",
	feedCardSpaceIcon: feedcardSpaceImage,
	feedCardBathInfo: "2",
	feedCardBathIcon: feedcardBathImage,
};
