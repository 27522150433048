
import ReferralContent from "../../components/admin/referral";
import Header from "../../layouts/header";

export default function Referrals() {
    return (
      <>
        <section className="referral">
        <div className="wrapper">
             {/* <Header /> */}
            <ReferralContent />
          </div>
        </section>
      </>
    );
  }