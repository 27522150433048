import React, { useState } from 'react';
import { Link } from "react-router-dom";
import LeftSidebar from "../../layouts/leftSider";
import SettingSider from "../../layouts/settingSider";
import Cookies from 'js-cookie';
import Alert from "react-bootstrap/Alert";
import axios from 'axios';
import ImageCrop from "../ImageCrop/imageCrop";
import {Modal, Button , ProgressBar } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MonthPicker } from '@mui/x-date-pickers/MonthPicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import {  toast } from 'react-toastify';
import {
  facebookIcon,
  linkdinIcon,
  Logo,
  LogoBlue,
  mailIcon,
  phoneIcon,
  twitterIcon,
  Avatar,
} from "../../utils/images";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck , faBars } from '@fortawesome/free-solid-svg-icons'
async function profileUpdate(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/profile/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}


async function profilePasswordUpdate(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/profile/password/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}



export default function EditProfileContent({ onEdit }) {
  // const [datetimeon, setValue] = React.useState(new Date());
  const profile_image = Cookies.get('profile_image');
 
  const [showLeftSideMenu, setShowLeftSideMenu] = useState(false);
  const handleCloseLeftSideMenu = () => setShowLeftSideMenu(false);
  const handleShowLeftSideMenu = () => setShowLeftSideMenu(true);
  const [first_name, setFirstName] = useState('');
  const [relation_status, setRelation_status] = useState('');
  const [dob, setDob] =React.useState(null);
  const monthDays = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29 , 30,31 ]
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [privacy, setPrivacy] = useState('');
  const [county, setCounty] = useState('');
  const [about, setAbout] = useState('');
  const [location, setLocation] = useState('');
  const [zipcode, setZipCode] = useState('');
  const [youtube, setYoutube] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [percentComplete, setPercentComplete] = useState("0%");
  const [old_password, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [password_confirmation, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [url, setUrl] = useState();
  const [errorPasswordMessage, setErrorPasswordMessage] = useState('');
  const [successPasswordMessage, setSuccessPasswordMessage] = useState('');
  const [image, setImage] = useState('');
  const [frontfilename, setFrontfilename] = useState();
  const [item, setItem] = React.useState([]);
  const userToken = Cookies.get('token');
  const userid = Cookies.get('user_id');
  const user_type = Cookies.get('user_type');
	const [usStates, setUsStates] = useState(false);
  const [cities, setCities] = useState(false);
  const [years_in_business, setYearsInBusiness] = useState('');

  const [imagesLogoPhoto, setImagesLogoPhoto] = useState();
  const [imagesBusinessPhoto, setImagesBusinessPhoto] = useState();
  const [imagesPhotoHavingFun, setImagesPhotoHavingFun] = useState();
  const [imagesFamily, setImagesFamily] = useState();
  const [imagesHobbies, setImagesHobbies] = useState();
  const [privateBusiness, setPrivateBusiness] = useState(false);
  const [privateFamily, setPrivateFamily] = useState(false);
  const [privateHavingFun, setPrivateHavingFun] = useState(false);
  const [privateHobbies, setPrivateHobbies] = useState(false);


  const [personalInfo, setPersonalInfo] = useState(false);
  const [mobileAuthenication, setMobileAuthenication] = useState(false);
  const [emailVerifcation, setEmailVerifcation] = useState(true);
  const [kycVerifcation, setKycVerifcation] = useState(false);
  const [hasProperty, setHasProperty] = useState(false);
  const [packageSelection, setPackageSelection] = useState(true);
  const [questionnaire, setQuestionnaire] = useState(false);
  const [tourCompleted, setTourCompleted] = useState(false);

  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;
    
    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
  };
  const normalizeInputDob = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;
    
    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 2) return currentValue;
      if (cvLength < 5) return `${(currentValue.slice(0, 2) > 12 )?'01':currentValue.slice(0, 2) }/${(currentValue.slice(2) > 31 )?'01':currentValue.slice(2)}`;
      return `${currentValue.slice(0, 2)}/${currentValue.slice(2, 4)}`;
    }
  };
const handlePhone = (val) => {
  setPhone(normalizeInput(val, phone));
  };
  const handleDob = (val) => {
    setDob(normalizeInputDob(val, dob));
    };

    const handleDay = (val) => {
     
      setDay(val);
      setDob(val+" "+month);
      };
      const handleMonth = (val) => {
     
        setMonth(val);
        setDob(day+" "+val);
        };

  const handleCities = (val) => {
   // console.log('handleCities' , usStates[val].cities);
    setCities(usStates[val].cities);
    };



  const handleChange =  e => {
    //setFiles(e.target.files)
    setShowLoader(true);
    setErrorMessage(null);
    const url = process.env.REACT_APP_API_URL+'api/uploadFile';
    const formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      setFrontfilename(e.target.files[i].name);
      formData.append(`files[${i}]`, e.target.files[i])
      }
        formData.append('directory' , 'profile');
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => {
            let percentComplete = progressEvent.loaded / progressEvent.total
            percentComplete = parseInt(percentComplete * 100);
            setPercentComplete(percentComplete+"%");
          //  updateProgress(percentComplete);
          }
        };
        axios.post(url, formData, config).then((response) => {
        
          setImage(JSON.stringify(response.data.files))
          setShowLoader(false);
        });

      };



      const handleRemoveImagesBusinessPhoto = (i) => { 
	
        const imgarrayrealname = imagesBusinessPhoto.realname;
        imgarrayrealname.splice(i, 1);
        const imgarraypath = imagesBusinessPhoto.path;
        imgarraypath.splice(i, 1);
        setImagesBusinessPhoto({ 'path' : imgarraypath ,  'realname'  : imgarrayrealname});
        
       }
       
       

       const handleRemovePhotoHavingFun = (i) => { 
	
        const imgarrayrealname = imagesPhotoHavingFun.realname;
        imgarrayrealname.splice(i, 1);
        const imgarraypath = imagesPhotoHavingFun.path;
        imgarraypath.splice(i, 1);
        setImagesPhotoHavingFun({ 'path' : imgarraypath ,  'realname'  : imgarrayrealname});
        
       } 

       const handleRemoveFamily = (i) => { 
	
        const imgarrayrealname = imagesFamily.realname;
        imgarrayrealname.splice(i, 1);
        const imgarraypath = imagesFamily.path;
        imgarraypath.splice(i, 1);
        setImagesFamily({ 'path' : imgarraypath ,  'realname'  : imgarrayrealname});
        
       } 

       const handleRemoveHobbies = (i) => { 
	
        const imgarrayrealname = imagesHobbies.realname;
        imgarrayrealname.splice(i, 1);
        const imgarraypath = imagesHobbies.path;
        imgarraypath.splice(i, 1);
        setImagesHobbies({ 'path' : imgarraypath ,  'realname'  : imgarrayrealname});
        
       } 


       const handleChangeHobbies =  e => {
        //setFiles(e.target.files)
        setErrorMessage(null);
        setSuccessMessage(null);
        setShowLoader(true);
        const url = process.env.REACT_APP_API_URL+'api/uploadFile';
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
          formData.append(`files[${i}]`, e.target.files[i])
          }
            formData.append('directory' , 'profile');
            const config = {
              headers: {
                'content-type': 'multipart/form-data',
              },
              onUploadProgress: progressEvent => {
                let percentComplete = progressEvent.loaded / progressEvent.total
                percentComplete = parseInt(percentComplete * 100);
                setPercentComplete(percentComplete+"%");
              //  updateProgress(percentComplete);
              }
            };
            axios.post(url, formData, config).then((response) => {
              let  path = [];
              let  realname = [];
                if(imagesHobbies){
                  path =	imagesHobbies.path
                  realname =	imagesHobbies.realname
                }
                if(response.data.files.path){
                  response.data.files.path.map(function(val, index){
                    path = [...path ,val ];
                  })
                  response.data.files.realname.map(function(val, index){
                
                    realname = [...realname ,val ];
  
                  })
                }
  
                setImagesHobbies({ 'path' : path ,  'realname'  : realname })
  
                setShowLoader(false);
            });
    
          };


       const handleChangeFamily =  e => {
        //setFiles(e.target.files)
        setErrorMessage(null);
        setSuccessMessage(null);
        setShowLoader(true);
        const url = process.env.REACT_APP_API_URL+'api/uploadFile';
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
          formData.append(`files[${i}]`, e.target.files[i])
          }
            formData.append('directory' , 'profile');
            const config = {
              headers: {
                'content-type': 'multipart/form-data',
              },
              onUploadProgress: progressEvent => {
                let percentComplete = progressEvent.loaded / progressEvent.total
                percentComplete = parseInt(percentComplete * 100);
                setPercentComplete(percentComplete+"%");
              //  updateProgress(percentComplete);
              }
            };
            axios.post(url, formData, config).then((response) => {
              let  path = [];
              let  realname = [];
                if(imagesFamily){
                  path =	imagesFamily.path
                  realname =	imagesFamily.realname
                }
                if(response.data.files.path){
                  response.data.files.path.map(function(val, index){
                    path = [...path ,val ];
                  })
                  response.data.files.realname.map(function(val, index){
                
                    realname = [...realname ,val ];
  
                  })
                }
  
                setImagesFamily({ 'path' : path ,  'realname'  : realname })
  
                setShowLoader(false);
            });
    
          };

       

       const handleChangePhotoHavingFun =  e => {
        //setFiles(e.target.files)
        setErrorMessage(null);
        setSuccessMessage(null);
        setShowLoader(true);
        const url = process.env.REACT_APP_API_URL+'api/uploadFile';
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
          formData.append(`files[${i}]`, e.target.files[i])
          }
            formData.append('directory' , 'profile');
            const config = {
              headers: {
                'content-type': 'multipart/form-data',
              },
              onUploadProgress: progressEvent => {
                let percentComplete = progressEvent.loaded / progressEvent.total
                percentComplete = parseInt(percentComplete * 100);
                setPercentComplete(percentComplete+"%");
              //  updateProgress(percentComplete);
              }
            };
            axios.post(url, formData, config).then((response) => {
              let  path = [];
              let  realname = [];
                if(imagesPhotoHavingFun){
                  path =	imagesPhotoHavingFun.path
                  realname =	imagesPhotoHavingFun.realname
                }
                if(response.data.files.path){
                  response.data.files.path.map(function(val, index){
                    path = [...path ,val ];
                  })
                  response.data.files.realname.map(function(val, index){
                
                    realname = [...realname ,val ];
  
                  })
                }
  
                setImagesPhotoHavingFun({ 'path' : path ,  'realname'  : realname })
  
                setShowLoader(false);
            });
    
          };


      const handleChangeImagesBusinessPhoto =  e => {
        //setFiles(e.target.files)
        setErrorMessage(null);
        setSuccessMessage(null);
        setShowLoader(true);
        const url = process.env.REACT_APP_API_URL+'api/uploadFile';
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
          formData.append(`files[${i}]`, e.target.files[i])
          }
            formData.append('directory' , 'profile');
            const config = {
              headers: {
                'content-type': 'multipart/form-data',
              },
              onUploadProgress: progressEvent => {
                let percentComplete = progressEvent.loaded / progressEvent.total
                percentComplete = parseInt(percentComplete * 100);
                setPercentComplete(percentComplete+"%");
              //  updateProgress(percentComplete);
              }
            };
            axios.post(url, formData, config).then((response) => {
              let  path = [];
              let  realname = [];
                if(imagesBusinessPhoto){
                  path =	imagesBusinessPhoto.path
                  realname =	imagesBusinessPhoto.realname
                }
                if(response.data.files.path){
                  response.data.files.path.map(function(val, index){
                    path = [...path ,val ];
                  })
                  response.data.files.realname.map(function(val, index){
                
                    realname = [...realname ,val ];
  
                  })
                }
  
                setImagesBusinessPhoto({ 'path' : path ,  'realname'  : realname })
  
                setShowLoader(false);
            });
    
          };


          const handleChangeImagesLogoPhoto =  e => {
            //setFiles(e.target.files)
            setErrorMessage(null);
            setSuccessMessage(null);
            setShowLoader(true);
            const url = process.env.REACT_APP_API_URL+'api/uploadFile';
            const formData = new FormData();
            for (let i = 0; i < e.target.files.length; i++) {
              formData.append(`files[${i}]`, e.target.files[i])
              }
                formData.append('directory' , 'profile');
                const config = {
                  headers: {
                    'content-type': 'multipart/form-data',
                  },
                  onUploadProgress: progressEvent => {
                    let percentComplete = progressEvent.loaded / progressEvent.total
                    percentComplete = parseInt(percentComplete * 100);
                    setPercentComplete(percentComplete+"%");
                  //  updateProgress(percentComplete);
                  }
                };
                axios.post(url, formData, config).then((response) => {
                  let  path = [];
                  let  realname = [];
                    // if(imagesLogoPhoto){
                    //   path =	imagesLogoPhoto.path
                    //   realname =	imagesLogoPhoto.realname
                    // }
                    if(response.data.files.path){
                      response.data.files.path.map(function(val, index){
                        path = [...path ,val ];
                      })
                      response.data.files.realname.map(function(val, index){
                    
                        realname = [...realname ,val ];
      
                      })
                    }
      
                    setImagesLogoPhoto({ 'path' : path ,  'realname'  : realname })
      
                    setShowLoader(false);
                });
        
              };



    const handleSubmit = async e => {
      e.preventDefault();
      setErrorMessage(null);
      let error = 0;
      if(phone.length !== 14){
        let checkerror = {phone:"Invalid phone format. ex: (555) 555-5555"};
        setErrorMessage(checkerror);
        error = 1;
      }
        if(!phone ){
      
          let checkerror = {phone:"Please Enter Phone"};
          setErrorMessage(checkerror);
          error = 1;
        }
        if(!error){
        const res = await profileUpdate({
          image,
          about,
          first_name,
          relation_status,
          dob,
          last_name,
          company,
          email,
          password,
          phone,
          password_confirmation,
          userToken,
          city,
          state,
          zipcode,
          youtube,
          location,
          county,
          privacy,
          years_in_business,
          'imagesLogoPhoto' : JSON.stringify(imagesLogoPhoto),
          'imagesBusinessPhoto' : JSON.stringify(imagesBusinessPhoto),
          'imagesPhotoHavingFun' : JSON.stringify(imagesPhotoHavingFun),
          'imagesFamily' : JSON.stringify(imagesFamily),
          'imagesHobbies' : JSON.stringify(imagesHobbies),
          privateBusiness,
          privateFamily,
          privateHavingFun,
          privateHobbies,

          
        });
        if(res.success){
          handleEdit(Date.now());
          if(image!=''){
            Cookies.set('profile_image',  JSON.parse(image).path[0])
            setImage('');
            setFrontfilename('');

            
          }
        //  sessionStorage.setItem("token", token.author.api_token);
        setSuccessMessage('Your Profile has been updated successfully.');
        toast.success("Your Profile has been updated successfully.");
        document.getElementById('edit-container').scrollIntoView({block: "center"});
        }else{
        
          setErrorMessage(res.errors);
          document.getElementById('edit-container').scrollIntoView({block: "center"});
        }
  
      }
      }

      React.useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'api/user/detail' , {
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin' : 'http://localhost:3000/',
            'Access-Control-Allow-Credentials' : 'true/',
            'Content-Type': 'application/json' ,
            'Authorization': 'Bearer '+userToken+'',
          },
        }
          )
          .then(results => results.json())
          .then(data => {
          if(data.status){
            // console.log('data.user' , data.user);

            setFirstName(data.user.first_name);
            setRelation_status(data.user.relation_status);
            setDob(data.user.dob);
            setLastName(data.user.last_name);
            setEmail(data.user.email);
            setCompany(data.user.company);
            setPhone(data.user.phone);
            setLocation(data.user.location);
            setCity(data.user.city);
            setPrivacy(data.user.privacy);
            setState(data.user.state);
            setCounty(data.user.county);
            setAbout(data.user.about);
            setZipCode(data.user.zipcode);
            setYoutube(data.user.youtube);
            setYearsInBusiness(data.user.years_in_business);
            // console.log("data.user.dob.split" , data.user.dob.split(" ")[0])
            if(data.user.dob!=''){
              setDay(data.user.dob.split(" ")[0]);
              setMonth(data.user.dob.split(" ")[1]);
            }
           
            // if(data.user.privateBusiness){
            //   setPrivateBusiness(true);
            // }else{
            //   setPrivateBusiness(false);
            // }
            setPrivateBusiness(data.user.privateBusiness);

            setPrivateFamily(data.user.privateFamily);
            setPrivateHavingFun(data.user.privateHavingFun);
            setPrivateHobbies("data.user.imagesBusinessPhoto" , data.user.privateHobbies);
            //console.log(data.user.imagesBusinessPhoto);
            if(data.user.imagesBusinessPhoto!=''){
              setImagesBusinessPhoto(JSON.parse(data.user.imagesBusinessPhoto));
            }
         
            if(data.user.imagesLogoPhoto!=''){
              setImagesLogoPhoto(JSON.parse(data.user.imagesLogoPhoto));
            }
            if(data.user.imagesFamily!=''){
              setImagesFamily(JSON.parse(data.user.imagesFamily));
            }
            if(data.user.imagesHobbies){
              setImagesHobbies(JSON.parse(data.user.imagesHobbies));
            }
            
            if(data.user.imagesPhotoHavingFun){
              setImagesPhotoHavingFun(JSON.parse(data.user.imagesPhotoHavingFun));
            }
            

            //console.log("imagesPhotoHavingFun" ,JSON.parse(data.user.imagesPhotoHavingFun) );

            if(data.user.first_name!='' 
            && data.user.first_name!=null
            && data.user.last_name!=''
            && data.user.last_name!=null 
            && data.user.company!=''
            && data.user.company!=null 
            && data.user.phone!=''
            && data.user.phone!=null 
            && data.user.county!=''
            && data.user.county!=null 
            && data.user.image!=''
            && data.user.image!=null 
            && data.user.location!=''
            && data.user.location!=null 
            && data.user.city!=''
            && data.user.city!=null 
            && data.user.state!=''
            && data.user.state!=null 
            && data.user.zipcode!=''
            && data.user.zipcode!=null 
            && data.user.youtube!=''
            && data.user.youtube!=null 
            && data.user.years_in_business!=''
            && data.user.years_in_business!=null 
            ){
                setPersonalInfo(true);
            }

            if(data.user.isphonevarified==1){
                setMobileAuthenication(true);
            }

            if(data.user.kyc_required==1 ){
                if(data.user.kycsubmitted==1 ){
                    setKycVerifcation(true);
                }

            }else{
                setKycVerifcation(true);
            }

            if(data.user.property.length > 0){
                setHasProperty(true);
            }
            if(data.user.questions.length > 0){
                setQuestionnaire(true);
            }
            if(data.user.madetour==1){
                setTourCompleted(true);
            }

          }
          });
      }, [url]); // <-- Have to pass in [] here!



      React.useEffect(() => {
        if(state!=''){
        fetch(
          process.env.REACT_APP_API_URL +
            `api/get/us/states`,
          {
            method: "GET",
            headers: {
              "Access-Control-Allow-Origin": "http://localhost:3000/",
              "Content-Type": "application/json", 
            },
          }
        )
          .then((results) => results.json())
          .then((data) => {

            setUsStates(data);
            data.map((a) => {
              //console.log(a.name +"=="+ state);
              if(a.name==state){
                setCities(a.cities);
              }
            });
            


          });
        }
      
    }, [state]); // <-- Have to pass in [] here!

  



    const handleEdit = (item) => {
      onEdit(item);
    };

    var total = 0;
    if(personalInfo){
          total ++ 
    }
    if(mobileAuthenication){
        total ++ 
    }
    if(emailVerifcation){
        total ++
    }
    if(kycVerifcation){
        total ++
    }
    if(user_type=='seller'){
    if(hasProperty){
        total ++;
    }
  }
    if(packageSelection){
        total ++;
    }
    if(questionnaire){
        total ++;
    }

    if(tourCompleted){
        total ++;
    }
   
    let now = 0;
    if(user_type=='seller'){

 now =  (total  * 100 /8 ).toFixed(0);
    }else{
         now =  (total  * 100 /7 ).toFixed(0);
    }
// var vari = 'success';
// if(now < 25){
//     vari = 'danger';
// }else if(now < 50){
//     vari = 'warning';
// }
// else if(now < 75){
//     vari = 'info';
// }else{
//     vari = 'success';
// }




    return (
      
        <section className="edit-profile-content-social row">
        
        {(showLoader)&& 
		<div id="loading">
      {(percentComplete)&&percentComplete}
			<div className="loader"></div>
 		</div>
		}

       




         <Modal    dialogClassName='custom-dialog' show={showLeftSideMenu} onHide={handleCloseLeftSideMenu} size="lg"  >
					
					<Modal.Body>
					<div style={{     width:'25px' }} className="cursor-pointer bg-primary px-2 py-1 mb-2 " onClick={handleCloseLeftSideMenu}>x</div>
          
          <div className='row'>
          <div className="sidebar col-6  ">  
            <LeftSidebar />
          </div>
     
          <div className="sidebar search-sidebar col-6   ">
            <SettingSider />
          </div>
          </div>
         

					</Modal.Body>
					
				</Modal>

       
          <div className="edit-container-social ol-xsm-12 col-sm-12   col-md-12 col-lg-12 col-12" >
          <form onSubmit={handleSubmit}>
          {successMessage && (
            <Alert variant="success" >
                 {successMessage && (
                <p>  {successMessage} </p>
                )}
                </Alert>
              )}
                
          {errorMessage && (
            <Alert variant="danger" >
                 {errorMessage.first_name && (
                <p>  {errorMessage.first_name} </p>
                )}
                {errorMessage.last_name && (
                <p>  {errorMessage.last_name} </p>
                )}
                {errorMessage.email && (
                <p>  {errorMessage.email} </p>
                )}
                
                     {errorMessage.phone && (
                <p>  {errorMessage.phone} </p>
                )}
                   {errorMessage.company && (
                <p>  {errorMessage.company} </p>
                )}
                  
              
                 
                </Alert>

          )}


{/* 
<div className="card p-3 shadow mt-2  mb-4 " id="edit-container">
                 <p className='text-center'> Your Profile is {now}% completed, Please click  <Link to="/congrates" > here </Link>  to complete your profile </p>
                </div> */}
            <h3>Edit Profile</h3>
         


            <div className="row edit-form">
         
          
           


              <div className="signup-input col-md-6 d-none">
                <label>First Name</label>
                <input type="text" value={first_name}  onChange={e => setFirstName(e.target.value)}  />
              </div>
              <div className="signup-input col-md-6 d-none">
                  <label>Last Name</label>
                  <input type="text"  value={last_name}   onChange={e => setLastName(e.target.value)} />
              </div>
              <div className="signup-input col-md-6 d-none">
                <label>Email</label>
                <input type="email"  value={email} onChange={e => setEmail(e.target.value)} />
              </div>
              <div className="signup-input col-md-3 ">
                  <label>Phone</label>
                  <input type="tel" pattern="\(\d{3}\)[ ]?\d{3}[-]?\d{4}" maxlength="14"  value={phone} onChange={e => handlePhone(e.target.value)}  placeholder="(xxx) xxx-xxxx"/>
              </div>
              <div className="signup-input col-md-3">
                  <label>Company</label>
                  <input type="text"  value={company} onChange={e => setCompany(e.target.value)}/>
              </div>

              <div className="signup-input col-md-3">
                  <label>Address</label>
                  <input type="text"  value={location} onChange={e => setLocation(e.target.value)}/>
              </div>

              <div className="signup-input col-md-3">
                <label>RelationShip Status</label>
                
                <select value={relation_status} name="" id=""  onChange={e => setRelation_status(e.target.value)} 
                                        >
              <option value="Single">Single</option>
              <option value="Married">Married</option>
              <option value="Divorced">Divorced</option>
              </select>
              </div>

              <div className="signup-input col-md-3">
                <label>Dob</label>
                {/* <input type="text"   value={dob} onChange={e => handleDob(e.target.value)}  placeholder="month/day"/> */}
                <div className='d-flex justify-content-center' >
                <select className='w-50 ml-1' value={day} onChange={e => handleDay(e.target.value)}>
                    {monthDays.map((day)=>(
                      <option>{day}</option>
                    ))
                    }
                  
                </select>
                <select className='w-50 ml-1' value={month} onChange={e => handleMonth(e.target.value)}>
                  <option>Jan</option>
                  <option>Feb</option>
                  <option>March</option>
                  <option>April</option>
                  <option>May</option>
                  <option>June</option>
                  <option>July</option>
                  <option>Aug</option>
                  <option>Sep</option>
                  <option>Oct</option>
                  <option>Nov</option>
                  <option>Dec</option>
                </select>
                </div>
                {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                {/* <DateTimePicker
                  views={['year']}
                  renderInput={(props) => <TextField {...props} />}
                  label="DatePicker"
                  value={dob}
                  onChange={(newValue) => {
                    setDob(newValue);
                  }}
                /> */}
                {/* <DatePicker
               views={['day','month' , 'year']}
                  label="DatePicker"
                  value={dob}
                  onChange={(newValue) => {
                    setDob(newValue);
                  }}
                  renderInput={(params) => <TextField className='dobdatepicker' {...params} helperText={null} />}
                /> */}

{/* <DatePicker
         views={['month']}
         label="Year"
         value={dob}
         disableFuture
         onChange={(newValue) => {
          setDob(newValue);
         }}
         renderInput={(params) => (
             <TextField
                 {...params}
                 helperText={null}
                 // Here the line:
                 inputProps={{
                    ...params.inputProps,
                    dob: dob === null ? '' : dob.getMonth()+1
                 }}
             />
         )}
      />  */}
              {/* </LocalizationProvider> */}


               
                {/* <input type="month" value={dob}  onChange={e => setDob(e.target.value)}  /> */}
              </div>
              <div className="signup-input col-md-3 d-none" >
                  <label>State</label>
                  <select value={state} name="" id=""  onChange={e =>  { setState(e.target.value)  ; handleCities(e.target.selectedIndex) } }
                                        >
              <option value="">State</option>
              {(usStates)&&  
              usStates.map((val, index) => (
                <option key={index}  data-id={index} value={val.name}>{val.name}</option>
              ))
     
              }
          
            </select>
              </div>

              <div className="signup-input col-md-6 d-none">
                  <label>City</label>
                  <select  value={city} onChange={e => setCity(e.target.value)}>
              {(cities)&&  
              cities.map((val, index) => (
                <>
                {(index==0)?
                  <>
                  <option key={'tes'}  value="" data-id="0">Select City</option>
                  </>
                  :null
                }
                

                
                <option key={index}  value={val.name} data-id={index}>{val.name}</option>
                </>
              ))
     
              }
              </select>
              </div>
              <div className="signup-input col-md-3 d-none">
                  <label>County</label>
                  <input type="text"  value={county} onChange={e => setCounty(e.target.value)}/>
              </div>
              <div className="signup-input col-md-3">
                  <label>Zip</label>
                  <input type="text"  value={zipcode} onChange={e => setZipCode(e.target.value)}/>
              </div>
              <div className="signup-input col-md-3">
                  <label>Years in Business</label>
                  <select  value={years_in_business} onChange={e => setYearsInBusiness(e.target.value)} >
                    <option>1</option>
                    <option>2</option>
                    <option>4</option>
                    <option>6</option>
                    <option>8</option>
                    <option>10</option>
                    <option>10+</option>
                  </select>
              </div>
              <div className="signup-input col-md-3">
                  <label>About </label>
                  <textarea rows="1"  value={about}  onChange={e => setAbout(e.target.value)} ></textarea>
              </div>
              <div className="signup-input col-md-6">
                  <label>Youtube   (Example : https://www.youtube.com/watch?v=uE-TADy-oN0) </label>
                  <input type="text"  value={youtube} onChange={e => setYoutube(e.target.value)}/>
              </div>
              {/* <div className="signup-input col-md-6">
                  <label>Privacy</label>
                  <select  value={privacy} onChange={e => setPrivacy(e.target.value)}>
                      <option value={'Public'}>Public</option>
                      <option value={'Private'}>Private</option>
                    </select>
              </div> */}
            
             
          
              
           
              {/* <div className="col-md-6 signup-input file-field">
             

                <input  key="front_file" type="file"  onChange={handleChange} />
                <h3>   {(profile_image!='null')?
                          <img className='profileEditImage'    src={process.env.REACT_APP_IMAGES_URL + profile_image} alt="" />
                        :
                        null
                        }    Profie Image <br /> Click  to upload</h3>
                <span>{frontfilename}</span>


              </div> */}


<div className=' col-12 p-3'>Let’s setup your profile picture</div>
 
{/* <div className='signup-input col-md-6'>
         
              <ImageCrop   profile_image={profile_image} setImage={setImage}/>
              </div> */}
            
            <div className="col-md-6 mb-3 signup-input file-field">
             
             <input  key="front_file" type="file"  onChange={handleChange} />
             <h3>     Profie Image  Click  to upload <br />
             <div className='profileuploadimage mx-2'>{(!image)?
                      (profile_image !='null' && profile_image !=null)?
                       <img className='rounded' width={75} height={50}    src={process.env.REACT_APP_IMAGES_URL + profile_image} alt="profile_image" />
                       :
                       <img className='rounded' width={75} height={50}    src={Avatar} alt="Avatar" />
                       
                     :
                     <img className='rounded'  width={75} height={50}    src={process.env.REACT_APP_IMAGES_URL +JSON.parse(image).path[0]} alt="real" />
                     }  </div>
            </h3>
            
          


           </div>

              <div className='col-md-6 mb-3'>
                <div className='chekboxdiveditprfile'>
                <input   type="checkbox"  checked={privateBusiness}
            onChange={() => setPrivateBusiness(!privateBusiness)} /> Private
                </div>
            
                  <div className=" file-field row  file-field-image-container">
            
            <input type="file" multiple onChange={handleChangeImagesBusinessPhoto}/>
            <h3>
         
              
            Business Photos <br /> Click  to upload</h3>
            </div>
<div className='row  view-images-container'>
              {
              
              (imagesBusinessPhoto) && imagesBusinessPhoto.path.map((img , index) => (
                <div className='col-4 col-md-4 col-lg-2 ' key={index} >
                  
                    
                    
                    <div className='text-center'>
                    <div  onClick={ ()=> handleRemoveImagesBusinessPhoto(index)} className="badge badge-danger closecustom text-center"  aria-hidden="true">&times;</div>
                    <div><img width="75" className='rounded' height="50" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 </div>
                </div>
                </div>
              
              ))}

          </div>
              </div>




              <div className='col-md-6 mb-3'>
                <div className='chekboxdiveditprfile'><input type="checkbox" checked={privateHavingFun}
            onChange={() => setPrivateHavingFun(!privateHavingFun)} /> Private
            </div>
             
            
                  <div className=" file-field row  file-field-image-container">
            
            <input type="file" multiple onChange={handleChangePhotoHavingFun}/>
            <h3>
         
              
            Photos having fun <br /> Click  to upload 
            </h3>
            </div>
<div className='row  view-images-container'>
              {
              
              (imagesPhotoHavingFun) && imagesPhotoHavingFun.path.map((img , index) => (
                <div className='col-4 col-md-4 col-lg-2 ' key={index} >
                  
                    
                    
                    <div className='text-center'>
                    <div  onClick={ ()=> handleRemovePhotoHavingFun(index)} className="badge badge-danger closecustom text-center"  aria-hidden="true">&times;</div>
                    <div><img width="75" className='rounded' height="50" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 </div>
                </div>
                </div>
              
              ))}

          </div>
              </div>




              <div className='col-md-6 mb-3'>
                <div className='chekboxdiveditprfile'>
                <input type="checkbox" checked={privateFamily}
            onChange={() => setPrivateFamily(!privateFamily)} /> Private
            </div>
             
            
                  <div className=" file-field row  file-field-image-container">
            
            <input type="file" multiple onChange={handleChangeFamily}/>
            <h3>
         
            Family Photos <br /> Click  to upload</h3>
            </div>
<div className='row  view-images-container'>
              {
              
              (imagesFamily) && imagesFamily.path.map((img , index) => (
                <div className='col-4 col-md-4 col-lg-2 ' key={index} >
                  
                    
                    
                    <div className='text-center'>
                    <div  onClick={ ()=> handleRemoveFamily(index)} className="badge badge-danger closecustom text-center"  aria-hidden="true">&times;</div>
                    <div><img width="75" className='rounded' height="50" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 </div>
                </div>
                </div>
              
              ))}

          </div>
              </div>




              <div className='col-md-6 mb-3'>
                <div className='chekboxdiveditprfile'>
                <input type="checkbox" checked={privateHobbies}
            onChange={() => setPrivateHobbies(!privateHobbies)}/> Private
            </div>
             
            
                  <div className=" file-field row  file-field-image-container">
            
            <input type="file" multiple onChange={handleChangeHobbies}/>
            <h3>
         
            Hobbies Photos <br /> Click  to upload</h3>
            </div>
<div className='row  view-images-container'>
              {
              
              (imagesHobbies) && imagesHobbies.path.map((img , index) => (
                <div className='col-4 col-md-4 col-lg-2 ' key={index} >
                  
                    
                    
                    <div className='text-center'>
                    <div  onClick={ ()=> handleRemoveHobbies(index)} className="badge badge-danger closecustom text-center"  aria-hidden="true">&times;</div>
                    <div><img width="75" className='rounded' height="50" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 </div>
                </div>
                </div>
              
              ))}

          </div>
              </div>




              {(user_type=='Real_Estate_Professional')&&
           
           <>
           

           <div className='col-md-6 mb-3'>
              
             
            
                  <div className=" file-field row  file-field-image-container">
            
            <input type="file" multiple onChange={handleChangeImagesLogoPhoto}/>
            <h3>
         
            Do you have logo? <br /> Click  to upload</h3>
            </div>
<div className='row  view-images-container'>
              {
              
              (imagesLogoPhoto) && imagesLogoPhoto.path.map((img , index) => (
                <div className='col-4 col-md-4 col-lg-2 ' key={index} >
                  
                    
                    
                    <div className='text-center'>
                    <div  onClick={ ()=> handleRemoveHobbies(index)} className="badge badge-danger closecustom text-center"  aria-hidden="true">&times;</div>
                    <div><img width="75" className='rounded' height="50" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 </div>
                </div>
                </div>
              
              ))}

          </div>
              </div>



            <div className="col-md-3"></div>
            <div className="col-md-3"></div>
            </>
              }
             
            </div>
            <div className="signup-submit">
              <button type="submit"  className="edit-btn">Save</button>
            </div>
            </form>

          </div>
        </section>
      
    );
  }