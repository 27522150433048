import { FaMapMarkerAlt } from "react-icons/fa";
import { propertyTypeIcon } from "../../utils/images";
import Cookies from 'js-cookie';
import React, { useState } from 'react';
export default function GrandFamily(props) {
    const userToken =  Cookies.get('token');
	const [follow , setFollow] = useState(false);
	const [followButton , setFollowButton] = useState(false);
    const [selectedSentRequest , setSelectedSentRequest] = useState('');
    const [unfollow , setUnfollow] = useState('');
    const user_package = Cookies.get("package");
    const user_id =  Cookies.get('user_id');
    React.useEffect(() => {
        if(unfollow!=''){
        fetch(process.env.REACT_APP_API_URL+'api/friends/unfriend' , {
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin' : 'http://localhost:3000/',
            'Access-Control-Allow-Credentials' : 'true/',
            'Content-Type': 'application/json' ,
            'Authorization': 'Bearer '+userToken+'',
          },
          body: JSON.stringify({'target':unfollow})
        }
          )
          .then(results => results.json())
          .then(data => {
           
            setFollow(false);
            
          });
        }
      }, [unfollow]); // <-- Have to pass in [] here!


    React.useEffect(() => {
		if(selectedSentRequest!=''){
        fetch(process.env.REACT_APP_API_URL+'api/friends/friendreq' , {
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin' : 'http://localhost:3000/',
            'Access-Control-Allow-Credentials' : 'true/',
            'Content-Type': 'application/json' ,
            'Authorization': 'Bearer '+userToken+'',
          },
          body: JSON.stringify({'id':selectedSentRequest})
        }
          )
          .then(results => results.json())
          .then(data => {
           
			setFollowButton(true);
          });
		}
      }, [selectedSentRequest ]); // <-- Have to pass in [] here!


	React.useEffect(() => {
		if(userToken && props.details){

        fetch(process.env.REACT_APP_API_URL+'api/friends/users' , {
          method: 'GET',
          headers: {
            'Access-Control-Allow-Origin' : 'http://localhost:3000/',
            'Access-Control-Allow-Credentials' : 'true/',
            'Content-Type': 'application/json' ,
            'Authorization': 'Bearer '+userToken+'',
          },
        } 
          )
          .then(results => results.json())
          .then(data => {
			
			if(data.requestsent){
				data.requestsent.map((friend) => { 
					if(friend.id==props.details.id){
						setFollow(true);
					}
				 })
		}
           
            //setFollowsYou(data.followsyou);
          });


          fetch(process.env.REACT_APP_API_URL+'api/follows/get' , {
            method: 'GET',
            headers: {
              'Access-Control-Allow-Origin' : 'http://localhost:3000/',
              'Access-Control-Allow-Credentials' : 'true/',
              'Content-Type': 'application/json' ,
              'Authorization': 'Bearer '+userToken+'',
            },
          }
            )
            .then(results => results.json())
            .then(data => {


              if(data.youfollow){
                data.youfollow.map((friend) => { 
                  if(friend.sender_id==props.details.id){
                    setFollow(true);
                  }
                 })
            }

            if(data.followsyou){
              data.followsyou.map((friend) => { 
                if(friend.receiver_id==props.details.id){
                  setFollow(true);
                }
               })
          }

            });



		}else{
			setFollow(true);
		}
      }, [props.details]); // <-- Have to pass in [] here!

	  React.useEffect(()=>{
		setFollowButton(follow);
	  },[follow])
	  React.useEffect(() => {
	}, [follow]);


    return (
        <section className="container">
            <div className="row">
                <div className="col-12 about-title">
                    <h2 className="main-title-head">{props.title}</h2>
                    {(user_id)?
						<>

                    {(followButton)?
                    <button   onClick={(e) => { setUnfollow(e.currentTarget.dataset.id) ; }   }  data-id={props.details.id} className="btn share-btn">Unfollow</button>
                    :
                    <>
                    {(user_package!='standard') &&
                    <button  onClick={(e) => { setSelectedSentRequest(e.currentTarget.dataset.id) ; }   }  data-id={props.details.id}  className="btn share-btn">Follow on social media</button>
                  }
                  </>
               }
    </>
:
					<>  <a href="/login"  className="btn share-btn">Follow </a> </>
					}
                </div>
                <div className="col-12 details-btns">
                <p className="location-check">{props.details.about}</p>
               </div>
            </div>
        </section>
    )
}