import Header from "../../layouts/header";
import EditProfileContent from "../../components/admin/document";
import React, { useState } from 'react';
export default function Document() {
  const [item, setItem] = React.useState([]);

  const handleEdit = (item) => {
  
    setItem(item);
  };


    return (
      <>
        <section className="edit-profile">
        <div className="wrapper">
            {/* <Header   /> */}
            <EditProfileContent onEdit={handleEdit} />
            </div>
        </section>
      </>
    );
  }