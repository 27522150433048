import { Link } from "react-router-dom";
import LeftSidebar from "../../layouts/leftSider";
import SettingSider from "../../layouts/settingSider";
import React, { useState , useEffect } from 'react';
import { detail } from "../../routers/routePath";
import Cookies from 'js-cookie';
import moment from 'moment';
import {Modal, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck , faBars } from '@fortawesome/free-solid-svg-icons'



export default function ActivityContent() {
  const [showLeftSideMenu, setShowLeftSideMenu] = useState(false);
  const handleCloseLeftSideMenu = () => setShowLeftSideMenu(false);
  const handleShowLeftSideMenu = () => setShowLeftSideMenu(true);
  const userToken = Cookies.get('token');
  const userid = Cookies.get('user_id');
  const [activity, setActivity] = useState([]);
  
  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+'api/user/activity/Log' , {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+userToken+'',
      },
    }
      )
      .then(results => results.json())
      .then(data => {
        setActivity(data.data);
      });
  }, []); // <-- Have to pass in [] here!


    return (
      <>
        <section className="edit-profile-content row">
        <div  className='message-pages-top  w-100'>
            <div className="d-flex mx-4 mt-4 justify-content-between">

              <div className=" d-lg-none   ">
                  <FontAwesomeIcon  onClick={handleShowLeftSideMenu} icon={faBars} />
                  
                </div>
            
            </div>

          </div>

        <div className="sidebar col-md-6 col-lg-2 col-0 d-none d-lg-block h-auto">  
            <LeftSidebar />
          </div>
     
          <div className="sidebar search-sidebar col-md-6 col-lg-2 col-0 d-none d-lg-block h-auto ">
            <SettingSider />
          </div>



          {/* <div  className='side-bar d-none d-lg-block h-auto'>
             <div className='' style={{     backgroundColor:' #f6f9fe' }}>   <LeftSidebar /></div>
             <div className='' style={{     backgroundColor:' #f6f9fe' }}><SettingSider /></div>

          </div> */}


         <Modal    dialogClassName='custom-dialog' show={showLeftSideMenu} onHide={handleCloseLeftSideMenu} size="lg"  >
					
					<Modal.Body>
					<div style={{     width:'25px' }} className="cursor-pointer bg-primary px-2 py-1 mb-2 " onClick={handleCloseLeftSideMenu}>x</div>
          
          <div className='row'>
          <div className="sidebar col-6  ">  
            <LeftSidebar />
          </div>
     
          <div className="sidebar search-sidebar col-6   ">
            <SettingSider />
          </div>
          </div>
         

					</Modal.Body>
					
				</Modal>

       
          <div className="edit-container ol-xsm-12 col-sm-12   col-md-12 col-lg-11 col-12">
            <h3>Activity Logs</h3>
            {activity.map((act , index) => 
               (
             
                <div key={index}>
                {(act.type=="App\\Notifications\\propertyNotification")?
                <div className="activity-msg col-12">
                <p>
                  
                  You have added property  <a href={detail + "?property=" + JSON.parse(act.data).property_slug + " "}>{JSON.parse(act.data).property_title}</a>
                   </p>
                <h5>Posted on: {moment(act.created_at ).format(process.env.REACT_APP_DATE_FORMAT)}</h5>
                </div>
                :(act.type=="App\\Notifications\\PostNotification")?
<div className="activity-msg col-12">
                <p className="reducedescriptionthreeclamp">
                  
                  You have added post   <a href={"post?details=" + JSON.parse(act.data).post_slug + " "}>Post</a>
                  <br></br>
                  {JSON.parse(act.data).post_body}
                   </p>
                <h5>Posted on: {moment(act.created_at ).format(process.env.REACT_APP_DATE_FORMAT)}</h5>
                </div>
                 :(act.type=="App\\Notifications\\OfferNotification")?
                 <div className="activity-msg col-12">
                                 <p>
                                   
                                   You have added a offer on a property   <a href={detail + "?property=" + JSON.parse(act.data).property_slug + " "}>{JSON.parse(act.data).property_title}</a>
                                    </p>
                                 <h5>Posted on: {moment(act.created_at ).format(process.env.REACT_APP_DATE_FORMAT)}</h5>
              </div>

                :null
                
              }
                
                </div>
            ))}

          </div>
        </section>
      </>
    );
  }