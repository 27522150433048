import React, { useState } from 'react';

import Alert from "react-bootstrap/Alert";
import { useHistory } from "react-router-dom";
import {
	addedPostImage,
	Avatar,
	commentIcon,
	LikeIcon,
	locationIcon,
	mapImage,
	photoIcon,
	postImageOne,
	postImageThree,
	postImageTwo,
	propertiesTypeIcon,
	shareIcon,
	submitCommentIcon,
	User,
	userLikeImage,
  suitablecardImageOne,
  suitablecardImageTwo,
  suitablecardImageThree,
  suitablecardImageFour,
  Ushxtoken,
  BusinessMan
} from "../../utils/images";

  export default function TypeForm(props) {
   

    return (
     
      <>
      <ol class="progtrckr">
    <li class="progtrckr-doing" value="0">
     <span> User Type</span>
    </li>
    
    <li class="progtrckr-todo" value="1">
      <span>Personal Information</span>
    </li>
    <li class="progtrckr-todo" value="2">
      <span>Package</span>
    </li>
    <li class="progtrckr-todo" value="3">
      <span>Additional Information</span>
    </li>
 
    </ol>
  <h3 className="signup-message">Please Select User Type </h3>
        {/* <div className="row">
       
           <div className="signup-input col-md-6">
              <label>Type of user</label>
              <select value={props.user_type} onChange={e =>  {props.onUserType(e.target.value)  } } className="form-select type-select" aria-label="Default select example"  onChange={e => props.onUserType(e.target.value)}>
                <option value="seller" selected>Seller</option>
                <option value="buyer">Buyer</option>
                <option value="investor">Real Estate Investor</option>
                <option value="contractor">Contractor</option>
                <option value="affiliates">Affiliates</option> 
                <option value="realtor">Real Estate Professional</option>
              </select>
          </div> 
        </div> */}

        <div className="row animatefadein">
          
          {(props.user_type=='seller')? 

              <div   onClick={e =>  {props.onUserType('seller')  } } className="col-lg-2  col-6  col-md-2">
              <div className="suitable-card user_border_seller  selected_type suitcable-card-mbl">
                <div className="suitable-card-img">
                  <img src={suitablecardImageOne} alt="Image" />
                </div>
                <h3>Seller</h3>
              </div>
              </div>
              :
              <div   onClick={e =>  {props.onUserType('seller')  } } className="  col-lg-2 col-6 col-md-2">
              <div className="suitable-card user_border_seller suitcable-card-mbl">
                <div className="suitable-card-img">
                  <img src={suitablecardImageOne} alt="Image" />
                </div>
                <h3>Seller</h3>
              </div>
              </div>
          
        }
            


            {(props.user_type=='buyer')? 

            <div  onClick={e =>  {props.onUserType('buyer')  } }  className="  col-6 col-lg-2 col-md-2">
              <div className="suitable-card  user_border_buyer selected_type suitcable-card-mbl">
                <div className="suitable-card-img">
                  <img src={suitablecardImageTwo} alt="Image" />
                </div>
                <h3>Buyer</h3>
              </div>
            </div>

            :
            <div  onClick={e =>  {props.onUserType('buyer')  } }  className="col-lg-2 col-6 col-md-2">
            <div className="suitable-card user_border_buyer suitcable-card-mbl">
              <div className="suitable-card-img">
                <img src={suitablecardImageTwo} alt="Image" />
              </div>
              <h3>Buyer</h3>
            </div>
          </div>

      }


{(props.user_type=='investor')? 

            <div   onClick={e =>  {props.onUserType('investor')  } } className=" col-6 col-lg-2 col-md-2">
              <div className="suitable-card user_border_investor selected_type suitcable-card-mbl">
                <div className="suitable-card-img">
                  <img src={suitablecardImageThree} alt="Image" />
                </div>
                <h3>Real Estate Investor</h3>
              </div>
            </div>

          :

          <div   onClick={e =>  {props.onUserType('investor')  } } className="col-lg-2 col-6 col-md-2">
          <div className="suitable-card user_border_investor suitcable-card-mbl">
            <div className="suitable-card-img">
              <img src={suitablecardImageThree} alt="Image" />
            </div>
            <h3>Real Estate Investor</h3>
          </div>
        </div>

    }


{(props.user_type=='contractor')? 


            <div   onClick={e =>  {props.onUserType('contractor')  } } className=" col-6 col-lg-2 col-md-2">
              <div className="suitable-card user_border_contractor selected_type suitcable-card-mbl">
                <div className="suitable-card-img">
                  <img src={suitablecardImageFour} alt="Image" />
                </div>
                <h3>Contractor</h3>
              </div>
            </div>

            :
            <div   onClick={e =>  {props.onUserType('contractor')  } } className=" col-lg-2 col-6 col-md-2">
            <div className="suitable-card  user_border_contractor suitcable-card-mbl">
              <div className="suitable-card-img">
                <img src={suitablecardImageFour} alt="Image" />
              </div>
              <h3>Contractor</h3>
            </div>
          </div>
  }

            
{(props.user_type=='affiliates')? 


            <div  onClick={e =>  {props.onUserType('affiliates')  } } className="  col-6 col-lg-2 col-md-2">
              <div className="suitable-card  user_border_affiliates selected_type suitcable-card-mbl">
                <div className="suitable-card-img">
                  <img src={Ushxtoken} alt="Image" />
                </div>
                <h3>Crypto User</h3>
              </div>
            </div>

          :

          <div  onClick={e =>  {props.onUserType('affiliates')  } } className="col-lg-2 col-6 col-md-2">
              <div className="suitable-card user_border_affiliates suitcable-card-mbl">
                <div className="suitable-card-img">
                  <img src={Ushxtoken} alt="Image" />
                </div>
                <h3>Crypto User</h3>
              </div>
            </div>

}

{(props.user_type=='Real_Estate_Professional')? 

            <div  onClick={e =>  {props.onUserType('Real_Estate_Professional')  } }  className=" col-6 col-lg-2 col-md-2">
              <div className="suitable-card  user_border_Real_Estate_Professional  selected_type suitcable-card-mbl">
                <div className="suitable-card-img">
                  <img src={BusinessMan} alt="Image" />
                </div>
                <h3>Real Estate Professional</h3>
              </div>
            </div>
              :

              <div  onClick={e =>  {props.onUserType('Real_Estate_Professional')  } }  className="col-lg-2 col-6 col-md-2">
              <div className="suitable-card user_border_Real_Estate_Professional  suitcable-card-mbl">
                <div className="suitable-card-img">
                  <img src={BusinessMan} alt="Image" />
                </div>
                <h3>Real Estate Professional</h3>
              </div>
            </div>

            }
        </div>

    
        
        </>
    );
  }
  