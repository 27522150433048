import { useState } from "react";
import {
  bathTypeIcon,
  bedTypeIcon,
  homeTypeIcon,
  locationSelectIcon,
  lotTypeIcon,
  priceTypeIcon,
  propertyTypeIcon,
  roomsTypeIcon,
  squareTypeIcon,
  viewLessIcon,
  viewMoreIcon,
} from "../../utils/images";

export default function ListingFilters(props) {

  const [searchToggle, setSearchToggle] = useState(true);

  const handleSearchToggleClick = () => {
    setSearchToggle(!searchToggle);
  };

  return (
    <section className="listing-filters">
      <div className="container">
        <h2>Listing</h2>
        <div
          className={`listing-main ${
            searchToggle ? "hide-content" : "show-content"
          }`}
        >
          <div className="listing-input">
            <span>
              <img src={propertyTypeIcon} alt="" />
            </span>
            <select name="" id="" onChange={(e) => {
                                      props.setPageNumber(1)
                                        props.setCategory(e.target.value )
                                        props.setSetReloadData(e.target.value);

                                    }}>
              <option value="">Select Category</option>
              <option value="House">House</option>
              <option value="Townhouse">Townhouse</option>
              <option value="Villa">Villa</option>
              <option value="Apartment">Apartment</option>
              <option value="Flat">Flat</option>
              <option value="Studio">Studio</option>
              <option value="Warehouse">Warehouse</option>
              <option value="Block Of Units">Block Of Units</option>
              <option value="Terrace">Terrace</option>
              <option value="Serviced Apartment">Serviced Apartment</option>
            </select>
          </div>
          <div className="listing-input">
            <span>
              <img src={locationSelectIcon} alt="" />
            </span>
            <select value={ props.location} name="" id="" onChange={(e) => {
                                      props.setPageNumber(1)
                                        props.setLocation(e.target.value )
                                        props.setSetReloadData(e.target.value) }}
                                        >
              <option value="">Location</option>
              {(props.usStates)&&  
              props.usStates.map((val, index) => (
                <option key={index} value={val.name}>{val.name}</option>
              ))
     
              }
          
            </select>
          </div>
          <div className="listing-input">
            <span>
              <img src={priceTypeIcon} alt="" />
            </span>
            <select name="" id="" onChange={(e) => {
                                      props.setPageNumber(1)
                                        props.setPrice(e.target.value )
                                        props.setSetReloadData(e.target.value);

                                    }}>
               <option value="">Price</option>
               <option value="< 5,000 - 20,000"> { '<'} $5,000 - $20,000</option>
                    <option value="20,000-100,000">$20,000-$100,000</option>
                    <option value="100,000-300,000">$100,000-$300,000</option>
                    <option value="300,000-500,000"> $300,000-$500,000</option>
                    <option value="500,000-750,000">$500,000-$750,000</option>
                    <option value="750,000-1M">$750,000-$1M</option>
                    <option value="1M-1.5M" >$1M-$1.5M</option>
                    <option value="1.5M-3M" >$1.5M-$3M</option>
                    <option value="3M-4.5M" >$3M-$4.5M</option>
                    <option value="4.5M-7M" >$4.5M-$7M</option>
                    <option value="7M-10M" >$7M-$10M</option>
                    <option value="> 10M" > { '>'} $10M  </option>
                   
               {/* {(props.priceRange)&&  
              props.priceRange.map((val, index) => (
                (props.priceRange[index+1])&& 
                <option  key={index} value={val+"-"+props.priceRange[index+1]}>Price {"$"+val+" - $"+props.priceRange[index+1]}</option>

              ))
     
              } */}


              



            </select>
          </div>
          {/* <div className="listing-input">
            <span>
              <img src={roomsTypeIcon} alt="" />
            </span>
            <select name="" id=""  onChange={(e) => {
                                      props.setPageNumber(1)
                                        props.setRooms(e.target.value )
                                        props.setSetReloadData(e.target.value);

                                    }}>
                <option value="">Select  Rooms </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </div> */}
          <div className="listing-input">
            <span>
              <img src={bedTypeIcon} alt="" />
            </span>
            <select name="" id=""  onChange={(e) => {
                                      props.setPageNumber(1)
                                        props.setBeds(e.target.value )
                                        props.setSetReloadData(e.target.value);

                                    }}>
                <option value="">Select Bedrooms</option>
                {/* {(props.bedRange)&&  
              props.bedRange.map((val, index) => (
                
                <option  key={index} value={val}>{val}</option>

              ))
     
              } */}
            <option   value='1'>1</option>
            <option   value='2'>2</option>
            <option   value='3'>3</option>
            <option   value='4'>4</option>
            <option   value='5'>5</option>
            <option   value='6'>6</option>
            <option   value='7'>7</option>
            <option   value='8'>8</option>
            <option   value='9'>9</option>
            <option   value='10'>10</option>
            <option   value='11'>11</option>
            <option   value='12'>12</option>
            <option   value='13'>13</option>
            <option   value='14'>14</option>
            <option   value='15'>15</option>
            <option   value='16+'>16 +</option>
            </select>
          </div>
          <div className="listing-input">
            <span>
              <img src={bathTypeIcon} alt="" />
            </span>
            <select name="" id=""  onChange={(e) => {
                                      props.setPageNumber(1)
                                        props.setBaths(e.target.value )
                                        props.setSetReloadData(e.target.value);

                                    }}>
                <option value="">Select Bathrooms</option>
                {(props.bathRange)&&  
              props.bathRange.map((val, index) => (
                
                <option  key={index} value={val}>{val}</option>

              ))
     
              }
            </select>
          </div>
        
        </div>
        <div className="view-more-content">
          <span onClick={handleSearchToggleClick}>
            View {searchToggle ? "More" : "Less"}
            <span>
              <img src={searchToggle ? viewMoreIcon : viewLessIcon} alt="" />
            </span>
          </span>
        </div>
      </div>
    </section>
  );
}
