import React, { useState , useEffect } from 'react';
import { Link } from "react-router-dom";
import { MdOutlineSend } from 'react-icons/md';
import SettingSider from "../../layouts/settingSider";
import LeftSidebar from "../../layouts/leftSider";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import useToken from '../App/useToken';
import Alert from "react-bootstrap/Alert";
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck , faBars  , faPlus , faCheck } from '@fortawesome/free-solid-svg-icons'
async function sendCode(credentials) {

    return fetch(process.env.REACT_APP_API_URL+'api/user/send/mobile/code', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Content-Type': 'application/json' ,
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
  }
  async function checkCode(credentials) {

    return fetch(process.env.REACT_APP_API_URL+'api/user/check/mobile/code', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
  }

  export default function Phone(props) {
    
    const { token, setToken } = useToken();
    const history = useHistory();
  
    if(!token) {
       history.push("/login");
    }
    const userToken = Cookies.get('token');
    const userid = Cookies.get('user_id');
    const user_type = Cookies.get('user_type');
    const  kyc_required =   Cookies.get('kyc_required');
    const [is_varified, setIsVarified] = useState(false);
    const isphonevarified = Cookies.get('isphonevarified');
    React.useEffect(() => {
        setIsVarified(isphonevarified);
    }, [isphonevarified]); // <-- Have to pass in [] here!

    console.log("isphonevarified" , isphonevarified);

  
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
   // const [phone, setPhone] = useState();

    const [code, setCode] = useState('');


    

    const handleSendCode = async e => {
        e.preventDefault();
        props.setErrorMessage(null);
        setErrorMessage(null);
        setSuccessMessage(null);
        let error = 0;
       
        if(props.phone.length !== 14){
          let checkerror = {phone:"Invalid phone format. ex: (555) 555-5555"};
          setErrorMessage(checkerror);
          error = 1;
        }
          if(!props.phone ){
        
            let checkerror = {phone:"Please Enter Phone"};
            setErrorMessage(checkerror);
            error = 1;
          }



          if(!error){
            const res = await sendCode({
              'phone' : props.phone,
              } );
              if(res.success){
                if(res.response!='error'){
                  setSuccessMessage('Code has been sent please check your phone.');
                }else{
                  setErrorMessage({phone:"Error while sending message."});
                }
              
              // setReloadBoard(Date.now());

              }else{
              
              setErrorMessage(res.errors);
              }
          }
      
  
        }
        React.useEffect(()  =>  {
      
        

          if(code!=undefined){

        
            if(   code.length < 4){
              return false;
            }
          }else{
            return false;
          }
          console.log("called" , code);
          
        
         
            setErrorMessage(null);
            props.setErrorMessage(null);
            setSuccessMessage(null);

            async function fetchMyAPI() {
            const res = await checkCode({
             'phone' :   props.phone,
                code,
                userToken
              } );
              if(res.success){
                if(res.response=='correct'){
                    setIsVarified(1);
                    Cookies.set('isphonevarified' , 1);
                    setSuccessMessage('Phone Verification completed.');
                    
                }else{
                    setErrorMessage({wrong:'Sorry you have entered wrong code'});
                }
                //setSuccessMessage('Code has been sent please check you phone.');
               // setReloadBoard(Date.now());
              }else{
              
               setErrorMessage(res.errors);
              }

            }

            fetchMyAPI()

          }, [code]); // <-- Have to pass in [] here!
   
    return (
    <>
   
{(user_type=='seller')?
<ol class="progtrckr">
<li class="progtrckr-done" value="0">
     <span> Basic Info</span>
    </li>
    <li class="progtrckr-done" value="1">
   <span> Interests</span>
  </li>
<li class="progtrckr-done" value="2">
     <span> Add Property</span>
    </li>
    <li class="progtrckr-doing" value="3">
     <span> Phone Verification</span>
    </li>
    {(kyc_required==1)&& 
    <li class="progtrckr-todo" value="4">
     <span>KYC Verification</span>
  </li>
    }
    </ol>
:
<ol class="progtrckr">
<li class="progtrckr-done" value="0">
     <span> Basic Info</span>
    </li>
    <li class="progtrckr-done" value="1">
   <span> Interests</span>
  </li>
    <li class="progtrckr-doing" value="2">
     <span> Phone Verification</span>
    </li>
    {(kyc_required==1)&& 
    <li class="progtrckr-todo" value="3">
     <span>KYC Verification</span>
  </li>
    }
    </ol>

}

    
  <h3 className="signup-message">Please enter the code sent to your phone number </h3>
        <div className="row animatefadein">
       
           {(is_varified==1)?
           <>

           <Alert variant="success" >
           
                   <p>Phone  number Verified  <FontAwesomeIcon   icon={faCheck} /></p>
          
           </Alert>
      </>
           :
           <>
          
           <div className="col-md-12">


                      {successMessage && (
                      <Alert variant="success" >
                          {successMessage && (
                          <p>  {successMessage} </p>
                          )}
                          </Alert>

                      )}

                      {errorMessage && (
                      <Alert variant="danger" >
                        {errorMessage.phone && (
                          <p>  {errorMessage.phone} </p>
                          )}

                          {errorMessage.code && (
                          <p>  {errorMessage.code} </p>
                          )}

                      {errorMessage.wrong && (
                              <p>  {errorMessage.wrong} </p>
                          )}
                          
                          </Alert>

                      )}
                      </div>
        
           <div className="row col-md-12 edit-form">
           
              <div className="signup-input col-md-6">
                <label>Phone</label>
                <input type="tel" pattern="\(\d{3}\)[ ]?\d{3}[-]?\d{4}" maxlength="14"  value={props.phone} onChange={e => props.setPhone(e.target.value)} placeholder="(xxx) xxx-xxxx"/>

              </div>
              <div className="signup-input col-md-6">
                  <label>Code</label>
                  <input  type="text" value={code} onChange={e => setCode(e.target.value)} />
              </div>
            
            
           
              
            </div>
            <div className="signup-submit">
              <button type="button" onClick={handleSendCode} className="submit-btn">Send Code </button>
              {/* <button type="button"  onClick={handleCheckCode} className="submit-btn">Check Code </button> */}
            </div>
           
           </>
           
           }

            



            
              
         </div>
        </>
    );
  }
  