import React, { useState } from 'react';
import { Link } from "react-router-dom";
import LeftSidebar from "../../layouts/leftSider";
import SettingSider from "../../layouts/settingSider";
import Cookies from 'js-cookie';
import Alert from "react-bootstrap/Alert";
import axios from 'axios';
import {Modal, Button} from 'react-bootstrap';

import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck , faBars } from '@fortawesome/free-solid-svg-icons'

async function addDocument(credentials) {
    return fetch(process.env.REACT_APP_API_URL+'api/profile/addDocuments', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
          'Access-Control-Allow-Credentials' : 'true/',
          'Content-Type': 'application/json' ,
          'Authorization': 'Bearer '+credentials.userToken+'',
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
  }


export default function EditProfileContent({ onEdit }) {

  const [showLeftSideMenu, setShowLeftSideMenu] = useState(false);
  const handleCloseLeftSideMenu = () => setShowLeftSideMenu(false);
  const handleShowLeftSideMenu = () => setShowLeftSideMenu(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  const userToken = Cookies.get('token');
  const userid = Cookies.get('user_id');
	const [usStates, setUsStates] = useState(false);
    const [frontfilename, setFrontfilename] = useState();
    const [document, setDocument] = useState('');
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState();
    const [allDocuments, setAllDocuments] = useState([]);

    const handleChange =  e => {
        //setFiles(e.target.files)
        //setErrorMessage(null);
        const url = process.env.REACT_APP_API_URL+'api/uploadFile';
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
          setFrontfilename(e.target.files[i].name);
          formData.append(`files[${i}]`, e.target.files[i])
          }
            formData.append('directory' , 'user_documents');
            const config = {
              headers: {
                'content-type': 'multipart/form-data',
              },
            };
            axios.post(url, formData, config).then((response) => {
            
                setDocument(JSON.stringify(response.data.files))
               
            });
    
          };

    
          const handleSubmit = async e => {
      e.preventDefault();
      setErrorMessage(null);
   
       
        const res = await addDocument({
            document,
            title,
          userToken,
        });
        if(res.success){
         // if(image!=''){
            setTitle('');
            setFrontfilename('');
         // }
        //  sessionStorage.setItem("token", token.author.api_token);
        setSuccessMessage('Document Successfully added.');
        handleClose();
        setUrl(JSON.stringify(res.docs));
        }else{
        
          setErrorMessage(res.errors);
        }
  
      }

      React.useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'api/get/user/documents' , {
          method: 'GET',
          headers: {
            'Access-Control-Allow-Origin' : 'http://localhost:3000/',
            'Access-Control-Allow-Credentials' : 'true/',
            'Content-Type': 'application/json' ,
            'Authorization': 'Bearer '+userToken+'',
          },
        }
          )
          .then(results => results.json())
          .then(data => {
            setAllDocuments(data);
            // console.log('docus' , data);

          });
      }, [url]); // <-- Have to pass in [] here!

    return (
      
        <section className="edit-profile-content row">

         <div  className='message-pages-top  w-100'>
            <div className="d-flex mx-4 mt-4 justify-content-between">

              <div className=" d-lg-none   ">
                    <FontAwesomeIcon  onClick={handleShowLeftSideMenu} icon={faBars} />
                  
                </div>
            
            </div>

          </div>

        <div className="sidebar col-md-6 col-lg-2 col-0 d-none d-lg-block h-auto">  
            <LeftSidebar />
          </div>
     
          <div className="sidebar search-sidebar col-md-6 col-lg-2 col-0 d-none d-lg-block h-auto ">
            <SettingSider />
          </div>



          {/* <div  className='side-bar d-none d-lg-block h-auto'>
             <div className='' style={{     backgroundColor:' #f6f9fe' }}>   <LeftSidebar /></div>
             <div className='' style={{     backgroundColor:' #f6f9fe' }}><SettingSider /></div>

          </div> */}


         <Modal    dialogClassName='custom-dialog' show={showLeftSideMenu} onHide={handleCloseLeftSideMenu} size="lg"  >
					
					<Modal.Body>
					<div style={{     width:'25px' }} className="cursor-pointer bg-primary px-2 py-1 mb-2 " onClick={handleCloseLeftSideMenu}>x</div>
          
          <div className='row'>
          <div className="sidebar col-6  ">  
            <LeftSidebar />
          </div>
     
          <div className="sidebar search-sidebar col-6   ">
            <SettingSider />
          </div>
          </div>
         

					</Modal.Body>
					
				</Modal>

       
          <div className="edit-container ol-xsm-12 col-sm-12   col-md-12 col-lg-11 col-12">
         


            <h3>Documents </h3>
            <button type="button" onClick={handleShow} className="btn btn-primary mt-2 mb-2">
									Add Document
										
									</button>
                                    {successMessage && (
            <Alert  variant="success" >
                 {successMessage && (
                <p>  {successMessage} </p>
                )}
                </Alert>
              )}


              {(allDocuments) && 

                    <table className="table">
                        <thead><tr>
                            <th >Document Title</th>
                            <th>File Name</th>
                            <th > Date Upload</th>
                        </tr>
                        </thead>
                        <tbody>
                        {allDocuments.map((val, key) => (
                            <tr key={key}>
                                <td>{val.title}</td>
                                <td> <a href={ process.env.REACT_APP_IMAGES_URL + JSON.parse(val.document).path[0]}  target="_blank" download> { JSON.parse(val.document).realname[0]}</a> </td>
                                <td>{moment(val.created_at ).format("DD MMMM YYYY ")}</td>
                            </tr>
                           ))}
                        </tbody>
                    </table>
              
              }








            <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header >
          <Modal.Title>Add Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        {errorMessage && (
            <Alert variant="danger" >
                 {errorMessage.title && (
                <p>  {errorMessage.title} </p>
                )}
                {errorMessage.document && (
                <p>  {errorMessage.document} </p>
                )}
                  
              
                 
                </Alert>

          )}


            <div className='signup-input'>
                <input type="text"   value={title}  onChange={e => setTitle(e.target.value)} placeholder="Title"  /> 
            </div>
            <div className='signup-input'>
                <input type="file"  onChange={handleChange}  placeholder="Document"  />
                <span>{frontfilename}</span> 
            </div>
		 


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Add Document
          </Button>
        </Modal.Footer>
      </Modal>

          </div>
        </section>
      
    );
  }