import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import Alert from "react-bootstrap/Alert";
import axios from 'axios';
import Select from 'react-select';
import {Modal, Button} from 'react-bootstrap';
import CreatableSelect  from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ImageCrop from "../ImageCropFeed/imageCrop";

import EditProfileContent from "../../components/admin/editProfileFirstStep";


import {
    kitchenImage,
    houseTwoImage,
    houseView,
    parkView,
    diningView,
    locationTick,
    messagesDarkIcon,
    messagesLightIcon,
    User
  } from "../../utils/images";

import { faCoffee , faXmark, faCircleCheck , faBars  , faPlus } from '@fortawesome/free-solid-svg-icons'
async function updateQuestions(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/user/save/questions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

async function updateTags(credentials) {
    return fetch(process.env.REACT_APP_API_URL+'api/profile/addtags', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
          'Access-Control-Allow-Credentials' : 'true/',
          'Content-Type': 'application/json' ,
          'Authorization': 'Bearer '+credentials.userToken+'',
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
  }
  
export default function Basicinfo(props) {

  const handleEdit = (item) => {
    props.handleStepsForword(Date.now())
  };

  const user_type = Cookies.get('user_type');
  const  kyc_required =   Cookies.get('kyc_required');
    const [image, setImage] = useState('');
    const user_id =  Cookies.get('user_id');
    const profile_image = Cookies.get('profile_image');
    const [showLeftSideMenu, setShowLeftSideMenu] = useState(false);
  const handleCloseLeftSideMenu = () => setShowLeftSideMenu(false);
  const handleShowLeftSideMenu = () => setShowLeftSideMenu(true);
  
  const userToken = Cookies.get('token');
  const [successMessage, setSuccessMessage] = useState("");

  const [questionsSelected, setQuestionsSelected] = useState();
  const [first, setFirst] = useState(true);
  const [alreadyquestionsSelected, setAlreadyQuestionsSelected] = useState();
  const [inputData, setInputData] = useState([]);


  



    




    return (

        
        <section className="edit-profile-content row">
              <ol class="progtrckr">



  {(user_type=='seller')?
<ol class="progtrckr">
<li class="progtrckr-doing" value="0">
   <span> Basic Info</span>
  </li>
  <li class="progtrckr-todo" value="1">
   <span> Interests</span>
  </li>
<li class="progtrckr-todo" value="2">
   <span> Add Property</span>
  </li>
  <li class="progtrckr-todo" value="3">
   <span> Phone Verification</span>
  </li>
    {(kyc_required==1)&& 
    <li class="progtrckr-todo" value="4">
     <span>KYC Verification</span>
  </li>
    }
    </ol>
:
<ol class="progtrckr">
<li class="progtrckr-doing" value="0">
   <span> Basic Info</span>
  </li>
  <li class="progtrckr-todo" value="1">
   <span> Interests</span>
  </li>
  <li class="progtrckr-todo" value="2">
   <span> Phone Verification</span>
  </li>
    {(kyc_required==1)&& 
    <li class="progtrckr-todo" value="3">
     <span>KYC Verification</span>
  </li>
    }
    </ol>

}


  </ol>


        <div className="edit-container ol-xsm-12 col-sm-12   col-md-12 col-lg-12 col-12">
        {/* <h3>Profile Picture </h3>

        <div className='my-3 col-4' style={{ height:'60px' }}>
            

            {(user_id!=props.details.id)?
  <>
            {(props.details.image!='' && props.details.image!=null)?
         
                                  <img  className={  `social-media-post-profile-image user_${props.details.user_type}` }  src={process.env.REACT_APP_IMAGES_URL + JSON.parse(props.details.image).path[0]} alt={props.details.user_type} title={props.details.user_type} style={{ marginBottom: '-18px' }}  />
                                  :
                                  <img  className={  `social-media-post-profile-image user_${props.details.user_type}` }  src={User} alt={props.details.user_type}  title={props.details.user_type} style={{ marginBottom: '-18px' }}  />
  
  
  
                                      }
                                </>
                                :
                                profile_image &&
                                  <ImageCrop  details={props.details}  profile_image={profile_image} setImage={setImage}/>
                                  
                                  
                          }
                                      
  
                             
                                     
  
                                      </div> */}

                                      <EditProfileContent onEdit={handleEdit} />


      </div>
     



      
      </section>

    )

}