import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";
import { feedCardContent } from "../../utils/constant";
import { detail } from "../../routers/routePath";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
	chooseUsIconOne,
	chooseUsIconThree,
	chooseUsIconTwo,
	featureElectricImage,
	featureManImage,
	feedcardBathImage,
	feedcardBedImage,
	feedcardImage,
	feedcardSpaceImage,
	newsPlayIcon,
	suitablecardImageFour,
	suitablecardImageOne,
	suitablecardImageThree,
	suitablecardImageTwo,
	testimonialClientImage,
	testimonialQuoteIcon,
} from "../../utils/images";

export default function LatestFeedSection(props) {
//   const ratingChanged = (newRating) => {

//   };
  return (
    <section className="latest-feed-section">
      <div className="container">
        <h2>Latest Properties</h2>
        <div className="row">
        {props.feedCardContent.map((val, key) => (
						<div key={val.id} className="col-md-6 mt-4">
							<div className="feed-card">
								<div className="feed-card-img">
									{(val.images!=null && val.images!="")?
									<img
									src={process.env.REACT_APP_IMAGES_URL + val.images.path[0]}
									alt=""
								/>
									:
										<img src={feedcardImage} alt="" />
								}
									


									{/* <span>rent</span> */}
								</div>
								<div className="feed-card-description">
									
									<h4>
										<Link to={"property/" + val.slug + " "}>
											{val.title}
										</Link>
									</h4>
									<h5 className="reducedescription">
										{val.about}
									</h5>
									<p className="pt-2"> Price: ${val.demand} USD</p>
									{/* <div className="feed-rating mb-3">
										<ReactStars
											count={5}
											onChange={ratingChanged}
											size={24}
											activeColor="#d7524d"
										/>
									</div> */}
									<div className="feed-card-info">
										<div>
											<span>
												<img src={feedcardBedImage} alt="BedIcon" />
											</span>
											<span>{val.beds}</span>
										</div>
										<div>
											<span>
												<img src={feedcardSpaceImage} alt="" />
											</span>
											<span>{val.size} sq ft</span>
										</div>
										<div>
											<span>
												<img src={feedcardBathImage} alt="" />
											</span>
											<span>{val.baths}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
          
        </div>
        <div className="view-more-feed">
         <a href="/listing"> <button>View More</button> </a>
        </div>
      </div>
    </section>
  );
}
