import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import React from "react";
import Slider from "react-slick";
import {
	HouseView,
	WhiteHouse,
	HouseFront,
	GreenHouse,
} from "../../utils/images";
import { faImages } from "@fortawesome/free-solid-svg-icons";

export default function Gallery(propsmain) {
	function SampleNextArrow(props) {
		const { collectionSliderArrow, style, onClick } = props;
		return (
			<div
				className="collectionSliderArrow"
				style={{ ...style }}
				onClick={onClick}
			>
				<FaChevronLeft />
			</div>
		);
	}
	function SamplePrevArrow(props) {
		const { collectionSliderArrowPrev, style, onClick } = props;
		return (
			<div
				className="collectionSliderArrowPrev"
				style={{ ...style }}
				onClick={onClick}
			>
				<FaChevronRight />
			</div>
		);
	}
	var settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 3.5,
		slidesToScroll: -1,
		autoplay: true,
		autoplaySpeed: 2000,
		cssEase: "linear",
		variableWidth: true,
		adaptiveHeight: true,
		arrow: false,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
				},
			},
		],
	};

	return (
		<section className="container">
			<h2 className="main-title-head">{propsmain.title}</h2>

			{propsmain.images && (
				<Slider {...settings}>
					{propsmain.images.path.map((img, index) => (
						// eslint-disable-next-line react/jsx-no-comment-textnodes
						<div className="gallery-slider">
							{
								// eslint-disable-next-line jsx-a11y/alt-text
								<img
									className="gallery-slider-image"
									key={index}
									src={process.env.REACT_APP_IMAGES_URL + img}
								/>
							}
						</div>
					))}
				</Slider>
			)}
		</section>
	);
}
