import React, { useState } from 'react';

import Alert from "react-bootstrap/Alert";
import { useHistory } from "react-router-dom";
import Select from 'react-select';
 

  export default function QuestionForm(props) {
    const [checked, setChecked] = useState(false);
    const [checkedPrivacy, setCheckedPrivacy] = useState(false);
    const [usStates, setUsStates] = useState(props.usStates);

    const history = useHistory()
    const referrer =history.location.pathname.replace('/signup/','')




    const [cities, setCities] = useState(props.cities);

    React.useEffect(() => {
      setUsStates(props.usStates);
      setCities(props.cities);
    },[props.cities]); // <-- Have to pass in [] here!


    const handleCities = (val) => {
     // console.log('handleCities' , usStates[val].cities);
      setCities(usStates[val].cities);
      };

    const [options, setOptions]= useState([]);
    const [optionsseller, setOptionsSeller]= useState([]);
    const [optionsLoan, setOptionsLoan]= useState([]);
    React.useEffect(() => {
    
    fetch(
      process.env.REACT_APP_API_URL + `api/realtors/all`,
      {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "http://localhost:3000/",
        "Content-Type": "application/json", 
      },
      }
    )
      .then((results) => results.json())
      .then((data) => {
      //setUsStates(data);

      // console.log("fded" ,referrer );
      // console.log("data.data" ,data.data );
      var arr = [];
      if(data.data){ 
        data.data.map((val, index) => (
       
         (val.professionaltype==1 && val.slug===referrer )? arr.push({value:val.id , label:val.first_name + ' ' + val.last_name }):null
        ))
        setOptions(arr);
      }

      var arr = [];
      if(data.data){ 
        data.data.map((val, index) => (
         (val.professionaltype==2 && val.slug===referrer )? arr.push({value:val.id , label:val.first_name + ' ' + val.last_name }):null
        ))
        setOptionsSeller(arr);
      }

      var arr = [];
      if(data.data){ 
        data.data.map((val, index) => (
         (val.professionaltype==3 && val.slug===referrer )? arr.push({value:val.id , label:val.first_name + ' ' + val.last_name }):null
        ))
        setOptionsLoan(arr);
      }


      });
    
  }, []); // <-- Have to pass in [] here!


    return (
     
      <>
      <ol class="progtrckr">
    <li class="progtrckr-done"  onClick={() => props.setCount(0)} value="0">
     <span> User Type</span>
    </li>
    <li class="progtrckr-done"  onClick={() => props.setCount(1)} value="1">
      <span>Personal Information</span>
    </li>
    <li class="progtrckr-done" onClick={() => props.setCount(2)}  value="2">
      <span>Package</span>
    </li>
    <li class="progtrckr-doing"  value="3">
     <span>Additional information</span>
      </li>
    </ol>


  <h3 className="signup-message">Please Fill Questionnaire  </h3>
        <div className="row animatefadein">
          {
          (props.userType=='seller')?
          <>
         
           <div className="signup-input col-md-6">
           <label>What state is your property?</label>
       

           <select  name="" id=""  onChange={e =>  {props.onState(e.target.value) ; handleCities(e.target.selectedIndex) }}
                                        >
           
              {(usStates)&&  
              usStates.map((val, index) => (
                <option key={index}  value={val.name} data-id={index}>{val.name}</option>
              ))
     
              }
          
            </select>


         </div>
         <div className="signup-input col-md-6">
            <label>What city is your property?</label>
  
            <select  onChange={e => props.onPreferCity(e.target.value)}>
              {(cities)&&  
              cities.map((val, index) => (
                <option key={index}  value={val.name} data-id={index}>{val.name}</option>
              ))
     
              }
              </select>
            
          </div>

        
          <div   className='mt-2  signup-input col-md-12'>
         
         {/* <input type="checkbox" className='signupcheckbox' name="isReferredByRealtor" id="isReferredByRealtor"  defaultChecked={props.isReferredByRealtor}
         onChange={() => props.onIsReferredByRealtor(!props.isReferredByRealtor)}   />   */}
           <label for="isReferredByRealtor"> Were you referred by a Real Estate Professional?  </label>

           <select value={props.isReferredByRealtor}  onChange={(e) => props.onIsReferredByRealtor(e.target.value)} >
                        <option   value="1">Yes</option>
                          <option  value="0">No</option>
                      </select>
       </div>
       <div   className='mt-2  signup-input  col-md-12'>
           {(props.isReferredByRealtor=='1') &&
           <div className='mt-2  signup-input  col-md-12'>

             {(optionsseller) &&
                 <Select defaultValue={optionsseller} className='mb-3' placeholder="Select "  onChange={e => props.onIsRep(e.value)}  options={optionsseller} />
             }

          </div>
           }
           </div>
           <div className='mt-2  signup-input  col-md-12'>
           {/* <input type="checkbox" className='signupcheckbox' name="isLoanOfficer" id="isLoanOfficer"  defaultChecked={props.isLoanOfficer}
         onChange={() => props.onIsLoanOfficer(!props.isLoanOfficer)}   />   */}
           <label for="isLoanOfficer"> Were you referred by a loan officer?   </label>

           <select value={props.isLoanOfficer}  onChange={(e) => props.onIsLoanOfficer(e.target.value)} >
                        <option value="1">Yes</option>
                        <option selected value="0">No</option>
                      </select>
           

           {(props.isLoanOfficer=='1') &&
           <div className='mt-2  signup-input col-md-12'>

             {(optionsLoan) &&
                 <Select className='mb-3' defaultValue={optionsLoan} placeholder="Select "  onChange={e => props.onIsRepLoan(e.value)}  options={optionsLoan} />
             }

</div>
           }
             </div>


         <div className='mt-2 signup-input col-md-6'>
            {/* <input type="checkbox" className='signupcheckbox' name="isRealtor" id="isRealtor"  defaultChecked={props.isRealtor}
              onChange={() => props.onIsRealtor(!props.isRealtor)}   />   */}
           <label for="isRealtor"> Are you a Real Estate Professional?</label>
           <select value={props.isRealtor}  onChange={(e) => props.onIsRealtor(e.target.value)} >
                        <option selected value="1">Yes</option>
                        <option value="0">No</option>
                      </select>

         </div>
         {(props.isRealtor=="0")?
         <div className='mt-2 signup-input col-md-6'>
         {/* <input type="checkbox" className='signupcheckbox' name="isWholeseller" id="isWholeseller"  defaultChecked={props.isWholeseller}
           onChange={() =>  props.onIsWholeseller(!props.isWholeseller)}   />   */}
        <label for="isWholeseller"> Are you a wholesaler?</label>

        <select value={props.isWholeseller}  onChange={(e) => props.onIsWholeseller(e.target.value)} >
                        <option value="1">Yes</option>
                        <option selected value="0">No</option>
                      </select>
      </div>
      :null

         }
{(props.isWholeseller=="1") && 
  <div className='col-md-12'>
You can go to property section and upload
      </div>
}

         

         <div className='mt-2  signup-input col-md-6'>
            {/* <input type="checkbox" className='signupcheckbox' name="isForSaleByOwner" id="isForSaleByOwner"  defaultChecked={props.isForSaleByOwner}
              onChange={() => props.onIsForSaleByOwner(!props.isForSaleByOwner)}   />   */}
           <label for="isForSaleByOwner"> Are you a for sale by Owner? </label>

           <select value={props.isForSaleByOwner}  onChange={(e) => props.onIsForSaleByOwner(e.target.value)} >
                        <option value="1">Yes</option>
                        <option selected value="0">No</option>
                      </select>

           
         </div>
         {(props.isForSaleByOwner=="1") && 
         <div   className='mt-2  col-md-12'>
         <p>    
          
          <iframe src="https://player.vimeo.com/video/455464445?h=7579f0124b&title=0&byline=0&portrait=0" width="400" height="300" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

            

          </p>
         </div>
  }


         <div className='mt-2 signup-input col-md-6'>
            {/* <input type="checkbox" className='signupcheckbox' name="isPropertyReheb" id="isPropertyReheb"  defaultChecked={props.isPropertyReheb}
              onChange={() => props.onIsPropertyReheb(!props.isPropertyReheb)}   />   */}
           <label for="isPropertyReheb">Does the property need updating or rehab?</label>

           <select value={props.isPropertyReheb}  onChange={(e) => props.onIsPropertyReheb(e.target.value)} >
                        <option value="1">Yes</option>
                        <option selected value="0">No</option>
                      </select>
         </div>
         {(props.isPropertyReheb=="1")? 
         <div   className='mt-2  col-md-12'>
         <div className='fs-15'>       We need to have an appointment to go over the house before listing the property. Will you consider selling your property in our "My Landlord Is Fired" program?
          
          <iframe src="https://player.vimeo.com/video/455461499?h=ff7e43f031&title=0&byline=0&portrait=0" width="400" height="300" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

          

           


          </div>
         </div>
    :
    <div   className='my-2  col-md-12'>
      <div className='fs-15'>
      You can go to property section and upload

            


      </div>
    </div>
         }

<div   className='my-2  col-md-12'>
<p> Testimonials:</p>
    
    <p>
    <iframe src="https://player.vimeo.com/video/326590387?h=f9e658dbb0&title=0&byline=0&portrait=0" width="400" height="300" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

    </p>
  </div>
        
        
         </>

       



          :(props.userType=='buyer')?
          <>
          
           <div className="signup-input col-md-6">
           <label>What state are you interested in?</label>
           <select  name="" id=""  onChange={e =>  {props.onState(e.target.value) ; handleCities(e.target.selectedIndex) }}
                                        >
           
              {(usStates)&&  
              usStates.map((val, index) => (
                <option key={index}  value={val.name} data-id={index}>{val.name}</option>
              ))
     
              }
          
            </select>


         </div>
         <div className="signup-input col-md-6">
            <label>What city are you interested in?</label>
            <select  onChange={e => props.onPreferCity(e.target.value)}>
              {(cities)&&  
              cities.map((val, index) => (
                <option key={index}  value={val.name} data-id={index}>{val.name}</option>
              ))
     
              }
              </select>

          </div>
         <div className="signup-input col-md-6">
           <label> List your preferred zip codes ?</label>
           <input type="text" onChange={e => props.onZipcode(e.target.value)}  required />

           
         </div>
         <div className="signup-input col-md-6">
           <label> What is the maximum monthly mortgage payment you can afford?</label>
           <input type="text" onChange={e => props.onBudgetForMortgage(e.target.value)} required />
         </div>

         <div className="signup-input col-md-6">
           <label>Desired amount of bedrooms?</label>
           <input type="text" onChange={e => props.onBedrooms(e.target.value)}  required />
         </div>
         <div className="signup-input col-md-6">
           <label>Desired amount of bathrooms?</label>
           <input type="text" onChange={e => props.onBathrooms(e.target.value)} required />
         </div>

         <div className="signup-input col-md-6">
           <label>Any specific feature that you must have in your house?</label>
           <input type="text" onChange={e => props.onSpecificFeature(e.target.value)} required />
         </div>

         <div  className='mt-2 signup-input col-md-6'>
         {/* <input type="checkbox" className='signupcheckbox' name="isNeedRenovation" id="isNeedRenovation"  defaultChecked={props.isNeedRenovation}
        onChange={() => props.onIsNeedRenovation(!props.isNeedRenovation)}   />   */}
           <label for="isNeedRenovation"> Are you interested in a property that would need renovation so you can customize if available?</label>
           <select value={props.isNeedRenovation}  onChange={(e) => props.onIsNeedRenovation(e.target.value)} >
                        <option value="1">Yes</option>
                        <option selected value="0">No</option>
                      </select>
         </div>
         <div className='mt-2 signup-input col-md-6'>
         {/* <input type="checkbox" className='signupcheckbox' name="isRuralArea" id="isRuralArea"  defaultChecked={props.isRuralArea}
        onChange={() => props.onIsRuralArea(!props.isRuralArea)}   />   */}
           <label for="isRuralArea"> Will you live in a rural area?</label>

           <select value={props.isRuralArea}  onChange={(e) => props.onIsRuralArea(e.target.value)} >
                        <option value="1">Yes</option>
                        <option selected value="0">No</option>
                      </select>
         </div>
        

         <div   className='mt-2 signup-input col-md-6'>
         {/* <input type="checkbox" className='signupcheckbox' name="isVeteran" id="isVeteran"  defaultChecked={props.isVeteran}
        onChange={() => props.onIsVeteran(!props.isVeteran)}   />   */}
           <label for="isVeteran"> Are you a veteran?</label>

           <select value={props.isVeteran}  onChange={(e) => props.onIsVeteran(e.target.value)} >
                        <option value="1">Yes</option>
                        <option selected value="0">No</option>
                      </select>
           
         </div>

         <div   className='mt-2  signup-input  col-md-12'>
         
      
           <label for="isReferredByRealtor"> Were you referred by a Real Estate Professional?   </label>

           <select value={props.isReferredByRealtor}  onChange={(e) => props.onIsReferredByRealtor(e.target.value)} >
                        <option value="1">Yes</option>
                        <option selected value="0">No</option>
                      </select>
       </div>
     
           {(props.isReferredByRealtor =="1") &&
           <div className='mt-2   col-md-12'>

             {(options) &&
                 <Select className='mb-3' defaultValue={options} placeholder="Select "  onChange={e => props.onIsRep(e.value)}  options={options} />
             }

          </div>
           }
<div className='mt-2   signup-input  col-md-12'>
           {/* <input type="checkbox" className='signupcheckbox' name="isLoanOfficer" id="isLoanOfficer"  defaultChecked={props.isLoanOfficer}
         onChange={() => props.onIsLoanOfficer(!props.isLoanOfficer)}   />   */}
           <label for="isLoanOfficer"> Were you referred by a loan officer?   </label>
           <select value={props.isLoanOfficer}  onChange={(e) => props.onIsLoanOfficer(e.target.value)} >
                        <option value="1">Yes</option>
                        <option selected value="0">No</option>
                      </select>
                      </div>

           {(props.isLoanOfficer=="1") &&
           <div className='mt-2  cbox col-md-12'>

             {(optionsLoan) &&
                 <Select className='mb-3' defaultValue={optionsLoan} placeholder="Select "  onChange={e => props.onIsRepLoan(e.value)}  options={optionsLoan} />
             }

</div>
           }
         


         <div   className='mt-2 signup-input  col-md-12'>
         {/* <input type="checkbox" className='signupcheckbox' name="isFico" id="isFico"  defaultChecked={props.isFico}
        onChange={() => props.onIsFico(!props.isFico)}   />   */}
           <label for="isFico"> Is your credit over a 620 Fico score?  <a target="_blank" href='https://www.experian.com/'> Click here to check  Fico score </a> </label>
           <select value={props.isFico}  onChange={(e) => props.onIsFico(e.target.value)} >
                        <option value="1">Yes</option>
                        <option selected value="0">No</option>
                      </select>
         </div>

          {(props.isFico=="1")?
          <>
         
       <div   className='mt-2   col-md-12'>
              <br></br>
              Yes….Let’s get you prequalified 
              <br></br>
<a href='https://www.blink.mortgage/app/signup/p/ilendingincb/mariohenry' target="_blank">https://www.blink.mortgage/app/signup/p/ilendingincb/mariohenry </a>
<br></br>
If you get an error saying we are not licensed in your state, don’t panic, inbox us, and we will get you another link to fill out. There needs to be a messenger link there for questions directly to us. 

         


          </div>
          </>
          :
          <>
          <div   className='mt-2  signup-input col-md-12'>

          {/* <input type="checkbox" className='signupcheckbox' name="isPrequlification" id="isPrequlification"  defaultChecked={props.isPrequlification}
            onChange={() => props.onIsPrequlification(!props.isPrequlification)}   />   */}
              <label for="isPrequlification"> If you have scores below 620 and you are interested in the "My Landlord Is Fired" program, here is the  quick overview 
          "My Landlord Is Fired" promo
          Prequalification ?   </label>

          <select value={props.isPrequlification}  onChange={(e) => props.onIsPrequlification(e.target.value)} >
                        <option value="1">Yes</option>
                        <option selected value="0">No</option>
                      </select>
            
         <p>     
         {(props.isPrequlification=="1") &&
          <iframe src="https://player.vimeo.com/video/661457768?h=b5dde76290" width="400" height="300" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
         }
            <br></br>


            {/* <input type="checkbox" className='signupcheckbox' name="isVeteranBedCredit" id="isVeteranBedCredit"  defaultChecked={props.isVeteranBedCredit}
            onChange={() => props.onIsVeteranBedCredit(!props.isVeteranBedCredit)}   />   */}
              <label for="isVeteranBedCredit">  If you are a Veteran with bad credit and want to own a house?   </label>
              <select value={props.isVeteranBedCredit}  onChange={(e) => props.onIsVeteranBedCredit(e.target.value)} >
                        <option value="1">Yes</option>
                        <option selected value="0">No</option>
                      </select>

         
              {(props.isVeteranBedCredit=="1") &&
              <div>
 <iframe src="https://player.vimeo.com/video/443210598?h=ffbde0702b&title=0&byline=0&portrait=0"width="400" height="300"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              </div>
         
        }
         <p className='pb-3'> Testimonials:</p>
          <div className='row' >

       
          <div className='col-md-6'>
          <iframe src="https://player.vimeo.com/video/321900578?h=126efbca63&title=0&byline=0&portrait=0" width="100%" height="200" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div className='col-md-6'>
          <iframe src="https://player.vimeo.com/video/326970927?h=a9edccbbd6&title=0&byline=0&portrait=0" width="100%" height="200" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div className='col-md-6'>
          <iframe src="https://player.vimeo.com/video/330101678?h=382ddbe2c1&title=0&byline=0&portrait=0" width="100%" height="200" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div className='col-md-6'>
          <iframe src="https://player.vimeo.com/video/327400052?h=50da315e61&title=0&byline=0&portrait=0" width="100%" height="200" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          </div>
          </div>


          </p>
         </div>
         </>
  }
         
         
         </>
          :(props.userType=='contractor')?
          <>
          <div className="signup-input col-md-6">
            <label>Years in Business?</label>

            <select onChange={e => props.onYearsInBuiness(e.target.value)} required >
                    <option>1</option>
                    <option>2</option>
                    <option>4</option>
                    <option>6</option>
                    <option>8</option>
                    <option>10</option>
                    <option>10+</option>
                  </select>
          </div>
          <div className="signup-input col-md-6">
            <label>What is your best trade?</label>
            <input type="text" onChange={e => props.onBestTrade(e.target.value)} required />
          </div>
          <div className="signup-input col-md-6">
            <label>How many years contracting experience do you have? </label>
            <input type="text" onChange={e => props.onContractingExperiance(e.target.value)}  required/>
          </div>
          <div className="signup-input col-md-6">
            <label>What is your day rate you are expecting? </label>
            <input type="text" onChange={e => props.onExpectedRate(e.target.value)}  required/>
          </div>
          <div className="signup-input col-md-12">
           <h4>FAQS</h4>
          </div>
          <div className="signup-input col-md-12">
          <label for="isCrew">Question: Do you have a crew? <br></br>
         
          
          </label><span className='Ralewayfamily'> Answer: We do not hire crews! If you have a crew everyone will need to apply individually</span>
          
         
         </div >
     
         <div className="signup-input col-md-12 mt-3">
         <label for="isDayRate">Question:  I only get paid by the job not a day rate?<br></br>
        
         </label>
         <span className='Ralewayfamily'> Answer:  We only pay permitted trades like HVAC, Electric, Plumbing, Roof, and Cement work by the job.  Everyone else gets paid based on a formula of day rate and the estimated completion time of the job. </span>
         
         </div>
         <div className='col-md-12'>
         
         
         <iframe src="https://player.vimeo.com/video/465856257?h=8eb9936c5e&title=0&byline=0&portrait=0" width="400" height="300" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

         </div>
          </>
        :(props.userType=='investor')?
        <>
         <div className="signup-input col-md-6">
            <label>Years in Business?</label>
            <select onChange={e => props.onYearsInBuiness(e.target.value)} required >
                    <option>1</option>
                    <option>2</option>
                    <option>4</option>
                    <option>6</option>
                    <option>8</option>
                    <option>10</option>
                    <option>10+</option>
                  </select>
          </div>

         <div className="signup-input col-md-6">
            <label>How much funds do you have to invest? </label>
            <input type="text" onChange={e => props.onFunds(e.target.value)} required />
          </div>
          <div   className='mt-2 signup-input col-md-6'>
              {/* <input type="checkbox" className='signupcheckbox' name="isBeginnerInvestor" id="isBeginnerInvestor"  defaultChecked={props.isBeginnerInvestor}
              onChange={() => props.onIsBeginnerInvestor(!props.isBeginnerInvestor)}   />   */}
           <label for="isBeginnerInvestor">Are you a beginner investor? </label>

           <select value={props.isBeginnerInvestor}  onChange={(e) => props.onIsBeginnerInvestor(e.target.value)} >
                        <option value="1">Yes</option>
                        <option selected value="0">No</option>
                      </select>
         </div>
         <div   className='mt-2  signup-input col-md-6'>
              {/* <input type="checkbox" className='signupcheckbox' name="isAccessToLender" id="isAccessToLender"  defaultChecked={props.isAccessToLender}
              onChange={() => props.onIsAccessToLender(!props.isAccessToLender)}   />   */}
           <label for="isAccessToLender">Do you need access to a lender? </label>

           <select value={props.isAccessToLender}  onChange={(e) => props.onIsAccessToLender(e.target.value)} >
                        <option value="1">Yes</option>
                        <option selected value="0">No</option>
                      </select>
         </div>
         <div   className='mt-2  signup-input col-md-6'>
              {/* <input type="checkbox" className='signupcheckbox' name="isFlipingBuySold" id="isFlipingBuySold"  defaultChecked={props.isFlipingBuySold}
              onChange={() => props.onIsFlipingBuySold(!props.isFlipingBuySold)}   />   */}
           <label for="isFlipingBuySold">Are you interested in flipping or buy and hold? </label>

           <select value={props.isFlipingBuySold}  onChange={(e) => props.onIsFlipingBuySold(e.target.value)} >
                        <option value="1">Yes</option>
                        <option selected value="0">No</option>
                      </select>
         </div>
         <div   className='mt-2 signup-input  col-md-12'>
              {/* <input type="checkbox" className='signupcheckbox' name="isMoneyTowardDeals" id="isMoneyTowardDeals"  defaultChecked={props.isMoneyTowardDeals}
              onChange={() => props.onIsMoneyTowardDeals(!props.isMoneyTowardDeals)}   />   */}
           <label for="isMoneyTowardDeals">Are you interested in just putting your money towards a deal on a joint venture? </label>
         
           <select value={props.isMoneyTowardDeals}  onChange={(e) => props.onIsMoneyTowardDeals(e.target.value)} >
                        <option value="1">Yes</option>
                        <option selected value="0">No</option>
                      </select>
         </div>

         <iframe src="https://player.vimeo.com/video/443213211?h=f280bb2773&title=0&byline=0&portrait=0" width="400" height="300" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
         </>
          
           :(props.userType=='affiliates')?
           <>
              <div   className='mt-2  signup-input col-md-6'>
                      {/* <input type="checkbox" className='signupcheckbox' name="isUnderStandCrypto" id="isUnderStandCrypto"  defaultChecked={props.isUnderStandCrypto}
                      onChange={() => props.onIsUnderStandCrypto(!props.isUnderStandCrypto)}   />   */}

                    <label for="isUnderStandCrypto">Do you understand cryptocurrency? </label>
                      <select value={props.isUnderStandCrypto}  onChange={(e) => props.onIsUnderStandCrypto(e.target.value)} >
                        <option  selected value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                  
                </div>

                {(props.isUnderStandCrypto=='0') &&
                          // <div>
                          //  <p> You’ll be able to sign up for the beginner master class to understand how USHX works and how the token can be used on our platform. 
                          //  </p>
                          //   </div>

                          <div   className='mt-2  signup-input col-md-6'>
                        
                        <label for="isUnderStandCrypto">Do you Want to signup as Beginner Master Class? </label>
                          <select value={props.isMasterClass}  onChange={(e) => props.onIsMasterClass(e.target.value)} >
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </select>
                      
                    </div>
              }

<div   className='mt-2  signup-input col-md-6'>
                     

                  <label for="isUnderStandCrypto">Do you own any cryptocurrency?</label>
                      <select value={props.isOwnCrypto}  onChange={(e) => props.onIsOwnCrypto(e.target.value)} >
                        <option value="1">Yes</option>
                        <option selected value="0">No</option>
                      </select>


                </div>

                {(props.isOwnCrypto=='1') &&
                          
                            <div className="signup-input col-md-6">
                              <label>What cryptocurrency do you own? </label>
                              <input type="text" onChange={e => props.onCrypto(e.target.value)} required />
                            </div>
                          
                          
              }


           </>
           :(props.userType=='Real_Estate_Professional')?
           <>
          <div className="signup-input col-md-6">
            <label>Years in Business?</label>
            <select onChange={e => props.onYearsInBuiness(e.target.value)} required >
                    <option>1</option>
                    <option>2</option>
                    <option>4</option>
                    <option>6</option>
                    <option>8</option>
                    <option>10</option>
                    <option>10+</option>
                  </select>
          </div>
          <div className="signup-input col-md-6">
            <label>Type of real estate professional ?</label>
            <select onChange={e => props.onProfessionalType(e.target.value)} required >
                    <option value="1">Real Estate Agent ( Buyer's agent)</option>
                    <option value="2">Real Estate Agent ( Seller's agent)</option>
                    <option value="3">Mortgage Loan Originator</option>
                    <option value="4">Property manager</option>
                    <option value="5">Real Estate Attorney</option>
                  </select>
          </div>


           </>
          :
          <>
          {(props.userType!='affiliates')&&
           <div className="signup-input col-md-6">
            <label>Years in Business?</label>
            <select onChange={e => props.onYearsInBuiness(e.target.value)} required >
                    <option>1</option>
                    <option>2</option>
                    <option>4</option>
                    <option>6</option>
                    <option>8</option>
                    <option>10</option>
                    <option>10+</option>
                  </select>
          </div>
          }
         <div className="signup-input col-md-6">
            <label>What city is your property? {props.userType}</label>
            <input type="text" onChange={e => props.onPreferCity(e.target.value)} required />
          </div>
           <div className="signup-input col-md-6">
           <label>What state is your property?</label>
           <input type="text" onChange={e => props.onState(e.target.value)}  required/>
         </div>
         </>
          }
       
        </div>
        
        </>
    );
  }
  