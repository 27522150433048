import React, { useState } from "react";
import CallToAction from "../../components/contractors/callToAction";
import FiltersResults from "../../components/listing/filterResults";
import ListingFilters from "../../components/listing/listingFilters";
import Footer from "../../layouts/footer";
import Header from "../../layouts/header";
import Cookies from 'js-cookie';


export default function Listing() {
	const [feedCardContent, setFeedCardContent] = useState([]);
	const [feedCardContentmeta, setFeedCardContentMeta] = useState([]);
	const [baths, setBaths] = useState("null");
	const [pageNumber, setPageNumber] = useState(1);
	const [reloaddata, setSetReloadData] = useState([]);
	const [category, setCategory] = useState("null");
	const [location, setLocation] = useState("null");
	const [beds, setBeds] = useState("null");
	const [rooms, setRooms] = useState("null");
	const userToken = Cookies.get('token');
	const [price, setPrice] = useState("null");
	const [usStates, setUsStates] = useState(false);
	const [isFirstTime, setFirstTime] = useState(true);
	const [priceRange, setPriceRange] = useState([]);
	const [bedRange, setBedRange] = useState([]);
	const [bathRange, setBathRange] = useState([]);
	const user_package = Cookies.get("package");
	const user_state = Cookies.get("user_state");

	React.useEffect(() => {
		console.log('user_state' , user_state);
		if(user_state){
			setLocation(user_state);
			setSetReloadData(Date.now());
			
		}else{
			setLocation(null);
			setSetReloadData(Date.now());
		}
		setFirstTime(false);
	}, [user_state]);


	
	//console.log('user_state' , user_state)

	const fetchFilterData = async () => {
		fetch(
			process.env.REACT_APP_API_URL +
				`api/properties/filters`,
			{
				method: "GET",
				headers: {
					"Access-Control-Allow-Origin": "http://localhost:3000/",
					"Content-Type": "application/json", 
				},
			}
		)
			.then((results) => results.json())
			.then((data) => {
				//setUsStates(data);
				      if(data){
						const length = (data.maxdemaind - 500) / 10000 + 1;
						const arr = Array.from({ length }, (_, i) =>500 + i * 10000);
						setPriceRange(arr);

						let maxBathGame = data.maxbaths
						const batharr =  [...Array(maxBathGame+1).keys()]
						batharr.shift();
						setBathRange(batharr);

						let maxBedGame = data.maxbeds;
						const bedarr =  [...Array(maxBedGame+1).keys()]
						bedarr.shift();
						setBedRange(bedarr);

					  }
						


			});
	};


	
	const fetchData = async () => {
		if(!isFirstTime){
			fetch(
				process.env.REACT_APP_API_URL +
					`api/properties?page=${pageNumber}&price=${price}&rooms=${rooms}&baths=${baths}&beds=${beds}&category=${category}&location=${location}`,
				{
					method: "GET",
					headers: {
						"Access-Control-Allow-Origin": "http://localhost:3000/",
						"Access-Control-Allow-Credentials": "true/",
						"Content-Type": "application/json", 
						'Authorization': 'Bearer '+userToken+'',
					},
				}
			)
				.then((results) => results.json())
				.then((data) => {
					if (data) {
					
						setFeedCardContent(data.data);
						setFeedCardContentMeta(data.meta);
					}else{
						setFeedCardContent([]);
						setFeedCardContentMeta([]);
					}
				});
		}
		
	};

	React.useEffect(() => {
		fetchFilterData();
		fetchData();
		fetchStatesData();
	}, [reloaddata]); // <-- Have to pass in [] here!


	const fetchStatesData = async () => {
		fetch(
			process.env.REACT_APP_API_URL +
				`api/get/us/states`,
			{
				method: "GET",
				headers: {
					"Access-Control-Allow-Origin": "http://localhost:3000/",
					"Content-Type": "application/json", 
				},
			}
		)
			.then((results) => results.json())
			.then((data) => {
				setUsStates(data);
			});
	};



	return (
		<>
			 {/* <Header /> */}
			<ListingFilters
				setRooms={setRooms}
				setBeds={setBeds}
				setCategory={setCategory}
				location={location}
				setLocation={setLocation}
				setSetReloadData={setSetReloadData}
				setPageNumber={setPageNumber}
				setBaths={setBaths}
				setPrice={setPrice}
				fetchData={fetchData}
				usStates={usStates}
				priceRange= {priceRange}
				bathRange= {bathRange}
				bedRange= {bedRange}
			/>
			<FiltersResults
				setSetReloadData={setSetReloadData}
				setPageNumber={setPageNumber}
				feedCardContent={feedCardContent}
				feedCardContentmeta={feedCardContentmeta}
				fetchData={fetchData}
			/>
			<CallToAction />

			{(!userToken) &&
<section className="call-to-action m-2">
      <div className="container">
        <div className="row call-to-action-main">
          <div className="col-md-6">
            <div className="call-to-action-content">
              <h2>Join Us</h2>
              <p>Join our top social media platform and start connecting with people!</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="call-subscribe">
            <a href='/signup' > <button type='button'>Join Now</button></a>
              
            


            </div>
          </div>
        </div>
      </div>
    </section>
}


			{/* <Footer /> */}
		</>
	);
}
