import React from "react";
import ReactStars from "react-rating-stars-component";
import { apartmentCardContent } from "../../utils/constant";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { detail } from "../../routers/routePath";
import {currencyFormat} from "../../utils/functions";
export default function ApartmentCard({  details  , onView  }) {

	// const { detail } = props;
	const ratingChanged = (newRating) => {
		
	};

	const handleEdit = (item) => {
		onView(item);
	  };

	return (
		<>
			<div className="apartment-card ">
				<div className="feed-card-img">
				{ (details.images!='' && details.images!=null)?
					<img className="related-prop-img" src={process.env.REACT_APP_IMAGES_URL + JSON.parse(details.images).path[0]}	alt=""/>
				:
					<img src={apartmentCardContent.feedCardImg} alt="" />
				}
				

				

					{/* <span>{apartmentCardContent.feedCardTag}</span> */}
				</div>
				<div className="feed-card-description">
					<p className="price-info">{details.demand} USD</p>
					<h4 className="name-clint">
						<Link onClick={()=>handleEdit(details.slug)}  to={ "/property/" + details.slug + " "}>
											{details.title}
										</Link>

					</h4>
					{/* <div className="feed-rating mb-3">
						<ReactStars
							count={5}
							onChange={ratingChanged}
							size={24}
							activeColor="#d7524d"
						/>
					</div> */}
					<div className="feed-card-info">
						<div>
							<span>
								<img src={apartmentCardContent.feedCardBedIcon} alt="BedIcon" />
							</span>
							<span>{details.beds}</span>
						</div>
						<div>
							<span>
								<img src={apartmentCardContent.feedCardSpaceIcon} alt="" />
							</span>
							<span>{details.size}</span>
						</div>
						<div>
							<span>
								<img src={apartmentCardContent.feedCardBathIcon} alt="" />
							</span>
							<span>{details.baths}</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
