import React, { useState } from 'react';
import { Link } from "react-router-dom";
import LeftSidebar from "../../layouts/leftSider";
import SettingSider from "../../layouts/settingSider";
import Cookies from 'js-cookie';
import Alert from "react-bootstrap/Alert";
import axios from 'axios';
import {Modal, Button} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck , faBars } from '@fortawesome/free-solid-svg-icons'


async function sendCode(credentials) {

    return fetch(process.env.REACT_APP_API_URL+'api/user/send/mobile/code', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Content-Type': 'application/json' ,
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
  }
  async function checkCode(credentials) {

    return fetch(process.env.REACT_APP_API_URL+'api/user/check/mobile/code', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
  }



export default function EditProfileContent({ onEdit }) {

  const [showLeftSideMenu, setShowLeftSideMenu] = useState(false);
  const handleCloseLeftSideMenu = () => setShowLeftSideMenu(false);
  const handleShowLeftSideMenu = () => setShowLeftSideMenu(true);

  const [old_password, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [password_confirmation, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [url, setUrl] = useState();
  const [image, setImage] = useState('');
  const [frontfilename, setFrontfilename] = useState('');
  const [item, setItem] = React.useState([]);
  const userToken = Cookies.get('token');
  const userid = Cookies.get('user_id');
	const [usStates, setUsStates] = useState(false);
    const [is_varified, setIsVarified] = useState(false);
    const [code, setCode] = useState('');
    const [phone, setPhone] = useState('');
    const isphonevarified = Cookies.get('isphonevarified');
    React.useEffect(() => {
        setIsVarified(isphonevarified);
    }, [isphonevarified]); // <-- Have to pass in [] here!
    React.useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'api/user/detail' , {
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin' : 'http://localhost:3000/',
            'Access-Control-Allow-Credentials' : 'true/',
            'Content-Type': 'application/json' ,
            'Authorization': 'Bearer '+userToken+'',
          },
        }
          )
          .then(results => results.json())
          .then(data => {
         
           
            setPhone(data.user.phone);
    
          });
      }, []); // <-- Have to pass in [] here!

      const normalizeInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;
        
        if (!previousValue || value.length > previousValue.length) {
          if (cvLength < 4) return currentValue;
          if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
          return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
        }
      };
  
    const handlePhone = (val) => {
      setPhone(normalizeInput(val, phone));
      };

      

    const handleSendCode = async e => {
        e.preventDefault();
        setErrorMessage(null);
        setSuccessMessage(null);
        let error = 0;
        if(phone.length !== 14){
          let checkerror = {phone:"Invalid phone format. ex: (555) 555-5555"};
          setErrorMessage(checkerror);
          error = 1;
        }
          if(!phone ){
        
            let checkerror = {phone:"Please Enter Phone"};
            setErrorMessage(checkerror);
            error = 1;
          }

          if(!error){

        const res = await sendCode({
          'phone' : phone,
          } );
          if(res.success){
            if(res.response!='error'){
              setSuccessMessage('Code has been sent please check your phone.');
            }else{
              setErrorMessage({phone:"Error while sending message."});
            }
           // setReloadBoard(Date.now());
          }else{
          
           setErrorMessage(res.errors);
          }
        }
  
        }

        const handleCheckCode = async e => {
            e.preventDefault();
            setErrorMessage(null);
            setSuccessMessage(null);
            const res = await checkCode({
             'phone' :   phone,
                code,
                userToken
              } );
              if(res.success){
                if(res.response=='correct'){
                    setIsVarified(1);
                    Cookies.set('isphonevarified' , 1);
                    
                    setSuccessMessage('Phone Verification completed.');
                    
                }else if(res.response=='Already Found'){
                    setSuccessMessage('Phone code already sent.');
                }
                
                else{
                    setErrorMessage({wrong:'Sorry you have entered wrong code'});
                }
                //setSuccessMessage('Code has been sent please check you phone.');
               // setReloadBoard(Date.now());
              }else{
              
               setErrorMessage(res.errors);
              }
      
            }
      

    




    

    return (
      
        <section className="edit-profile-content row">
        <div  className='message-pages-top  w-100'>
            <div className="d-flex mx-4 mt-4 justify-content-between">

              <div className=" d-lg-none   ">
              <FontAwesomeIcon  onClick={handleShowLeftSideMenu} icon={faBars} />
                  
                </div>
            
            </div>

          </div>

        <div className="sidebar col-md-6 col-lg-2 col-0 d-none d-lg-block h-auto">  
            <LeftSidebar />
          </div>
     
          <div className="sidebar search-sidebar col-md-6 col-lg-2 col-0 d-none d-lg-block h-auto ">
            <SettingSider />
          </div>



          {/* <div  className='side-bar d-none d-lg-block h-auto'>
             <div className='' style={{     backgroundColor:' #f6f9fe' }}>   <LeftSidebar /></div>
             <div className='' style={{     backgroundColor:' #f6f9fe' }}><SettingSider /></div>

          </div> */}


         <Modal    dialogClassName='custom-dialog' show={showLeftSideMenu} onHide={handleCloseLeftSideMenu} size="lg"  >
					
					<Modal.Body>
					<div style={{     width:'25px' }} className="cursor-pointer bg-primary px-2 py-1 mb-2 " onClick={handleCloseLeftSideMenu}>x</div>
          
          <div className='row'>
          <div className="sidebar col-6  ">  
            <LeftSidebar />
          </div>
     
          <div className="sidebar search-sidebar col-6   ">
            <SettingSider />
          </div>
          </div>
         

					</Modal.Body>
					
				</Modal>

       
          <div className="edit-container ol-xsm-12 col-sm-12   col-md-12 col-lg-11 col-12">
         
          <h3 className="signup-message">Please enter the code sent to your phone number </h3>
        <div className="row mx-0 animatefadein">
       
           {(is_varified==1)?
           <>

           <Alert variant="success" >
           
                   <p>Phone  number Verified  <FontAwesomeIcon className="  text-success" icon={faCircleCheck} /></p>
          
           </Alert>
      </>
           :
           <>
           <div className="row edit-form">
            <div className="col-md-12">


            {successMessage && (
            <Alert variant="success" >
                 {successMessage && (
                <p>  {successMessage} </p>
                )}
                </Alert>

          )}

            {errorMessage && (
            <Alert variant="danger" >
               {errorMessage.phone && (
                <p>  {errorMessage.phone} </p>
                )}

                {errorMessage.code && (
                <p>  {errorMessage.code} </p>
                )}

            {errorMessage.wrong && (
                    <p>  {errorMessage.wrong} </p>
                )}
                 
                </Alert>

          )}
          </div>
              <div className="signup-input col-md-6">
                <label>Phone</label>

                <input type="tel" pattern="\(\d{3}\)[ ]?\d{3}[-]?\d{4}" maxlength="14"  placeholder="(xxx) xxx-xxxx" value={phone} onChange={e => handlePhone(e.target.value)}  />
              </div>
              <div className="signup-input col-md-6">
                  <label>Code</label>
                  <input type="text" value={code} onChange={e => setCode(e.target.value)} />
              </div>
            
            
           
              <div className="signup-submit">
              <button type="button" onClick={handleSendCode} className="submit-btn ml-3">Send Code </button>
              <button type="button"  onClick={handleCheckCode} className="submit-btn mr-3">Check Code </button>
            </div>
            </div>
            
           
           </>
           
           }
              </div>
          </div>
        </section>
      
    );
  }