import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types';
import Alert from "react-bootstrap/Alert";
import Cookies from 'js-cookie';
import {
  LoginSocialGoogle,
  LoginSocialAmazon,
  LoginSocialFacebook,
  LoginSocialGithub,
  LoginSocialInstagram,
  LoginSocialLinkedin,
  LoginSocialMicrosoft,
  LoginSocialPinterest,
  LoginSocialTwitter,
  IResolveParams
} from 'reactjs-social-login';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck , faBars  , faPlus , faCheck , faFacebook} from '@fortawesome/free-solid-svg-icons'
import { faFacebookF , faGoogle} from '@fortawesome/free-brands-svg-icons'


import { useHistory } from "react-router-dom";

      async function loginUser(credentials) {
        return fetch(process.env.REACT_APP_API_URL+'api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(credentials)
        })
          .then(data => data.json())
      }

  export default function LoginForm({ setToken }) {

    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState('')
    const amazonRef = useRef(null)
    const instagramRef = useRef(null)
    const googleRef = useRef(null)
    const [googleRefresh, setGoogleRefresh ] = useState(Date.now())
    const facebookRef = useRef(null)
    const microsoftRef = useRef(null)
    const linkedinRef = useRef(null)
    const githubRef = useRef(null)
    const pinterestRef = useRef(null)
    const twitterRef = useRef(null)
    const REDIRECT_URI = 'http://localhost:3000/login'

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const history = useHistory();

    const onLogout = useCallback(() => {
      switch (provider) {
        case 'amazon':
          amazonRef.current?.onLogout()
          break
        case 'facebook':
          facebookRef.current?.onLogout()
          break
        case 'google':
          googleRef.current?.onLogout()
          break
        case 'instagram':
          instagramRef.current?.onLogout()
          break
        case 'microsoft':
          microsoftRef.current?.onLogout()
          break
        case 'github':
          githubRef.current?.onLogout()
          break
        case 'pinterest':
          pinterestRef.current?.onLogout()
          break
        case 'twitter':
          twitterRef.current?.onLogout()
          break
        case 'linkedin':
          linkedinRef.current?.onLogout()
          break
        default:
          break
      }
    }, [provider])
  
  
    const onLoginStart = useCallback(() => {
      console.log('login start')
      setGoogleRefresh("new");
    }, [])
  
    const onLogoutFailure = useCallback(() => {
      alert('logout fail')
    }, [])
  
    const onLogoutSuccess = useCallback(() => {
      setProfile(null)
      //setProvider('')
      alert('logout success')
    }, [])
  
    const handleSocialLogin = (user , prov) => {
      let providerid = '';
       let first_name = '';
       let last_name = '';
       let email = '';
       let access_token = ''
       console.log('provider handleSocialLogin' , prov);
      if(prov=='facebook'){
        first_name =  user.first_name;
        last_name = user.last_name;
        email = user.email;
        providerid = user.id;
        access_token = user.accessToken;

        fetch(process.env.REACT_APP_API_URL+'api/author/check/socialLogin' , {
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin' : 'http://localhost:3000/',
            'Content-Type': 'application/json' ,
          },
          body: JSON.stringify({'providerid' : providerid , 'provider' : prov })
        }
          )
          .then(results => results.json())
          .then(data => {
             if(data.response){
               if(data.response=="usernotfound"){
                //redirect to signup
                Cookies.set('socialuserproviderid', providerid);
                Cookies.set('socialuserfirstname', first_name);
                Cookies.set('socialuserlastname', last_name);
                Cookies.set('socialuseremail', email);
                Cookies.set('socialusereprovider', prov);
                Cookies.set('providerjson', access_token);
                history.push("/signup");
               }else{
                 //log him in 
    
                 Cookies.set('token', data.user.api_token);
                 Cookies.set('user_id',data.user.id);
                 Cookies.set('first_name', data.user.first_name);
                 Cookies.set('kyc_required', data.user.kyc_required);
                 Cookies.set('last_name', data.user.last_name);
                 Cookies.set('user_type', data.user.user_type);
                 Cookies.set('user_slug', data.user.slug);
                 Cookies.set('user_state', data.user.state);
                 Cookies.set('package', data.user.packageSelected);
                 Cookies.set('isphonevarified', data.user.isphonevarified);
                 Cookies.set('kycsubmitted' , data.user.kycsubmitted);
                 (data.user.image!='' && data.user.image!=null)?
                 Cookies.set('profile_image',  JSON.parse(data.user.image).path[0])
                 :
                 Cookies.set('profile_image', null)
         
               
                history.push("/edit-profile");
    
               }
             }
          });


      }else{
        console.log("its google");

        fetch('https://www.googleapis.com/oauth2/v1/userinfo?access_token='+user.access_token+''  , {
          method: 'GET',
       
        }
          )
          .then(results => results.json())
          .then(data => {
            

               first_name =  data.given_name;
                last_name = data.family_name;
                email = data.email;
                providerid = data.id;
                access_token = user.access_token;
                //setProvider('google');
                fetch(process.env.REACT_APP_API_URL+'api/author/check/socialLogin' , {
                  method: 'POST',
                  headers: {
                    'Access-Control-Allow-Origin' : 'http://localhost:3000/',
                    'Content-Type': 'application/json' ,
                  },
                  body: JSON.stringify({'providerid' : providerid , 'provider' : prov })
                }
                  )
                  .then(results => results.json())
                  .then(data => {
                     if(data.response){
                       if(data.response=="usernotfound"){
                        //redirect to signup
                        Cookies.set('socialuserproviderid', providerid);
                        Cookies.set('socialuserfirstname', first_name);
                        Cookies.set('socialuserlastname', last_name);
                        Cookies.set('socialuseremail', email);
                        Cookies.set('socialusereprovider', prov);
                        Cookies.set('providerjson', access_token);
                        history.push("/signup");
                       }else{
                         //log him in 
            
                         Cookies.set('token', data.user.api_token);
                         Cookies.set('user_id',data.user.id);
                         Cookies.set('first_name', data.user.first_name);
                         Cookies.set('kyc_required', data.user.kyc_required);
                         Cookies.set('last_name', data.user.last_name);
                         Cookies.set('user_type', data.user.user_type);
                         Cookies.set('user_slug', data.user.slug);
                         Cookies.set('user_state', data.user.state);
                         Cookies.set('package', data.user.packageSelected);
                         Cookies.set('isphonevarified', data.user.isphonevarified);
                         Cookies.set('kycsubmitted' , data.user.kycsubmitted);
                         (data.user.image!='' && data.user.image!=null)?
                         Cookies.set('profile_image',  JSON.parse(data.user.image).path[0])
                         :
                         Cookies.set('profile_image', null)
                 
                       
                        history.push("/edit-profile");
            
                       }
                     }
                  });


          });


      
      }
     
      
    
    };
    
    const handleSocialLoginFailure = (err) => {
      console.error(err);
    };


    const device_token = Cookies.get('device_token');
    const handleSubmit = async e => {
      e.preventDefault();
      setErrorMessage(null);
      let token = null
      if(token === null){
        token = await loginUser({
          email,
          password,
          device_token,  
        });
        if(token.loggedin){
        //  sessionStorage.setItem("token", token.author.api_token);
        //   sessionStorage.setItem("user_id", token.author.id);
        //   sessionStorage.setItem("first_name", token.author.first_name);
        //   sessionStorage.setItem("last_name", token.author.last_name);
          Cookies.set('token', token.author.api_token);
          Cookies.set('user_id',token.author.id);
          Cookies.set('first_name', token.author.first_name);
          Cookies.set('kyc_required', token.author.kyc_required);
          Cookies.set('last_name', token.author.last_name);
          Cookies.set('user_type', token.author.user_type);
          Cookies.set('user_slug', token.author.slug);
          Cookies.set('user_state', token.author.state);
          Cookies.set('package', token.author.packageSelected);
          Cookies.set('isphonevarified', token.author.isphonevarified);
          Cookies.set('kycsubmitted' , token.author.kycsubmitted);
          (token.author.image!='' && token.author.image!=null)?
          Cookies.set('profile_image',  JSON.parse(token.author.image).path[0])
          :
          Cookies.set('profile_image', null)
  
        
         history.push("/edit-profile");
        }else{
          setErrorMessage(token.errors);
        }
      }

      
      //setToken(token);
    }

    return (
      <form onSubmit={handleSubmit} className="mt-5 d-flex justify-content-center">
      <section className="login-form  mt-5">
        <h2 className="login-form-title mt-5 pt-5">Login</h2>
        <h3 className="login-message">Please login to continue</h3>
        {errorMessage && (
            <Alert variant="danger" >
                  <Alert.Heading>
                  {errorMessage}
                  </Alert.Heading>
                </Alert>

          )}
        <div className="login-input">
            <label>Email</label>
            <input type="email" onChange={e => setEmail(e.target.value)} />
        </div>
        <div className="login-input">
            <label>Password</label>
            <input type="password"  onChange={e => setPassword(e.target.value)}/>
        </div>
        <a href="/forgotpassword"> <p className="forgot-password">Forgot password?</p> </a>
        <button type="submit" className="login-page-btn">LOGIN</button>
        <h3 className="social-media-title">-- OR Login with Social Media</h3>
        <ul className="social-icons">
            <li>
            <LoginSocialFacebook
            className='socialicon'
          ref={facebookRef}
          appId={process.env.REACT_APP_FB_APP_ID || ''}
          onLoginStart={onLoginStart}
          onLogoutSuccess={onLogoutSuccess}
          onResolve={({ provider, data }: IResolveParams) => {
             setProvider(provider)
             setProfile(data)
             handleSocialLogin(data , 'facebook');
            console.log("data" ,data);
            // console.log("provider" ,provider);
          }}
          onReject={(err) => {
          //  window.location.reload();
          }}
        >
      
        <FontAwesomeIcon   icon={faFacebookF} />
        </LoginSocialFacebook>


              </li>


           
            <li>

          
                <LoginSocialGoogle
                className='socialicon'
                //ref={googleRef}
                client_id={process.env.REACT_APP_GG_APP_ID || '1059994461509-uui82o2cneedecpa3s75ta33k5pa02j9.apps.googleusercontent.com'}
               // onLogoutFailure={onLogoutFailure}
                //onLoginStart={onLoginStart}
                onLogoutSuccess={onLogoutSuccess}
                
                scope="openid profile email"
                onResolve={({ provider, data }: IResolveParams) => {
                  setProvider('google')
                  setProfile(data)
                  handleSocialLogin(data , 'google');
                  console.log("provider setting" ,provider);
                }}
                onReject={err => {
                  ///window.location.reload();
                }}
                >
                  <FontAwesomeIcon   icon={faGoogle} />
                </LoginSocialGoogle>
            
       
              
             
              
              
              </li>
        </ul>
        <a href="/signup" > <button type='button'  className="login-page-btn mb-5" > Go to sign up </button></a>
      </section>
      </form>
    );
  }

  LoginForm.propTypes = {
    setToken: PropTypes.func.isRequired
  }
  