import React, { useState } from 'react';



  export default function SignupForm(props) {
    const [usStates, setUsStates] = useState(props.usStates);

    const [cities, setCities] = useState(props.cities);

    React.useEffect(() => {
      setUsStates(props.usStates);
      setCities(props.cities);
    },[props.cities]); // <-- Have to pass in [] here!


    const handleCities = (val) => {
     // console.log('handleCities' , usStates[val].cities);
      setCities(usStates[val].cities);
      };

   


    return (
    <>
  <ol class="progtrckr">
  <li class="progtrckr-done"onClick={() => props.setCount(0)} value="0">
     <span> User Type</span>
    </li>
    <li class="progtrckr-doing" value="1">
      <span>Personal Information</span>
    </li>
    <li class="progtrckr-todo" value="2">
      <span>Package</span>
    </li>
    <li class="progtrckr-todo" value="3">
     <span>Additional information</span>
      </li>
    </ol>
  <h3 className="signup-message">Please Join </h3>
        <div className="row animatefadein">
          <div className="signup-input col-md-6">
              <label>First Name</label>
              <input type="text"  value={props.first_name} onChange={e => props.onFirstName(e.target.value)} />
          </div>
          <div className="signup-input col-md-6">
              <label>Last Name</label>
              <input type="text"  value={props.last_name}  onChange={e => props.onLastName(e.target.value)} />
          </div>
          {/* <div className="signup-input col-md-6">
              <label>Company / Organization</label>
              <input type="text" value={props.company} onChange={e => props.onCompany(e.target.value)} />
          </div> */}
          
          
          <div className="signup-input col-md-6">
              <label>Email</label>
              <input type="email" value={props.email}  onChange={e => props.onEmail(e.target.value)} />
          </div>
          <div className="signup-input col-md-6">
              <label>Phone</label>
              <input type="tel" pattern="\(\d{3}\)[ ]?\d{3}[-]?\d{4}" maxlength="14"  placeholder="(xxx) xxx-xxxx" value={props.phone}  onChange={e => props.onPhone(e.target.value)}/>
          </div>
          <div className="signup-input col-md-6">
          <label>State</label>
          <select  name="" id=""  onChange={e =>  {props.handleIsState(e.target.value) ; handleCities(e.target.selectedIndex) }}
                                        >
           
              {(usStates)&&  
              usStates.map((val, index) => (
                <option key={index}  value={val.name} data-id={index}>{val.name}</option>
              ))
     
              }
          
            </select>
            </div>

            <div className="signup-input col-md-6">
            <label>City</label>
           
              <select  onChange={e => props.onCity(e.target.value)}>
              {(cities)&&  
              cities.map((val, index) => (
                <option key={index}  value={val.name} data-id={index}>{val.name}</option>
              ))
     
              }
              </select>

          </div>
         
          {(props.provider=='none') && 
          <>
          <div className="signup-input col-md-6">
          <label>Password (At least 8 characters)</label>
            <input type="password"  onChange={e => props.onPassword(e.target.value)}/>
          </div>
          <div className="signup-input col-md-6" >
              <label>Retype Password</label>
              <input type="password"  onChange={e => props.onConfirmPassword(e.target.value)} />
          </div>
          </>
          }
          
        </div>
        <div className="cbox">
          <input type="checkbox" name="checkA" id="checkA" defaultChecked={props.checked}
        onChange={() => props.onIsChecked(!props.checked)}  />
          <label htmlFor="checkA">Do you accept our  <a href='/terms-conditions' target="_blank"> terms of use </a>? </label>
        </div>
        <div className="cbox">
          <input type="checkbox" name="checkA" id="checkB"  defaultChecked={props.checkedPrivacy}
        onChange={() => props.onIsCheckedPrivacy(!props.checkedPrivacy)}   />
          <label htmlFor="checkB">Do you accept the <a href='/privacy-policy' target="_blank"> privacy policy </a>?</label>
        </div>
        <div className="signup-submit">
          {/* <button   type="button"  className="signup-btn">Next</button> */}
        </div>
        </>
    );
  }
  