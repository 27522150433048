import {
  addedPostImage,
  commentIcon,
  LikeIcon,
  locationIcon,
  mapImage,
  photoIcon,
  postImageOne,
  postImageThree,
  postImageTwo,
  propertiesIcon,
  shareIcon,
  submitCommentIcon,
  User,
  userLikeImage,
} from "../../utils/images";

import moment from 'moment';
import LeftSidebar from "../../layouts/leftSider";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import NotificationPanel from "../../layouts/notificationpanel";
import AssociatedProperties from "./associatedProperties";
import Post from "../Posts/post";
import React, { useState , useRef } from "react";
import { post   ,detail , profile  } from "../../routers/routePath";
import Cookies from 'js-cookie';
import Lightbox from 'react-image-lightbox';
import Alert from "react-bootstrap/Alert";
import axios from 'axios';
import {  toast } from 'react-toastify';
import Select from 'react-select';
import CreatableSelect  from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck , faBars , faPencil , faPenSquare } from '@fortawesome/free-solid-svg-icons'

import EditProfileContent from "../../components/admin/editProfileSocial";
import YouTube , { YouTubeProps } from 'react-youtube';
import Dropdown from 'react-bootstrap/Dropdown';


async function updateQuestions(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/user/save/questions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

async function updateTags(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/profile/addtags', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}


async function profileUpdate(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/profile/addEducation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

async function profileGalleryUpdate(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/profile/upload-gallery', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

async function profileAddJob(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/profile/addJob', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}



export default function ProfileContent(props) {

  const playerRef = useRef(null);

  const pauseVideo = () => {
    playerRef.current.internalPlayer.isMuted().then(val => {
      if(val){
        playerRef.current.internalPlayer.unMute();
     
      }else{
        playerRef.current.internalPlayer.mute();
      }
      setMute(val);
  })
  };

  function youtube_parser(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
}


  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    // access to player in all event handlers via event.target
    event.target.playVideo();
  }

  const opts: YouTubeProps['opts'] = {
    height: '200',
    width: '200',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };


  const [item, setItem] = React.useState([]);

  const handleEdit = (item) => {
    props.handleEdit(item);
    setItem(item);
    setShowEditProfile(false);
  };


  const userToken =  Cookies.get('token');
  const user_id =  Cookies.get('user_id');
  const [posts, setPosts] = useState(props.posts);
  const [showEducation, setShowEducation] = useState(false);
  const [privateGallery, setPrivateGallery] = useState(false);
  
  const [showJob, setShowJob] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [educations , setEducations] = useState(props.details.educations);
  
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [jobs , setJobs] = useState(props.details.jobs);
  const [questions , setQuestions] = useState(props.details.questions);
  const [follow , setFollow] = useState(false);
    const [followButton , setFollowButton] = useState(false);
  const [tab, setTab] = useState('aboutme');
  const [numbering , setNumbering] = useState(-1);
    const [selectedSentRequest , setSelectedSentRequest] = useState('');
    const [unfollow , setUnfollow] = useState('');
    const { details } = props.details;
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [successMessageQ, setSuccessMessageQ] = useState('');
    const [degree, setDegree] = useState('');
    const [degreeID, setDegreeID] = useState('');
    const [institute, setInstitute] = useState('');
    const [eduFrom, setEduFrom] = useState('');
    const [eduTo, setEduTo] = useState('');
    const [areaofstudy, setAreaOfStudy] = useState('');
    const [company, setCompany] = useState('');
    const [position, setPosition] = useState('');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [jobID, setJobID] = useState('');
    const [responsibilities, setResponsibilities] = useState('');
    const [errorMessageJob, setErrorMessageJob] = useState('');
    const [successMessageJob, setSuccessMessageJob] = useState('');
    const [youfollow ,setYoufollow ] = useState([]);
    const [followsYou ,setFollowsYou ] = useState([]);
    const [images, setImages] = useState([]);
    const [initCount, setInitCount] = useState(0);
    const [imagesGallery, setImagesGallery] = useState();

    const [questionsSelected, setQuestionsSelected] = useState();
  
  const [first, setFirst] = useState(true);
  const [alreadyquestionsSelected, setAlreadyQuestionsSelected] = useState();
  const [inputData, setInputData] = useState([]);

  const [tagsSelected, setTagsSelected] = useState();
  const [alreadytagsSelected, setAlreadyTagsSelected] = useState();
  const [youtubeid, setYoutubeid] = useState('');
  React.useEffect(() => {
    setPrivateGallery(props.details.privateGallery);
        setTab(props.firsttab);
        setYoutubeid();
    }, [props.firsttab , props.details.privateGallery]); // <-- Have to pass in [] here!

    React.useEffect(() => {
   
      if(props.details.youtube && props.details.youtube!=''){
        setYoutubeid(youtube_parser(props.details.youtube));
      }else{
       // setYoutubeid('uE-TADy-oN0');
       setYoutubeid('');
      }
   
      }, [ props.details]); // <-- Have to pass in [] here!

  React.useEffect(() => {
    if(props.details.imagesGallery){
      if(props.details.imagesGallery!='' ){
      setImagesGallery(JSON.parse(props.details.imagesGallery));

    }else{
      setImagesGallery('');
    }
  }else{
    setImagesGallery('');
  }
        
    }, [props.details.imagesGallery]); // <-- Have to pass in [] here!

  const handleChangeImagesGallery =  e => {
    //setFiles(e.target.files)
    setErrorMessage(null);
    setSuccessMessage(null);
    const url = process.env.REACT_APP_API_URL+'api/uploadFile';
    const formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append(`files[${i}]`, e.target.files[i])
      }
        formData.append('directory' , 'profile');
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        axios.post(url, formData, config).then((response) => {
          let  path = [];
          let  realname = [];
            if(imagesGallery){
              path =	imagesGallery.path
              realname =	imagesGallery.realname
            }
            if(response.data.files.path){
              response.data.files.path.map(function(val, index){
                path = [...path ,val ];
              })
              response.data.files.realname.map(function(val, index){
            
                realname = [...realname ,val ];

              })
            }

            console.log("new" ,{ 'path' : path ,  'realname'  : realname });
            setImagesGallery({ 'path' : path ,  'realname'  : realname })

        });

      };


      const handleSetAsProfile = (img) =>{
       console.log("handleSetAsProfile" , img);

       let  path = [];
       let  realname = [];
      
     
        path = [...path ,img ];
         
        realname = [...realname ,img ];

           


        const res =  profileGalleryUpdate({
    
          userToken,
          'image' : JSON.stringify({ 'path' : path ,  'realname'  : realname })
          
        });
        if(res){
          
          setTimeout(
            function() {
              Cookies.set('profile_image',  img)
              window.location.reload();
            }, 2000);

          
        }


      }

      const handleRemoveImagesGallery = (i) => { 
    
        const imgarrayrealname = imagesGallery.realname;
        imgarrayrealname.splice(i, 1);
        const imgarraypath = imagesGallery.path;
        imgarraypath.splice(i, 1);
        setImagesGallery({ 'path' : imgarraypath ,  'realname'  : imgarrayrealname});
        
       }


  React.useEffect(() => {


  var newArray = inputData;
  const answerednew = [];

  newArray.map((item, index) => (
              
    (questionsSelected.find(x => x.value === item.id)) ? 
   
    answerednew.push(item)
      :
    
      answerednew.push({id: item.id,  question: item.question , status: item.status , created_at:item.created_at , updated_at:item.updated_at  })
     
     
  ))
  setInputData(answerednew);

}, [questionsSelected ]); // <-- Have to pass in [] here!
  const [options, setOptions] = useState([]);
  const aquaticCreatures = [];
  const selected = [];
  const answered = [];
  const customStyles = {
    container: provided => ({
      ...provided,
      width: 400
    })
  };
  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "api/questions", {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "http://localhost:3000/",
        "Access-Control-Allow-Credentials": "true/",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken + "",
      },
    })
      .then((results) => results.json())
      .then((data) => {
          if(data.questions){
            data.questions.map((item, index) => (
              aquaticCreatures[index] = {label: item.question,  value: item.id , answer:'' }
               

            ))

            data.questions.map((item, index) => (
              
              (data.userquestions.find(x => x.question_id === item.id)) ? 
                selected.push({label: item.question,  value: item.id , answer:data.userquestions.find(x => x.question_id === item.id).answer })
                :null 
               

            ))

          //  console.log("first" , selected);
            setQuestionsSelected(selected);
           // console.log("second", selected);


            data.questions.map((item, index) => (
              
              (data.userquestions.find(x => x.question_id === item.id)) ? 
              answered.push({id: item.id,  question: item.question , status: item.status , created_at:item.created_at , updated_at:item.updated_at , answer:data.userquestions.find(x => x.question_id === item.id).answer })
                :
                answered.push(item)
               

            ))


           


            setOptions(aquaticCreatures);
            setInputData(answered);
           // console.log("setInputData", answered);
          
          }
        
      });
  }, [ props.details]); // <-- Have to pass in [] here!

  //Movie tags start


  const [successMessageMovieTag, setSuccessMessageMovieTags] = useState();
  const [inputValueMovie, setInputValueMovie] = useState('');
  const [selectionMovie, setSelectionMovie] = useState([]);

  const movieTags = [];
  const selectedMovieTags = [];
  const [newSelectedMovie, setNewSelectedMovie] = useState([]);
  
  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "api/get-tags", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "http://localhost:3000/",
        "Content-Type": "application/json",
      },

     
      body: JSON.stringify({'id':props.details.id})
    })
      .then((results) => results.json())
      .then((data) => {
          if(data.movies_tags){
            data.movies_tags.map((item, index) => (
        
              movieTags[index] = {label: item.name,  value: item.id} 
           
               

            ))

            data.movies_tags.map((item, index) => (
              
              (data.usertags.find(x => x.tag_id === item.id )) ? 
              selectedMovieTags.push({label: item.name,  value: item.id })
                :null 
               

            ))

            setNewSelectedMovie(selectedMovieTags);
            setSelectionMovie(movieTags);

            
      
          
          }
        
      });
  }, [ props.details]); // <-- Have to pass in [] here!
  



  const handleInputChange = (value) => {
    setInputValueMovie(value);
  };

  const handleOnChange = (val) => {
    const newOption = { label: inputValueMovie, inputValueMovie };

    inputValueMovie !== '' && setSelectionMovie([...selectionMovie, newOption]);

    setInputValueMovie('');

    setNewSelectedMovie(val); 

  };

  const handleSubmitMovieTags = async e => {
    e.preventDefault();
    setSuccessMessageMovieTags(null);
    const res = await updateTags({
      'tags' : JSON.stringify(newSelectedMovie),
      'tag_type':'movies',
      userToken
    });
    if(res.success){
    //  sessionStorage.setItem("token", token.author.api_token);
    setSuccessMessageMovieTags('Movies has been updated successfully.');
    toast.success("Movies has been updated successfully.");
    }else{
    
      //setErrorPasswordMessage(res.errors);
    }

  }



// moive tags end 


//sports tags start


const [successMessageSportsTag, setSuccessMessageSportsTags] = useState();
const [inputValueSports, setInputValueSports] = useState('');
const [selectionSports, setSelectionSports] = useState([]);
const [mute, setMute] = useState(true);
const sportTags = [];
const selectedSportsTags = [];
const [newSelectedSports, setNewSelectedSports] = useState([]);


console.log("props.details" ,props.details);
React.useEffect(() => {
  fetch(process.env.REACT_APP_API_URL + "api/get-tags", {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "http://localhost:3000/",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({'id':props.details.id})
  })
    .then((results) => results.json())
    .then((data) => {
        if(data.sports_tags){
          data.sports_tags.map((item, index) => (
          
            sportTags[index] = {label: item.name,  value: item.id} 
          
             

          ))

          data.sports_tags.map((item, index) => (
            
            (data.usertags.find(x => x.tag_id === item.id  )) ? 
            selectedSportsTags.push({label: item.name,  value: item.id })
              :null 
             

          ))

          setNewSelectedSports(selectedSportsTags);
          setSelectionSports(sportTags);
    
        
        }
      
    });
}, [ props.details]); // <-- Have to pass in [] here!




const handleInputChangeSports = (value) => {
  setInputValueSports(value);
};

const handleOnChangeSports = (val) => {
  const newOption = { label: inputValueSports, inputValueSports };

  inputValueSports !== '' && setSelectionSports([...selectionSports, newOption]);

  setInputValueSports('');

  setNewSelectedSports(val); 

};

const handleSubmitSportsTags = async e => {
  e.preventDefault();
  setSuccessMessageSportsTags(null);
  const res = await updateTags({
    'tags' : JSON.stringify(newSelectedSports),
    'tag_type':'sports',
    userToken
  });
  if(res.success){
  //  sessionStorage.setItem("token", token.author.api_token);
  setSuccessMessageSportsTags('Sports has been updated successfully.');
  toast.success("Sports has been updated successfully.");
  }else{
  
    //setErrorPasswordMessage(res.errors);
  }

}



//sports tags end 




  const handleSubmitGallery = async e => {
    console.log("uploding" , imagesGallery);
    e.preventDefault();
    setErrorMessage(null);
    const res = await profileGalleryUpdate({
    
      userToken,
      'imagesGallery' : JSON.stringify(imagesGallery),
      privateGallery,

      
    });
    if(res.success){
      
    //  sessionStorage.setItem("token", token.author.api_token);
    setSuccessMessage('Your Profile has been updated successfully.');
    toast.success("Your Profile has been updated successfully.");
    }else{
    
      setErrorMessage(res.errors);
    }
  }
  const handleSubmitQuestion = async e => {
    e.preventDefault();
    setSuccessMessageQ(null);
    const res = await updateQuestions({
      'inputData' : JSON.stringify(inputData),
      userToken
    });
    if(res.success){
      setQuestions(res.questionts);
    //  sessionStorage.setItem("token", token.author.api_token);
    setSuccessMessageQ('Questionnaires has been updated successfully.');
    toast.success("Questionnaires has been updated successfully.");
    }else{
    
      //setErrorPasswordMessage(res.errors);
    }

  }

      const handleInputValue = (value , id) => {


         var newArray = inputData;

        for (let i = 0; i < newArray.length; i++) {
          if(newArray[i].id==id){
            newArray[i].answer = value;
          }
            
        }
        setFirst(false);
        setInputData(newArray);

      }


    const handleSubmit = async e => {
      e.preventDefault();
     
      setErrorMessage(null);
      setSuccessMessage(null);
        const res = await profileUpdate({
          'id':degreeID,
          degree,
          institute,
          areaofstudy,
          'from':eduFrom,
          'to' :eduTo,
          userToken,

          
        });
        if(res.success){
          setEducations(res.education);
        //  sessionStorage.setItem("token", token.author.api_token);
       

           //  sessionStorage.setItem("token", token.author.api_token);
           setSuccessMessage('Your Profile has been updated successfully.');
           toast.success("Your Profile has been updated successfully.");
           setDegree('');
           setDegreeID('');
           setEduFrom('');
           setEduTo('');
           setAreaOfStudy('');
           setInstitute('');

        //document.getElementById('edit-container').scrollIntoView({block: "center"});
        }else{
        
       
          setErrorMessage(res.errors);
          //document.getElementById('edit-container').scrollIntoView({block: "center"});
        }
  
      }

     
       function handleEditEducation(t){
            setDegree(t.degree);
            setDegreeID(t.id);
            setEduFrom(t.from);
            setEduTo(t.to);
            setAreaOfStudy(t.areaofstudy);
            setInstitute(t.institute);
            setShowEducation(true);
      }

      function handleEditJob(t){
        setCompany(t.company);
        setJobID(t.id);
        setPosition(t.position);
        setTo(t.to);
        setFrom(t.from);
        setResponsibilities(t.responsibilities);
        setShowJob(true);
  }



  

      const handleSubmitJob = async e => {
        e.preventDefault();
        setErrorMessageJob(null);
        setSuccessMessageJob(null);
          const res = await profileAddJob({
            'id' : jobID,
            company,
            position,
            from,
            to,
            responsibilities,
            userToken,
  
            
          });
          if(res.success){
            setJobs(res.jobs);
            if(jobID!=''){
              setSuccessMessageJob('Job updated successfully.');
              toast.success("Job updated successfully.")
            }else{
              setSuccessMessageJob('Job added successfully.');
              toast.success("Job added successfully.")
            }
            
            setCompany('');
            setPosition('');
            setFrom('');
            setTo('');
            setJobID('');
            setResponsibilities('');
          //document.getElementById('edit-container').scrollIntoView({block: "center"});
          }else{
          
            setErrorMessageJob(res.errors);
           // document.getElementById('edit-container').scrollIntoView({block: "center"});
          }
    
        }

    React.useEffect(() => {
      if(unfollow!=''){
      fetch(process.env.REACT_APP_API_URL+'api/friends/unfriend' , {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin' : 'http://localhost:3000/',
          'Access-Control-Allow-Credentials' : 'true/',
          'Content-Type': 'application/json' ,
          'Authorization': 'Bearer '+userToken+'',
        },
        body: JSON.stringify({'target':unfollow})
      }
        )
        .then(results => results.json())
        .then(data => {
          
          setFollow(false);
          
        });
      }
    }, [unfollow]); // <-- Have to pass in [] here!


  React.useEffect(() => {
        if(selectedSentRequest!=''){
        fetch(process.env.REACT_APP_API_URL+'api/friends/friendreq' , {
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin' : 'http://localhost:3000/',
            'Access-Control-Allow-Credentials' : 'true/',
            'Content-Type': 'application/json' ,
            'Authorization': 'Bearer '+userToken+'',
          },
          body: JSON.stringify({'id':selectedSentRequest})
        }
          )
          .then(results => results.json())
          .then(data => {
     
            setFollowButton(true);
          });
        }
      }, [selectedSentRequest ]); // <-- Have to pass in [] here!


    React.useEffect(() => {

if(user_id!=props.details.id){
 
    if(userToken && props.details){
   
        fetch(process.env.REACT_APP_API_URL+'api/friends/users' , {
          method: 'GET',
          headers: {
            'Access-Control-Allow-Origin' : 'http://localhost:3000/',
            'Access-Control-Allow-Credentials' : 'true/',
            'Content-Type': 'application/json' ,
            'Authorization': 'Bearer '+userToken+'',
          },
        } 
          )
          .then(results => results.json())
          .then(data => {
            
            if(data.requestsent){
                    data.requestsent.map((friend) => { 
                        if(friend.id==props.details.id){
                            setFollow(true);
                        }
                     })
            }
          });



          fetch(process.env.REACT_APP_API_URL+'api/follows/get' , {
            method: 'GET',
            headers: {
              'Access-Control-Allow-Origin' : 'http://localhost:3000/',
              'Access-Control-Allow-Credentials' : 'true/',
              'Content-Type': 'application/json' ,
              'Authorization': 'Bearer '+userToken+'',
            },
          }
            )
            .then(results => results.json())
            .then(data => {
         

              if(data.youfollow){
                data.youfollow.map((friend) => { 
                  if(friend.sender_id==props.details.id){
                    setFollow(true);
                  }
                 })
            }

            if(data.followsyou){
              data.followsyou.map((friend) => { 
                if(friend.receiver_id==props.details.id){
                  setFollow(true);
                }
               })
          }

            });


        }else{
            setFollow(true);
        }
}
    





      }, [props.details]); // <-- Have to pass in [] here!


  React.useEffect(() => {
        setPosts(props.posts);
    }, [props.posts]); // <-- Have to pass in [] here!


  React.useEffect(()=>{
        setFollowButton(follow);
      },[follow])

    React.useEffect(() => {
      setEducations(props.details.educations);
    }, [props.details.educations]); // <-- Have to pass in [] here!
    React.useEffect(() => {
      setShowEducation(false);
      setShowJob(false);
      setShowEditProfile(false);
      setSuccessMessage(null);
      setSuccessMessageQ(null);
      setSuccessMessageJob(null);
      setSuccessMessageMovieTags(null);
      setSuccessMessageSportsTags(null);
      setErrorMessageJob(null);
      setErrorMessage(null);
      

    }, [tab]); // <-- Have to pass in [] here!

    React.useEffect(() => {
      setJobs(props.details.jobs);
    }, [props.details.jobs]); // <-- Have to pass in [] here!

    React.useEffect(() => {
      setQuestions(props.details.questions);
    }, [props.details.questions]); // <-- Have to pass in [] here!


    React.useEffect(() => {
     
      setYoufollow(props.youfollow );
    }, [props.youfollow ]); // <-- Have to pass in [] here!

    React.useEffect(() => {
    
      setFollowsYou(props.followsYou);
    }, [props.followsYou]); // <-- Have to pass in [] here!



    React.useEffect(() => {

      // if(post.images){
      //   setImages(post.images.path);
      
      // }
      let  path = [];
      if(!props.details.privateBusiness && props.details.imagesBusinessPhoto ){
       if(JSON.parse(props.details.imagesBusinessPhoto)){
          JSON.parse(props.details.imagesBusinessPhoto).path.map(function(item, i){
           
            path = [...path ,item ];
          })
        }
        
      }
      if(!props.details.privateHavingFun && props.details.imagesPhotoHavingFun ){
       if(JSON.parse(props.details.imagesPhotoHavingFun)){
          JSON.parse(props.details.imagesPhotoHavingFun).path.map(function(item, i){
           
            path = [...path ,item ];
          })
        }
        
      }
      if(!props.details.privateFamily && props.details.imagesFamily ){
       if(JSON.parse(props.details.imagesFamily)){
          JSON.parse(props.details.imagesFamily).path.map(function(item, i){
           
            path = [...path ,item ];
          })
        }
        
      }
      if(!props.details.privateHobbies && props.details.imagesHobbies ){
       if(JSON.parse(props.details.imagesHobbies)){
          JSON.parse(props.details.imagesHobbies).path.map(function(item, i){
           
            path = [...path ,item ];
          })
        }
        
      }

      setImages(path);

      
      }, [props.details]); // <-- Have to pass in [] here!


    const [current , setCurrent] = useState('');
    const [prev , setPrev] = useState('');
    const [next , setNext] = useState('');
    
    
      const handleClickImage = (e, count) => {
        e && e.preventDefault();
        setInitCount(count);
        setCurrent(process.env.REACT_APP_IMAGES_URL + images[count]);
        if(count > 0){
          setPrev(process.env.REACT_APP_IMAGES_URL + images[count-1]);
        }else{
          setPrev('');
        }
    
        if(count + 1 == images.length  ){
          
          setNext('');
        }else{
          
          setNext(process.env.REACT_APP_IMAGES_URL + images[count+1]);
          
        }
      // console.log('images.length' , images.length);
        // console.log('current' , current);
        // console.log('next' , next);
        // console.log('prev' , prev);
         }
    

         const handleNextImage = () => {
        
        
        setCurrent(process.env.REACT_APP_IMAGES_URL + images[initCount + 1]);
        
        
          
          if(images[initCount]){
          
            setPrev(process.env.REACT_APP_IMAGES_URL + images[initCount]);
          }else{
            
            setPrev('');
          }
          
        
    
        if(initCount +1 == images.length  ){
          setNext('');
        }else{
          if(images[initCount + 2]){
            setNext(process.env.REACT_APP_IMAGES_URL + images[initCount + 2]);
          }else{
            setNext('');
          }
          
        
        }
        setInitCount(initCount+1);
    
         }
    
         const handlePrevImage = () => {
      
    
        setCurrent(process.env.REACT_APP_IMAGES_URL + images[initCount - 1 ]);
        if(initCount > 0){
          if(images[initCount-2]){
            setPrev(process.env.REACT_APP_IMAGES_URL + images[initCount-2]);
          }else{
            setPrev('');
          }
          
        }else{
          setPrev('');
        }
    
        
          if(images[initCount]){
            setNext(process.env.REACT_APP_IMAGES_URL + images[initCount]);
          }else{
            setNext('');
          }
          
        setInitCount(initCount-1);
         }
       
      const   handleCloseModal = (e) => {
        e && e.preventDefault();
       
        setCurrent('');
         }
    

  return (
    <div className="wrapper pt-0 ">
      {/* <div className="side-bar col-sm-12 col-md-3 p-4 profile-sidebar">
        <h1 className="profile-about-title">About user</h1>
        <ul className="about-details">
          <li>Name : {props.details.first_name} {props.details.last_name}</li>
        </ul>
        <h1 className="profile-about-title">Location</h1>
        <p className="location-text mt-2 mb-2"> {props.details.state},{props.details.city}</p>
   
        {/* <label className="about-label">Send a message</label>
        <input type="" class="form-control input-profile" id="input" placeholder="" />

      


      </div> */}
      <div className="content col-sm-12 col-md-12">
       
       
        <div className="feed-main-social">
        <div className="row mb-1">
        <div className="col-md-3">
          <button  type="button"  onClick={(e) => setTab('aboutme') } className={`${tab=='aboutme' ? 'btn profile-add-btn active' : 'btn profile-add-btn'}` }>About Me</button>
          </div>
          
          <div className="col-md-3">
          <button type="button" onClick={(e) => setTab('feeds') }  className={`${tab=='feeds' ? 'btn profile-add-btn active' : 'btn profile-add-btn'}` }> Feeds</button>
          </div>
          <div className="col-md-3">
          <button  type="button" onClick={(e) => setTab('photos') } className={`${tab=='photos' ? 'btn profile-add-btn active' : 'btn profile-add-btn'}` }>Photos</button>
          </div>
          <div className="col-md-3">
          <button  type="button"  onClick={(e) => setTab('connections') } className={`${tab=='connections' ? 'btn profile-add-btn active' : 'btn profile-add-btn'}` }>Connections</button>
          </div>
         
      
     
      
        </div>


        {(tab=='feeds')&&
        
        
        posts && 
        <div className="added-post profilephotos-post">
        {(posts.length>0)?
       
                    posts.map((post) => (
                                    
                      <Post key={post.id} postlink = {post.slug} post={post}></Post>


              
            ))
         

            : 
              
           <>No Posts available</>

          }
              </div>
        }


    {(tab=='aboutme')&&
          <div className="added-post profilephotos-post">

<h1 className="profile-about-title">About user</h1>



<div className="row p-2">

  <div className="col-md-6 profilesecionborder">
  <div className="row">
            <div className="col-6 my-2">
            Name : {props.details.first_name} {props.details.last_name}
            </div>
            <div className="col-6 my-2">
            Relationship Status : {props.details.relation_status} 
            </div>
        </div>
        <div className="row">
        <div className="col-6  my-2">
            Phone : {props.details.phone} 
            </div>
            <div className="col-6 my-2">
            Dob : { props.details.dob }  
            </div>
        </div>
        <div className="row">
           
            <div className="col-6  my-2">
            Location : {props.details.state},{props.details.city}
            </div>
        </div>

  </div>
  <div className="col-md-6 imgsectionborder ">
    <div className="row">

  {(!props.details.privateBusiness && props.details.imagesBusinessPhoto )&&
    <>
  
   
    {
     
     (JSON.parse(props.details.imagesBusinessPhoto)) && JSON.parse(props.details.imagesBusinessPhoto).path.map((img , index) => (
       <div className="col-md-3 px-1" key={index} >
       {/* <div className=" mt-1"  > <FontAwesomeIcon  icon={faPenSquare} style={{ fontSize: '20px'}} /> Set as Profile Photo</div>
            */}
              { (user_id==props.details.id) &&
              <div className="position-absolute  zindex">
                <Dropdown className="profileimg">
                <Dropdown.Toggle variant="Primary" id="dropdown-basic">
                <FontAwesomeIcon  icon={faPenSquare} style={{ fontSize: '20px'}} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item  data-tag={img} onClick={ e => handleSetAsProfile(img)}>Make as profile Photo</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              </div>
              }


           <img className="associated-property-img-profile" onClick={ e => handleClickImage(e, 0)  } width="100%"  key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 
         
       </div>
     
     ))}
 
     </>

  }


{(!props.details.privateHavingFun && props.details.imagesPhotoHavingFun )&&
                   <>
                  
                   {
                    (JSON.parse(props.details.imagesPhotoHavingFun)) && JSON.parse(props.details.imagesPhotoHavingFun).path.map((img , index) => (
                      <div className="col-md-3 px-1" key={index} >
                     
                     { (user_id==props.details.id) &&
              <div className="position-absolute  zindex">
                <Dropdown className="profileimg">
                <Dropdown.Toggle variant="Primary" id="dropdown-basic">
                <FontAwesomeIcon  icon={faPenSquare} style={{ fontSize: '20px'}} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item  data-tag={img} onClick={ e => handleSetAsProfile(img)}>Make as profile Photo</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              </div>
              }
                            <img  className="associated-property-img-profile" onClick={ e => handleClickImage(e, 0)} width="100%" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 
                          
                      </div>
                    
                    ))}
                    </>
            }


{(!props.details.privateFamily && props.details.imagesFamily)&&
                   <>
                 
                   {
                    (JSON.parse(props.details.imagesFamily)) && JSON.parse(props.details.imagesFamily).path.map((img , index) => (
                      <div className="col-md-3 px-1" key={index} >
                   
                   
                   { (user_id==props.details.id) &&
              <div className="position-absolute  zindex">
                <Dropdown className="profileimg">
                <Dropdown.Toggle variant="Primary" id="dropdown-basic">
                <FontAwesomeIcon  icon={faPenSquare} style={{ fontSize: '20px'}} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item  data-tag={img} onClick={ e => handleSetAsProfile(img)}>Make as profile Photo</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              </div>
              }
                           <img className="associated-property-img-profile" onClick={ e => handleClickImage(e, 0)} width="100%" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 

                      </div>
                    
                    ))}
                    </>
            }

{(!props.details.privateHobbies && props.details.imagesHobbies)&&
                   <>
               
                   {
                    (JSON.parse(props.details.imagesHobbies)) && JSON.parse(props.details.imagesHobbies).path.map((img , index) => (
                      <div className="col-md-3 px-1" key={index} >
                       { (user_id==props.details.id) &&
              <div className="position-absolute  zindex">
                <Dropdown className="profileimg">
                <Dropdown.Toggle variant="Primary" id="dropdown-basic">
                <FontAwesomeIcon  icon={faPenSquare} style={{ fontSize: '20px'}} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item  data-tag={img} onClick={ e => handleSetAsProfile(img)}>Make as profile Photo</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              </div>
              }
                     
                          <img className="associated-property-img-profile"  onClick={ e => handleClickImage(e, 0)} width="100%" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 
                         
                      </div>
                    
                    ))}
                    </>
            }

{(!props.details.privateBusiness &&  (props.details.imagesBusinessPhoto) && props.details.imagesBusinessPhoto.length > 0) || (!props.details.privateHavingFun &&  (props.details.imagesPhotoHavingFun) && props.details.imagesPhotoHavingFun.length > 0)  || (!props.details.privateFamily &&  (props.details.imagesFamily) && props.details.imagesFamily.length > 0) || (!props.details.privateHobbies &&  (props.details.imagesHobbies) && props.details.imagesHobbies.length > 0)?
  <></>
  :  <>No photos available</>
}


     
</div>
</div>

</div>

       <div className="d-flex justify-content-end">
        <button type="button"  className="btn details-img-btn mr-1" onClick={pauseVideo}>{  (mute)?'Mute':'Unmute'}</button>
 
        { (user_id!=props.details.id)?
        <>
        {(followButton)?
              <button   onClick={(e) => { setUnfollow(e.currentTarget.dataset.id) ; }   }  data-id={props.details.id} className="btn details-img-btn">Unfollow</button>
              :
                        <button  onClick={(e) => { setSelectedSentRequest(e.currentTarget.dataset.id) ; }   }  data-id={props.details.id} className="btn details-img-btn">Follow</button>
                        }
        </>
        :null
                      }
</div> 


     

            {(user_id==props.details.id && showEditProfile)?
              <EditProfileContent onEdit={handleEdit} />
              :
              (user_id==props.details.id ) &&
                <button   onClick={(e) => { setShowEditProfile(true)}   }   className="btn details-img-btn">Edit Profile</button>
              
             
            }

            <h1 className="profile-about-title mb-2">Education</h1>

     
              {(educations)&&  
              <ul className="about-details">
                      {(educations).map((val, index) => (
                        <li key={index}>- {val.degree} at {val.institute} from  { moment(val.from).format('MMM YYYY') } to  { moment(val.to).format('MMM YYYY') }  in {val.areaofstudy}   
                               {(user_id==props.details.id)&&

                                          <span className="editbtn" onClick={ (e) => handleEditEducation(val)  } >Edit</span> 
                                    }
                            </li>
                      ))}
                      </ul>
                      }
                


              {successMessage && (
                    <Alert variant="success" >
                        {successMessage && (
                        <p>  {successMessage} </p>
                        )}
                        </Alert>
                      )}
          
                {errorMessage && (
                  <Alert variant="danger" >
                      {errorMessage.degree && (
                      <p>  {errorMessage.degree} </p>
                      )}
                        {errorMessage.institute && (
                      <p>  {errorMessage.institute} </p>
                      )}
                      {errorMessage.from && (
                      <p>  {errorMessage.from} </p>
                      )}
                      {errorMessage.to && (
                      <p>  {errorMessage.to} </p>
                      )}
                    
                      
                      </Alert>

                )}

                {(user_id==props.details.id && showEducation)?
                  <>
                <input type="hidden" value={degreeID} class="form-control input-profile"  onChange={e => setDegreeID(e.target.value)} id="inputtext" placeholder="Degree" />
                <div className="row">
                  <div className="col-md-3 col-sm-6">
                    <input type="text" value={degree} class="form-control  input-profile"  onChange={e => setDegree(e.target.value)} id="inputtext" placeholder="Degree/Certificate " />
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <input type="text" value={institute} class="form-control  input-profile"  onChange={e => setInstitute(e.target.value)} id="inputtext" placeholder="Institute" />
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <input type="text" value={areaofstudy} class="form-control  input-profile"  onChange={e => setAreaOfStudy(e.target.value)} id="inputtext" placeholder="Area of Study" />
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <input  type="month"  value={eduFrom} class="form-control  input-profile" id="input" onChange={e => setEduFrom(e.target.value)} placeholder="From" />
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <input  type="month"  value={eduTo} class="form-control  input-profile" id="input" onChange={e => setEduTo(e.target.value)} placeholder="To" />
                  </div>
                
                </div>
                
                <button   onClick={(e) => { handleSubmit(e)}   }   className="btn details-img-btn">Add Education</button>
                </>
                :
                (user_id==props.details.id ) &&
                <button   onClick={(e) => { setShowEducation(true)}   }   className="btn details-img-btn">Add Education</button>
                    }

<h1 className="profile-about-title mb-2">Work</h1>


{(jobs)&&  
<ul className="about-details">
  {(jobs).map((val, index) => (
    <li key={index}>- Worked as {val.position} in {val.company} from { moment(val.from).format('MMM YYYY') } to { moment(val.to).format('MMM YYYY') } ({val.responsibilities}) 
    
    {(user_id==props.details.id )&&

<span className="editbtn"  onClick={ (e) => handleEditJob(val)  } > Edit</span>
                                    }
                                    
    </li>
  ))}
  </ul>
  }



{successMessageJob && (
<Alert variant="success" >
     {successMessageJob && (
    <p>  {successMessageJob} </p>
    )}
    </Alert>
  )}
    
{errorMessageJob && (
<Alert variant="danger" >
     {errorMessageJob.company && (
    <p>  {errorMessageJob.company} </p>
    )}
    {errorMessageJob.position && (
    <p>  {errorMessageJob.position} </p>
    )}
     {errorMessageJob.from && (
    <p>  {errorMessageJob.from} </p>
    )}
     {errorMessageJob.to && (
    <p>  {errorMessageJob.to} </p>
    )}
  
  {errorMessageJob.responsibilities && (
    <p>  {errorMessageJob.responsibilities} </p>
    )}
  
     
    </Alert>

)}

{(user_id==props.details.id && showJob)?
  <>
  <input type="hidden" value={jobID} class="form-control input-profile"  onChange={e => setJobID(e.target.value)} id="inputtext" placeholder="Organization" />
  <div className="row">
                  <div className="col-md-3 col-sm-6">
                      <input type="text" value={company} class="form-control input-profile"  onChange={e => setCompany(e.target.value)} id="inputtext" placeholder="Organization" />
                  </div>
                  <div className="col-md-3 col-sm-6">
                  <input type="text" value={position} class="form-control input-profile" id="input" onChange={e => setPosition(e.target.value)} placeholder="Role" />
                    </div>
                    <div className="col-md-3 col-sm-6">
                    <input type="month" value={from} class="form-control input-profile"  onChange={e => setFrom(e.target.value)} id="inputtext" placeholder="From" />
                    </div>
                    <div className="col-md-3 col-sm-6">
                    <input type="month" value={to} class="form-control input-profile" id="input" onChange={e => setTo(e.target.value)} placeholder="To" />
                    </div>
                    <div className="col-md-6 col-sm-6">
                    <textarea onChange={e => setResponsibilities(e.target.value)} class="form-control input-profile" placeholder="Responsibilities">{responsibilities}</textarea> 

                    </div>
    </div>





<button   onClick={(e) => { handleSubmitJob(e)}   }   className="btn details-img-btn">Add New Work</button>
</>
:
(user_id==props.details.id ) &&
<button   onClick={(e) => { setShowJob(true)}   }   className="btn details-img-btn">Add New Work</button>
    }


                  <h1 className="profile-about-title mb-2">About me</h1>

                          
                  {(questions)&&  
                  <ul className="about-details">
                    {(questions).map((val, index) => (
                      <li key={index}> <label className="font-weight-bold">{val.question.question}</label>  : {val.answer} </li>
                    ))}
                    </ul>
                    }

{(user_id==props.details.id )&&
<>
<div className="row edit-form">
            {successMessageQ && (
                            <Alert variant="success">
                              {successMessageQ && <p> {successMessageQ} </p>}
                            </Alert>
                          )}
            <div className="card w-100 p-3 mt-2 mb-2 shadow-sm">
                
                  {(options &&  questionsSelected)&&
                    <Select  
                    className='w-100'
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    placeholder="Questions"
                    isMulti
                    defaultValue={questionsSelected}
                    styles={customStyles}
                    options={options}
                    onChange={opt => setQuestionsSelected(opt)}

                  />
                   }
            
            
            </div>

          
            
          {(inputData)&& 
         inputData.map((val, key) => (
         
          <div key={key}>
          {
             

            <>
              {(questionsSelected)&& 
                   (questionsSelected.find(x => x.value === val.id)) ? 

                   <>
                    {(first)?
                      <div key={key} className="signup-input col-md-12">
                      <label>{val.question}</label>
                        <input   type="text" value={questionsSelected.find(x => x.value === val.id).answer}   onChange={e => handleInputValue(e.target.value , val.id )}  />
                      </div>
                    :
                    <div key={key} className="signup-input col-md-12">
                    <label>{val.question}</label>
                      <input type="text"   onChange={e => handleInputValue(e.target.value , val.id )}  />
                    </div>
                    }
                   </>
              
                : null

              }
             
              </>
          }
  
            </div>
         
        
          ))
         
          
          }
            
         
           

            </div>
            <div className="signup-submit">
              <button type="submit"  onClick={handleSubmitQuestion} className="edit-btn">Update</button>
            </div>

            </>


}


<h1 className="profile-about-title mb-2">Movies</h1>


{(user_id!=props.details.id )?
    
    <div className="my-2">
         {(newSelectedMovie)&&
           newSelectedMovie.map((val, key) => (
            <span className="tagsclass">{val.label} </span>
           ))
        }
    </div>
:
<>
{successMessageMovieTag && (
                            <Alert variant="success">
                              {successMessageMovieTag && <p> {successMessageMovieTag} </p>}
                            </Alert>
                          )}
                        
<div className="card w-100 p-3 mt-2 mb-2 shadow-sm">
                
                {(newSelectedMovie)&&
                <CreatableSelect 
                options={selectionMovie}
                isMulti={true}
                isClearable={true}
                onChange={(opt)=>handleOnChange(opt)}
                onInputChange={handleInputChange}
                inputValue={inputValueMovie}
                value={newSelectedMovie}
                controlShouldRenderValue={true}
              />
                }
                
                   
                   <div className="signup-submit">
              <button type="submit"  onClick={handleSubmitMovieTags} className="edit-btn">Update</button>
            </div>
            
            
            </div>
            </>
            }




            <h1 className="profile-about-title mb-2">Sports</h1>
            {(user_id!=props.details.id )?
    
    <div>
         {(newSelectedSports)&&
           newSelectedSports.map((val, key) => (
            <span className="tagsclass">{val.label} </span>
           ))
        }
    </div>
:
<>


{successMessageSportsTag && (
                            <Alert variant="success">
                              {successMessageSportsTag && <p> {successMessageSportsTag} </p>}
                            </Alert>
                          )}
<div className="card w-100 p-3 mt-2 mb-2 shadow-sm">
                
                {(newSelectedSports)&&
                <CreatableSelect 
                options={selectionSports}
                isMulti={true}
                isClearable={true}
                onChange={(opt)=>handleOnChangeSports(opt)}
                onInputChange={handleInputChangeSports}
                inputValue={inputValueSports}
                value={newSelectedSports}
                controlShouldRenderValue={true}
              />
                }
                  
                  
                   
                   <div className="signup-submit">
              <button type="submit"  onClick={handleSubmitSportsTags} className="edit-btn">Update</button>
            </div>
            
            
            </div>
            </>
}

 
            </div>
            
    }
        
       

      {(tab=='photos')&&
      <div className="added-post profilephotos-post">
       
        

        {/* {(!props.details.privateHavingFun)&&
                   <>
                   <label className="font-weight-bold my-2">Having Fun Photos</label>
                   <div className="row">
                   {
                    (JSON.parse(props.details.imagesPhotoHavingFun)) && JSON.parse(props.details.imagesPhotoHavingFun).path.map((img , index) => (
                      <div className="col-md-4" key={index} >
                       
                          <img onClick={ e => handleClickImage(e, 0)} width="100%" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 
                     
                      </div>
                    
                    ))}
                    </div>
                    </>
            }


{(!props.details.privateFamily)&&
                   <>
                   <label className="font-weight-bold my-2">Family Photos</label>
                   <div className="row">
                   {
                    (JSON.parse(props.details.imagesFamily)) && JSON.parse(props.details.imagesFamily).path.map((img , index) => (
                      <div className="col-md-4" key={index} >
                     
                          <img onClick={ e => handleClickImage(e, 0)} width="100%" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 
                     
                      </div>
                    
                    ))}
                    </div>
                    </>
            }

{(!props.details.privateHobbies)&&
                   <>
                   <label className="font-weight-bold my-2">Hobbies Photos</label>
                   <div className="row">
                   {
                    (JSON.parse(props.details.imagesHobbies)) && JSON.parse(props.details.imagesHobbies).path.map((img , index) => (
                      <div className="col-md-4" key={index} >
                         
                          <img  onClick={ e => handleClickImage(e, 0)} width="100%" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 
                     
                      </div>
                    
                    ))}
                    </div>
                    </>
            } */}

            


<label className="font-weight-bold my-2">Gallery</label>




{(!privateGallery && user_id!=props.details.id)&&
                   <>

                   <div className="row">
                   {
                  
                    (imagesGallery) && imagesGallery.path.map((img , index) => (
                      <div className="col-md-3" key={index} >
                       
                          <img  className="associated-property-img-profile" onClick={ e => handleClickImage(e, 0)  }  key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 
                     
                      </div>
                    
                    ))}
                    </div>
                    </>
            }

{successMessage && (
                    <Alert variant="success" >
                        {successMessage && (
                        <p>  {successMessage} </p>
                        )}
                        </Alert>
                      )}

{(user_id==props.details.id)&& 

<div className='col-md-6' >

  

<div className='chekboxdiveditprfile'>
<input   type="checkbox"  checked={privateGallery}
            onChange={() => setPrivateGallery(!privateGallery)} /> Private
                </div>
            
                  <div className=" file-field row  file-field-image-container">
            
            <input type="file" multiple onChange={handleChangeImagesGallery}/>
            <h3>
         
              
                   
            Gallery <br /> Click  to upload</h3>
            </div>
          
<div className='row  view-images-container'>
              {
              
              (imagesGallery) && imagesGallery.path.map((img , index) => (
                <div className='col-6 col-md-6 col-lg-3  ' key={index} >
                  
                    
                    
                    <div className='text-center'>
                    <div  onClick={ ()=> handleRemoveImagesGallery(index)} className="badge badge-danger closecustom text-center"  aria-hidden="true">&times;</div>
                    <div><img width="75" className='rounded' height="50" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 </div>
                </div>
                </div>
              
              ))}

          </div>




             
              

        <div className="col-md-12"> 
        <button type="submit"  onClick={handleSubmitGallery} className="edit-btn ">Upload</button>
        </div>
             
              </div>
            }

{current &&
                    <Lightbox
                        mainSrc={current}
                        nextSrc={next}
                        prevSrc={prev}
                        onMovePrevRequest={handlePrevImage}
                          onMoveNextRequest={handleNextImage}
                        onCloseRequest={handleCloseModal}
                        />
                }

      </div>
      }

{(tab=='connections')&&
      <div className="added-post">
          <label className="font-weight-bold my-2">Following</label>
          {youfollow && (
          <div className='row p-2'>
           {youfollow.map((friend , index) => 
          (

            <div    key={index} className='col-md-6'>
                <div  className="user-request-info-community  mt-3">
                    <div className="requested-user-image">
                    <Link to={"/profile/" + friend.sender.slug + " "} >
                    {(friend.sender.image!='' && friend.sender.image!=null)?
       
                        <img  className={  `social-media-post-profile-image user_${friend.sender.user_type}` }   src={process.env.REACT_APP_IMAGES_URL + JSON.parse(friend.sender.image).path[0]} alt="" title={friend.sender.user_type} />
                        :
                        <img className={  `social-media-post-profile-image user_${friend.sender.user_type}` }  src={User} alt=""  title={friend.sender.user_type} />
                            }
                      </Link>
                    </div>
                    <div className="requested-user-detail">
                    <h4>
                    <Link to={"/profile/" + friend.sender.slug + " "} >{friend.sender.first_name}  {friend.sender.last_name.substring(0, 1)}.</Link>
                    </h4>
                    <h5 className="mb-3">{friend.sender.company}</h5>
                
            </div>
            </div>
            </div>
                ))}

                </div>

                )}


            <label className="font-weight-bold my-2">Follower</label>

           
            {followsYou && (
          <div className='row p-2'>
         
           {followsYou.map((friend , index) => 
          (
           (friend.receiver.is_admin ==0)?
         
           <div    key={index} className='col-md-6'> 
                <div key={index} className="user-request-info-community  mt-3">
                    <div className="requested-user-image">
                    <Link to={"/profile/" + friend.receiver.slug + " "} >
                    {(friend.receiver.image!='' && friend.receiver.image!=null)?
       
                        <img  className={  `social-media-post-profile-image user_${friend.receiver.user_type}` }  src={process.env.REACT_APP_IMAGES_URL + JSON.parse(friend.receiver.image).path[0]} alt="" title={friend.receiver.user_type}  />
                        :
                        <img className={  `social-media-post-profile-image user_${friend.receiver.user_type}` }  src={User} alt="" title={friend.receiver.user_type}  />
                            }
                      </Link>
                    </div>
                    <div className="requested-user-detail">
                    <h4><Link to={"/profile/" + friend.receiver.slug + " "} >{friend.receiver.first_name}  {friend.receiver.last_name.substring(0, 1)}.</Link></h4>
                    <h5 className="mb-3">{friend.receiver.company}</h5>
             
            </div>
            </div>
            </div>
            :
            null

                ))}

                </div>

                )}
      </div>
      }



{(youtubeid !='')?
  <>
  <YouTube className="mt-2" videoId={youtubeid} opts={opts} onReady={onPlayerReady} ref={playerRef} />
  </>    
  :null
}
                    


        </div>
        <div className="notification-main-social">
          <AssociatedProperties properties={props.details.property} />
        </div>
      </div>
    </div>
  );
}
