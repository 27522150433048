import { chooseUsImage } from "../../utils/images";

export default function ChooseUsSection() {
  return (
    <section className="choose-us-section">
      <div className="container">
        <h2>
          <span>What are we</span> offering
        </h2>
        <div className="row">
          <div className="col-md-4">
            <div className="choose-us-content">
              <h4>US Housing Exchange</h4>
              <h5>
              A private site for individual buyers, sellers, investors, contractors and crypto users to engage with one another and have properties listed for free as an alternative to traditional listing methods.  This site’s purpose is to take back “The American Dream” of homeownership for the average American from large corporate and institutional buyers!
              </h5>
            </div>
          </div>
          <div className="col-md-4">
            <div className="choose-us-content">
              <h4>Master Classes</h4>
              <h5>
              Within our social media platform there will be experts that will offer classes based on their expertise, sign up and enjoy. 
              </h5>
            </div>
          </div>
          <div className="col-md-4">
            <div className="choose-us-content">
              <h4>NFTs </h4>
              <h5>
              Buy our NFTs as we fight to end homelessness, find out how you can adopt a veteran, thru the purchase of our cryptocurrency platform
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
