import {
    kitchenImage,
    houseTwoImage,
    houseView,
    parkView,
    diningView,
    locationTick
  } from "../../utils/images";
  import React, { useState } from "react";

  import {currencyFormat} from "../../utils/functions";

export default function Discription(prop) {
	const [services, setServies] = useState(prop.services);

    React.useEffect(() => {
		setServies(prop.services);
	}, [prop.services]); // <-- Have to pass in [] here!

    return (
        <section className="container">
            <h2 className="main-title-head">{prop.title}</h2>
            
            <div className="container">
                <div className="row contractor-services">
                    {(services) &&
                        <>
                            	{services.map((val, key) => (
                                      <div className="col-lg-4 contractor-service">


                    { (val.images!='' && val.images!=null)?
                                        <img className="house-img" src={process.env.REACT_APP_IMAGES_URL + JSON.parse(val.images).path[0]}	alt=""/>
                                    :
                                        <img src={houseTwoImage} alt="" />
                                    }

                                      {/* <img src={houseTwoImage} alt="" /> */}


                                      <h4 className="service-title">{val.title}</h4>
                                      <p className="text-secondary">
                                         {val.description}
                                      </p>
                                      <h3 className="service-price">{val.new_price} USD<span className="text-danger">{val.old_price} USD</span></h3>
                                      {/* <p className="text-success text-center">You’re saving $150</p> */}
                                      <button className="btn btn-success service-action">Contact us</button>
                                  </div>

                                ))}
                        </>
                    
                    }


                  



                </div>
            </div>
        </section>
    )
}