export default function SpecialFeature(props) {
    return (
        <>
            <section className="container">
                <h2 className="main-title-head">{props.title}</h2>
                {props.amenities && (

                <div className="overview-property">

               


{props.amenities.map((am , key) => 
    (

                    <div key={key} className="property-detailed">
                        <h6>{am.title}</h6>
                    </div>
                        ))} 

                      


                </div>
                  )}
            </section>
        </>
    )
}