import React, { useState } from 'react';
import { Link } from "react-router-dom";
import LeftSidebar from "../../layouts/leftSider";
import SettingSider from "../../layouts/settingSider";
import Cookies from 'js-cookie';
import Alert from "react-bootstrap/Alert";
import axios from 'axios';
import Select from 'react-select';
import {Modal, Button} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck , faBars  , faPlus } from '@fortawesome/free-solid-svg-icons'
async function updateQuestions(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/user/save/questions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}




export default function EditProfileContent({ onEdit }) {
  const [showLeftSideMenu, setShowLeftSideMenu] = useState(false);
  const handleCloseLeftSideMenu = () => setShowLeftSideMenu(false);
  const handleShowLeftSideMenu = () => setShowLeftSideMenu(true);
  
  const userToken = Cookies.get('token');
  const [successMessage, setSuccessMessage] = useState("");

  const [questionsSelected, setQuestionsSelected] = useState();
  const [first, setFirst] = useState(true);
  const [alreadyquestionsSelected, setAlreadyQuestionsSelected] = useState();
  const [inputData, setInputData] = useState([]);
  React.useEffect(() => {


  var newArray = inputData;
  const answerednew = [];

  newArray.map((item, index) => (
              
    (questionsSelected.find(x => x.value === item.id)) ? 
   
    answerednew.push(item)
      :
    
      answerednew.push({id: item.id,  question: item.question , status: item.status , created_at:item.created_at , updated_at:item.updated_at  })
     
     
  ))
  setInputData(answerednew);

}, [questionsSelected ]); // <-- Have to pass in [] here!
  const [options, setOptions] = useState([]);
  const aquaticCreatures = [];
  const selected = [];
  const answered = [];
  const customStyles = {
    container: provided => ({
      ...provided,
      width: 400
    })
  };
  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "api/questions", {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "http://localhost:3000/",
        "Access-Control-Allow-Credentials": "true/",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken + "",
      },
    })
      .then((results) => results.json())
      .then((data) => {
          if(data.questions){
            data.questions.map((item, index) => (
              aquaticCreatures[index] = {label: item.question,  value: item.id , answer:'' }
               

            ))

            data.questions.map((item, index) => (
              
              (data.userquestions.find(x => x.question_id === item.id)) ? 
                selected.push({label: item.question,  value: item.id , answer:data.userquestions.find(x => x.question_id === item.id).answer })
                :null 
               

            ))

          //  console.log("first" , selected);
            setQuestionsSelected(selected);
           // console.log("second", selected);


            data.questions.map((item, index) => (
              
              (data.userquestions.find(x => x.question_id === item.id)) ? 
              answered.push({id: item.id,  question: item.question , status: item.status , created_at:item.created_at , updated_at:item.updated_at , answer:data.userquestions.find(x => x.question_id === item.id).answer })
                :
                answered.push(item)
               

            ))


           


            setOptions(aquaticCreatures);
            setInputData(answered);
           // console.log("setInputData", answered);
          
          }
        
      });
  }, [ ]); // <-- Have to pass in [] here!
  

  const handleSubmit = async e => {
    e.preventDefault();
    setSuccessMessage(null);
    const res = await updateQuestions({
      'inputData' : JSON.stringify(inputData),
      userToken
    });
    if(res.success){
    //  sessionStorage.setItem("token", token.author.api_token);
    setSuccessMessage('Questionnaires has been updated successfully.');
    }else{
    
      //setErrorPasswordMessage(res.errors);
    }

  }

      const handleInputValue = (value , id) => {


         var newArray = inputData;

        for (let i = 0; i < newArray.length; i++) {
          if(newArray[i].id==id){
            newArray[i].answer = value;
          }
            
        }
        setFirst(false);
        setInputData(newArray);

      }

    




    

    return (
      
        <section className="edit-profile-content row">
         <div  className='message-pages-top  w-100'>
            <div className="d-flex mx-4 mt-4 justify-content-between">

              <div className=" d-lg-none   ">
              <FontAwesomeIcon  onClick={handleShowLeftSideMenu} icon={faBars} />
                  
                </div>
            
            </div>

          </div>

        <div className="sidebar col-md-6 col-lg-2 col-0 d-none d-lg-block h-auto">  
            <LeftSidebar />
          </div>
     
          <div className="sidebar search-sidebar col-md-6 col-lg-2 col-0 d-none d-lg-block h-auto ">
            <SettingSider />
          </div>



          {/* <div  className='side-bar d-none d-lg-block h-auto'>
             <div className='' style={{     backgroundColor:' #f6f9fe' }}>   <LeftSidebar /></div>
             <div className='' style={{     backgroundColor:' #f6f9fe' }}><SettingSider /></div>

          </div> */}


         <Modal    dialogClassName='custom-dialog' show={showLeftSideMenu} onHide={handleCloseLeftSideMenu} size="lg"  >
					
					<Modal.Body>
					<div style={{     width:'25px' }} className="cursor-pointer bg-primary px-2 py-1 mb-2 " onClick={handleCloseLeftSideMenu}>x</div>
          
          <div className='row'>
          <div className="sidebar col-6  ">  
            <LeftSidebar />
          </div>
     
          <div className="sidebar search-sidebar col-6   ">
            <SettingSider />
          </div>
          </div>
         

					</Modal.Body>
					
				</Modal>

       
          <div className="edit-container ol-xsm-12 col-sm-12   col-md-12 col-lg-11 col-12">
         

            <h3>Select Questionnaires </h3>
            <div className="row edit-form">
            {successMessage && (
                            <Alert variant="success">
                              {successMessage && <p> {successMessage} </p>}
                            </Alert>
                          )}
            <div className="card w-100 p-3 mt-2 mb-2 shadow-sm">
                
                  {(options &&  questionsSelected)&&
                    <Select 
                    className='w-100'
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    placeholder="Questions"
                    isMulti
                    defaultValue={questionsSelected}
                    styles={customStyles}
                    options={options}
                    onChange={opt => setQuestionsSelected(opt)}

                  />
                   }
            
            
            </div>

          
            
          {(inputData)&& 
         inputData.map((val, key) => (
         
          <div key={key}>
          {
             

            <>
              {(questionsSelected)&& 
                   (questionsSelected.find(x => x.value === val.id)) ? 

                   <>
                    {(first)?
                      <div key={key} className="signup-input col-md-12">
                      <label>{val.question}</label>
                        <input   type="text" value={questionsSelected.find(x => x.value === val.id).answer}   onChange={e => handleInputValue(e.target.value , val.id )}  />
                      </div>
                    :
                    <div key={key} className="signup-input col-md-12">
                    <label>{val.question}</label>
                      <input type="text"   onChange={e => handleInputValue(e.target.value , val.id )}  />
                    </div>
                    }
                   </>
              
                : null

              }
             
              </>
          }
  
            </div>
         
        
          ))
         
          
          }
            
         
           

            </div>
            <div className="signup-submit">
              <button type="submit"  onClick={handleSubmit} className="edit-btn">Update</button>
            </div>
          </div>
        </section>
      
    );
  }