import { realtorsContent } from "../../utils/constant";
import ReactStars from "react-rating-stars-component";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { investorDetails } from "../../routers/routePath";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "react-js-pagination";
import moment from 'moment';
import {
	locationSelectIcon,
	propertyTypeIcon,
	Avatarbg,
} from "../../utils/images";

export default function RealtorsResults(props) {
	const ratingChanged = (newRating) => {
		
	};


	return (
		<section className="filter-results mt-5">
			<div className="container">
				<h2>Top Investors</h2>

				{/* <div className="listing-input">
					<span>
						<img src={locationSelectIcon} alt="" />
					</span>
					<select name="" id="">
						<option value="">Location</option>
						<option value="">Saab</option>
						<option value="">Mercedes</option>
						<option value="">Audi</option>
					</select>
				</div> */}

				<div className="row">
					{props.feedCardContent.map((val, key) => (
                       
						<div key={key} className="col-md-6 mt-4">
							<div className="feed-card">
								<div className="feed-card-img ">
									

                                {(val.image!='' && val.image!=null)?
                                    
                                    <img   src={process.env.REACT_APP_IMAGES_URL + JSON.parse(val.image).path[0]} alt="" />
                                    :
                                    <img src={Avatarbg} alt={val.image} />
                                        }


									{/* <span>{realtorsContent.feedCardTag}</span> */}
								</div>
								<div className="feed-card-description realtor-desc">
									<h4>
										<Link to={"/investor/" + val.slug + " "}>
											{val.first_name} {val.last_name.substring(0, 1)}.
										</Link>
									</h4>
									<ul className="realtor-feats">
										<li>
											<img src={realtorsContent.cardSaveIcon} alt="BedIcon" />{" "}
											{val.company}
										</li>
										
										{/* <li>
											<img src={realtorsContent.cardStarIcon} alt="BedIcon" />{" "}
											{val.phone}
										</li> */}
										<li>
											<img src={realtorsContent.cardStarIcon} alt="BedIcon" />{" "}
											Available on our social media platform
										</li>
										{(val.latestactivity) && 
										<li>
										<img src={realtorsContent.cardClockIcon} alt="BedIcon" />{" "}
										Last activity at USHX   {moment(val.latestactivity ).fromNow()  }
									</li>
										}
									</ul>
									<p className="card-address">
									{ 
									<>
									{(val.location)? val.location +",":'USA ,'}
									{(val.city)?  val.city+",":null}
									{(val.state)?  val.state +",":null}
									{(val.zipcode)?  val.zipcode :null}
									</>
										}
									</p>
								</div>
							</div>
						</div>
					))}
					
				</div>
				<div className="result-pagination mt-5">
                   
                <Pagination
						activePage={
							props.feedCardContentmeta
								? props.feedCardContentmeta.current_page
								: 0
						}
						itemsCountPerPage={
							props.feedCardContentmeta ? props.feedCardContentmeta.per_page : 0
						}
						totalItemsCount={
							props.feedCardContentmeta.total ? props.feedCardContentmeta.total : 0
						}
						onChange={(pageNumber) => {
							props.setPageNumber(pageNumber);
							props.setSetReloadData(pageNumber);
						}}
						pageRangeDisplayed={8}
						itemClass="page-item"
						linkClass="page-link"
						firstPageText="<<"
						lastPageText=">>"
					/>
				</div>
			</div>
		</section>
	);
}
