import {
    communityDarkIcon,
    communityLightIcon,
    exploreDarkIcon,
    exploreLightIcon,
    feedDarkIcon,
    feedLightIcon,
    logoutDarkIcon,
    logoutLightIcon,
    messagesDarkIcon,
    messagesLightIcon,
    notificationDarkIcon,
    notificationLightIcon,
    profileDarkIcon,
    profileLightIcon,
    settingDarkIcon,
    settingLightIcon,
  } from "../utils/images";
  import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
  import Cookies from 'js-cookie';
  export default function SettingSider() {
    const user_type = Cookies.get('user_type');
    const kyc_required = Cookies.get('kyc_required');

    //console.log('kyc_required' , kyc_required);


    return (
      <ul className="sidebar-list">
        <li>
          <NavLink to="/edit-profile" data-tut="reactour__edit_profile">
            <span>
              <img className="light-icon" src={communityLightIcon} alt="" />
              <img className="dark-icon" src={communityDarkIcon} alt="" />
            </span>
            <span>Edit Profile</span>
          </NavLink>
        </li>

        <li>
          <NavLink to="/update-password">
            <span>
              <img className="light-icon" src={settingLightIcon} alt="" />
              <img className="dark-icon" src={settingDarkIcon} alt="" />
            </span>
            <span>Update Password</span>
          </NavLink>
        </li>

        <li data-tut="reactour__browse_uploads">
          <NavLink to="/documents">
            <span>
              <img className="light-icon" src={settingLightIcon} alt="" />
              <img className="dark-icon" src={settingDarkIcon} alt="" />
            </span>
            <span>Documents Upload</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/questionnairs">
            <span>
              <img className="light-icon" src={settingLightIcon} alt="" />
              <img className="dark-icon" src={settingDarkIcon} alt="" />
            </span>
            <span>Questionnaires</span>
          </NavLink>
        </li>

  {(kyc_required==1) && 
        <li >
        <NavLink  to="/verification" activeClassName="active" data-tut="reactour__browse_kyc">
          <span>
            <img className="light-icon" src={messagesLightIcon} alt="" />
            <img className="dark-icon" src={messagesDarkIcon} alt="" />
          </span>
          <span>KYC Verification</span>
        </NavLink>
      </li>
  }

<li>
          <NavLink to="/mobile-verification">
            <span>
              <img className="light-icon" src={settingLightIcon} alt="" />
              <img className="dark-icon" src={settingDarkIcon} alt="" />
            </span>
            <span>Mobile Verification</span>
          </NavLink>
        </li>
        


        {(user_type=='seller' || user_type=='Real_Estate_Professional') &&
        <li>
          <NavLink to="/listings" data-tut="reactour__browse_check_property">
            <span>
              <img className="light-icon" src={notificationLightIcon} alt="" />
              <img className="dark-icon" src={notificationDarkIcon} alt="" />
            </span>
            <span>My Listing</span>
          </NavLink>
        </li>
         }

        <li>
          <NavLink to="/activity">
            <span>
              <img className="light-icon" src={exploreLightIcon} alt="" />
              <img className="dark-icon" src={exploreDarkIcon} alt="" />
            </span>
            <span>Activity Log</span>
          </NavLink>
        </li>

        <li>
          <NavLink to="/referrals">
            <span>
              <img className="light-icon" src={settingLightIcon} alt="" />
              <img className="dark-icon" src={settingDarkIcon} alt="" />
            </span>
            <span>Referrals</span>
          </NavLink>
        </li>
      </ul>
    );
  }
  