import React, { useState } from "react";
import CallToAction from "../../components/contractors/callToAction";
import Content from "../../components/terms/content";
import Footer from "../../layouts/footer";
import Header from "../../layouts/header";

import { useHistory ,useLocation } from 'react-router-dom';

export default function Listing() {
	

	return (
		<>
			 {/* <Header /> */}
			<Content  />
			{/* <CallToAction /> */}
			{/* <Footer /> */}
		</>
	);
}
