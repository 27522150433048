import React, { useState } from "react";
import Header from "../../layouts/header";
import ListingsContent from "../../components/admin/listings";
import Cookies from 'js-cookie';

export default function Listings() {
  const [feedCardContent, setFeedCardContent] = useState([]);
  const [feedCardContentmeta, setFeedCardContentMeta] = useState([]);
  const [reloaddata, setSetReloadData] = useState([]);
  const userToken = Cookies.get('token');
  const fetchData = async () => {



		fetch(
			process.env.REACT_APP_API_URL +
				`api/properties/user`,
			{
				method: "GET",
				headers: {
					"Access-Control-Allow-Origin": "http://localhost:3000/",
					"Access-Control-Allow-Credentials": "true/",
					"Content-Type": "application/json", 
					'Authorization': 'Bearer '+userToken+'',
				},
			}
		)
			.then((results) => results.json())
			.then((data) => {
				if (data) {
					setFeedCardContent(data.data);
					setFeedCardContentMeta(data.meta);
				}
			});
	};

	React.useEffect(() => {
		fetchData();
	}, [reloaddata]); // <-- Have to pass in [] here!


    return (
      <>
        <section className="verification">
		<div className="wrapper">
             {/* <Header /> */}
            <ListingsContent 
            feedCardContent={feedCardContent}
            feedCardContentmeta={feedCardContentmeta}
            />
			</div>
        </section>
      </>
    );
  }