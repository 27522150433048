import { Avatar, User } from '../../utils/images'
import { GrLocation } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import SettingSider from '../../layouts/settingSider';
import LeftSidebar from '../../layouts/leftSider';
import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { Modal, Button } from 'react-bootstrap';
import { detail, profile } from "../../routers/routePath";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faXmark, faCircleCheck, faBars } from '@fortawesome/free-solid-svg-icons'

import { toast } from 'react-toastify';
import { FaSearch } from "react-icons/fa";

export default function CommunityContent() {

  const [showLeftSideMenu, setShowLeftSideMenu] = useState(false);
  const handleCloseLeftSideMenu = () => setShowLeftSideMenu(false);
  const handleShowLeftSideMenu = () => setShowLeftSideMenu(true);
  const [peoples, setPeople] = useState([]);
  const [alreadyFriendRequestSent, setAlreadyFriendRequestSent] = useState([]);
  const [selectedSentRequest, setSelectedSentRequest] = useState('');
  const [fetchFriendRequests, setFetchFriendRequests] = useState([]);
  const [fetchPendingRequests, setFetchPendingRequests] = useState([]);
  const [selectAcceptFriendRequest, setSelectAcceptFriendRequest] = useState();
  const [selectAcceptFriendRequestSender, setSelectAcceptFriendRequestSender] = useState('');
  const [selectedRejectRequest, setSelectedRejectRequest] = useState();
  const [selectRejectFriendRequestSender, setSelectRejectFriendRequestSender] = useState('');
  const [unfollow, setUnfollow] = useState('');
  const [youfollow, setYoufollow] = useState([]);
  const [followsYou, setFollowsYou] = useState([]);
  const [searched, setSearchedUser] = useState([]);
  const userToken = Cookies.get('token');
  const user_package = Cookies.get("package");
  const [reloadPeople, SetReloadPeople] = useState();
  const [reloadNotifications, SetReloadNotifications] = useState();
  const [keyword, setKeyword] = useState('');
  const userid = Cookies.get('user_id');
  const [showLoader, setShowLoader] = useState(false);

const [showsearch , setShowsearch] = useState('');


  React.useEffect(() => {
    if (selectAcceptFriendRequestSender != '') {
      setShowLoader(true);
      fetch(process.env.REACT_APP_API_URL + 'api/friends/addFriend', {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:3000/',
          'Access-Control-Allow-Credentials': 'true/',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + userToken + '',
        },
        body: JSON.stringify({ 'target_id': selectAcceptFriendRequestSender, 'accept': true, 'notification_id': selectAcceptFriendRequest })
      }
      )
        .then(results => results.json())
        .then(data => {
          SetReloadPeople(Date.now());
          SetReloadNotifications(selectAcceptFriendRequest);
          fetchData();
          setShowLoader(false);
          toast.success("You successfully accepted the friend request.");
        });
    }
  }, [selectAcceptFriendRequest]); // <-- Have to pass in [] here!


  React.useEffect(() => {
    
    fetch(process.env.REACT_APP_API_URL + 'api/friends/users', {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Access-Control-Allow-Credentials': 'true/',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userToken + '',
      },
    }
    )
      .then(results => results.json())
      .then(data => {
        setPeople(data.needstobe);
        setAlreadyFriendRequestSent(data.requestsent);
      });
  }, [reloadPeople]); // <-- Have to pass in [] here!

  React.useEffect(() => {
    if (selectedSentRequest != '') {
      setShowLoader(true);
      fetch(process.env.REACT_APP_API_URL + 'api/friends/friendreq', {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:3000/',
          'Access-Control-Allow-Credentials': 'true/',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + userToken + '',
        },
        body: JSON.stringify({ 'id': selectedSentRequest })
      }
      )
        .then(results => results.json())
        .then(data => {

          toast.success("You followed the user successfully.");
          SetReloadPeople(Date.now());
          fetchData();
          setShowLoader(false);
          setSelectedSentRequest('');
        });
    }
  }, [selectedSentRequest]); // <-- Have to pass in [] here!


  React.useEffect(() => {
    if (unfollow != '') {
      setShowLoader(true);
      fetch(process.env.REACT_APP_API_URL + 'api/friends/unfriend', {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:3000/',
          'Access-Control-Allow-Credentials': 'true/',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + userToken + '',
        },
        body: JSON.stringify({ 'target': unfollow })
      }
      )
        .then(results => results.json())
        .then(data => {
          toast.success("You unfollowed the user successfully.");
          SetReloadPeople(Date.now());
          fetchData();
          setShowLoader(false);
        });
    }
  }, [unfollow]); // <-- Have to pass in [] here!


  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + 'api/follows/get', {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Access-Control-Allow-Credentials': 'true/',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userToken + '',
      },
    }
    )
      .then(results => results.json())
      .then(data => {
        setYoufollow(data.youfollow);
        setFollowsYou(data.followsyou);
      });
  }, [reloadPeople]); // <-- Have to pass in [] here!


  React.useEffect(() => {
    console.log("use effect")
    fetch(process.env.REACT_APP_API_URL + 'api/friends/notifications', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
        'Access-Control-Allow-Credentials': 'true/',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userToken + '',
      },
    }
    )
      .then(results => results.json())
      .then(data => {
        if (data.status) {
          setFetchFriendRequests(data.data);
          setFetchPendingRequests(data.pendingrequests);

        }
      });
  }, [reloadNotifications, reloadPeople]); // <-- Have to pass in [] here!





  React.useEffect(() => {
    if (selectRejectFriendRequestSender != '') {
      setShowLoader(true);
      fetch(process.env.REACT_APP_API_URL + 'api/friends/addFriend', {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:3000/',
          'Access-Control-Allow-Credentials': 'true/',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + userToken + '',
        },
        body: JSON.stringify({ 'target_id': selectRejectFriendRequestSender, 'accept': false, 'notification_id': selectedRejectRequest })
      }
      )
        .then(results => results.json())
        .then(data => {
          SetReloadPeople(Date.now());
          SetReloadNotifications(selectedRejectRequest);
          fetchData();
          setShowLoader(false);
          toast.success("You have successfully cancelled  the friend request .");
        });
    }
  }, [selectedRejectRequest]); // <-- Have to pass in [] here!


  const fetchData = async () => {
		if(keyword!=''){
      setShowLoader(true);
			fetch(
				process.env.REACT_APP_API_URL +
					`api/search/users?search=${keyword}`,
				{
					method: "GET",
					headers: {
						"Access-Control-Allow-Origin": "http://localhost:3000/",
						"Access-Control-Allow-Credentials": "true/",
						"Content-Type": "application/json", 
						'Authorization': 'Bearer '+userToken+'',
					},
				}
			)
				.then((results) => results.json())
				.then((data) => {
					if (data.users) {
						setSearchedUser(data.users);
					}else{
						setSearchedUser([]);
					}
          setShowLoader(false);
          setShowsearch('seracher');
				});
		}
		
	};

	// React.useEffect(() => {
	// 	fetchData();
	// }, [keyword]); // <-- Have to pass in [] here!
  return (
    <>
      {(showLoader)&& 
		<div id="loading">
			<div className="loader"></div>
 		</div>
		}
      <section className="edit-profile-content row">
        {/* <div className="sidebar">
            <LeftSidebar />
        </div> */}

        <Modal show={showLeftSideMenu} onHide={handleCloseLeftSideMenu} size="md">

          <Modal.Body>
            <div style={{ width: '25px' }} className="cursor-pointer bg-primary px-2 py-1 mb-2 " onClick={handleCloseLeftSideMenu}>x</div>
            <div className='' style={{ backgroundColor: ' #f6f9fe' }}>    <LeftSidebar /></div>

          </Modal.Body>

        </Modal>

        <div className='profile-pages-top  w-100'>
          <div className="d-flex mx-4 mt-4 justify-content-between">

            <div className=" d-lg-none   ">
              <FontAwesomeIcon onClick={handleShowLeftSideMenu} icon={faBars} />
            </div>

          </div>

        </div>





        <div className="side-bar d-none d-lg-block h-auto  ">
          <LeftSidebar />
        </div>

        <div className="edit-container ol-xsm-12 col-sm-12   col-md-12 col-lg-11 col-12">

          <div className='row'>
            <div className="create-post-form Ralewayfamily col-12 col-lg-8 col-md-10 mx-auto">
              <div className="create-post-content">
                <div className='d-flex'>
                  <span>
                    <FaSearch />
                  </span>
                  <input

                  onChange={e => setKeyword(e.target.value)}
                    type="text"
                    placeholder="Search..."

                  />
                  <div className=' request-btns'>
                    <button type="button" onClick={fetchData} className="follow-btn request-btns mx-2 my-1">Search </button>
                  </div>
                </div>

              </div>

            </div>

          </div>


 {(showsearch!='') &&
          <div className="notification-card">
            <div className="notify-head">
              <h2>Search</h2>
              {/* <Link to="/">See All</Link> */}
            </div>

            {searched && (
              <div className='row p-2'>
                

                {
                (searched.length>0)?
                searched.map((friend, index) =>
                (
                  <div key={index} className=' col-md-4'>
                    <div className="user-request-info-community  mt-3">
                      <div className="requested-user-image">
                        <Link to={"/profile/" + friend.slug + " "} >
                          {(friend.image != '' && friend.image != null) ?

                            <img className={`social-media-post-profile-image user_${friend.user_type}`} src={process.env.REACT_APP_IMAGES_URL + JSON.parse(friend.image).path[0]} alt={friend.user_type} title={friend.user_type} />
                            :
                            <img className={`social-media-post-profile-image user_${friend.user_type}`} src={User} alt={friend.user_type} title={friend.user_type} />
                          }
                        </Link>


                      </div>
                      <div className="requested-user-detail">
                        <Link to={"/profile/" + friend.slug + " "} >  <h4>{friend.first_name} {friend.last_name.substring(0, 1)}.</h4>       </Link>
                        <h5 className="mb-3">{friend.company}</h5>
                        <div className="request-btns">
                          {(user_package != 'standard') &&

                             (friend.new_status=='friend')?
                             <button type="button" className="follow-btn" onClick={(e) => { setUnfollow(friend.id); }} data-id={friend.id}  >Remove </button>
                          
                              :
                              (friend.new_status=='requestsentbyme')?
                              <button type="button" className="ignore-btn" onClick={(e) => { setSelectedRejectRequest(e.currentTarget.dataset.id); setSelectRejectFriendRequestSender(e.currentTarget.dataset.sender); }} data-id={friend.notid} data-sender={friend.id}  >Cancel Request</button>

                              :(friend.new_status=='requestreceviedbyme')?
                              <>
                              <button type="button" className="ignore-btn" onClick={(e) => { setSelectedRejectRequest(e.currentTarget.dataset.id); setSelectRejectFriendRequestSender(e.currentTarget.dataset.sender); }} data-id={friend.notid} data-sender={friend.id} >Reject Request</button>
                              <button type="button" className="follow-btn" onClick={(e) => { setSelectAcceptFriendRequest(e.currentTarget.dataset.id); setSelectAcceptFriendRequestSender(e.currentTarget.dataset.sender); }} data-id={friend.notid} data-sender={friend.id}  >Accept Request</button>
                              </>
                             :
                             <button type="button" className="follow-btn" onClick={(e) => { setSelectedSentRequest(e.currentTarget.dataset.id); }} data-id={friend.id} >Follow</button>
                          
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : 'No users found'
              }

              </div>

            )}


          </div>

            }


          <div className="notification-card">
            <div className="notify-head">
              <h2>Following</h2>
              {/* <Link to="/">See All</Link> */}
            </div>

            {followsYou && (
              <div className='row p-2'>

                {followsYou.map((friend, index) =>
                (
                  (friend.receiver.is_admin == 0) ?

                    <div key={index} className='col-md-4'>
                      <div key={index} className="user-request-info-community  mt-3">
                        <div className="requested-user-image">
                          <Link to={"/profile/" + friend.receiver.slug + " "} >
                            {(friend.receiver.image != '' && friend.receiver.image != null) ?

                              <img className={`social-media-post-profile-image user_${friend.receiver.user_type}`} src={process.env.REACT_APP_IMAGES_URL + JSON.parse(friend.receiver.image).path[0]} alt={friend.receiver.user_type} title={friend.receiver.user_type} />
                              :
                              <img className={`social-media-post-profile-image user_${friend.receiver.user_type}`} src={User} alt={friend.receiver.user_type} title={friend.receiver.user_type} />
                            }
                          </Link>
                        </div>
                        <div className="requested-user-detail">
                          <Link to={"/profile/" + friend.receiver.slug + " "} >  <h4>{friend.receiver.first_name}  {friend.receiver.last_name.substring(0, 1)}.</h4>  </Link>
                          <h5 className="mb-3">{friend.receiver.company}</h5>
                          <div className="request-btns">
                            {/* <button className="ignore-btn">Ignore</button> */}
                            <button type="button" className="follow-btn" onClick={(e) => { setUnfollow(friend.receiver.id); }} data-id={friend.id}  >Remove </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    null

                ))}

              </div>

            )}


          </div>



          <div className="notification-card">
            <div className="notify-head">
              <h2>Followed </h2>
              {/* <Link to="/">See All</Link> */}
            </div>

            {youfollow && (
              <div className='row p-2'>
                {youfollow.map((friend, index) =>
                (

                  <div key={index} className='col-md-4'>
                    <div className="user-request-info-community  mt-3">
                      <div className="requested-user-image">
                        <Link to={"/profile/" + friend.sender.slug + " "} >
                          {(friend.sender.image != '' && friend.sender.image != null) ?

                            <img className={`social-media-post-profile-image user_${friend.sender.user_type}`} src={process.env.REACT_APP_IMAGES_URL + JSON.parse(friend.sender.image).path[0]} alt={friend.sender.user_type} title={friend.sender.user_type} />
                            :
                            <img className={`social-media-post-profile-image user_${friend.sender.user_type}`} src={User} alt={friend.sender.user_type} title={friend.sender.user_type} />
                          }
                        </Link>
                      </div>
                      <div className="requested-user-detail">
                        <Link to={"/profile/" + friend.sender.slug + " "} >  <h4>{friend.sender.first_name}  {friend.sender.last_name.substring(0, 1)}.</h4></Link>
                        <h5 className="mb-3">{friend.sender.company}</h5>
                        <div className="request-btns">
                          {/* <button className="ignore-btn">Ignore</button> */}
                          <button type="button" className="follow-btn" onClick={(e) => { setUnfollow(friend.sender.id); }} data-id={friend.id}  >Remove </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              </div>

            )}



          </div>


          <div className="notification-card">
            <div className="notify-head">
              <h2>Friend Requests</h2>
              {/* <Link to="/">See All</Link> */}
            </div>


            {fetchFriendRequests && (
              <div className='row p-2'>
                {fetchFriendRequests.map((friend, index) =>
                (
                  (friend.type == 'friend-request') ?
                    <div key={index} className='col-md-6'>
                      <div className="user-request-info-community  mt-3">
                        <div className="requested-user-image">
                          <Link to={"/profile/" + friend.sender.slug + " "} >
                            {(friend.sender.image != '' && friend.sender.image != null) ?

                              <img className={`social-media-post-profile-image user_${friend.sender.user_type}`} src={process.env.REACT_APP_IMAGES_URL + JSON.parse(friend.sender.image).path[0]} alt={friend.sender.user_type} title={friend.sender.user_type} />
                              :
                              <img className={`social-media-post-profile-image user_${friend.sender.user_type}`} src={User} alt={friend.sender.user_type} title={friend.sender.user_type} />
                            }
                          </Link>
                        </div>
                        <div className="requested-user-detail">
                          <Link to={"/profile/" + friend.sender.slug + " "} >  <h4>{friend.sender.first_name} {friend.sender.last_name.substring(0, 1)}.</h4>  </Link>
                          <h5 className="mb-3">{friend.sender.company}</h5>
                          <div className="request-btns">
                            {/* <button className="ignore-btn">Ignore</button> */}
                            <button type="button" className="ignore-btn" onClick={(e) => { setSelectedRejectRequest(e.currentTarget.dataset.id); setSelectRejectFriendRequestSender(e.currentTarget.dataset.sender); }} data-id={friend.id} data-sender={friend.sender_id}  >Reject Request</button>
                            <button type="button" className="follow-btn" onClick={(e) => { setSelectAcceptFriendRequest(e.currentTarget.dataset.id); setSelectAcceptFriendRequestSender(e.currentTarget.dataset.sender); }} data-id={friend.id} data-sender={friend.sender_id}  >Accept Request</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    null



                ))}

              </div>

            )}


          </div>



          <div className="notification-card">
            <div className="notify-head">
              <h2>Pending Requests</h2>
              {/* <Link to="/">See All</Link> */}
            </div>


            {fetchPendingRequests && (
              <div className='row p-2'>
                {fetchPendingRequests.map((friend, index) =>
                (
                  (friend.type == 'friend-request') ?
                    <div key={index} className='col-md-6'>
                      <div className="user-request-info-community  mt-3">
                        <div className="requested-user-image">
                          <Link to={"/profile/" + friend.receiver.slug + " "} >
                            {(friend.receiver.image != '' && friend.receiver.image != null) ?

                              <img className={`social-media-post-profile-image user_${friend.receiver.user_type}`} src={process.env.REACT_APP_IMAGES_URL + JSON.parse(friend.receiver.image).path[0]} alt={friend.receiver.user_type} title={friend.receiver.user_type} />
                              :
                              <img className={`social-media-post-profile-image user_${friend.receiver.user_type}`} src={User} alt={friend.receiver.user_type} title={friend.receiver.user_type} />
                            }
                          </Link>
                        </div>
                        <div className="requested-user-detail">
                          <Link to={"/profile/" + friend.receiver.slug + " "} > <h4>{friend.receiver.first_name} {friend.receiver.last_name.substring(0, 1)}.</h4>    </Link>
                          <h5 className="mb-3">{friend.receiver.company}</h5>
                          <div className="request-btns">
                            {/* <button className="ignore-btn">Ignore</button> */}
                            <button type="button" className="ignore-btn" onClick={(e) => { setSelectedRejectRequest(e.currentTarget.dataset.id); setSelectRejectFriendRequestSender(e.currentTarget.dataset.sender); }} data-id={friend.id} data-sender={friend.receiver_id}  >Cancel Request</button>

                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    null



                ))}

              </div>

            )}


          </div>

          <div className="notification-card">
            <div className="notify-head">
              <h2>You Might Follow</h2>
              {/* <Link to="/">See All</Link> */}
            </div>

            {peoples && (
              <div className='row p-2'>
                {peoples.map((friend, index) =>
                (
                  <div key={index} className=' col-md-4'>
                    <div className="user-request-info-community  mt-3">
                      <div className="requested-user-image">
                        <Link to={"/profile/" + friend.slug + " "} >
                          {(friend.image != '' && friend.image != null) ?

                            <img className={`social-media-post-profile-image user_${friend.user_type}`} src={process.env.REACT_APP_IMAGES_URL + JSON.parse(friend.image).path[0]} alt={friend.user_type} title={friend.user_type} />
                            :
                            <img className={`social-media-post-profile-image user_${friend.user_type}`} src={User} alt={friend.user_type} title={friend.user_type} />
                          }
                        </Link>


                      </div>
                      <div className="requested-user-detail">
                        <Link to={"/profile/" + friend.slug + " "} >  <h4>{friend.first_name} {friend.last_name.substring(0, 1)}.</h4>       </Link>
                        <h5 className="mb-3">{friend.company}</h5>
                        <div className="request-btns">
                          <button className="ignore-btn">Ignore</button>
                          {(user_package != 'standard') &&
                            <button type="button" className="follow-btn" onClick={(e) => { setSelectedSentRequest(e.currentTarget.dataset.id); }} data-id={friend.id} >Follow</button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              </div>

            )}

          </div>










        </div>
      </section>
    </>
  );
}