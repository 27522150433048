import React, { useState } from "react";
import CallToAction from "../../components/contractors/callToAction";
import Content from "../../components/privacy/content";
import Footer from "../../layouts/footer";
import Header from "../../layouts/header";

export default function Listing() {
	



	return (
		<>
			 {/* <Header /> */}
			<Content />
			{/* <CallToAction /> */}
			{/* <Footer /> */}
		</>
	);
}
