import SimpleMap from "../../components/contractorDetails/addressSection";
import Apartment from "../../components/contractorDetails/apartmentSection";
import ApartmentCard from "../../components/contractorDetails/appartmentCard";
import DetailBanner from "../../components/contractorDetails/detailbanner";
import Discription from "../../components/contractorDetails/discriptionSection";
import Gallery from "../../components/contractorDetails/gallerySection";
import GrandFamily from "../../components/contractorDetails/grandfamilySection";
import PropertyMain from "../../components/contractorDetails/propertySection";
import SpecialFeature from "../../components/contractorDetails/specialFeatureSection";
import SaleAgent from "../../components/contractorDetails/saleagentSection";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import Views from "../../components/contractorDetails/views";
import CallToAction from "../../components/contractorDetails/callToAction";
import ContractorDetails from "../../components/contractorDetails/contactorDetails";
import DetailGallery from "../../components/contractorDetails/detailGallery";
import React, { useState } from "react";
import { useHistory ,useLocation } from 'react-router-dom';

export default function Contractors() {
  const [details, setDetails] = useState([]);
	const [activity, setActivity] = useState([]);
	const history = useHistory()
	const [gallery, setGellary] = useState(false);

	const slug =history.location.pathname.replace('/contractor/','')
  
	const fetchData = async () => {
		fetch(process.env.REACT_APP_API_URL + `api/user/getDetailContractor`, {
			method: "POST",
			headers: {
				"Access-Control-Allow-Origin": "http://localhost:3000/",
				"Access-Control-Allow-Credentials": "true/",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ slug: slug }),
		})
			.then((results) => results.json())
			.then((data) => {
				if (data) {
					setDetails(data.user);
					let  path = [];
					let  realname = [];
					 
					  if(!data.user.privateBusiness){
						if(data.user.imagesBusinessPhoto!=''){
							JSON.parse(data.user.imagesBusinessPhoto).path.map(function(val, index){
								path = [...path ,val ];
							  })
							  JSON.parse(data.user.imagesBusinessPhoto).realname.map(function(val, index){
							
								realname = [...realname ,val ];
			  
							  })
						}
						
					  }

					  if(!data.user.privateHavingFun){
						if(data.user.imagesPhotoHavingFun!=''){
							JSON.parse(data.user.imagesPhotoHavingFun).path.map(function(val, index){
								path = [...path ,val ];
							  })
							  JSON.parse(data.user.imagesPhotoHavingFun).realname.map(function(val, index){
							
								realname = [...realname ,val ];
			  
							  })
						}
						
					  }

					  if(!data.user.privateFamily){
						if(data.user.imagesFamily!=''){
							JSON.parse(data.user.imagesFamily).path.map(function(val, index){
								path = [...path ,val ];
							  })
							  JSON.parse(data.user.imagesFamily).realname.map(function(val, index){
							
								realname = [...realname ,val ];
			  
							  })
						}
						
					  }


					  if(!data.user.privateHobbies){
						if(data.user.imagesHobbies!=''){
							JSON.parse(data.user.imagesHobbies).path.map(function(val, index){
								path = [...path ,val ];
							  })
							  JSON.parse(data.user.imagesHobbies).realname.map(function(val, index){
							
								realname = [...realname ,val ];
			  
							  })
						}
						
					  }

					  if(path.length>0){
						setGellary({ 'path' : path ,  'realname'  : realname })
					  }
				}
			});
	};
  React.useEffect(() => {
		fetchData();
	}, []); // <-- Have to pass in [] here!
  return (
    <>
       {/* <Header /> */}
      <ContractorDetails details={details} />
      <GrandFamily details={details} title={"About us"} />
      <Discription services={details.services} title={"Our Services"} />
	  {(gallery)&&
				<DetailGallery images={gallery} /> 
				}
      {/* <PropertyMain /> */}
      {/* <CallToAction /> */}
      {/* <Footer /> */}
    </>
  );
}
