import BenefitsSection from "../../components/home/benefitsSection";
import CallToAction from "../../components/home/callToAction";
import ChooseUsSection from "../../components/home/chooseUSsection";
import FeaturesSection from "../../components/home/featuresSection";
import HomeBanner from "../../components/home/homebanner";
import LatestFeedSection from "../../components/home/latestFeed";
import LatestNews from "../../components/home/latestNewsSection";
import OurSpecialFeature from "../../components/home/ourSpecialFeature";
import SuitableSection from "../../components/home/suitableSection";
import TestimonialSection from "../../components/home/testimonialSection";
import TokenSection from "../../components/home/tokenSection";
import WhyChooseUs from "../../components/home/whyChooseUsSection";
import Footer from "../../layouts/footer";
import Header from "../../layouts/header";

export default function Token() {
    return (
        <>
             {/* <Header /> */}
            <HomeBanner />
            <TokenSection />
            {/* <CallToAction /> */}
            {/* <Footer /> */}
        </>
    )
}