import { Link } from "react-router-dom";
import LeftSidebar from "../../layouts/leftSider";
import SettingSider from "../../layouts/settingSider";

import React, { useState } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment';
import {Modal, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck , faBars  , faPlus } from '@fortawesome/free-solid-svg-icons'
export default function NotificationsContent() {
  const [showLeftSideMenu, setShowLeftSideMenu] = useState(false);
  const handleCloseLeftSideMenu = () => setShowLeftSideMenu(false);
  const handleShowLeftSideMenu = () => setShowLeftSideMenu(true);
  const [reloadNotifications , SetReloadNotifications] = useState();
  const userToken = Cookies.get('token');
  const [fetchFriendRequests , setFetchFriendRequests] = useState([]);
  const [postCommentsNotifications , setPostCommentsNotifications] = useState([]);
  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+'api/friends/notifications' , {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+userToken+'',
      },
    }
      )
      .then(results => results.json())
      .then(data => {
       if(data.status){
        setFetchFriendRequests(data.data);
        setPostCommentsNotifications(data.postcommentnotifcation);
       }
      });
  }, [reloadNotifications]); // <-- Have to pass in [] here!


    return (
      <>
        <section className="edit-profile-content row">
        <Modal show={showLeftSideMenu} onHide={handleCloseLeftSideMenu} size="md">
					
					<Modal.Body>
					<div style={{     width:'25px' }} className="cursor-pointer bg-primary px-2 py-1 mb-2 " onClick={handleCloseLeftSideMenu}>x</div>
					<div className='' style={{     backgroundColor:' #f6f9fe' }}>    <LeftSidebar /></div>

					</Modal.Body>
					
				</Modal>

        <div  className='profile-pages-top  w-100'>
            <div className="d-flex mx-4 mt-4 justify-content-between">

              <div className=" d-lg-none   ">
              <FontAwesomeIcon  onClick={handleShowLeftSideMenu} icon={faBars} />
                  
                </div>
            
            </div>

          </div>



      

<div className="side-bar d-none d-lg-block h-auto  ">
				<LeftSidebar />
			</div>

          <div className="edit-container ol-xsm-12 col-sm-12   col-md-12 col-lg-11 col-12">
            <h3>Requests Notifications</h3>
           
            {fetchFriendRequests && (
          <>
           {fetchFriendRequests.map((friend, index) => 
          (

            <div key={index}>
               {(friend.type=='friend-request')?


<div className="activity-msg col-12">
<p>{friend.message}  from  {friend.sender.first_name}</p>
<h5>Posted on: {moment(friend.formatted_date ).format(process.env.REACT_APP_DATE_FORMAT) }    </h5>
</div>


            :

          <div className="activity-msg col-12">
                          <p>{friend.message}</p>
                          <h5>Posted on: {moment(friend.formatted_date ).format(process.env.REACT_APP_DATE_FORMAT) }</h5>
                      </div>


              }
              </div>



              ))}

              </>



              )}

<h3 className="mt-2"> General Notifications</h3>

{postCommentsNotifications && (
          <>
           {postCommentsNotifications.map((friend , index) => 
          (

           
            <div key={index}>
               
                {(friend.notifiable_type=='App\\Models\\like')?
                      <div className="activity-msg col-12">
                      <p>    {JSON.parse(friend.data).user } has {JSON.parse(friend.data).like_unlike }  your post </p>
                      <h5> Posted On: {moment(friend.created_at ).format(process.env.REACT_APP_DATE_FORMAT) }    </h5>
                      </div>
                :(friend.notifiable_type=='App\\Models\\Comment')?
                <div className="activity-msg col-12">
                <p>    {JSON.parse(friend.data).user } has commented on your post </p>
                <h5>Posted On: {moment(friend.created_at ).format(process.env.REACT_APP_DATE_FORMAT) }    </h5>
                </div>
                :
                <div className="activity-msg col-12">
                <p>    {JSON.parse(friend.data).user_from_fname } {JSON.parse(friend.data).user_from_lname }  has added you to the group </p>
                <h5>Posted On: {moment(friend.created_at ).format(process.env.REACT_APP_DATE_FORMAT) }    </h5>
                </div>
                }

               


           
              </div>



              ))}

              </>



              )}

          

          </div>
        </section>
      </>
    );
  }