
import NotificationsContent from "../../components/admin/notifications";
import Header from "../../layouts/header";

export default function Notifications() {
    return (
      <>
        <section className="activity">
          <div className="mt">
             {/* <Header /> */}
            <NotificationsContent />
          </div>
        </section>
      </>
    );
  }