import { Link } from "react-router-dom";
import LeftSidebar from "../../layouts/leftSider";
import SettingSider from "../../layouts/settingSider";
import React, { useState , useEffect } from 'react';
import { detail } from "../../routers/routePath";
import Cookies from 'js-cookie';
import moment from 'moment';


export default function RealtorFeeds({activity}) {

  // const [id, setId] = useState(realtor);
  // const [activity, setActivity] = useState([]);

  


    return (
      <>
        <section className="edit-profile-content row realtor-container">

          <div className="edit-container realtor-feeds-container">
            <div className="col-10 m-auto p-0">
              <h2 className="feeds-title">Latest Feeds</h2>
            </div>
           
        


            {activity.map((act) => 
               (
             
            <div className="activity-msg feed-section col-10 m-auto">
                {(act.type=="App\\Notifications\\propertyNotification")?
               <>
                <p>
                  
                  You have added property  <a href={detail + "?property=" + JSON.parse(act.data).property_slug + " "}>{JSON.parse(act.data).property_title}</a>
                   </p>
                <h5>Posted on: {moment(act.created_at ).format("DD MM YY ")}</h5>
                </>
                :(act.type=="App\\Notifications\\PostNotification")?
<>
                <p>
                  
                  You have added post  {JSON.parse(act.data).post_body}
                   </p>
                <h5>Posted on: {moment(act.created_at ).format("DD MM YY ")}</h5>
                </>
                 :(act.type=="App\\Notifications\\OfferNotification")?
                 <>
                                 <p>
                                   
                                   You have added a offer on a property   <a href={detail + "?property=" + JSON.parse(act.data).property_slug + " "}>{JSON.parse(act.data).property_title}</a>
                                    </p>
                                 <h5>Posted on: {moment(act.created_at ).format("DD MM YY ")}</h5>
              </>

                :null
                
              }
                
              
            </div>
            ))}


          </div>
        </section>
      </>
    );
  }