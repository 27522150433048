import React, { useState } from "react";
import ProfileContent from "../../components/profile/profileContent";
import ProfileDetails from "../../components/profile/profileDetails";
import Footer from "../../layouts/footer";
import Header from "../../layouts/header";
import Cookies from 'js-cookie';
import { useHistory ,useLocation } from 'react-router-dom';
export default function Profile() {
  const [details, setDetails] = useState([]);
  const [post, setPost] = useState([]);
  const [reload, setReload] = useState('');
  const history = useHistory()
  const slug =history.location.pathname.replace('/profile/','')
  const [youfollow ,setYoufollow ] = useState([]);
  const [followsYou ,setFollowsYou ] = useState([]);
  const [firsttab, setFirstTab] = useState('aboutme');
  const fetchData = async () => {
    const userToken = Cookies.get('token');

		fetch(process.env.REACT_APP_API_URL + `api/user/getProfile`, {
			method: "POST",
			headers: {
				'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+userToken+'',
			},
			body: JSON.stringify({ slug: slug }),
		})
			.then((results) => results.json())
			.then((data) => {
				if (data) {
					setDetails(data.user);
          setPost(data.posts);
          setYoufollow(data.youfollow);
          setFollowsYou(data.followsyou);
				}
			});
	};

	React.useEffect(() => {
		fetchData();
    setFirstTab('aboutme')
	}, [slug , reload]); // <-- Have to pass in [] here!

  const handleEdit = (item) => {
    setReload(item);
  };


    return (
      <>
        <section className="realtors">
             {/* <Header /> */}
            <ProfileDetails details={details} />
            <ProfileContent handleEdit={handleEdit} firsttab={firsttab} posts={post}  details={details} youfollow={youfollow}  followsYou={followsYou} />
           
        </section>
      </>
    );
  }