import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Alert from "react-bootstrap/Alert";
import Cookies from 'js-cookie';


import { useHistory } from "react-router-dom";

      async function loginUser(credentials) {
        return fetch(process.env.REACT_APP_API_URL+'api/forgotPassword', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(credentials)
        })
          .then(data => data.json())
      }

  export default function LoginForm({ setToken }) {
    const [email, setEmail] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const history = useHistory();
    const handleSubmit = async e => {
      e.preventDefault();
      setErrorMessage(null);
      setSuccessMessage(null);
      const token = await loginUser({
        email
      });
      if(token.success){
        setSuccessMessage('Sent. Please check your email!');
      }else{
        setErrorMessage(token.errors);
      }

      
      //setToken(token);
    }

    return (
      <form onSubmit={handleSubmit}>
      <section className="login-form">
        <h2 className="login-form-title">Forgot Password</h2>
        {errorMessage && (
            <Alert variant="danger" >
                 
                  {errorMessage.email && (
                <p>  {errorMessage.email} </p>
                )}
                  
                </Alert>

          )}
          {successMessage && (
            <Alert variant="success" >
                  <Alert.Heading>
                  {successMessage}
                  </Alert.Heading>
                </Alert>

          )}
        <div className="login-input">
            <label>Email</label>
            <input type="email" onChange={e => setEmail(e.target.value)} />
        </div>
       
        <a href="/login"> <p className="forgot-password">login?</p></a>
        <button type="submit" className="login-page-btn">Send Email</button>
        
      </section>
      </form>
    );
  }

  LoginForm.propTypes = {
    setToken: PropTypes.func.isRequired
  }
  