import { Avatar } from '../../utils/images'
import { GrLocation } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import SettingSider from '../../layouts/settingSider';
import LeftSidebar from '../../layouts/leftSider';
import moment from 'moment';
import { propertyDetails } from "../../routers/routePath";
import {Modal, Button} from 'react-bootstrap';
import Cookies from 'js-cookie';
import React, { useState ,useEffect , useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck , faBars , faCopy } from '@fortawesome/free-solid-svg-icons'
import {
	
	FacebookShareButton,
	FacebookIcon,
	LinkedinShareButton,
	LinkedinIcon,
	TwitterShareButton,
	TwitterIcon,
	WhatsappShareButton,
	WhatsappIcon,
  } from "react-share";


async function sendNote(credentials) {

    return fetch(process.env.REACT_APP_API_URL+'api/property/notes/add', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
  
  
    }


export default function ListingsContent(props) {
  const [showLeftSideMenu, setShowLeftSideMenu] = useState(false);
  const handleCloseLeftSideMenu = () => setShowLeftSideMenu(false);
  const handleShowLeftSideMenu = () => setShowLeftSideMenu(true);
const [show, setShow] = useState(false);
  const [notes, setNotes] = useState(props.notes);
  const [message, setMessage] = useState();
  const userToken = Cookies.get('token');
  const [propertyid, setPropertyid] = useState();
  const handleClose = () => setShow(false);
 

  const [shareUrl, setShareUrl] = useState('www.google.com');
  const [title, setTitle] = useState('trst');

  const [showShare, setShowShare] = useState(false);
  const handleCloseShare = () => setShowShare(false);
  const handleShow = useCallback(
    (id ) => async () => {
        setPropertyid(id);
        setShow(true);
    },
    [],
  )

  const handleShowShare = useCallback(
    (url , title ) => async () => {
      setShareUrl(process.env.REACT_APP_URL+'property/'+url);
      setTitle(title);
        setShowShare(true);
    },
    [],
  )



  const handleSendNote = async e => {
    e.preventDefault();
      const res = await sendNote({
        'note': message,
        'visibility_status' : 'public', 
        'property_id' : propertyid,
        userToken
      } );
      if(res){
        setMessage('');
        setNotes(res.note);
        setShow(false);
      }
  
    }


    return (
      <>
        <section className="edit-profile-content row">
        <div  className='message-pages-top  w-100'>
            <div className="d-flex mx-4 mt-4 justify-content-between">

              <div className=" d-lg-none   ">

                  <FontAwesomeIcon  onClick={handleShowLeftSideMenu} icon={faBars} />
                  
                </div>
            
            </div>

          </div>

        <div className="sidebar col-md-6 col-lg-2 col-0 d-none d-lg-block h-auto">  
            <LeftSidebar />
          </div>
     
          <div className="sidebar search-sidebar col-md-6 col-lg-2 col-0 d-none d-lg-block h-auto ">
            <SettingSider />
          </div>



          {/* <div  className='side-bar d-none d-lg-block h-auto'>
             <div className='' style={{     backgroundColor:' #f6f9fe' }}>   <LeftSidebar /></div>
             <div className='' style={{     backgroundColor:' #f6f9fe' }}><SettingSider /></div>

          </div> */}


         <Modal    dialogClassName='custom-dialog' show={showLeftSideMenu} onHide={handleCloseLeftSideMenu} size="lg"  >
					
					<Modal.Body>
					<div style={{     width:'25px' }} className="cursor-pointer bg-primary px-2 py-1 mb-2 " onClick={handleCloseLeftSideMenu}>x</div>
          
          <div className='row'>
          <div className="sidebar col-6  ">  
            <LeftSidebar />
          </div>
     
          <div className="sidebar search-sidebar col-6   ">
            <SettingSider />
          </div>
          </div>
         

					</Modal.Body>
					
				</Modal>

       
          <div className="edit-container ol-xsm-12 col-sm-12   col-md-12 col-lg-11 col-12">

            <h3 className='listing-title'>Your Listing</h3>
            <div className='col-md-4 mb-2'>
            <a href='/addProperty' className="btn property-add-btn">Add Property</a>
            </div>
       

            {props.feedCardContent.map((val, key) => (

            <div key={key} className="listing-item row">
                <div className="listing-left">

                    {(val.images!=null && val.images!="")?
									<img
                                    className='social-media-post-proplistng-image'
									src={process.env.REACT_APP_IMAGES_URL + val.images.path[0]}
									alt=""
								/>
									:
                                    <img src={Avatar} alt="" />
								}


                    <div className="listing-item-middle">
                        <h3><a href=  { "/property-details/" + val.slug + " "} >{val.title}</a></h3>
                        <h4><GrLocation /> {val.address}</h4>
                        <span>{val.total_views} people viewed the listing <br></br> </span>
                        <span>{val.appointments.length} people wanted to see property</span>
                    </div>
                </div>
                <div className="listing-item-right">
                    <span>Posted on:{moment(val.created_at ).format("DD MMMM YYYY ")}</span>
                    {(val.status=='0' && 
                         <button className='gold-btn'>Under Review</button>
                        )}
                   

                    <ul>
                        <li><a href=  {"/property-details/" + val.slug + " "} className="blue-btn">Appointments to watch</a></li>
                        <li><a href="#!" onClick={handleShow(val.id)} className="blue-btn">Request Edit</a></li>
                        <li><a href="#!" onClick={handleShowShare(val.slug , val.body)}  className="blue-btn">Share</a></li>
                    </ul>
                </div>
            </div>

            ))}


            <Modal show={show} onHide={handleClose} size="lg">
                    <Modal.Header >
                    <Modal.Title>Add Request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <textarea cols="80"  rows="4" placeholder="Request"  value={message} onChange={e => setMessage(e.target.value)}> </textarea>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSendNote}>
                        Add Request
                    </Button>
                    </Modal.Footer>
                </Modal>



                <Modal show={showShare} onHide={handleCloseShare} size="lg">
                <Modal.Header >
                  <Modal.Title>Share on social media</Modal.Title>
                </Modal.Header>
                <Modal.Body>
            <FacebookShareButton
                    url={shareUrl}
                    quote={title}
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>

              <TwitterShareButton
                    url={shareUrl}
                    title={title}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>

              <WhatsappShareButton
                    url={shareUrl}
                    title={title}
                    separator=":: "
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
              <LinkedinShareButton url={shareUrl} >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>

              <button className="btn btn-info  btn-circle btn-sm"
              onClick={() =>  navigator.clipboard.writeText(shareUrl)}
              >
             <FontAwesomeIcon   icon={faCopy} />
              </button>

                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseShare}>
                    Close
                  </Button>
                </Modal.Footer>
                </Modal>


          </div>
        </section>
      </>
    );
  }