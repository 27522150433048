import {
	addedPostImage,
	commentIcon,
	LikeIcon,
	locationIcon,
	mapImage,
	photoIcon,
	postImageOne,
	postImageThree,
	postImageTwo,
	propertiesTypeIcon,
	shareIcon,
	submitCommentIcon,
	User,
	notificationDarkIcon,
	userLikeImage,
} from "../../utils/images";
import LeftSidebar from "../../layouts/leftSider";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { NavDropdown, Form } from "react-bootstrap";
import NotificationPanel from "../../layouts/notificationpanel";
import Post from "../Posts/post";
import Header from "../../layouts/header";
import React, { useState , useCallback } from "react";
import { detail } from "../../routers/routePath";
import Alert from "react-bootstrap/Alert";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import Cookies from "js-cookie";
import {Modal, Button} from 'react-bootstrap';
import Select from 'react-select'
import Autocomplete from "react-google-autocomplete";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck , faBars  , faPlus , faCheck, faHeart as fsHeart , faBuilding } from '@fortawesome/free-solid-svg-icons'
import {  faHeart  } from '@fortawesome/free-regular-svg-icons'



async function addPost(credentials) {
	return fetch(process.env.REACT_APP_API_URL + "api/posts/store", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "http://localhost:3000/",
			"Access-Control-Allow-Credentials": "true/",
			"Content-Type": "application/json",
			Authorization: "Bearer " + credentials.userToken + "",
		},
		body: JSON.stringify(credentials),
	}).then((data) => data.json());
}

export default function Media() {
	const userToken = Cookies.get("token");
	const user_type = Cookies.get("user_type");
	const user_package = Cookies.get("package");
	const [posts, setPosts] = useState([]);
	const [newpost, setNewPost] = useState([]);
	const [title, setTitle] = useState("sample Title");
	const [category, setCategory] = useState(1);
	const [body, setBody] = useState();
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const [userProperites, setUserProperties] = useState([]);
	const [toggleContents, setToggleContents] = useState(" Share a property");
	const [property, setSelectedProperty] = useState();
  const [location, setLocation] = useState('');
	const [images, setFrontFiles] = useState();
  const [postsreload, setPostsreload]= useState();
  const [newpostreload, setNewPostReload]= useState([]);
  const profile_image = Cookies.get('profile_image');
  const user_slug = Cookies.get('user_slug');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showLoader, setShowLoader] = useState(false);
  const [usStates, setUsStates] = useState(false);

  const [showNew, setShowNew] = useState(false);
  const handleCloseNew = () => setShowNew(false);
  const handleShowNew = () => setShowNew(true);

  const [showNoti, setShowNoti] = useState(false);
  const handleCloseNoti = () => setShowNoti(false);
  const handleShowNoti = () => setShowNoti(true);
  const [showLeftSideMenu, setShowLeftSideMenu] = useState(false);
  const handleCloseLeftSideMenu = () => setShowLeftSideMenu(false);
  const handleShowLeftSideMenu = () => setShowLeftSideMenu(true);

//   const options = [
// 	{ value: 'chocolate', label: 'Chocolate' }
//   ]

  const [options, setOptions]= useState([]);
  React.useEffect(() => {
  
	fetch(
	  process.env.REACT_APP_API_URL +
		`api/get/us/states`,
	  {
		method: "GET",
		headers: {
		  "Access-Control-Allow-Origin": "http://localhost:3000/",
		  "Content-Type": "application/json", 
		},
	  }
	)
	  .then((results) => results.json())
	  .then((data) => {
		//setUsStates(data);
		var arr = [];
		if(data){
			data.map((val, index) => (
				arr.push({value:val.name , label:val.name })
			))
			setOptions(arr);
		}
	  });
  
}, []); // <-- Have to pass in [] here!


 
 
  const handleRemoveImage = (i) => { 
	
	 const imgarrayrealname = images.realname;
	 imgarrayrealname.splice(i, 1);
	 const imgarraypath = images.path;
	 imgarraypath.splice(i, 1);
	 setFrontFiles({ 'path' : imgarraypath ,  'realname'  : imgarrayrealname});
	 
  } 

	const handleChange = (e) => {
		//setFiles(e.target.files)
		setErrorMessage(null);
		setSuccessMessage(null);
		const url = process.env.REACT_APP_API_URL + "api/uploadFile";
		const formData = new FormData();
		for (let i = 0; i < e.target.files.length; i++) {
			formData.append(`files[${i}]`, e.target.files[i]);
		}
		formData.append("directory", "posts");
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};
		axios.post(url, formData, config).then((response) => {
		//	setFrontFiles(response.data.files);
		let  path = [];
		let  realname = [];
			 if(images){
				 path =	images.path
				 realname =	images.realname
			}
			if(response.data.files.path){
				response.data.files.path.map(function(val, index){
					path = [...path ,val ];
				})
				response.data.files.realname.map(function(val, index){
			
					realname = [...realname ,val ];

				})
			}

			setFrontFiles({ 'path' : path ,  'realname'  : realname })
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();


		setErrorMessage(null);
		setSuccessMessage(null);

		
			setShowLoader(true);
			const res = await addPost({
				body,
				title,
				category,
				property,
		location,
		'images' : JSON.stringify(images),
				userToken,
			});
			if (res.success) {
				setShowLoader(false);
				//  sessionStorage.setItem("token", token.author.api_token);
				document.getElementById("create-post-form").reset();
				setBody("");
				setSuccessMessage("Post has been added successfully.");
				//setPostsreload(body);
				//setNewPost(res.post);
				setNewPost([res.post , ...newpost])
				setFrontFiles({ 'path' : [] ,  'realname'  : [] })
				setLocation('');
				setToggleContents(' Share a property');

			} else {
				setShowLoader(false);
				setErrorMessage(res.errors);
			}
	// }else{
	// 	handleShowNew();
	// }
	};
	React.useEffect(() => {
	
	
		
	}, [newpost]); // <-- Have to pass in [] here!

	React.useEffect(() => {
		fetch(process.env.REACT_APP_API_URL + "api/properties/user", {
			method: "GET",
			headers: {
				"Access-Control-Allow-Origin": "http://localhost:3000/",
				"Access-Control-Allow-Credentials": "true/",
				"Content-Type": "application/json",
				Authorization: "Bearer " + userToken + "",
			},
		})
			.then((results) => results.json())
			.then((data) => {
				
				setUserProperties(data.data);
			});
	}, []); // <-- Have to pass in [] here!

	React.useEffect(() => {
		fetch(process.env.REACT_APP_API_URL + "api/posts", {
			method: "GET",
			headers: {
				"Access-Control-Allow-Origin": "http://localhost:3000/",
				"Access-Control-Allow-Credentials": "true/",
				"Content-Type": "application/json",
				Authorization: "Bearer " + userToken + "",
			},
		})
			.then((results) => results.json())
			.then((data) => {
				setPosts(data.data);
			});
	}, [postsreload]); // <-- Have to pass in [] here!



	return (
		<>
		
		<div  className='testclass  w-100'>
			<div className="d-flex mx-3 mt-4 justify-content-between">

			<div className=" d-lg-none   ">
					
					<FontAwesomeIcon  onClick={handleShowLeftSideMenu} icon={faBars} />
				</div>
				<div className=" d-lg-none d-md-none d-sm-none">
					<img className="dark-icon"  onClick={handleShowNoti} src={notificationDarkIcon} alt="" />
				</div>
			 
			</div>

          </div>



		<div className="wrapper">
	
		{(showLoader)&& 
		<div id="loading">
			<div className="loader"></div>
 		</div>
		}
			

			<Modal show={showNew} onHide={handleCloseNew} size="lg">
        <Modal.Header >
          <Modal.Title>Upgrade to founders</Modal.Title>
        </Modal.Header>
        <Modal.Body>
		
		<p> To add post on the social media please upgrade to founders package. Click   <a  href="/" className="follow-btn Ralewayfamily">here</a> to upgrade.</p>
		

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseNew}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>



			 {/* <Header /> */}
			<div className="side-bar d-none d-lg-block h-auto  ">
				<LeftSidebar />
			</div>
			

			


			<div className="content">
				<div className="feed-main">
					<div className="create-post">
						<div className="post-profile">

              {(profile_image!='null')?
               <Link to={"/profile/" + user_slug + " "} ><img className={  `social-media-post-profile-image user_${user_type}` } src={process.env.REACT_APP_IMAGES_URL + profile_image} alt="" /></Link> 
              :
			  <Link to={"/profile/" + user_slug + " "} >  <img src={User} alt="" /> </Link>

              }


						</div>
						<form onSubmit={handleSubmit} id="create-post-form">
							<div className="create-post-form Ralewayfamily">
								<div className="create-post-content">
									<span>
										<FaSearch />
									</span>
									<textarea
										type="text"
										placeholder="Add details to share"
										onChange={(e) => setBody(e.target.value)}
									></textarea>
								</div>
								{successMessage && (
									<Alert variant="success">
										{successMessage && <p> {successMessage} </p>}
									</Alert>
								)}

								{errorMessage && (
									<Alert variant="danger">
										{errorMessage.body && <p> {errorMessage.body} </p>}
									</Alert>
								)}

<div className="row">
	<div className="col-sm-6 col-md-6 col-lg-4">
			<div className="d-flex">
				{(user_type=='seller' || user_type=='Real_Estate_Professional') &&
											
					<>
							


								<FontAwesomeIcon  className="  mt-2  fontawosomiconcustom addpostimgcustom"  icon={faBuilding} />


									<Dropdown
										onSelect={(eventKey) => {
											const title = userProperites[eventKey].title;

											setSelectedProperty(userProperites[eventKey].id);
											setToggleContents(<> {title}</>);
										}}
									>
										<Dropdown.Toggle
											variant="primary"
											id="dropdown-flags"
											className="text-left dropdowncustom"
											
										>
											{toggleContents}
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{userProperites.map((properties, ket) => (
												<Dropdown.Item key={properties.id} eventKey={ket}>
													{" "}
													
													{properties.title}
												</Dropdown.Item>
											))}

													
												<Dropdown.Divider />
												<Dropdown.Item   target="_blank"  href="/addProperty"> Click to add Property </Dropdown.Item>
												
										</Dropdown.Menu>
									</Dropdown>
									</>
											}
				</div>
		</div>
		<div className="col-sm-6 col-md-6 col-lg-3">
			<div className="d-flex">
								<span className="mobile-span-top mt-2">
										<img className="addpostimgcustom" src={photoIcon} alt="icon" />
									</span>

									

									<input
										type="file"
										className="btn custom-file-inputnew"
										multiple
										onChange={handleChange}
									/>
				</div>					
		</div>
		<div className="col-sm-6 col-md-6 col-lg-3">
		<span className="">
											<img className="addpostimgcustom mt-2" src={locationIcon} alt="icon" />
										</span>
									<button type="button" onClick={handleShow} className="btn custom-file-inputnew-location">
									
										
									</button>
		</div>
		<div className="col-sm-6 col-md-6 col-lg-2">
		<div className="mobile-post-btn">
								 <button
										type="submit"
										className="btn btn-primary Ralewayfamily"
										style={{ marginLeft: "auto" }}
									>
										Post
									</button>
								 </div>
		</div>
								

								
								
								</div>
							
								{/* <div className="create-post-features">
								{(user_type=='seller' || user_type=='Real_Estate_Professional') &&
													<div className="d-flex">

								<i className="fa-regular fa-building fontawosomiconcustom addpostimgcustom" ></i>

									<Dropdown
										onSelect={(eventKey) => {
											const title = userProperites[eventKey].title;

											setSelectedProperty(userProperites[eventKey].id);
											setToggleContents(<> {title}</>);
										}}
									>
										<Dropdown.Toggle
											variant="primary"
											id="dropdown-flags"
											className="text-left dropdowncustom"
											
										>
											{toggleContents}
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{userProperites.map((properties, ket) => (
												<Dropdown.Item key={properties.id} eventKey={ket}>
													{" "}
													
													{properties.title}
												</Dropdown.Item>
											))}

													
												<Dropdown.Divider />
												<Dropdown.Item   target="_blank"  href="/addProperty"> Click to add Property </Dropdown.Item>
												
										</Dropdown.Menu>
									</Dropdown>
									</div>
											}

								   <span className="mobile-span-top">
										<img className="addpostimgcustom" src={photoIcon} alt="icon" />
									</span>

									

									<input
										type="file"
										className="btn custom-file-inputnew"
										multiple
										onChange={handleChange}
									/>
									

								
									


	                  <span>
											<img className="addpostimgcustom" src={locationIcon} alt="icon" />
										</span>
									<button type="button" onClick={handleShow} className="btn custom-file-inputnew-location">
									
										
									</button>
                                 <div className="mobile-post-btn">
								 <button
										type="submit"
										className="btn btn-primary Ralewayfamily"
										style={{ marginLeft: "auto" }}
									>
										Post
									</button>
								 </div>
								
								</div> */}
								{(property) && 
								
									<> Selected Property : { toggleContents }</>
								}
								{
									
									(images) && images.path.map((img , index) => (
										<>
											
											   {(index==0)?
											   <>
												<br></br>
											   Selected Images:
												</>
											   :null}
												<span key={index} onClick={ ()=> handleRemoveImage(index)} className="closecustom"  aria-hidden="true">&times;</span>
											 
												<img width="75" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 
										</>
									
									))}

									{(location!='') && 
								
									<> 
									<br></br>
									
									<div className="mt-2 Ralewayfamily"> Selected Location : { location }</div>
									</>
								}
							</div>
						</form>
					</div>

          <Modal show={show} onHide={handleClose} size="sm">
        <Modal.Header >
          <Modal.Title>Add Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <input type="text"  placeholder="Location"  /> */}
		  {/* {(options) &&
		   <Select placeholder="Select Location"   onChange={e => setLocation(e.value)} options={options} />
		  } */}


<Autocomplete
  apiKey={process.env.REACT_APP_API_GOOGLE_MAP_KEY}
  style={{ width: "90%" }}
  onPlaceSelected={(place) => {
    setLocation(place);
  }}
  options={{
    types: ["(regions)"],
    componentRestrictions: { country: "us" },
  }}
  defaultValue="Alaska"
/>;
		 


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

{/* 
	  {(newpost.id) && 
              <Post  key={ newpost.id}  postlink = {newpost.slug} post={newpost}></Post>
          } */}




{newpost.map((post) => (
                           
						   <Post key={post.id} postlink = {post.slug} post={post}></Post>


					 
				 ))}


					{posts.map((post) => (
                           
          					<Post key={post.id} postlink = {post.slug} post={post}></Post>


						
					))}

				
				</div>
				<div className="notification-main d-none d-sm-block h-auto">
					<NotificationPanel/>
				</div>


				<Modal show={showNoti} onHide={handleCloseNoti} size="md">
					
					<Modal.Body>
                      <div style={{     width:'25px' }} className="cursor-pointer bg-primary px-2 py-1 mb-2 " onClick={handleCloseNoti}>x</div>
					<NotificationPanel />

					</Modal.Body>
					
				</Modal>

				<Modal show={showLeftSideMenu} onHide={handleCloseLeftSideMenu} size="md">
					
					<Modal.Body>
					<div style={{     width:'25px' }} className="cursor-pointer bg-primary px-2 py-1 mb-2 " onClick={handleCloseLeftSideMenu}>x</div>
					<div className='' style={{     backgroundColor:' #f6f9fe' }}>    <LeftSidebar /></div>

					</Modal.Body>
					
				</Modal>


			</div>
		</div>
		</>
	);
}
