import Header from "../../layouts/header";
import MessagesContent from "../../components/admin/messages";

export default function Messages() {
    return (
      <>
        <section className="activity">
        <div className="wrapper">
             {/* <Header /> */}
            <MessagesContent />
            </div>
        </section>
      </>
    );
  }