import { MdOutlineSend } from 'react-icons/md';
import SettingSider from "../../layouts/settingSider";
import LeftSidebar from "../../layouts/leftSider";
import React, { useState } from 'react';
import Alert from "react-bootstrap/Alert";
import axios from 'axios';
import Cookies from 'js-cookie';
import {Modal, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck , faBars } from '@fortawesome/free-solid-svg-icons'
async function addProperty(credentials) {
    return fetch(process.env.REACT_APP_API_URL+'api/property/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
          'Access-Control-Allow-Credentials' : 'true/',
          'Content-Type': 'application/json' ,
          'Authorization': 'Bearer '+credentials.userToken+'',
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
  }
export default function AddPropertyContent() {

  const [showLeftSideMenu, setShowLeftSideMenu] = useState(false);
  const handleCloseLeftSideMenu = () => setShowLeftSideMenu(false);
  const handleShowLeftSideMenu = () => setShowLeftSideMenu(true);
    const userToken = Cookies.get('token');
    const userid = Cookies.get('user_id');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [images, setFrontFiles] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showLoader, setShowLoader] = useState(false);
    const [imagesFrontHouse, setFrontFilesFrontHouse] = useState();
    const [imagesLivingRoom, setFrontFilesLivingRoom] = useState();
    const [imagesDiningRoom, setFrontFilesDiningRoom] = useState();
    const [imagesKitchen, setFrontFilesKitchen] = useState();
    const [imagesBathRoom, setFrontFilesBathRoom] = useState();
    const [imagesBackyard, setFrontFilesBackyard] = useState();
    const [category_check_list, setCategoryCheckList] = useState([]);
    const categoryCheckboxFilterHandler = e => {
        if (e.target.checked == true){
            setCategoryCheckList([...category_check_list, Number(e.target.value)]);
        }else{
            let check_list = [];
            category_check_list.map(check => {
                if (Number(check) != Number(e.target.value)){
                    check_list.push(Number(check));
                }
            });
            setCategoryCheckList(check_list);
        }
    };

    const handleRemoveImage = (i) => { 
	
      const imgarrayrealname = images.realname;
      imgarrayrealname.splice(i, 1);
      const imgarraypath = images.path;
      imgarraypath.splice(i, 1);
      setFrontFiles({ 'path' : imgarraypath ,  'realname'  : imgarrayrealname});
      
     } 
     const handleRemoveImageFrontHouse = (i) => { 
	
      const imgarrayrealname = imagesFrontHouse.realname;
      imgarrayrealname.splice(i, 1);
      const imgarraypath = imagesFrontHouse.path;
      imgarraypath.splice(i, 1);
      setFrontFilesFrontHouse({ 'path' : imgarraypath ,  'realname'  : imgarrayrealname});
      
     } 

     const handleRemoveImageLivingRoom = (i) => { 
	
      const imgarrayrealname = imagesLivingRoom.realname;
      imgarrayrealname.splice(i, 1);
      const imgarraypath = imagesLivingRoom.path;
      imgarraypath.splice(i, 1);
      setFrontFilesLivingRoom({ 'path' : imgarraypath ,  'realname'  : imgarrayrealname});
      
     } 

     const handleRemoveImageDiningRoom = (i) => { 
	
      const imgarrayrealname = imagesDiningRoom.realname;
      imgarrayrealname.splice(i, 1);
      const imgarraypath = imagesDiningRoom.path;
      imgarraypath.splice(i, 1);
      setFrontFilesDiningRoom({ 'path' : imgarraypath ,  'realname'  : imgarrayrealname});
      
     } 

     const handleRemoveImageKitchen = (i) => { 
	
      const imgarrayrealname = imagesKitchen.realname;
      imgarrayrealname.splice(i, 1);
      const imgarraypath = imagesKitchen.path;
      imgarraypath.splice(i, 1);
      setFrontFilesKitchen({ 'path' : imgarraypath ,  'realname'  : imgarrayrealname});
      
     } 


     const handleRemoveImageBathRoom = (i) => { 
	
      const imgarrayrealname = imagesBathRoom.realname;
      imgarrayrealname.splice(i, 1);
      const imgarraypath = imagesBathRoom.path;
      imgarraypath.splice(i, 1);
      setFrontFilesBathRoom({ 'path' : imgarraypath ,  'realname'  : imgarrayrealname});
      
     } 


     const handleRemoveImageBackyard = (i) => { 
	
      const imgarrayrealname = imagesBackyard.realname;
      imgarrayrealname.splice(i, 1);
      const imgarraypath = imagesBackyard.path;
      imgarraypath.splice(i, 1);
      setFrontFilesBackyard({ 'path' : imgarraypath ,  'realname'  : imgarrayrealname});
      
     } 



     const handleChangeBackyard =  e => {
      //setFiles(e.target.files)
      setErrorMessage(null);
      setSuccessMessage(null);
      setShowLoader(true);
      const url = process.env.REACT_APP_API_URL+'api/uploadFile';
      const formData = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append(`files[${i}]`, e.target.files[i])
        }
          formData.append('directory' , 'properties');
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
          axios.post(url, formData, config).then((response) => {
            let  path = [];
            let  realname = [];
              if(imagesBackyard){
                path =	imagesBackyard.path
                realname =	imagesBackyard.realname
              }
              if(response.data.files.path){
                response.data.files.path.map(function(val, index){
                  path = [...path ,val ];
                })
                response.data.files.realname.map(function(val, index){
              
                  realname = [...realname ,val ];

                })
              }

              setFrontFilesBackyard({ 'path' : path ,  'realname'  : realname })

              setShowLoader(false);
          });
  
        };



     const handleChangeBathRoom =  e => {
      //setFiles(e.target.files)
      setErrorMessage(null);
      setSuccessMessage(null);
      setShowLoader(true);
      const url = process.env.REACT_APP_API_URL+'api/uploadFile';
      const formData = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append(`files[${i}]`, e.target.files[i])
        }
          formData.append('directory' , 'properties');
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
          axios.post(url, formData, config).then((response) => {
            let  path = [];
            let  realname = [];
              if(imagesBathRoom){
                path =	imagesBathRoom.path
                realname =	imagesBathRoom.realname
              }
              if(response.data.files.path){
                response.data.files.path.map(function(val, index){
                  path = [...path ,val ];
                })
                response.data.files.realname.map(function(val, index){
              
                  realname = [...realname ,val ];

                })
              }

              setFrontFilesBathRoom({ 'path' : path ,  'realname'  : realname })

              setShowLoader(false);
          });
  
        };


     const handleChangeKitchen =  e => {
      //setFiles(e.target.files)
      setErrorMessage(null);
      setSuccessMessage(null);
      setShowLoader(true);
      const url = process.env.REACT_APP_API_URL+'api/uploadFile';
      const formData = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append(`files[${i}]`, e.target.files[i])
        }
          formData.append('directory' , 'properties');
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
          axios.post(url, formData, config).then((response) => {
            let  path = [];
            let  realname = [];
              if(imagesKitchen){
                path =	imagesKitchen.path
                realname =	imagesKitchen.realname
              }
              if(response.data.files.path){
                response.data.files.path.map(function(val, index){
                  path = [...path ,val ];
                })
                response.data.files.realname.map(function(val, index){
              
                  realname = [...realname ,val ];

                })
              }

              setFrontFilesKitchen({ 'path' : path ,  'realname'  : realname })

              setShowLoader(false);
          });
  
        };


        const handleChangeDiningRoom =  e => {
          //setFiles(e.target.files)
          setErrorMessage(null);
          setSuccessMessage(null);
          setShowLoader(true);
          const url = process.env.REACT_APP_API_URL+'api/uploadFile';
          const formData = new FormData();
          for (let i = 0; i < e.target.files.length; i++) {
            formData.append(`files[${i}]`, e.target.files[i])
            }
              formData.append('directory' , 'properties');
              const config = {
                headers: {
                  'content-type': 'multipart/form-data',
                },
              };
              axios.post(url, formData, config).then((response) => {
                let  path = [];
                let  realname = [];
                  if(imagesDiningRoom){
                    path =	imagesDiningRoom.path
                    realname =	imagesDiningRoom.realname
                  }
                  if(response.data.files.path){
                    response.data.files.path.map(function(val, index){
                      path = [...path ,val ];
                    })
                    response.data.files.realname.map(function(val, index){
                  
                      realname = [...realname ,val ];
    
                    })
                  }
    
                  setFrontFilesDiningRoom({ 'path' : path ,  'realname'  : realname })
    
                  setShowLoader(false);
              });
      
            };
     const handleChangeLivingRoom =  e => {
      //setFiles(e.target.files)
      setErrorMessage(null);
      setSuccessMessage(null);
      setShowLoader(true);
      const url = process.env.REACT_APP_API_URL+'api/uploadFile';
      const formData = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append(`files[${i}]`, e.target.files[i])
        }
          formData.append('directory' , 'properties');
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
          axios.post(url, formData, config).then((response) => {
            let  path = [];
            let  realname = [];
              if(imagesLivingRoom){
                path =	imagesLivingRoom.path
                realname =	imagesLivingRoom.realname
              }
              if(response.data.files.path){
                response.data.files.path.map(function(val, index){
                  path = [...path ,val ];
                })
                response.data.files.realname.map(function(val, index){
              
                  realname = [...realname ,val ];

                })
              }

              setFrontFilesLivingRoom({ 'path' : path ,  'realname'  : realname })

              setShowLoader(false);
          });
  
        };


     const handleChangeFrontHouse =  e => {
      //setFiles(e.target.files)
      setErrorMessage(null);
      setSuccessMessage(null);
      setShowLoader(true);
      const url = process.env.REACT_APP_API_URL+'api/uploadFile';
      const formData = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append(`files[${i}]`, e.target.files[i])
        }
          formData.append('directory' , 'properties');
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
          axios.post(url, formData, config).then((response) => {
            let  path = [];
            let  realname = [];
              if(imagesFrontHouse){
                path =	imagesFrontHouse.path
                realname =	imagesFrontHouse.realname
              }
              if(response.data.files.path){
                response.data.files.path.map(function(val, index){
                  path = [...path ,val ];
                })
                response.data.files.realname.map(function(val, index){
              
                  realname = [...realname ,val ];

                })
              }

              setFrontFilesFrontHouse({ 'path' : path ,  'realname'  : realname })

              setShowLoader(false);
          });
  
        };



    const handleChange =  e => {
        //setFiles(e.target.files)
        setErrorMessage(null);
        setSuccessMessage(null);
        setShowLoader(true);
        const url = process.env.REACT_APP_API_URL+'api/uploadFile';
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
          formData.append(`files[${i}]`, e.target.files[i])
          }
            formData.append('directory' , 'properties');
            const config = {
              headers: {
                'content-type': 'multipart/form-data',
              },
            };
            axios.post(url, formData, config).then((response) => {
              let  path = [];
              let  realname = [];
                if(images){
                  path =	images.path
                  realname =	images.realname
                }
                if(response.data.files.path){
                  response.data.files.path.map(function(val, index){
                    path = [...path ,val ];
                  })
                  response.data.files.realname.map(function(val, index){
                
                    realname = [...realname ,val ];

                  })
                }

                setFrontFiles({ 'path' : path ,  'realname'  : realname })

                setShowLoader(false);
            });
    
          };


    const handleSubmit = async e => {

        
        e.preventDefault();
        setErrorMessage(null);
       
         
          const res = await addProperty({
            title,
            category,
            about,
            address,
            city,
            area,
            county,
            state,
            size,
            beds,
            floors,
            baths,
            rooms,
            is_realtor,
            is_live_in_jo_tex,
            about_seller,
            seller_full_name,
            seller_contact,
            seller_email,
            seller_location,
            is_associated_realtor,
            about_realtor,
            realtor_full_name,
            realtor_company,
            realtor_contact,
            realtor_email,
            realtor_location,
            is_show_property_onsite,
            is_available_for_sale,
            is_accept_investor_as_buyer,
            agree_with_property_value,
            share_property,
            terms_condictions,
            demand,
            property_detail_for_calc,
            'images' : JSON.stringify(images),
            'imagesFrontHouse' : JSON.stringify(imagesFrontHouse),
            'imagesLivingRoom' : JSON.stringify(imagesLivingRoom),
            'imagesDiningRoom' : JSON.stringify(imagesDiningRoom),
            'imagesKitchen' : JSON.stringify(imagesKitchen),
            'imagesBathRoom' : JSON.stringify(imagesBathRoom),
            'imagesBackyard' : JSON.stringify(imagesBackyard),
            "amenities":category_check_list,
            userToken
          });
          if(res.success){
          //  sessionStorage.setItem("token", token.author.api_token);
          document.getElementById("create-course-form").reset();
          setSuccessMessage('Property has been added successfully.');
          const section = document.querySelector( '#create-course-form' );
            section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
          }else{
          
            setErrorMessage(res.errors);
            const section = document.querySelector( '#create-course-form' );
            section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
          }
    
        }

    const [searchToggle, setSearchToggle] = useState(false);
    const [sellerToggle, setSellerToggle] = useState(false);

    const [title, setTitle] = useState();
    const [category, setCategory] = useState('House');
    const [about, setAbout] = useState();
    const [address, setAddress] = useState();
    const [city, setCity] = useState();
    const [area, setArea] = useState();
    const [county, setCounty] = useState();
    const [state, setState] = useState();
    const [size, setSize] = useState();
    const [beds, setBeds] = useState();
    const [baths, setBaths] = useState();
    const [rooms, setRooms] = useState();
    const [floors, setFloors] = useState();
    const [is_realtor, setIsRealtorToggle] = useState('no');
    const [is_live_in_jo_tex, setIsIsLiving] = useState('no');
    const [about_seller, setAboutSeller] = useState();
    const [seller_full_name, setSellerFullname] = useState();
    const [seller_contact, setSellerContact] = useState();
    const [seller_email, setSellerEmail] = useState();
    const [seller_location, setSellerLocation] = useState();
    const [is_associated_realtor, setIsRealtorAssciatedToggle] = useState('no');
    const [about_realtor, setAboutRealtor] = useState();
    const [realtor_full_name, setRealtorFullname] = useState();
    const [realtor_company, setRealtorCompany] = useState();
    const [realtor_contact, setRealtorContact] = useState();
    const [realtor_email, setRealtorEmail] = useState();
    const [realtor_location, setRealtorLocation] = useState();
    const [is_show_property_onsite, setIsShowPropertyOnsite] = useState('no');
    const [is_available_for_sale, setIsAvailabeForSale] = useState('no');
    const [is_accept_investor_as_buyer, setIsAcceptInvestoryAsBuyer] = useState('no');

    const [agree_with_property_value, setArgreeWithPropertyValue] = useState('no');
    const [share_property, setShareProperty] = useState('no');
    const [terms_condictions, setTermsConditions] = useState('no');
    const [demand, setDemand] = useState();
    const [property_detail_for_calc, setDetailForCalc] = useState();
    const [amenities, setAmeniteis] = useState([]);
    const [usStates, setUsStates] = useState(false);

    const [cities, setCities] = useState(false);

    
    const handleCities = (val) => {
        // console.log('handleCities' , usStates[val].cities);
         setCities(usStates[val].cities);
         };
   
       React.useEffect(() => {
         fetch(
           process.env.REACT_APP_API_URL +
             `api/get/us/states`,
           {
             method: "GET",
             headers: {
               "Access-Control-Allow-Origin": "http://localhost:3000/",
               "Content-Type": "application/json", 
             },
           }
         )
           .then((results) => results.json())
           .then((data) => {
             setUsStates(data);
             setCities(data[0].cities);
           });
       
     }, []); // <-- Have to pass in [] here!


    React.useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'api/amenities' , {
          method: 'GET',
          headers: {
            'Access-Control-Allow-Origin' : 'http://localhost:3000/',
            'Access-Control-Allow-Credentials' : 'true/',
            'Content-Type': 'application/json' ,
            'Authorization': 'Bearer '+userToken+'',
          },
        }
          )
          .then(results => results.json())
          .then(data => {
      
            setAmeniteis(data.data);
          });
      }, []); // <-- Have to pass in [] here!


    const checkforPropertyValue =  e => {
            if(e){
                setArgreeWithPropertyValue('yes');
            }else{
                setArgreeWithPropertyValue('no');
            }
    }

    const checksetShareProperty =  e => {
        if(e){
            setShareProperty('yes');
        }else{
            setShareProperty('no');
        }
}

const checksetTermsConditions =  e => {
    if(e){
        setTermsConditions('yes');
    }else{
        setTermsConditions('no');
    }
}



    return (
      <>
      		{(showLoader)&& 
		<div id="loading">
			<div className="loader"></div>
 		</div>
		}
        <section className="edit-profile-content row">



        <div  className='message-pages-top  w-100'>
            <div className="d-flex mx-4 mt-4 justify-content-between">

              <div className=" d-lg-none   ">
                 
                  <FontAwesomeIcon  onClick={handleShowLeftSideMenu} icon={faBars} />
                </div>
            
            </div>

          </div>

        <div className="sidebar col-md-6 col-lg-2 col-0 d-none d-lg-block h-auto">  
            <LeftSidebar />
          </div>
     
          <div className="sidebar search-sidebar col-md-6 col-lg-2 col-0 d-none d-lg-block h-auto ">
            <SettingSider />
          </div>



          {/* <div  className='side-bar d-none d-lg-block h-auto'>
             <div className='' style={{     backgroundColor:' #f6f9fe' }}>   <LeftSidebar /></div>
             <div className='' style={{     backgroundColor:' #f6f9fe' }}><SettingSider /></div>

          </div> */}


          <Modal    dialogClassName='custom-dialog' show={showLeftSideMenu} onHide={handleCloseLeftSideMenu} size="lg"  >
					
					<Modal.Body>
					<div style={{     width:'25px' }} className="cursor-pointer bg-primary px-2 py-1 mb-2 " onClick={handleCloseLeftSideMenu}>x</div>
          
          <div className='row'>
          <div className="sidebar col-6  ">  
            <LeftSidebar />
          </div>
     
          <div className="sidebar search-sidebar col-6   ">
            <SettingSider />
          </div>
          </div>
         

					</Modal.Body>
					
				</Modal>

       
          <div className="edit-container ol-xsm-12 col-sm-12   col-md-12 col-lg-11 col-12">
          <form onSubmit={handleSubmit} id="create-course-form">
          {successMessage && (
            <Alert variant="success" >
                 {successMessage && (
                <p>  {successMessage} </p>
                )}
                </Alert>
              )}

{errorMessage && (
            <Alert variant="danger" >
                 {errorMessage.title && (
                <p>  {errorMessage.title} </p>
                )}

            {errorMessage.about && (
                <p>  {errorMessage.about} </p>
            )}
             {errorMessage.city && (
                <p>  {errorMessage.city} </p>
            )}
             {errorMessage.state && (
                <p>  {errorMessage.state} </p>
            )}
             {errorMessage.area && (
                <p>  {errorMessage.area} </p>
            )}
             {errorMessage.county && (
                <p>  {errorMessage.county} </p>
            )}
             {errorMessage.category && (
                <p>  {errorMessage.category} </p>
            )}
             {errorMessage.is_realtor && (
                <p>  {errorMessage.is_realtor} </p>
            )}
             {errorMessage.is_associated_realtor && (
                <p>  {errorMessage.is_associated_realtor} </p>
            )}
             {errorMessage.is_show_property_onsite && (
                <p>  {errorMessage.is_show_property_onsite} </p>
            )}
             {errorMessage.is_available_for_sale && (
                <p>  {errorMessage.is_available_for_sale} </p>
            )}
             {errorMessage.is_accept_investor_as_buyer && (
                <p>  {errorMessage.is_accept_investor_as_buyer} </p>
            )}
             {errorMessage.agree_with_property_value && (
                <p>  {errorMessage.agree_with_property_value} </p>
            )}
            {errorMessage.share_property && (
                <p>  {errorMessage.share_property} </p>
            )}
                  
                  {errorMessage.terms_condictions && (
                <p>  {errorMessage.terms_condictions} </p>
            )}
                  
              
                 
                </Alert>

          )}

            <h3>Add Property</h3>
            <div className="row edit-form">
            <div className=" col-md-12 mb-5">
            <label className='mb-2'>DISCLAIMER</label>
<p>US Housing Exchange allows Sellers to list their properties on this site at no cost.  The Seller assumes all responsibility and liability for any properties they list. US Housing Exchange will not be held liable for any misreprenstations or  inaccurate information related to a property listed by Seller.</p>
              </div>
            

              <div className="signup-input col-md-6">
                <label>Property Name</label>
                <input type="text" value={title}  onChange={e => setTitle(e.target.value)}/>
              </div>
              <div className="signup-input col-md-6">
                <label>Category</label>
                <select value={category} className="form-select type-select" onChange={e => setCategory(e.target.value)} aria-label="Default select example">
                    <option>House</option>
                    <option>Unit</option>
                    <option>Townhouse</option>
                    <option>Villa</option>
                    <option>Apartment</option>
                    <option>Flat</option>
                    <option>Studio</option>
                    <option>Warehouse</option>
                    <option>Block Of Units</option>
                    <option>Terrace</option>
                    <option>Serviced Apartment</option>
                    <option>Other</option>
                </select>
              </div>
              <div className="signup-input col-12">
                  <label>About The Property</label>
                  <textarea value={about} onChange={e => setAbout(e.target.value)}></textarea>
              </div>
              <div className="signup-input col-md-6">
                <label>Address</label>
                <input type="text" value={address}  onChange={e => setAddress(e.target.value)} />
              </div>
             
              <div className="signup-input col-md-6">
                  <label>Area</label>
                  <input type="text" value={area} onChange={e => setArea(e.target.value)}/>
              </div>
              {/* <div className="signup-input col-md-6">
                  <label>County</label>
                  <input type="text" value={county}  onChange={e => setCounty(e.target.value)}/>
              </div> */}
              <div className="signup-input col-md-6">
                  <label>State</label>
           

                  <select  name="" id=""  onChange={e =>  {setState(e.target.value) ; handleCities(e.target.selectedIndex) }}
                                        >
           
                        {(usStates)&&  
                        usStates.map((val, index) => (
                            <option key={index}  value={val.name} data-id={index}>{val.name}</option>
                        ))
                
                        }
                    
                </select>

              </div>
              <div className="signup-input col-md-6">
                  <label>City</label>
      

                  <select  onChange={e => setCity(e.target.value)}>
              {(cities)&&  
              cities.map((val, index) => (
                <option key={index}  value={val.name} data-id={index}>{val.name}</option>
              ))
     
              }
              </select>


              </div>
              <div className="signup-input col-md-6">
                  <label>Property Size</label>
                  <input type="text" value={size}  onChange={e => setSize(e.target.value)}/>
              </div>
              <div className="signup-input col-md-6">
                  <label>Total Bed</label>
                  <input type="number" value={beds} onChange={e => setBeds(e.target.value)}/>
              </div>
              <div className="signup-input col-md-6">
                  <label>Total Bath</label>
                  <input type="number" value={baths} onChange={e => setBaths(e.target.value)}/>
              </div>
              <div className="signup-input col-md-6">
                  <label>Total Rooms</label>
                  <input type="number" value={rooms} onChange={e => setRooms(e.target.value)}/>
              </div>
              <div className="signup-input col-md-6">
                  <label>Total Floor</label>
                  <input type="number"  value={floors} onChange={e => setFloors(e.target.value)}/>
              </div>
              {amenities && (
             
              <div className="row   col-md-12">
               {amenities.map((am , index) => 
                        (

                <div key={index} className="col-md-3 details-btn d-flex">
                    <input type='checkbox'  onChange={e => categoryCheckboxFilterHandler(e)}  value={am.id} />
                    <h3 className='ml-2'>{am.title}</h3>
                </div>
                   ))} 

              </div>
               )}


              <div className="col-md-3 file-field file-field-image-container">
                <input type="file" multiple onChange={handleChange}/>
                <h3>
             
                  
                Main picture <br /> Click  to upload</h3>

                  {
									
									(images) && images.path.map((img , index) => (
										<>
											
											  
												<p key={index} onClick={ ()=> handleRemoveImage(index)} className=" text-center closecustom"  aria-hidden="true">&times;</p>
											 <div className='d-flex justify-content-center'>
												<img width="75" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 
                        </div>
                  	</>
									
									))}

              </div>

              <div className="col-md-3 file-field file-field-image-container">
                <input type="file" multiple onChange={handleChangeFrontHouse}/>
                <h3>
             
                  
                Front view of the house <br /> Click  to upload</h3>

                  {
									
									(imagesFrontHouse) && imagesFrontHouse.path.map((img , index) => (
										<>
											
											  
												<p key={index} onClick={ ()=> handleRemoveImageFrontHouse(index)} className="closecustom text-center"  aria-hidden="true">&times;</p>
                        <div className='d-flex justify-content-center'>
												<img width="75" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 
                    </div>
                    </>
									
									))}

              </div>


              <div className="col-md-3 file-field file-field-image-container">
                <input type="file" multiple onChange={handleChangeLivingRoom}/>
                <h3>
             
                  
                Living Room <br /> Click  to upload</h3>

                  {
									
									(imagesLivingRoom) && imagesLivingRoom.path.map((img , index) => (
										<>
											
											  
												<p key={index} onClick={ ()=> handleRemoveImageLivingRoom(index)} className="closecustom text-center"  aria-hidden="true">&times;</p>
                        <div className='d-flex justify-content-center'>
												<img width="75" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 
                    </div>
                  	</>
									
									))}

              </div>


              <div className="col-md-3 file-field file-field-image-container">
                <input type="file" multiple onChange={handleChangeDiningRoom}/>
                <h3>
             
                  
                Dining Room <br /> Click  to upload</h3>

                  {
									
									(imagesDiningRoom) && imagesDiningRoom.path.map((img , index) => (
										<>
											
											  
												<p key={index} onClick={ ()=> handleRemoveImageDiningRoom(index)} className="closecustom text-center"  aria-hidden="true">&times;</p>
                        <div className='d-flex justify-content-center'>
												<img width="75" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 
                    </div>
                    </>
									
									))}

              </div>


              <div className="col-md-3 file-field file-field-image-container">
                <input type="file" multiple onChange={handleChangeKitchen}/>
                <h3>
             
                  
                Kitchen <br /> Click  to upload</h3>

                  {
									
									(imagesKitchen) && imagesKitchen.path.map((img , index) => (
										<>
											
											  
												<p key={index} onClick={ ()=> handleRemoveImageKitchen(index)} className="closecustom text-center"  aria-hidden="true">&times;</p>
                        <div className='d-flex justify-content-center'>
												<img width="75" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 
                      </div>
                    </>
									
									))}

              </div>

              <div className="col-md-3 file-field file-field-image-container">
                <input type="file" multiple onChange={handleChangeBathRoom}/>
                <h3>
             
                  
               Bathroom <br /> Click  to upload</h3>

                  {
									
									(imagesBathRoom) && imagesBathRoom.path.map((img , index) => (
										<>
											
											  
												<p key={index} onClick={ ()=> handleRemoveImageBathRoom(index)} className="closecustom text-center"  aria-hidden="true">&times;</p>
                        <div className='d-flex justify-content-center'>
												<img width="75" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />	 
                    </div>
                  	</>
									
									))}

              </div>


              <div className="col-md-3 file-field file-field-image-container">
                <input type="file" multiple onChange={handleChangeBackyard}/>
                <h3>
             
                  
               Backyard <br /> Click  to upload</h3>

                  {
									
									(imagesBackyard) && imagesBackyard.path.map((img , index) => (
										<>
											
											  
												<p key={index} onClick={ ()=> handleRemoveImageBackyard(index)} className="closecustom text-center"  aria-hidden="true">&times;</p>
                        <div className='d-flex justify-content-center'>
												<img width="75" key={index+img} src={process.env.REACT_APP_IMAGES_URL +img} />
                        </div>	 
										</>
									
									))}

              </div>




             
              {/* <div className="col-12 mb-2">
                <h3 className='yes-no'>Are you a realtor? <span onClick={() => setIsRealtorToggle('yes')}>Yes</span> / <span onClick={() => setIsRealtorToggle('no')}>No</span></h3>
              </div> */}

<             div className="col-12 mb-2 mt-5 ">
                <div className=" d-flex justify-content-left">
                  <div className=' w-xs-50 w-sm-50 w-75  '>
                  <h3 className='yes-no'>Do you live in Georgia or Texas?</h3>
                  </div>
                  <div>
                  <span className='mx-2'>
                        <input type="radio" value="yes" name="setIsIsLiving"  onClick={() => setIsIsLiving('yes')}  />
                        <label className='px-2' htmlFor="yes1">Yes</label>
                    </span>
                    <span className='mx-2'>
                        <input type="radio" name="setIsIsLiving" value="no" onClick={() => setIsIsLiving('no')} />
                        <label className='px-2' htmlFor="no1">No</label>
                    </span>
                  </div>
                   
                    
                </div>
              </div>



              <div className="col-12 mb-2">
                <div className="d-flex justify-content-left">
                  <div className=' w-xs-50 w-sm-50 w-75  '>
                  <h3 className='yes-no'>Are you a Real Estate Professional?</h3>
                  </div>
                  <div>
                    <span  className='mx-2'>
                          <input type="radio" value="yes" name="setIsRealtorToggle"  onClick={() => setIsRealtorToggle('yes')}  />
                          <label className='px-2' htmlFor="yes1">Yes</label>
                      </span>
                      <span  className='mx-2'>
                          <input type="radio" name="setIsRealtorToggle" value="no" onClick={() => setIsRealtorToggle('no')} />
                          <label className='px-2' htmlFor="no1">No</label>
                      </span>
                  </div>
                  
                 
                </div>
              </div>




              <div className={`signup-input col-md-12 ${is_realtor=='yes' ? "shown" : "hidden"}`}>
                  <label>Please provide information about the seller:  (it will not be public)</label>
                  <textarea name="" id="" cols="30" rows="10" onChange={e => setAboutSeller(e.target.value)}></textarea>
              </div>
              <div className={`signup-input col-md-6 ${is_realtor=='yes' ? "shown" : "hidden"}`}>
                  <label>Full Name</label>
                  <input type="text" />
              </div>
              <div className={`signup-input col-md-6 ${is_realtor=='yes' ? "shown" : "hidden"}`}>
                  <label>Contact Number</label>
                  <input type="text"  onChange={e => setSellerContact(e.target.value)}/>
              </div>
              <div className={`signup-input col-md-6 ${is_realtor=='yes' ? "shown" : "hidden"}`}>
                  <label>Email</label>
                  <input type="email" onChange={e => setSellerEmail(e.target.value)} />
              </div>
              <div className={`signup-input col-md-6 ${is_realtor=='yes' ? "shown" : "hidden"}`}>
                  <label>Location</label>
                  <input type="text" onChange={e => setSellerLocation(e.target.value)} />
              </div>


              {/* <div className="col-12 mb-2">
                <h3 className='yes-no'>Is there any realtor associated with the property? <span onClick={() => setIsRealtorAssciatedToggle('yes')}>Yes</span> / <span onClick={() => setIsRealtorAssciatedToggle('no')}>No</span></h3>
              </div> */}


              <div className="col-12 mb-2">
                <div className="d-flex justify-content-left">
                  <div className=' w-xs-50 w-sm-50 w-75  '>
                  <h3 className='yes-no'>Is there any Real Estate Professional associated with the property?</h3>
                  </div>
                  <div>
                  <span className='mx-2'>
                        <input type="radio" value="yes" name="setIsRealtorAssciatedToggle"  onClick={() => setIsRealtorAssciatedToggle('yes')}  />
                        <label className='px-2' htmlFor="yes1">Yes</label>
                    </span>
                    <span className='mx-2'>
                        <input  type="radio" name="setIsRealtorAssciatedToggle" value="no" onClick={() => setIsRealtorAssciatedToggle('no')} />
                        <label className='px-2' htmlFor="no1">No</label>
                    </span>
                  </div>
                  
                </div>
              </div>


              <div className={`signup-input col-md-12 ${is_associated_realtor=='yes' ? "shown" : "hidden"}`}>
                  <label>Please provide information about the seller:  (it will not be public)</label>
                  <textarea name="" id="" cols="30" rows="10" onChange={e => setAboutRealtor(e.target.value)} ></textarea>
              </div>
              <div className={`signup-input col-md-6 ${is_associated_realtor=='yes' ? "shown" : "hidden"}`}>
                  <label>Full Name</label>
                  <input type="text"  onChange={e => setRealtorFullname(e.target.value)} />
              </div>
              <div className={`signup-input col-md-6 ${is_associated_realtor=='yes' ? "shown" : "hidden"}`}>
                  <label>Company Name</label>
                  <input type="text" onChange={e => setRealtorCompany(e.target.value)} />
              </div>
              <div className={`signup-input col-md-6 ${is_associated_realtor=='yes' ? "shown" : "hidden"}`}>
                  <label>Contact Number</label>
                  <input type="text" onChange={e => setRealtorContact(e.target.value)} />
              </div>
              <div className={`signup-input col-md-6 ${is_associated_realtor=='yes' ? "shown" : "hidden"}`}>
                  <label>Email</label>
                  <input type="email"  onChange={e => setRealtorEmail(e.target.value)}/>
              </div>
              <div className={`signup-input col-md-6 ${is_associated_realtor=='yes' ? "shown" : "hidden"}`}>
                  <label>Location</label>
                  <input type="text" onChange={e => setRealtorLocation(e.target.value)} />
              </div>
              <div className="col-12 mb-2">
                <div className=" d-flex justify-content-left">
                  <div className=' w-xs-50 w-sm-50 w-75  '>
                  <h3 className='yes-no'>Do you want to accept the bookings for showing property on-site?</h3>
                  </div>
                   <div >
                   <span className='mx-2'>
                        <input type="radio" value="yes" name="setIsShowPropertyOnsite"  onChange={e => setIsShowPropertyOnsite(e.target.value)}  />
                        <label className='px-2' htmlFor="yes1">Yes</label>
                    </span>
                    <span className='mx-2'>
                        <input type="radio" name="setIsShowPropertyOnsite" value="no"  onChange={e => setIsShowPropertyOnsite(e.target.value)} />
                        <label className='px-2' htmlFor="no1">No</label>
                    </span>
                   </div>
                   
                </div>
              </div>
              <div className="col-12 mb-2"> 
                <div className="d-flex justify-content-left">
                  <div className=' w-xs-50 w-sm-50 w-75  '> 
                  <h3 className='yes-no'>Is your property available for sale immediately?</h3>
                  </div>
                    <div>
                    <span className='mx-2'>
                        <input type="radio" name="AvailabeForSale" value="yes" id="yes2"    onChange={e => setIsAvailabeForSale(e.target.value)}/>
                        <label className='px-2' htmlFor="yes2">Yes</label>
                    </span>
                    <span className='mx-2'>
                        <input type="radio" name="AvailabeForSale" value="no" id="no2" onChange={e => setIsAvailabeForSale(e.target.value)}/>
                        <label className='px-2' htmlFor="no2">No</label>
                    </span>
                    </div>
                   
                </div>
              </div>
              <div className="col-12 mb-2">
                <div className=" d-flex justify-content-left">
                  <div className=' w-xs-50 w-sm-50 w-75  '>
                  <h3 className='yes-no'>Do you accept the investors as buyers?</h3>
                  </div>
                  <div>
                  <span className='mx-2'>
                        <input type="radio" name="setIsAcceptInvestoryAsBuyer" value="yes" id="yes3" onChange={e => setIsAcceptInvestoryAsBuyer(e.target.value)}/>
                        <label className='px-2' htmlFor="yes3">Yes</label>
                    </span>
                    <span className='mx-2'>
                        <input type="radio" name="setIsAcceptInvestoryAsBuyer" value="no" id="no3" onChange={e => setIsAcceptInvestoryAsBuyer(e.target.value)}/>
                        <label className='px-2' htmlFor="no3">No</label>
                    </span>
                  </div>
                    
                </div>
              </div>
              <div className="col-12 mb-4">
                <div className="">
                    <span className='ml-0 mr-2'>
                        {/* <input type="checkbox" name="yes4" id="yes4" onChange={e => checkforPropertyValue(e.target.checked)}/> */}
                        <label htmlFor="yes4" className='agree-label'>Please provide some details about property value to help us with the assessment.</label>
                    </span>
                </div>
              </div>
              <div className="signup-input col-12">
                  <label>Askig price in USD?</label>
                  <select onChange={e => setDemand(e.target.value)}  required >
                  <option value=""> Select Amount</option>
                  <option value="< 5,000 - 20,000"> { '<'} $5,000 - $20,000</option>
                    <option value="20,000-100,000">$20,000-$100,000</option>
                    <option value="100,000-300,000">$100,000-$300,000</option>
                    <option value="300,000-500,000"> $300,000-$500,000</option>
                    <option value="500,000-750,000">$500,000-$750,000</option>
                    <option value="750,000-1M">$750,000-$1M</option>
                    <option value="1M-1.5M" >$1M-$1.5M</option>
                    <option value="1.5M-3M" >$1.5M-$3M</option>
                    <option value="3M-4.5M" >$3M-$4.5M</option>
                    <option value="4.5M-7M" >$4.5M-$7M</option>
                    <option value="7M-10M" >$7M-$10M</option>
                    <option value="> $10M" > { '>'} $10M  </option>
                    </select>
              </div>
              <div className="signup-input col-12">
                  <label>Please write some details about the property worth and your sale price. It will help us with the calculation</label>
                  <textarea    onChange={e => setDetailForCalc(e.target.value)} ></textarea>
              </div>
            </div>
            <div className="col-12 mb-4">
                <div className="cbox yes-no-container">
                    <span className='ml-0 mr-2'>
                        <input type="checkbox" name="yes5" id="yes5" onChange={e => checksetShareProperty(e.target.checked)}/>
                        <label htmlFor="yes5" className='agree-label'>Share property on your social media feed   (  <a href="#!"  onClick={handleShow} >Read More</a>  )</label>
                    </span>
                </div>
            </div>
            <div className="col-12 mb-4">
                <div className="cbox yes-no-container">
                    <span className='ml-0 mr-2'>
                        <input type="checkbox" name="yes6" id="yes6" onChange={e => checksetTermsConditions(e.target.checked)}/>
                        <label htmlFor="yes6" className='agree-label'>Do you agree to our <a href='/privacy-policy' target="_blank"> privacy policy </a> and <a href='/terms-conditions' target="_blank"> terms of use </a>?</label>
                    </span>
                </div>
            </div>
            <div className="signup-submit">
              <button className="submit-btn mr-2">Back</button>
              <button type='submit' className="submit-btn mr-2">Finish and Submit</button>
              <button className="submit-btn">Save as draft </button>
            </div>
            </form>
          </div>
         
        </section>

        <Modal show={show} onHide={handleClose} size="lg">
                    <Modal.Header >
                    <Modal.Title>What is USHX Social Media?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    Our goal is to create a positive environment and create win/win scenarios in all phases of real estate, whether you are buying, selling, or investing. In addition, we want to be a market disruption in several stages as we embark on making a necessary change in how real estate is done.
                  
                  
                    <br></br>
                    <br></br>
                    <a href='/privacy-policy' target="_blank"> Privacy policy </a>
                     <br></br>  <br></br>  
                     <a href='/terms-conditions' target="_blank">Terms of use </a>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>


      </>
    );
  }