export default function Description({ description }) {
	return (
		<section className="realtor-description">
			<div className="container">
				<h2 className="main-title-head">Description</h2>
				<p className="location-check">{description}</p>
			</div>
		</section>
	);
}
