import {
  communityDarkIcon,
  communityLightIcon,
  exploreDarkIcon,
  exploreLightIcon,
  feedDarkIcon,
  feedLightIcon,
  logoutDarkIcon,
  logoutLightIcon,
  messagesDarkIcon,
  messagesLightIcon,
  notificationDarkIcon,
  notificationLightIcon,
  profileDarkIcon,
  profileLightIcon,
  settingDarkIcon,
  propertyTypeIcon,
  settingLightIcon,
} from "../utils/images";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';

export default function LeftSidebar() {
  const userid = Cookies.get('user_id');
  const history = useHistory();
  const user_type = Cookies.get('user_type');

  if(!userid){
    history.push("/login");
  }

  function logOut() {
    //sessionStorage.removeItem("token");
    Cookies.remove('currentstep');
    Cookies.remove('token');
    Cookies.remove('user_state');
    Cookies.remove('user_id');
    Cookies.remove('isphonevarified');
    Cookies.remove('kycsubmitted');
    history.push("/login");
  }


  return (
    <ul className="sidebar-list">
      <li>
        <NavLink to="/socialMedia" activeClassName="active">
          <span>
            <img className="light-icon" src={feedLightIcon} alt="" />
            <img className="dark-icon" src={feedDarkIcon} alt="" />
          </span>
          <span>Feed</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/community" >
          <span>
            <img className="light-icon" src={communityLightIcon} alt="" />
            <img className="dark-icon" src={communityDarkIcon} alt="" />
          </span>
          <span  data-tut="reactour__browse_community">My Community</span>
        </NavLink>
      </li>
      <li >
        <NavLink to="/messages" activeClassName="active">
          <span>
            <img className="light-icon" src={messagesLightIcon} alt="" />
            <img className="dark-icon" src={messagesDarkIcon} alt="" />
          </span>
          <span data-tut="reactour__browse_messages">Messages</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/notification">
          <span>
            <img className="light-icon" src={notificationLightIcon} alt="" />
            <img className="dark-icon" src={notificationDarkIcon} alt="" />
          </span>
          <span>Notification</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/listing">
          <span>
            <img className="light-icon" src={exploreLightIcon} alt="" />
            <img className="dark-icon" src={exploreDarkIcon} alt="" />
          </span>
          <span>Explore</span>
        </NavLink>
      </li>
      {/* <li>
        <NavLink to="/profile">
          <span>
            <img className="light-icon" src={profileLightIcon} alt="" />
            <img className="dark-icon" src={profileDarkIcon} alt="" />
          </span>
          <span>Profile</span>
        </NavLink>
      </li> */}

      {(user_type=='seller' || user_type=='Real_Estate_Professional') &&
      <li>
        <NavLink to="/addProperty" activeClassName="active" >
          <span>
            <img className="light-icon" src={propertyTypeIcon} alt="" />
            <img className="dark-icon" src={propertyTypeIcon} alt="" />
          </span>
          <span data-tut="reactour__browse_add_property">Add Property</span>
        </NavLink>
      </li>
      }


      <li>
        <NavLink to="/edit-profile" activeClassName="active">
          <span>
            <img className="light-icon" src={settingLightIcon} alt="" />
            <img className="dark-icon" src={settingDarkIcon} alt="" />
          </span>
          <span>Setting</span>
        </NavLink>
      </li>
      <li>
        <NavLink to='#' onClick={logOut}>
          <span>
            <img className="light-icon" src={logoutLightIcon} alt="" />
            <img className="dark-icon" src={logoutDarkIcon} alt="" />
          </span>
          <span>Logout</span>
        </NavLink>
      </li>
    </ul>
  );
}
