import React, { useState } from 'react';

import Alert from "react-bootstrap/Alert";
import { useHistory } from "react-router-dom";

 

  export default function PackageForm(props) {
   
    const [sellerToggle, setSellerToggle] = useState(props.packageSelected);

    return (
     
      <>
  <ol class="progtrckr">
    <li class="progtrckr-done" onClick={() => props.setCount(0)} value="0">
     <span> User Type</span>
    </li>
    <li class="progtrckr-done"   onClick={() => props.setCount(1)} value="1">
      <span>Personal Information</span>
    </li>
    <li class="progtrckr-doing" value="2">
      <span>Package</span>
    </li>
    <li class="progtrckr-todo" value="3">
     <span>Additional information</span>
      </li>
    </ol>
        <h3 className="signup-message">Please select a package?</h3>
        <div className="row animatefadein">
        
        <div className="cbox yes-no-containernew row ">
                   
                    <div className='col-lg-6 mb-3' onClick={() => props.onPackageSelected('standard')}>
                      <div className='packageborder'>
                      <input type="radio" name="yes8" id="yes8" defaultChecked={(props.packageSelected=='standard')?true:false}  />
                        <label for="yes8" className='font-weight-bold'>Standard Package</label>
                        <div className='mt-2 d-flex justify-content-start'>
                          <div className='font-weight-bold mr-3 pl-1'>✓</div>  <div><p className='mb-2'>Free membership for you to view, buy, sell, and invest in real estate. Create relationships and interact with our community. </p> </div> 
                        </div>
                      </div>
                        

                    </div> 
                    <div className='col-lg-6' onClick={() => props.onPackageSelected('founder')}>
                    <div className='packageborder'>
                        <input type="radio" name="yes8" id="no8" defaultChecked={(props.packageSelected=='founder')?true:false}/>
                        <label for="no8" className='font-weight-bold '>Founders Club</label> 
                        <span className=' pl-2 font-weight-bold '>($100 worth of crypto/month)</span>
                        <div className='mt-2'>
                          <div className='d-flex justify-content-start'>
                          <div className='font-weight-bold mr-3 pl-1'>✓</div> 
                          <p className='mb-2'> Only available to first 10,000 members, there are many perks that come along with this exclusive membership </p>
                          </div>
                          <div className='d-flex justify-content-start'>
                          <div className='font-weight-bold mr-3 pl-1'>✓</div> 
                          <p className='mb-2'> Complimentary credit boost </p> 
                          </div>
                          
                          <div className='d-flex justify-content-start'>
                          <div className='font-weight-bold mr-3 pl-1'>✓</div> 
                          <p className='mb-2'> Get Paid to Exercise   </p> 
                          </div>
                        
                          <div className='d-flex justify-content-start'>
                          <div className='font-weight-bold mr-3 pl-1'>✓</div> 
                          <p className='mb-2'>Buy a property- Get access to down payment assistance  </p>
                          </div>
                          <div className='d-flex justify-content-start'>
                          <div className='font-weight-bold mr-3 pl-1'>✓</div> 
                          <p className='mb-2'>Sell a property - Your property will be highlighted and promoted on USHX platform  </p> 
                          </div>

                          <div className='d-flex justify-content-start'>
                          <div className='font-weight-bold mr-3 pl-1'>✓</div> 
                          <p className='mb-2'>Get access to properties 24hrs before standard members for investment opportunities   </p> 
                          </div>

                          <div className='d-flex justify-content-start'>
                          <div className='font-weight-bold mr-3 pl-1'>✓</div> 
                          <p className='mb-2'>Lifetime 2.0  </p> 
                          </div>
                          <div className='d-flex justify-content-start'>
                          <div className='font-weight-bold mr-3 pl-1'>✓</div> 
                          <p className='mb-2'>Earn rewards for inviting other founders club members  </p> 

                          </div>

                       
                      
                          </div>
                        
                        </div>
                    </div>
                </div>
        </div>
        </>
    );
  }
  