import { Link } from "react-router-dom";
import { eventImageOne, Avatar, userLikeImage } from "../utils/images";
import { NavDropdown } from "react-bootstrap";
import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { realtorDetails , detail } from "../routers/routePath";
import {  toast } from 'react-toastify';

export default function NotificationPanel() {
  const [peoples , setPeople] = useState([]);
  const [alreadyFriendRequestSent ,setAlreadyFriendRequestSent ] = useState([]);
  const [selectedCancelRequest , setSelectedCancelRequest] = useState('');
  const [selectedSentRequest , setSelectedSentRequest] = useState('');
  const [fetchFriendRequests , setFetchFriendRequests] = useState([]);
  const [selectAcceptFriendRequest , setSelectAcceptFriendRequest] = useState('');
  const [selectAcceptFriendRequestSender , setSelectAcceptFriendRequestSender] = useState();
  const userToken = Cookies.get('token');
  const [reloadPeople , SetReloadPeople] = useState();
  const [reloadNotifications , SetReloadNotifications] = useState();
  const [randomRealtors , SetRandomRealtors] = useState();
  const [latestsharedProperties , SetLatestsharedProperties] = useState();
  const [follow , setFollow] = useState(false);
  const [followButton , setFollowButton] = useState(false);
  const [unfollow , setUnfollow] = useState('');
  const user_package = Cookies.get("package");
  React.useEffect(() => {
    if(selectAcceptFriendRequest!=''){


    fetch(process.env.REACT_APP_API_URL+'api/friends/addFriend' , {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+userToken+'',
      },
      body: JSON.stringify({'target_id':selectAcceptFriendRequestSender , 'accept' : true , 'notification_id' : selectAcceptFriendRequest })
    }
      )
      .then(results => results.json())
      .then(data => {
        toast.success("You successfully accepted the friend request.");
        SetReloadNotifications(selectAcceptFriendRequest);
      });

    }
  }, [selectAcceptFriendRequest]); // <-- Have to pass in [] here!


  React.useEffect(() => {
    if(selectedCancelRequest!=''){
    fetch(process.env.REACT_APP_API_URL+'api/friends/cancelRequest' , {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+userToken+'',
      },
      body: JSON.stringify({'id':selectedCancelRequest})
    }
      )
      .then(results => results.json())
      .then(data => {
    
        SetReloadPeople(Date.now());
        toast.success("You successfully cancelled the  request.");
      });
    }
  }, [selectedCancelRequest]); // <-- Have to pass in [] here!


  React.useEffect(() => {
    if(selectedSentRequest!=''){

    
    fetch(process.env.REACT_APP_API_URL+'api/friends/friendreq' , {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+userToken+'',
      },
      body: JSON.stringify({'id':selectedSentRequest})
    }
      )
      .then(results => results.json())
      .then(data => {
     
        toast.success("You followed the user successfully.");
        SetReloadPeople(Date.now());
      });
    }
  }, [selectedSentRequest ]); // <-- Have to pass in [] here!

  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+'api/realtors/random' , {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+userToken+'',
      },
    }
      )
      .then(results => results.json())
      .then(data => {
     
        SetRandomRealtors(data.randomReltors);
        SetLatestsharedProperties(data.latestSharedProperties);
      });
  }, [reloadPeople]); // <-- Have to pass in [] here!



  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+'api/friends/users' , {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+userToken+'',
      },
    }
      )
      .then(results => results.json())
      .then(data => {
        setPeople(data.needstobe);
        setAlreadyFriendRequestSent(data.requestsent);
      });
  }, [reloadPeople]); // <-- Have to pass in [] here!




  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+'api/friends/notifications' , {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+userToken+'',
      },
    }
      )
      .then(results => results.json())
      .then(data => {
       if(data.status){
        setFetchFriendRequests(data.data);

      //  console.log("fetchFriendRequests" , fetchFriendRequests);
       }
      });
  }, [reloadNotifications]); // <-- Have to pass in [] here!


  React.useEffect(()=>{
		setFollowButton(follow);
	  },[follow])
let show=false;
  
  return (
    <div className="notification-sub">
      <div className="notification-card" key={'notification-card1'}>
        <div className="notify-head">
          <h2>Recent Event News</h2>
          {/* <Link to="/community">See All</Link> */}
        </div>

          {(randomRealtors && 
            <div className="event-notify">
            <div className="notify-user">
            <Link to={"/profile/" + randomRealtors[0].slug + " "} > 
              {(randomRealtors[0].image!='' && randomRealtors[0].image!=null)?
               <img className={  `social-media-post-like-image user_${randomRealtors[0].user_type}` } src={process.env.REACT_APP_IMAGES_URL + JSON.parse(randomRealtors[0].image).path[0]} alt="" title={randomRealtors[0].user_type} />
              :
              <img className={  `social-media-post-like-image user_${randomRealtors[0].user_type}` }  src={Avatar} alt="" title={randomRealtors[0].user_type} />
            }
             </Link>
            </div>
            <div className="notify-user-detail">
              <h2>
              <Link to={"/profile/" + randomRealtors[0].slug + " "} > 
                    {randomRealtors[0].first_name} {randomRealtors[0].last_name.substring(0, 1)}.
								</Link>

              </h2>
              <h4>{randomRealtors[0].company } </h4>
            </div>

            {alreadyFriendRequestSent && (
          <>
           {alreadyFriendRequestSent.map((friend , index) => 
          (
           
            (friend.id===randomRealtors[0].id)?
            show=true
						://setFollowButton(false)
            show=false
          ))}
          </>
            )}
            {(show)?
              <button    onClick={(e) => { setUnfollow(e.currentTarget.dataset.id) ; }   }  data-id={randomRealtors[0].id} className="btn details-img-btn btn-sm mx-4">Remove </button>
              :
              <>
              {(user_package!='standard') &&

						<button     onClick={(e) => { setSelectedSentRequest(e.currentTarget.dataset.id) ; }   }  data-id={randomRealtors[0].id} className="btn details-img-btn btn-sm mx-4 Ralewayfamily">Follow</button>
						
          }
          </>
          }
          </div>
            
            )}
        

        {(latestsharedProperties && 

        <>
        {latestsharedProperties.map((val, index) => (

        <div key={index} className="event-detail-main">
          <div className="event-detail-sub">
            <div className="event-image">
            <Link className="Ralewayfamily " to={"/property/" + val.slug + " "}>
                {(val.images!='' && val.images!=null )?
                <img className="social-media-post-profile-image" src={process.env.REACT_APP_IMAGES_URL + val.images.path[0]} alt="" />
              :
                <img src={eventImageOne} alt="" />
              }
            
            </Link>

            </div>
            <div className="event-desc">
              <h2><Link className="Ralewayfamily " to={"/property/" + val.slug + " "}>
											{val.title}
										</Link>
                    </h2>
              <h6 className="reducedescriptionnotificationpanl">
              {val.about}
              </h6>
            </div>
          </div>
          {(val.total_views>0)?
          <div className="event-person-count">
            <div className="total-persons">{val.total_views} Views</div>
            <div className="persons-profile">
           
                <span>

{val.viewers.map((value,indexinner) => 
                  (
                  <>
                {(value.image!='' && value.image!=null )?  
                  <Link to={"/profile/" + value.slug + " "} >  
                    <img   key={indexinner} className={  `social-media-post-like-image user_${value.user_type}` } src={process.env.REACT_APP_IMAGES_URL + JSON.parse(value.image).path[0]} alt="" title={value.user_type} /> 
                  </Link>
                :
                <Link to={"/profile/" + value.slug + " "} >  
                  <img   key={indexinner} src={userLikeImage} className={  `social-media-post-like-image user_${value.user_type}` } alt=""  title={value.user_type} /> 
                  </Link>
                }
               </>
                ))} 

              </span>
              
              
            

            </div>
          </div>
          :null  
        }
        </div>
        ))}
        </>


            )}


      </div>

     
      <div className="notification-card" key={'notification-card2'}>
        <div className="notify-head">
          <h2>Notifications </h2>
          {/* <Link to="/community">See All</Link> */}
        </div>
       


        




        
        { (fetchFriendRequests.length > 0)? 
          <>
           {fetchFriendRequests.map((friend , index) => 
          (
           

      

            <div  key={index}>
               
               {(friend.sender) &&
               <>

               {(friend.type=='friend-request' )?
                   
                            <div className="user-request-info mt-3">
                            <div className="requested-user-image">
                              {( friend.sender.image!='' && friend.sender.image!=null)?
       
                            <Link to={"/profile/" + friend.sender.slug + " "} > <img   className={  `social-media-post-profile-image user_${friend.sender.user_type}` }  src={process.env.REACT_APP_IMAGES_URL + JSON.parse(friend.sender.image).path[0]} alt="" title={friend.sender.user_type} /> </Link>
                            :
                            <Link to={"/profile/" + friend.sender.slug + " "} >    <img  className={  `social-media-post-profile-image user_${friend.sender.user_type}` } src={Avatar} alt="" title={friend.sender.user_type} /> </Link>
                              }

                            </div>
                            <div className="requested-user-detail">
                            <Link to={"/profile/" + friend.sender.slug + " "} > <h4> {friend.sender.first_name}  {friend.sender.last_name.substring(0, 1)}.</h4> </Link>
                              <h5 className="mb-3">{friend.sender.company}</h5>
                              <div className="request-btns">
                                <button  type="button" className="follow-btn Ralewayfamily" onClick={(e) => { setSelectAcceptFriendRequest(e.currentTarget.dataset.id) ; setSelectAcceptFriendRequestSender(e.currentTarget.dataset.sender);  }    }  data-id={friend.id} data-sender={friend.sender_id}  >Accept Request</button>
                              </div>
                            </div>
                          </div>

                :

                <div key={index} className="event-notify">
                <div className="user-action-detail">
                  <div className="notify-user">
                  {(friend.sender.image!='' && friend.sender.image!=null)?
       
       <Link to={"/profile/" + friend.sender.slug + " "} > <img  className={  `social-media-post-profile-image user_${friend.sender.user_type}` }  src={process.env.REACT_APP_IMAGES_URL + JSON.parse(friend.sender.image).path[0]} alt="" title={friend.sender.user_type} /> </Link>
                      :
                      <Link to={"/profile/" + friend.sender.slug + " "} >   <img   className={  `social-media-post-profile-image user_${friend.sender.user_type}` }  src={Avatar} alt="" title={friend.sender.user_type} /> </Link>
                        }
                  </div>
                  <div className="notify-user-action">
                    <h2>{friend.message}</h2>
                    <h4>{friend.formatted_date}</h4>
                  </div>
                </div>
                <div className="user-status">
                  <span>.</span>
                </div>
              </div>

              }
              </>
           }
            </div>

          

          ))}

</>
:
<p className="mt-2 Ralewayfamily">No New Notification</p>

}
        

     
        



      </div>
     


      <div className="notification-card" key={'notification-card3'}>
        <div className="notify-head">
          <h2>You Might Follow</h2>
          <Link to="/community">See All</Link>
        </div>
        {/* {alreadyFriendRequestSent && (
          <>
           {alreadyFriendRequestSent.map((friend , index) => 
          (
            <div key={index} className="user-request-info mt-3">
            <div className="requested-user-image">
            {(friend.image!='' && friend.image!=null)?
       
              <img  className="social-media-post-profile-image" src={process.env.REACT_APP_IMAGES_URL + JSON.parse(friend.image).path[0]} alt="" />
              :
              <img className="social-media-post-profile-image" src={Avatar} alt="" />
                }
            </div>
            <div className="requested-user-detail">
              <h4>{friend.first_name}</h4>
              <h5 className="mb-3">{friend.company}</h5>
              <div className="request-btns">
                <button  type="button" className="follow-btn" onClick={(e) => { setSelectedCancelRequest(e.currentTarget.dataset.id) ; }   }  data-id={friend.id}  >Cancel Request</button>
              </div>
            </div>
          </div>
          ))}

          </>
        
        

        )} */}

{peoples && (
          <>
           {peoples.map((friend , index) => 
          (
            <div key={index}>
            {(index < 5) && 
             <div  className="user-request-info mt-3">
             <div className="requested-user-image">
                {(friend.image!='' && friend.image!=null)?
        
        <Link to={"/profile/" + friend.slug + " "} >  <img  className={  `social-media-post-profile-image user_${friend.user_type}` } src={process.env.REACT_APP_IMAGES_URL + JSON.parse(friend.image).path[0]} alt="" title={friend.user_type} /> </Link>
               :
               <Link to={"/profile/" + friend.slug + " "} >    <img className={  `social-media-post-profile-image user_${friend.user_type}` } src={Avatar} alt="" title={friend.user_type} /> </Link>
                 }
             </div>
             <div className="requested-user-detail">
             <Link to={"/profile/" + friend.slug + " "} > <h4>{friend.first_name}  {friend.last_name.substring(0, 1)}.</h4> </Link>
               <h5 className="mb-3">{friend.company}</h5>
               <div className="request-btns">
                 <button className="ignore-btn">Ignore</button>
                 {(user_package!='standard') &&
                 <button type="button"  className="follow-btn Ralewayfamily" onClick={(e) => { setSelectedSentRequest(e.currentTarget.dataset.id) ; }   }  data-id={friend.id} >Follow</button>
                 }
                 </div>
             </div>
           </div>
            
            }
            </div>
           
          ))}

          </>
        
        

        )}
        


      </div>
      {/* <div className="people-card">
        <div className="notify-head">
          <h2>People</h2>
          <div className="ppl-dropdwon">
            <NavDropdown
              id="nav-dropdown-dark-example"
              title=""
              menuVariant="light"
            >
              <NavDropdown.Item href="#">Remove</NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
        <div className="people-suggestions">
          <div className="people-info-main">
            <div className="people-image">
              <img src={Avatar} alt="" />
            </div>
            <div className="people-info">
              <h4>Angela Jhonny</h4>
              <h5>Seller</h5>
            </div>
          </div>
          <div className="user-status">
            <span>.</span>
          </div>
        </div>
        <div className="people-suggestions">
          <div className="people-info-main">
            <div className="people-image">
              <img src={Avatar} alt="" />
            </div>
            <div className="people-info">
              <h4>Angela Jhonny</h4>
              <h5>Seller</h5>
            </div>
          </div>
          <div className="user-status">
            <small>24 min</small>
          </div>
        </div>
      </div> */}
    </div>
  );
}
