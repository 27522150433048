import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import {
	HouseView,
	WhiteHouse,
	HouseFront,
	GreenHouse,
} from "../../utils/images";
import { faImages } from "@fortawesome/free-solid-svg-icons";
import React, { useState , useEffect } from "react";


export default function Gallery(propsmain) {
	const [images , setImage] = useState(propsmain.images.path);
	const [initCount, setInitCount] = useState(0);
	const [current , setCurrent] = useState('');
const [prev , setPrev] = useState('');
const [next , setNext] = useState('');


	const handleClickImage = (e, count) => {
		e && e.preventDefault();
		setInitCount(count);
		setCurrent(process.env.REACT_APP_IMAGES_URL + images[count]);
		if(count > 0){
			setPrev(process.env.REACT_APP_IMAGES_URL + images[count-1]);
		}else{
			setPrev('');
		}

		if(count + 1 == images.length  ){
			
			setNext('');
		}else{
			
			setNext(process.env.REACT_APP_IMAGES_URL + images[count+1]);
			
		}
	// console.log('images.length' , images.length);
		// console.log('current' , current);
		// console.log('next' , next);
		// console.log('prev' , prev);
	   }

	   const handleNextImage = () => {
		
		
		setCurrent(process.env.REACT_APP_IMAGES_URL + images[initCount + 1]);
		
		
			
			if(images[initCount]){
			
				setPrev(process.env.REACT_APP_IMAGES_URL + images[initCount]);
			}else{
				
				setPrev('');
			}
			
		

		if(initCount +1 == images.length  ){
			setNext('');
		}else{
			if(images[initCount + 2]){
				setNext(process.env.REACT_APP_IMAGES_URL + images[initCount + 2]);
			}else{
				setNext('');
			}
			
		
		}
		setInitCount(initCount+1);

	   }

	   const handlePrevImage = () => {
	

		setCurrent(process.env.REACT_APP_IMAGES_URL + images[initCount - 1 ]);
		if(initCount > 0){
			if(images[initCount-2]){
				setPrev(process.env.REACT_APP_IMAGES_URL + images[initCount-2]);
			}else{
				setPrev('');
			}
			
		}else{
			setPrev('');
		}

		
			if(images[initCount]){
				setNext(process.env.REACT_APP_IMAGES_URL + images[initCount]);
			}else{
				setNext('');
			}
			
		setInitCount(initCount-1);
	   }


	useEffect(()=>{
		setImage(propsmain.images.path);
	},[propsmain.images.path])

	
	
	 
	const   handleCloseModal = (e) => {
		e && e.preventDefault();
	 
		setCurrent('');
	   }


	function SampleNextArrow(props) {
		const { collectionSliderArrow, style, onClick } = props;
		return (
			<div
				className="collectionSliderArrow"
				style={{ ...style }}
				onClick={onClick}
			>
				<FaChevronLeft />
			</div>
		);
	}
	function SamplePrevArrow(props) {
		const { collectionSliderArrowPrev, style, onClick } = props;
		return (
			<div
				className="collectionSliderArrowPrev"
				style={{ ...style }}
				onClick={onClick}
			>
				<FaChevronRight />
			</div>
		);
	}
	var settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 3.5,
		slidesToScroll: -1,
		autoplay: true,
		autoplaySpeed: 2000,
		cssEase: "linear",
		variableWidth: true,
		adaptiveHeight: true,
		arrow: false,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
				},
			},
		],
	};

	return (
		  <section className="container">
			<h2 className="main-title-head">{propsmain.title}</h2>

			{propsmain.images && (
				<Slider {...settings}>
					{propsmain.images.path.map((img, index) => (
						// eslint-disable-next-line react/jsx-no-comment-textnodes
						<div key={index} className="gallery-slider">
							{
								// eslint-disable-next-line jsx-a11y/alt-text
								<img
								onClick={ e => handleClickImage(e, index)}
									className="gallery-slider-image"
									key={index}
									src={process.env.REACT_APP_IMAGES_URL + img}
								/>
							}
						</div>
					))}
				</Slider>
			)}

			{current &&
					<Lightbox
					mainSrc={current}
					nextSrc={next}
					prevSrc={prev}
					onMovePrevRequest={handlePrevImage}
					  onMoveNextRequest={handleNextImage}
						onCloseRequest={handleCloseModal}
						/>
				}


		</section>
	);
}
