import { Link } from "react-router-dom";
import LeftSidebar from "../../layouts/leftSider";
import SettingSider from "../../layouts/settingSider";
import React, { useState , useEffect } from 'react';
import { detail } from "../../routers/routePath";
import Cookies from 'js-cookie';
import moment from 'moment';
import {Modal, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee , faXmark, faCircleCheck , faBars } from '@fortawesome/free-solid-svg-icons'
import { Avatar, User , copyIcon} from '../../utils/images'
import Alert from "react-bootstrap/Alert";
async function sendemail(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/profile/sendreferal', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

export default function ReferralContent() {
  const [showLeftSideMenu, setShowLeftSideMenu] = useState(false);
  const handleCloseLeftSideMenu = () => setShowLeftSideMenu(false);
  const handleShowLeftSideMenu = () => setShowLeftSideMenu(true);
  const user_slug = Cookies.get('user_slug');
  const userToken = Cookies.get('token');
  const userid = Cookies.get('user_id');
  const [activity, setActivity] = useState([]);
  const [referalLink, setReferalLink] = useState(user_slug);
  const [ref, setRef] = useState([]);
  const [url, setUrl] = useState();
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [successMessageNew, setSuccessMessageNew] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [inputList, setInputList] = useState([
    { email: "" },
  ]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { email: ""}]);
  };


  const handleSubmit = async e => {
    e.preventDefault();
    setErrorMessage(null);
    setSuccessMessage(null);
    setShowLoader(true);
      const res = await sendemail({
          email,
        userToken,
      });
      if(res.success){
      
        setEmail('');
        setSuccessMessage('Email Successfully sent.');
        setShowLoader(false);
      }else{
        setErrorMessage(res.errors);
        setShowLoader(false);
      }

    }


  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+'api/authors/refferals' , {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+userToken+'',
      },
    }
      )
      .then(results => results.json())
      .then(data => {
       setRef(data.data);
        //console.log('docus' , data);

      });
  }, []); // <-- Have to pass in [] here!




    return (
      <>
        <section className="edit-profile-content row">

        {(showLoader)&& 
		<div id="loading">
			<div className="loader"></div>
 		</div>
		}


        <div  className='message-pages-top  w-100'>
            <div className="d-flex mx-4 mt-4 justify-content-between">

              <div className=" d-lg-none   ">
                  <FontAwesomeIcon  onClick={handleShowLeftSideMenu} icon={faBars} />
                  
                </div>
            
            </div>

          </div>

        <div className="sidebar col-md-6 col-lg-2 col-0 d-none d-lg-block h-auto">  
            <LeftSidebar />
          </div>
     
          <div className="sidebar search-sidebar col-md-6 col-lg-2 col-0 d-none d-lg-block h-auto ">
            <SettingSider />
          </div>



          {/* <div  className='side-bar d-none d-lg-block h-auto'>
             <div className='' style={{     backgroundColor:' #f6f9fe' }}>   <LeftSidebar /></div>
             <div className='' style={{     backgroundColor:' #f6f9fe' }}><SettingSider /></div>

          </div> */}


         <Modal    dialogClassName='custom-dialog' show={showLeftSideMenu} onHide={handleCloseLeftSideMenu} size="lg"  >
					
					<Modal.Body>
					<div style={{     width:'25px' }} className="cursor-pointer bg-primary px-2 py-1 mb-2 " onClick={handleCloseLeftSideMenu}>x</div>
          
          <div className='row'>
          <div className="sidebar col-6  ">  
            <LeftSidebar />
          </div>
     
          <div className="sidebar search-sidebar col-6   ">
            <SettingSider />
          </div>
          </div>
         

					</Modal.Body>
					
				</Modal>

       
          <div className="edit-container ol-xsm-12 col-sm-12   col-md-12 col-lg-11 col-12">
          

          {successMessageNew && (
            <Alert  variant="success" >
                 {successMessageNew && (
                <p>  {successMessageNew} </p>
                )}
                </Alert>
              )}


            <div className="activity-msg col-12">
            <div className='text-center d-flex'> 
              
              
               <p className="mt-1"> <span className="font-weight-bold"> Referal Link: </span>   {process.env.REACT_APP_URL +'signup/'+ referalLink} </p> 

            <button title="Copy" className="mx-3 social-btn social-btn" style={{width:'25px' , height:'25px' , padding:'0px'}}
			onClick={() => { navigator.clipboard.writeText(process.env.REACT_APP_URL +'signup/'+ referalLink) ; setSuccessMessageNew('Link has been copied !') } }
			>
			
			    <img src={copyIcon} alt="icon" style={{width:'25px' , height:'25px'}} />
			
			</button>



            
            </div>
                </div>

                <div className="card mt-2 mx-auto p-4 bg-gray  row ">
                     <h3 className="mb-3 col-12">Add email to invite others to the platform. </h3>
                    <div className="signup-input  col-8 col-md-9">
  {successMessage && (
            <Alert  variant="success" >
                 {successMessage && (
                <p>  {successMessage} </p>
                )}
                </Alert>
              )}


{errorMessage && (
            <Alert variant="danger" >
                 {errorMessage.email && (
                <p>  {errorMessage.email} </p>
                )}
              
                 
                </Alert>

          )}
                    <input
                    name="Email"
                    placeholder="Email"
                    className="form-control "
                    type={email}
                    value={email}  onChange={e => setEmail(e.target.value)}
                  />
                    </div>


                {/* {inputList.map((x, i) => {
                   return (
                  <div className=" my-1  row">
                    <div className="signup-input  col-8 col-md-9">

                    <input
                    name="Email"
                    placeholder="Email"
                    className="form-control "
                    value={x.email}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                    </div>
                    
                  <div className="d-flex col-4 col-md-3">
                          {inputList.length !== 1 && (
                            <button
                              className="edit-btn-sm  edit-btn mt-0 bg-danger"
                              onClick={() => handleRemoveClick(i)}
                            >
                              X
                            </button>
                          )}
                          {inputList.length - 1 === i && (
                            <button
                              className="edit-btn-sm edit-btn  mx-1 mt-0"
                              onClick={handleAddClick}
                            >
                              +
                            </button>
                          )}
                        </div>
                        </div>
                   )
                })} */}

               <div className="col-12">

               <button className=" edit-btn  mx-1 mt-0"  onClick={handleSubmit} >
                              Send
                            </button>
               </div>
                          

                </div>
                <h3 className="mt-5">Referrals</h3>
                {(ref) && (ref.length>0)? 

<table className="table mt-5">
    <thead><tr>
        <th >User</th>
        <th >Email</th>
        <th > Date</th>
    </tr>
    </thead>
    <tbody>
    {ref.map((val, key) => (
        <tr key={key}>
            <td>
             <div className="row mx-1">

             <div className="requested-user-image ">
                        <Link to={"/profile/" + val.slug + " "} >
                          {(val.image != '' && val.image != null) ?

                            <img className={`social-media-post-profile-image user_${val.user_type}`} src={process.env.REACT_APP_IMAGES_URL + JSON.parse(val.image).path[0]} alt={val.user_type} title={val.user_type} />
                            :
                            <img className={`social-media-post-profile-image user_${val.user_type}`} src={User} alt={val.user_type} title={val.user_type} />
                          }
                        </Link>


                      </div>
                      <div className="requested-user-detail">
                        <Link to={"/profile/" + val.slug + " "} >  
                        <h4 className="my-3 mx-2">{val.first_name} {val.last_name.substring(0, 1)}.</h4>       </Link>
                     </div>


             </div>
        
            </td>
           <td><p className="my-3">{val.email}</p></td>
            <td> <p className="my-3"> {moment(val.created_at ).format("DD MMMM YYYY ")}</p></td>
        </tr>
       ))}
    </tbody>
</table>
:
<div className="mt-3">No referrals</div>


}
          </div>
        </section>
      </>
    );
  }