import SignupForm from "../../components/signup/signupForm";
import SignupLogo from "../../components/signup/signupLogo";
import React, { useState } from 'react';
import TypeForm from "../../components/signup/typeForm";
import PackageForm from "../../components/signup/packageForm";
import QuestionForm from "../../components/signup/questionForm";

import Alert from "react-bootstrap/Alert";
import { useHistory } from "react-router-dom";
import Header from "../../layouts/header";
import Cookies from 'js-cookie';


async function registerUser(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}
async function checkEmail(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/author/check/email', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}
export default function Signup() {
  const [count, setCount] = React.useState(0);
  const history = useHistory();
  const referrer =history.location.pathname.replace('/signup/','')
  const [first_name, setFirstName] = useState();
    const [last_name, setLastName] = useState();
    const [email, setEmail] = useState();
    const [company, setCompany] = useState();
    const [phone, setPhone] = useState();
    const [user_type, setUserType] = useState('seller');
    const [password, setPassword] = useState();
    const [password_confirmation, setConfirmPassword] = useState();
    const [checked, setChecked] = useState(false);
    const [checkedPrivacy, setCheckedPrivacy] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    
    const [city, setCity] = useState('');
    const [state, setState] = useState('Alabama');
    const [zipcode, setZipcode] = useState('');
    const [bedrooms, setBedrooms] = useState('');
    const [bathrooms, setBathrooms] = useState('');
    const [bestTrade, setBestTrade] = useState('');
    const [contractingExperiance, setContractingExperiance] = useState('');
    const [expectedRate, setExpectedRate] = useState('');
    const [funds, setFunds] = useState('');
    const [crypto, setCrypto] = useState('');
    const [specificFeature, setSpecificFeature] = useState('');
    const [budgetForMortgage, setBudgetForMortgage] = useState('');
    const [isRealtor, setIsRealtor] = useState(0);
    const [isWholeseller, setIsWholeseller] = useState(0);
    const [isForSaleByOwner, setIsForSaleByOwner] = useState(0);
    const [isPropertyReheb, setIsPropertyReheb] = useState(0);
    const [isNeedRenovation, setIsNeedRenovation] = useState(0);
    const [isRuralArea, setIsRuralArea] = useState(0);
    const [isVeteran, setIsVeteran] = useState(0);
    const [isCrew, setIsCrew] = useState(false);
    const [isDayRate, setIsDayRate] = useState(false);
    const [isBeginnerInvestor, setIsBeginnerInvestor] = useState(false);
    const [isAccessToLender, setIsAccessToLender] = useState(false);
    const [isFlipingBuySold, setIsFlipingBuySold] = useState(false);
    const [isUnderStandCrypto, setIsUnderStandCrypto] = useState(false);
    const [isMasterClass, setIsMasterClass] = useState(false);
    const [isOwnCrypto, setIsOwnCrypto] = useState(false);
    
    const [isMoneyTowardDeals, setIsMoneyTowardDeals] = useState(false);
    const [packageSelected, setPackageSelected] = useState('standard');
    const [isFico, setIsFico] = useState(0);
    const [isLoanOfficer, setIsLoanOfficer] = useState(0);
    const [isReferredByRealtor, setIsReferredByRealtor] = useState(0);
    const [isPrequlification, setIsPrequlification] = useState(0);
    const [isVeteranBedCredit, setIsVeteranBedCredit] = useState(0);
    const [isRep, setIsRep] = useState(0);
    const [isRepLoan, setIsRepLoan] = useState(0);
    const [preferstate, setStatePrefer] = useState('');
    const [prefercity, setCityPrefer] = useState('');
    const [provider, setProvider] = useState('none');
    const [providerjson, setProviderJson] = useState('');
    const [providerid, setProviderid] = useState('');
    const [years_in_business, setYearsInBuiness] = useState('');
    const [professionaltype, setProfessionalType] = useState('');


    const [usStates, setUsStates] = useState(false);

    const [cities, setCities] = useState(false);
    console.log ('socialusereprovider' , Cookies.get('socialusereprovider'))

     let socialuserproviderid = Cookies.get('socialuserproviderid');
     React.useEffect(() => {
     if(socialuserproviderid){
      setProviderid(socialuserproviderid);
      setFirstName(Cookies.get('socialuserfirstname'));
      setLastName(Cookies.get('socialuserlastname'));
      setEmail(Cookies.get('socialuseremail'));
      setProvider(Cookies.get('socialusereprovider'));
      setProviderJson(Cookies.get('providerjson'));
      setPassword('abc123xyz');
      setConfirmPassword('abc123xyz');
     }
 }, [socialuserproviderid]); // <-- Have to pass in [] here!
    


 React.useEffect(() => {
  fetch(
    process.env.REACT_APP_API_URL +
      `api/get/us/states`,
    {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "http://localhost:3000/",
        "Content-Type": "application/json", 
      },
    }
  )
    .then((results) => results.json())
    .then((data) => {
      setUsStates(data);
      setCities(data[0].cities);
    });

}, []); // <-- Have to pass in [] here!


 const handleYearsInBuiness = (val) => {
  setYearsInBuiness(val);
  };

  const handleProfessionalType = (val) => {
    setProfessionalType(val);
    };

 const handleCount = (val) => {
  setCount(val);
 };
    const handlePreferCity = (val) => {
      setCityPrefer(val);
      };
    const handleIsState = (val) => {
      setState(val);
      };
    const handleIsReferredByRealtor = (val) => {
      setIsReferredByRealtor(val);
      };

      const handleIsPrequlification = (val) => {
        setIsPrequlification(val);
        };
    const handleIsVeteranBedCredit = (val) => {
        setIsVeteranBedCredit(val);
      };
      const handleIsRep = (val) => {
        setIsRep(val);
      };

      const handleIsReploan = (val) => {
        setIsRepLoan(val);
      };
          
  
        
      const handleIsLoanOfficer = (val) => {
        setIsLoanOfficer(val);
        };


    const handleIsFico = (val) => {
      // if(!val){
      //   setIsReferredByRealtor(val);
      //   setIsLoanOfficer(val);
      // }
      setIsFico(val);
      };


    const handlePackageSelected = (val) => {
      setPackageSelected(val);
      };

    const handleIsChecked = (val) => {
      setChecked(val);
      };

      const handleIsCheckedPrivacy = (val) => {
        setCheckedPrivacy(val);
        };


    const handleIsMoneyTowardDeals = (val) => {
      setIsMoneyTowardDeals(val);
      };

      
    const handleIsFlipingBuySold = (val) => {
      setIsFlipingBuySold(val);
      };

      const handleIsUnderStandCrypto = (val) => {
        setIsUnderStandCrypto(val);
        };

        const handleIsMasterClass = (val) => {
          setIsMasterClass(val);
          };

      
        const handleIsOwnCrypto = (val) => {
          setIsOwnCrypto(val);
          };


    const handleIsAccessToLender = (val) => {
      setIsAccessToLender(val);
      };


    const handleIsBeginnerInvestor = (val) => {
      setIsBeginnerInvestor(val);
      };


    const handleIsDayRate = (val) => {
      setIsDayRate(val);
      };


    const handleIsCrew = (val) => {
      setIsCrew(val);
      };

    const handleIsVeteran = (val) => {
      setIsVeteran(val);
      };


    const handleIsRuralArea = (val) => {
      setIsRuralArea(val);
      };

    const handleIsNeedRenovation = (val) => {
      setIsNeedRenovation(val);
      };
    const handleIsPropertyReheb = (val) => {
     
      setIsPropertyReheb(val);
      };
    const handleIsForSaleByOwner = (val) => {
      setIsForSaleByOwner(val);
      };

    const handleIsWholeseller = (val) => {
      setIsWholeseller(val);
      };
    const handleIsRealtor = (val) => {
      setIsRealtor(val);
      };
      const handleFunds = (val) => {
        setFunds(val);
        };
        const handleCrypto= (val) => {
          setCrypto(val);
          };

      const handleContractingExperiance = (val) => {
        setContractingExperiance(val);
        };
        const handleExpectedRate = (val) => {
          setExpectedRate(val);
          };


    const handleBedrooms = (val) => {
      setBedrooms(val);
      };
    
      const handleBestTrade = (val) => {
        setBestTrade(val);
        };
    const handleBathrooms = (val) => {
      setBathrooms(val);
      };
    const handleSpecificFeature = (val) => {
      setSpecificFeature(val);
      };
    const handleBudgetForMortgage = (val) => {
      setBudgetForMortgage(val);
      };

    const handleFirstName = (val) => {
      setFirstName(val);
      };

      const handleLastName = (val) => {
        setLastName(val);
        };

      const handleEmail = (val) => {
        setEmail(val);
        };

      const handleCompany = (val) => {
        setCompany(val);
        };
        const normalizeInput = (value, previousValue) => {
          if (!value) return value;
          const currentValue = value.replace(/[^\d]/g, '');
          const cvLength = currentValue.length;
          
          if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
          }
        };

      const handlePhone = (val) => {
        setPhone(normalizeInput(val, phone));
        };

      const handlePassword = (val) => {
        setPassword(val);
        };

      const handleConfirmPassword = (val) => {
        setConfirmPassword(val);
        };
      
      const handleUserType = (val) => {
        setUserType(val);
        };

      const handleCity = (val) => {
        setCity(val);
        };
      const handleState = (val) => {
        setStatePrefer(val);
        };



      const handleZipcode = (val) => {
        setZipcode(val);
        };

        

         

          const handleSubmit = async e => {
            e.preventDefault();
            setErrorMessage(null);
         
             if(checked &&  checkedPrivacy ){
              const res = await registerUser({
                first_name,
                last_name,
                company,
                email,
                password,
                phone,
                user_type,
                password_confirmation,
                city,
                state,
                preferstate,
                prefercity,
                zipcode,
                bedrooms,
                bathrooms,
                bestTrade,
                contractingExperiance,
                expectedRate,
                funds,
                crypto,
                specificFeature,
                budgetForMortgage,
                isRealtor,
                isWholeseller,
                isForSaleByOwner,
                isPropertyReheb,
                isNeedRenovation,
                isRuralArea,
                isVeteran,
                isCrew,
                isDayRate,
                isBeginnerInvestor,
                isAccessToLender,
                isFlipingBuySold,
                isUnderStandCrypto,
                isMasterClass,
                isOwnCrypto,
                isMoneyTowardDeals,
                packageSelected,
                isFico,
                isLoanOfficer,
                isReferredByRealtor,
                provider,
                providerjson,
                providerid,
                isRep,
                isRepLoan,
                years_in_business,
                professionaltype,
                referrer
              });
              if(res.success){
              //  sessionStorage.setItem("token", token.author.api_token);
              Cookies.set('socialuserfirstname', '');
              Cookies.set('socialuserlastname', '');
              Cookies.set('socialuseremail', '');
              Cookies.set('socialusereprovider', '');
              Cookies.set('providerjson', '');
              Cookies.set('socialuserproviderid', '');
                Cookies.set('token', res.author.api_token);
                Cookies.set('user_id',res.author.id);
                Cookies.set('first_name', res.author.first_name);
                Cookies.set('last_name', res.author.last_name);
                Cookies.set('user_type', res.author.user_type);
                Cookies.set('user_slug', res.author.slug);
                Cookies.set('user_state', res.author.state);
                Cookies.set('kyc_required', res.author.kyc_required);
                Cookies.set('isphonevarified', res.author.isphonevarified);
                Cookies.set('kycsubmitted' , res.author.kycsubmitted);
                Cookies.set('package', 'packageSelected');
                (res.author.image!='' && res.author.image!=null)?
                Cookies.set('profile_image',  JSON.parse(res.author.image).path[0])
                :
                Cookies.set('profile_image', null)
                history.push("/profile-completion");

              }else{
              
                setErrorMessage(res.errors);
              }
      
             }else{
              let checkerror = {terms:"Please check terms and privacy policy"};
              setErrorMessage(checkerror);
             }
              
      
            }
  const components = [

    <div><TypeForm  user_type={user_type}  setCount={handleCount} onUserType={handleUserType}   className="mt-5 " /></div>,


    <div><SignupForm  
    usStates ={usStates}
    cities={cities}
    provider ={provider}
    onCity={handleCity} 
      onFirstName={handleFirstName}  
      first_name={first_name}
      onLastName={handleLastName}  
      last_name={last_name}
      onEmail={handleEmail}  
      email={email}
      onCompany={handleCompany} 
      company={company}
      onPhone={handlePhone} 
      phone={phone}
      onPassword={handlePassword} 
      onConfirmPassword={handleConfirmPassword} 
      handleIsState ={handleIsState}
    onIsChecked = {handleIsChecked}
    checked={checked}
    onIsCheckedPrivacy = {handleIsCheckedPrivacy}
    checkedPrivacy={checkedPrivacy}


    className="mt-5 " /></div>,
    
    <div><PackageForm  setCount={handleCount}  packageSelected={packageSelected} onPackageSelected={handlePackageSelected}   className="mt-5" /></div>,
    <div><QuestionForm  setCount={handleCount}
    usStates ={usStates}
    cities={cities}
    userType={user_type}   

    onYearsInBuiness = {handleYearsInBuiness}
    onProfessionalType = {handleProfessionalType}
    onPreferCity = {handlePreferCity}
    onState = {handleState}
    onZipcode = {handleZipcode}
    onBedrooms={handleBedrooms}  
    onBathrooms={handleBathrooms}  
    onBestTrade={handleBestTrade} 
    onFunds = {handleFunds}
    onCrypto = {handleCrypto}
    onExpectedRate={handleExpectedRate} 
    onContractingExperiance={handleContractingExperiance} 
    onSpecificFeature={handleSpecificFeature}  
    onBudgetForMortgage = {handleBudgetForMortgage}
    onIsRealtor = {handleIsRealtor}
    isRealtor={isRealtor}
    onIsWholeseller = {handleIsWholeseller}
    isWholeseller={isWholeseller}
    onIsForSaleByOwner = {handleIsForSaleByOwner}
    isForSaleByOwner={isForSaleByOwner}
    onIsPropertyReheb = {handleIsPropertyReheb}
    isPropertyReheb={isPropertyReheb}
    onIsNeedRenovation = {handleIsNeedRenovation}
    isNeedRenovation={isNeedRenovation}
    onIsRuralArea = {handleIsRuralArea}
    isRuralArea={isRuralArea}
    onIsVeteran = {handleIsVeteran}
    isVeteran={isVeteran}
    onIsCrew = {handleIsCrew}
    isCrew={isCrew}
    onIsDayRate = {handleIsDayRate}
    isDayRate={isDayRate}
    onIsBeginnerInvestor = {handleIsBeginnerInvestor}
    isBeginnerInvestor={isBeginnerInvestor}
    onIsAccessToLender = {handleIsAccessToLender}
    isAccessToLender={isAccessToLender}
    onIsFlipingBuySold = {handleIsFlipingBuySold}
    isFlipingBuySold={isFlipingBuySold}

    onIsMasterClass = {handleIsMasterClass}
    isMasterClass={isMasterClass}

    onIsUnderStandCrypto = {handleIsUnderStandCrypto}
    isUnderStandCrypto={isUnderStandCrypto}
    
   
    onIsOwnCrypto = {handleIsOwnCrypto}
    isOwnCrypto={isOwnCrypto}

    onIsMoneyTowardDeals = {handleIsMoneyTowardDeals}
    isMoneyTowardDeals={isMoneyTowardDeals}
    onIsFico = {handleIsFico}
    isFico={isFico}

    onIsLoanOfficer = {handleIsLoanOfficer}
    isLoanOfficer={isLoanOfficer}

    onIsReferredByRealtor = {handleIsReferredByRealtor}
    isReferredByRealtor={isReferredByRealtor}

    onIsPrequlification = {handleIsPrequlification}
    isPrequlification={isPrequlification}

    onIsVeteranBedCredit = {handleIsVeteranBedCredit}
    isVeteranBedCredit={isVeteranBedCredit}

    onIsRep = {handleIsRep}
    isRep={isRep}

    onIsRepLoan = {handleIsReploan}
    isRepLoan={isRepLoan}
    

    className="mt-5" /></div>
]

const handleStepsForword = async e =>{
  let error = 0;
  if(count==1){
    

    if(!checked ||  !checkedPrivacy ){
      let checkerror = {terms:"Please check terms and privacy policy"};
      setErrorMessage(checkerror);
      error = 1;
    }

    if(password.length < 8 ){
    
      let checkerror = {password_confirmation:"Password must be of at least 8 character "};
      setErrorMessage(checkerror);
      error = 1;
    }

    if(password!= password_confirmation){
    
      let checkerror = {password_confirmation:"Password Mismatch"};
      setErrorMessage(checkerror);
      error = 1;
    }


    
    if(!password_confirmation ){
    
      let checkerror = {password_confirmation:"Please Enter Confirm Password"};
      setErrorMessage(checkerror);
      error = 1;
    }

  


    if(!password ){
    
      let checkerror = {password:"Please Enter Password"};
      setErrorMessage(checkerror);
      error = 1;
    }




  //   var phoneRe = /\(([+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[eE]([+-]?\d+))?\)\s[0-9]+-[0-9]+/i;

  // //var digits = phone.replace(/\D/g, "");
  //  var testphon =  phoneRe.test(phone);

  if(phone.length !== 14){
    let checkerror = {phone:"Invalid phone format. ex: (555) 555-5555"};
    setErrorMessage(checkerror);
    error = 1;
  }

    if(!phone ){
  
      let checkerror = {phone:"Please Enter Phone"};
      setErrorMessage(checkerror);
      error = 1;
    }
   
    
    


    if(!email){
    
      let checkerror = {email:"Please Enter Email"};
      setErrorMessage(checkerror);
      error = 1;
    }else{
          const res = await checkEmail({
            email,
          });
          if(res.errors.email){
          
            setErrorMessage(res.errors);
            error = 1;
          }
    }

   


    
    // if(!company ){
    
    //   let checkerror = {company:"Please Enter Company Name"};
    //   setErrorMessage(checkerror);
    //   error = 1;
    // }

   


    if(!last_name ){
    
      let checkerror = {last_name:"Please Enter Last Name"};
      setErrorMessage(checkerror);
      error = 1;
    }
   

    if(!first_name ){
    
      let checkerror = {first_name:"Please Enter First Name"};
      setErrorMessage(checkerror);
      error = 1;
      
    }
    
   

  }
  if(!error){
    setErrorMessage(null);
    setCount(count + 1);
  }else{
    document.getElementById('signup-form-title').scrollIntoView({block: "center"});
  }

}

const handleStepsBackword = () => {
  setCount(count - 1);
}
  return (
    <>
      <section className="login-section">
       {/* <Header /> */}
       <div className="container">
      <form onSubmit={handleSubmit} className="mt-5 container p-0">
      <section className="signup-form mt-5 " >
        <div className="row mt-5">
          <h2 className="signup-form-title col-12 mt-5" id="signup-form-title">Signup</h2>
          {/* <div className="col-md-6 social-container">
            <h3 className="social-media-title signup-title mt-5">-- OR Login with Social Media</h3>
            <ul className="signup-icons">
                <li><i className="fa-brands fa-facebook-f"></i></li>
                <li><i className="fa-brands fa-linkedin-in"></i></li>
                <li><i className="fa-brands fa-twitter"></i></li>
            </ul>
          </div> */}

{errorMessage && (
            <Alert variant="danger" >
                 {errorMessage.first_name && (
                <p>  {errorMessage.first_name} </p>
                )}
                {errorMessage.last_name && (
                <p>  {errorMessage.last_name} </p>
                )}
                {errorMessage.email && (
                <p>  {errorMessage.email} </p>
                )}
                {errorMessage.password && (
                <p>  {errorMessage.password} </p>
                )}
                   {errorMessage.password_confirmation && (
                <p>  {errorMessage.password_confirmation} </p>
                )}
                     {errorMessage.phone && (
                <p>  {errorMessage.phone} </p>
                )}
                   {errorMessage.company && (
                <p>  {errorMessage.company} </p>
                )}
                   {errorMessage.user_type && (
                <p>  {errorMessage.user_type} </p>
                )}

          {errorMessage.terms && (
                          <p>  {errorMessage.terms} </p>
                          )}
              
                 
                </Alert>

          )}

          
        </div>
      
       
      
      {
            // render component from our components array
            components[count]
        }

</section>
    

      <div className="signup-submit">
      
        {/* show previous button if we are not on first element */}
        {count > 0 && <button type="button" className="signup-btn mx-2 " onClick={handleStepsBackword}>Previous</button>}

        {/* hide next button if we are at the last element */}
        {count < components.length - 1 && 
        <button type="button"  className="signup-btn mx-2 " onClick={handleStepsForword}>Next</button>}

{count == components.length - 1 && 
          <button type="submit"  className="signup-btn  mx-2" >Submit</button>}


      {/* <TypeForm  className="mt-5" /> */}
        {/* <SignupForm  className="mt-5" /> */}
        </div>
        </form>
        </div>
      </section>
    </>
  );
}
