import { Link } from "react-router-dom";
import LeftSidebar from "../../layouts/leftSider";
import SettingSider from "../../layouts/settingSider";
import React, { useState , useEffect } from 'react';
import { detail } from "../../routers/routePath";
import Cookies from 'js-cookie';
import moment from 'moment';


export default function RealtorFeeds({activity}) {

  // const [id, setId] = useState(realtor);
  // const [activity, setActivity] = useState([]);

  


    return (
      <>
        <section className="edit-profile-content row realtor-container">

          <div className="edit-container realtor-feeds-container">
            <div className="col-10 m-auto p-0">
              <h2 className="feeds-title">Latest Feeds</h2>
            </div>
           
        


            {activity.map((act, index) => 
               (
             <div key={index}>
            
                {(act.type=="App\\Notifications\\propertyNotification")?
              <div className="activity-msg feed-section col-10 m-auto">
                <p>
                  
                  You have added property  <a href={"/property/" + JSON.parse(act.data).property_slug + " "}>{JSON.parse(act.data).property_title}</a>
                   </p>
                <h5>Posted on: {moment(act.created_at ).format("DD MM YY ")}</h5>
                </div>
                :(act.type=="App\\Notifications\\PostNotification")?
<div className="activity-msg feed-section col-10 m-auto">
                <p>
                  
                  You have added post  {JSON.parse(act.data).post_body}
                   </p>
                <h5>Posted on: {moment(act.created_at ).format("DD MM YY ")}</h5>
                </div>
                 :(act.type=="App\\Notifications\\OfferNotification")?
                 <div className="activity-msg feed-section col-10 m-auto">
                                 <p>
                                   
                                   You have added a offer on a property   <a href={"/property/" + JSON.parse(act.data).property_slug + " "}>{JSON.parse(act.data).property_title}</a>
                                    </p>
                                 <h5>Posted on: {moment(act.created_at ).format("DD MM YY ")}</h5>
              </div>

                :null
                
              }
                
              
            </div>
            ))}

          </div>
        </section>
      </>
    );
  }