import AppRouter from "./routers";
import "./assets/css/bootstrap.min.css";
import "./assets/css/socialMedia.css"
import "./assets/css/main.css";
import { BrowserRouter as Router , Link , useHistory , useLocation  } from 'react-router-dom';
import { fetchToken, onMessageListener }  from "./firebase/firebase";
import React, { useState ,useEffect , useCallback  } from 'react';
import { TourProvider } from '@reactour/tour'
import Tooltip from './Tooltip'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

// import {Button, Toast} from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from "./components/scrollToTop";
import Cookies from 'js-cookie';

function App() {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [isTokenFound, setTokenFound] = useState(false);
  const userToken = Cookies.get('token');
  const [isShowingMore, setShowingMore] = useState(false)
  const disableBody = target => disableBodyScroll(target)
  const enableBody = target => enableBodyScroll(target)
  const [step, setStep] = useState(0);
 
  const [currentUrl, setCurrentUrl] = useState('');
// console.log("history" , Link);
const user_type = Cookies.get('user_type');






  const setCurrentStep = (step) => {
     console.log('currentUrl step' , step);
    switch (step) {
    
      case 3:
        //console.log('currentUrl' , currentUrl);
        if(currentUrl==''){
          window.location.href ='/edit-profile';
        }
        
        break;
        case 6:
        console.log("last step");

        fetch(process.env.REACT_APP_API_URL+'api/user/updatetour' , {
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin' : 'http://localhost:3000/',
            'Access-Control-Allow-Credentials' : 'true/',
            'Content-Type': 'application/json' ,
            'Authorization': 'Bearer '+userToken+'',
          },
        }
          )
          .then(results => results.json())
          .then(data => {
            window.location.href ='/congrates';
          })
        break;
      default:
        break;
    }
    Cookies.set('currentstep' , step );
   
    setStep(step);
  };

  const handleCurrentUrl = (val) => {
    
    setCurrentUrl(val);
  }

  const handleSteps = (val) => {
    
    setStep(val);
  }


  fetchToken(setTokenFound);
  onMessageListener().then(payload => {
//console.log('payload of app.js', payload);
    setNotification({title: payload.notification.title, body: payload.notification.body})
    setShow(true);
    toast.info(
      <div>
    <a href={process.env.REACT_APP_URL + payload.notification.title}>{payload.notification.body}</a>
  </div>
       , {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }).catch(err => console.log('failed: ', err));

  // const onShowNotificationClicked = () => {
  //   setNotification({title: "Notification", body: "This is a test notification"})
  //   setShow(true);
  // }
 

  
const modals = {
  test: TestModal,
}

function TestModal() {
  return (
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat
      quam eu mauris euismod imperdiet. Nullam elementum fermentum neque a
      placerat. Vivamus sed dui nisi. Phasellus vel dolor interdum, accumsan
      eros ut, rutrum dolor. Etiam in leo urna. Vestibulum maximus vitae urna at
      congue.
    </p>
  )
}

let tourConfig = [];

if((user_type=='seller' || user_type=='Real Estate Professional')){
   tourConfig = [
  
    {
      selector: '[data-tut="reactour__browse_properties"]',
      content: 'Click here to Browse Properties',
     
      style: {
        backgroundColor: '#bada55',
      },
    },
    {
      selector: '[data-tut="reactour__browse_socialmedia"]',
      content: `You can also access our social media platform  `,
    },
    {
      selector: '[data-tut="reactour__browse_profile"]',
      content: `You can also access your profile  `,
    }
    ,
    {
      selector: '[data-tut="reactour__edit_profile"]',
      content: `You can also edit your  profile  `,
    },
    {
      selector: '[data-tut="reactour__browse_uploads"]',
      content: 'You can also upload documents ',
      
    },
    {
      selector: '[data-tut="reactour__browse_community"]',
      content: 'You can also access our coummunity page   ',
      
    },
    {
      selector: '[data-tut="reactour__browse_messages"]',
      content: 'You can also check inbox messages ',
      
    },
    {
      selector: '[data-tut="reactour__browse_add_property"]',
      content: 'You can also add new properties   ',
      
    },
    {
      selector: '[data-tut="reactour__browse_check_property"]',
      content: 'You can check your properties   ',
      
    },
  ]
}else{
   tourConfig = [
  
    {
      selector: '[data-tut="reactour__browse_properties"]',
      content: 'Click here to Browse Properties',
      style: {
        background: '#bada55',
      },
      action: () => console.log("clicked on browser"),
    },
    {
      selector: '[data-tut="reactour__browse_socialmedia"]',
      content: `You can also access our social media platform  `,
    },
    {
      selector: '[data-tut="reactour__browse_profile"]',
      content: `You can also access your profile  `,
    }
    ,
    {
      selector: '[data-tut="reactour__edit_profile"]',
      content: `You can also edit your  profile  `,
    },
    
    {
      selector: '[data-tut="reactour__browse_uploads"]',
      content: 'You can also upload documents ',
      
    },
    {
      selector: '[data-tut="reactour__browse_community"]',
      content: 'You can also access our coummunity page   ',
      
    },
    {
      selector: '[data-tut="reactour__browse_messages"]',
      content: 'You can also check inbox messages ',
      
    },
  ]
}

const kyc_required = Cookies.get('kyc_required');

if(kyc_required==1){
  tourConfig.push({
    selector: '[data-tut="reactour__browse_kyc"]',
    content: 'Some features on our platform will require KYC verification',
    
  });
}


  
  return (

    <main> 
       <>
      <TourProvider
        steps={tourConfig}
        afterOpen={disableBody }
        beforeClose={enableBody}
        currentStep={step}
        setCurrentStep={setCurrentStep}

        styles={{
          badge: base => ({ ...base, background: '#ef5a3d' }),
          dot: (base, { current }) => ({
            ...base,
            background: current ? '#ef5a3d' : '#ccc',
          }),
          popover: base => ({
            ...base,
            background: '#dedede',
            borderRadius: 10,
          }),
        }}
        scrollSmooth
      >
        <Router>
        <ScrollToTop />
          <AppRouter currentUrl={currentUrl} handleCurrentUrl={handleCurrentUrl} step={step} onNextStep={handleSteps} />
        </Router>
      </TourProvider>
    </>


            <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />


      {/* <Toast onClose={() => setShow(false)} show={show} delay={300000} autohide animation style={{
          position: 'absolute',
          top: 20,
          right: 20,
          minWidth: 200,
          zIndex:9999,
        }}>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">{notification.title}</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body>{notification.body}</Toast.Body>
        </Toast> */}




       
     
    </main>
  );
}

export default App;
