import LoginForm from "../../components/login/loginForm";
import LoginLogo from "../../components/login/loginLogo";
import Header from "../../layouts/header";


import Cookies from 'js-cookie';

import { useHistory ,useLocation } from 'react-router-dom';




export default function Login() {
  const history = useHistory()
  const userToken = Cookies.get('token');

  if(userToken!=undefined){
      history.push("/edit-profile");

  }
  Cookies.set('socialuserfirstname', '');
  Cookies.set('socialuserlastname', '');
  Cookies.set('socialuseremail', '');
  Cookies.set('socialusereprovider', '');
  Cookies.set('providerjson', '');
  Cookies.set('socialuserproviderid', '');

 


  return (
    <>

      <section className="login-section">
       {/* <Header /> */}
        {/* <LoginLogo /> */}
        <div className="container ">
          <div className="row ml-lg-5">
              <div className="col-12 col-md-6 mx-auto">
              <LoginForm  className="mt-5 "/>
              </div>
         
          </div>
        
        </div>
       

       
        

      </section>
    </>
  );
}
