import BenefitsSection from "../../components/home/benefitsSection";
import CallToAction from "../../components/contractors/callToAction";
import ChooseUsSection from "../../components/home/chooseUSsection";
import FeaturesSection from "../../components/home/featuresSection";
import HomeBanner from "../../components/home/homebanner";
import LatestFeedSection from "../../components/home/latestFeed";
import LatestNews from "../../components/home/latestNewsSection";
import OurSpecialFeature from "../../components/home/ourSpecialFeature";
import SuitableSection from "../../components/home/suitableSection";
import TestimonialSection from "../../components/home/testimonialSection";
import TokenSection from "../../components/home/tokenSection";
import WhyChooseUs from "../../components/home/whyChooseUsSection";
import Footer from "../../layouts/footer";
import Header from "../../layouts/header";
import React, { useState } from "react";
import Cookies from 'js-cookie';

export default function Home() {
    const [feedCardContent, setFeedCardContent] = useState([]);
    const userToken = Cookies.get('token');
    const [reloaddata, setSetReloadData] = useState([]);
    const [news, setNews] = useState([]);
	const fetchData = async () => {
		fetch(
			process.env.REACT_APP_API_URL +
				`api/properties?page=1&rooms=null&baths=null&beds=null&category=null`,
			{
				method: "GET",
				headers: {
					"Access-Control-Allow-Origin": "http://localhost:3000/",
					"Access-Control-Allow-Credentials": "true/",
					"Content-Type": "application/json", 
					'Authorization': 'Bearer '+userToken+'',
				},
			}
		)
			.then((results) => results.json())
			.then((data) => {
				if (data) {
					setFeedCardContent(data.data);
				}
			});
	};
    React.useEffect(() => {
		fetchData();
	}, [reloaddata]); // <-- Have to pass in [] here!


    const fetchNews = async () => {
		fetch(
			process.env.REACT_APP_API_URL +
				`api/news`,
			{
				method: "GET",
				headers: {
					"Access-Control-Allow-Origin": "http://localhost:3000/",
					"Content-Type": "application/json"
				},
			}
		)
			.then((results) => results.json())
			.then((data) => {
				if (data) {
					setNews(data.data);
				}
			});
	};
    React.useEffect(() => {
		fetchNews();
	}, [reloaddata]); // <-- Have to pass in [] here!


    return (
        <>
         {/* <Header /> */}
            <HomeBanner />
            <SuitableSection />
            <LatestFeedSection 
            feedCardContent={feedCardContent}
            />
            {/* <BenefitsSection /> */}
            <ChooseUsSection />
            {/* <FeaturesSection /> */}
          
            {/* <WhyChooseUs /> */}
            {/* <OurSpecialFeature /> */}
          
             <LatestNews news={news} />
            
           
           
            {/* <Footer /> */}
        </>
    )
}