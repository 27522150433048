import { FaMapMarkerAlt } from "react-icons/fa";

export default function GrandFamily(props) {
    return (
        <section className="container">
            <h2 className="main-title-head">{props.title}</h2>
            <span className="location-check"><FaMapMarkerAlt className="map-main-icon" />
            {props.city} ,     {props.state}   ,    {props.zipcode}
            
            </span>
        </section>
    )
}