import {
  kitchenImage,
  houseTwoImage,
  houseView,
  parkView,
  diningView,
} from "../../utils/images";
import Lightbox from 'react-image-lightbox';

import React, { useState } from "react";
export default function DetailBanner(props) {
 
  const [images, setImages] = useState(props.images);
  const [imagesList, setImagesList] = useState([]);
  const [current , setCurrent] = useState('');
const [prev , setPrev] = useState('');
const [next , setNext] = useState('');
const [initCount, setInitCount] = useState(0);
  React.useEffect(() => {
		setImages(props.images);
   

    if(props.images){
      setImagesList(props.images.path);
    
    }
	}, [props.images]); // <-- Have to pass in [] here!

  const handleClickImage = (e, count) => {
		e && e.preventDefault();
		setInitCount(count);

		setCurrent(process.env.REACT_APP_IMAGES_URL + imagesList[count]);
		if(count > 0){
			setPrev(process.env.REACT_APP_IMAGES_URL + imagesList[count-1]);
		}else{
			setPrev('');
		}

		if(count + 1 == imagesList.length  ){
			
			setNext('');
		}else{
			
			setNext(process.env.REACT_APP_IMAGES_URL + imagesList[count+1]);
			
		}
	// console.log('images.length' , images.length);
		// console.log('current' , current);
		// console.log('next' , next);
		// console.log('prev' , prev);
	   }

	   const handleNextImage = () => {
		
		
		setCurrent(process.env.REACT_APP_IMAGES_URL + imagesList[initCount + 1]);
		
		
			
			if(imagesList[initCount]){
			
				setPrev(process.env.REACT_APP_IMAGES_URL + imagesList[initCount]);
			}else{
				
				setPrev('');
			}
			
		

		if(initCount +1 == imagesList.length  ){
			setNext('');
		}else{
			if(imagesList[initCount + 2]){
				setNext(process.env.REACT_APP_IMAGES_URL + imagesList[initCount + 2]);
			}else{
				setNext('');
			}
			
		
		}
		setInitCount(initCount+1);

	   }

	   const handlePrevImage = () => {
	

		setCurrent(process.env.REACT_APP_IMAGES_URL + imagesList[initCount - 1 ]);
		if(initCount > 0){
			if(imagesList[initCount-2]){
				setPrev(process.env.REACT_APP_IMAGES_URL + imagesList[initCount-2]);
			}else{
				setPrev('');
			}
			
		}else{
			setPrev('');
		}

    if(imagesList[initCount]){
      setNext(process.env.REACT_APP_IMAGES_URL + imagesList[initCount]);
    }else{
      setNext('');
    }
		setInitCount(initCount-1);
	   }
	 
	const   handleCloseModal = (e) => {
		e && e.preventDefault();
	 
		setCurrent('');
	   }


  return (
    <>
      <section className="hero-banner-detail">
        <div className="container pt-4 pt-sm-5">
          <div className="row">
            <div className="col-lg-6 col-md-12">

            {images && (


              <img
                className="detail-images detail-image-main"
                onClick={ e => handleClickImage(e, 0)}
                src={process.env.REACT_APP_IMAGES_URL + images.path[0]}
                alt="Detail-house-Image"
              />

            )}
            
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="row">

              {images && (
                <>
                {images.path.map((value,key) => 
                  (
                    <div  key={key}>
                    { (key > 0 &&  key < 5 )? 
                      <div  className="col-sm-6 main-banner-img  ">
                  <img
                    
                    onClick={ e => handleClickImage(e, key)}
                    className="detail-images detail-image-new"
                    src={process.env.REACT_APP_IMAGES_URL + value}
                    alt="Detail-house-Image"
                  />
                </div>:null

                    }
                  </div>
                    ))} 
                </>

                )}



              </div>
            </div>



            <div className="col-md-12">
              <div className="row">

              {images &&  images.path.length > 5 &&  (
                <>
                
                {images.path.map((value,key) => 
                  (
                    <>
                    { (key > 4  )? 
                      <div key={key} className="col-sm-3 main-banner-img  ">
                  <img
                    key={key}
                    onClick={ e => handleClickImage(e, key)}
                    className="detail-images detail-image-new"
                    src={process.env.REACT_APP_IMAGES_URL + value}
                    alt="Detail-house-Image"
                  />
                </div>:null

                    }
                  </>
                    ))} 
                
                  </>
                )}



              </div>
            </div>




            {current &&
         <Lightbox
         mainSrc={current}
         nextSrc={next}
         prevSrc={prev}
         onMovePrevRequest={handlePrevImage}
           onMoveNextRequest={handleNextImage}
            onCloseRequest={handleCloseModal}
            />
      }

          </div>
        </div>
      </section>
    </>
  );
}
