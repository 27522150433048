import AgentImage from "../../assets/images/agent-image.png";
import React, { useState } from "react";

import Alert from "react-bootstrap/Alert";
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
async function addAppointment(credentials) {
	return fetch(process.env.REACT_APP_API_URL + "api/appointments/add", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "http://localhost:3000/",
			"Access-Control-Allow-Credentials": "true/",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(credentials),
	}).then((data) => data.json());
}

export default function SaleAgent(props) {
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [phone, setPhone] = useState();
	const [message, setMessage] = useState();
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const { detail } = props;
	const [datetimeon, setValue] = React.useState(new Date());
	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrorMessage(null);
		setSuccessMessage(null);

		const res = await addAppointment({
			name,
			email,
			phone,
			message,
			datetimeon,
			property: props.property,
		});
		if (res.success) {
			//  sessionStorage.setItem("token", token.author.api_token);

			document.getElementById("create-appointment-form").reset();
			setName("");
			setEmail("");
			setPhone("");
			setMessage("");
			setSuccessMessage("Appointment has been added successfully.");
		} else {
			setErrorMessage(res.errors);
		}
	};

	return (
		<section className="sale-agent-sale">
			<div className="container">
				<h2 className="main-title-head">{props.title}</h2>
				<div className="row">
					<div className="col-xl-5 col-lg-5 col-md-6 main-border">
						{detail && (
							<>
								<div className="agent-info">
									<div className="agent-image">
										{detail.image ? (
											<img src={process.env.REACT_APP_IMAGES_URL + JSON.parse(detail.image).path[0]} alt="agent-image" />
										) : (
											// eslint-disable-next-line jsx-a11y/img-redundant-alt
											<img src={AgentImage} alt="agent-image" />
										)}
									</div>
									<div className="about-agent">
										<p>
											{detail.first_name} {detail.last_name.substring(0, 1)}.
										</p>
										<p>{detail.email}</p>
										<h6>Listing details:</h6>
										<p>Listed on: 3:39 aM</p>
										<p>Property ID: #50</p>
									</div>
								</div>
								<h4 className="main-info">Agent contact info :</h4>
								<ul className="list-styles">
									<li className="social-link">
										<a>Visit Agent Website</a>
									</li>
									<li className="social-link">
										<a>Agent Other Listings</a>
									</li>
									<li className="social-link">
										<a>phone: {detail.phone}</a>
									</li>
								</ul>
							</>
						)}
					</div>
					<div className="col-xl-7 col-lg-7 col-md-6">
						<h4 className="main-info show-schedule">Schedule a showing</h4>
						<form onSubmit={handleSubmit} id="create-appointment-form">
							{successMessage && (
								<Alert variant="success">
									{successMessage && <p> {successMessage} </p>}
								</Alert>
							)}

							{errorMessage && (
								<Alert variant="danger">
									{errorMessage.name && <p> {errorMessage.name} </p>}
									{errorMessage.email && <p> {errorMessage.email} </p>}
									{errorMessage.phone && <p> {errorMessage.phone} </p>}

									{errorMessage.message && <p> {errorMessage.message} </p>}
									{errorMessage.property && <p> {errorMessage.property} </p>}
								</Alert>
							)}


<div className="form-group row">
								<div className="col-xl-1 col-lg-1 col-md-0"></div>
								<label
									htmlFor="input"
									 className="col-xl-3 col-lg-3 col-md-4 form-label profile-info my-auto"
								>
									Date Time: <span className="text-danger">*</span>
								</label>
								<div className="col-sm-8">
	
	<LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        renderInput={(props) => <TextField {...props} />}
        label="DateTimePicker"
        value={datetimeon}
        onChange={(newValue) => {
          setValue(newValue);
        }}
      />
    </LocalizationProvider>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-xl-1 col-lg-1 col-md-0"></div>
								<label
									for="input"
									class="col-xl-3 col-lg-3 col-md-4 form-label profile-info my-auto"
								>
									Name: <span className="text-danger">*</span>
								</label>
								<div class="col-sm-8">
									<input
										type="text"
										class="form-control input-profile"
										id="input"
										placeholder=""
										onChange={(e) => setName(e.target.value)}
									/>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-xl-1 col-lg-1 col-md-0"></div>
								<label
									for="input"
									class="col-xl-3 col-lg-3 col-md-4 col-form-label profile-info my-auto"
								>
									Email: <span className="text-danger">*</span>
								</label>
								<div class="col-sm-8">
									<input
										type="email"
										class="form-control input-profile"
										id="input"
										placeholder=""
										onChange={(e) => setEmail(e.target.value)}
									/>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-xl-1 col-lg-1 col-md-0"></div>
								<label
									for="input"
									class="col-xl-3 col-lg-3 col-md-4 form-label profile-info my-auto"
								>
									Phone Number: <span className="text-danger">*</span>
								</label>
								<div class="col-sm-8">
									<input
										 type="tel" pattern="\(\d{3}\)[ ]?\d{3}[-]?\d{4}" maxlength="14"
										class="form-control input-profile"
										id="input"
										placeholder="(xxx) xxx-xxxx"
										onChange={(e) => setPhone(e.target.value)}
									/>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-xl-1 col-lg-1 col-md-0"></div>
								<label
									for="input"
									class="col-xl-3 col-lg-3 col-md-4 col-form-label profile-info my-auto"
								>
									Message: <span className="text-danger">*</span>
								</label>
								<div class="col-sm-8">
								<textarea
										
										className="form-control input-profile"
									   id="input"
									   placeholder=""
									   onChange={(e) => setMessage(e.target.value)}
								   ></textarea>
								</div>
							</div>
							<div className="form-group row">
								<div className="col-xl-1 col-lg-1 col-md-0"></div>
								<label
									htmlFor="input"
									 className="col-xl-3 col-lg-3 col-md-4 form-label profile-info my-auto"
								>
									
								</label>
								<div className="col-sm-8">
								<button type="submit">Book Appointment</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
}
