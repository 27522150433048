const home = "/";
const listing = "/listing";
const detail = "/property/:t";
const socialMedia = "/socialMedia";
const post = "/post/:t";
const USHXToken = "/USHXToken";
const realtors = "/real-estate-professionals";
const investors = "/Investors";
const investorDetails = "/Investor/:t";
const login = "/login";
const community = "/community";
const forgot = "/forgotpassword";
const signup = "/signup/:page?";
const completion = "/profile-completion";
const editProfile = "/edit-profile";
const passwordupdate = "/update-password";
const  mobile = "/mobile-verification";
const questionnairs = "/questionnairs";
const documents = "/documents";
const verification = "/verification";
const listings = "/listings";
const activity = "/activity";
const referrals = "/referrals";
const messages = "/messages";
const addProperty = "/addProperty";
const contractors = "/contractors";
const contractorDetails = "/contractor/:t";
const realtorDetails = "/real-estate-professional/:t";
const propertyDetails = "/property-details/:t";
const token = "/token";
const notifications = "/notification";
const profile = "/profile/:t";
const tutorial = "/congrates";
const terms = "/terms-conditions";
const privacy = "/privacy-policy";
export {
	home,
	listing,
	detail,
	socialMedia,
	USHXToken,
	realtors,
	realtorDetails,
	investors,
	investorDetails,
	login,
	notifications,
	community,
	forgot,
	signup,
	completion,
	editProfile,
	passwordupdate,
	mobile,
	verification,
	listings,
	activity,
	referrals,
	messages,
	addProperty,
	contractors,
	contractorDetails,
	propertyDetails,
	questionnairs,
	token,
	post,
	profile,
	tutorial,
	terms,
	privacy,
	documents

};
