import React, { useState } from "react";
import SimpleMap from "../../components/propertyDetails/addressSection";
import Apartment from "../../components/propertyDetails/apartmentSection";
import ApartmentCard from "../../components/propertyDetails/appartmentCard";
import DetailBanner from "../../components/propertyDetails/detailbanner";
import Discription from "../../components/propertyDetails/discriptionSection";
import Gallery from "../../components/propertyDetails/gallerySection";
import GrandFamily from "../../components/propertyDetails/grandfamilySection";
import PropertyMain from "../../components/propertyDetails/propertySection";
import SpecialFeature from "../../components/propertyDetails/specialFeatureSection";
import SaleAgent from "../../components/propertyDetails/saleagentSection";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import Views from "../../components/propertyDetails/views";
import Cookies from 'js-cookie';
import { useHistory ,useLocation } from 'react-router-dom';


export default function Detail() {



	const [details, setDetails] = useState([]);
	const history = useHistory()


	const slug =history.location.pathname.replace('/property-details/','')
	const [author, setAuthor] = useState(0);
	const userToken = Cookies.get('token');
	const [item, setItem]  = useState('');

	const fetchData = async () => {
		fetch(process.env.REACT_APP_API_URL + `api/properties/${slug}/show`, {
			method: "GET",
			headers: {
				"Access-Control-Allow-Origin": "http://localhost:3000/",
				"Access-Control-Allow-Credentials": "true",
				"Content-Type": "application/json",
				'Authorization': 'Bearer '+userToken+'',
			},
		})
			.then((results) => results.json())
			.then((data) => {
				if (data) {
					setDetails(data.data);
					setAuthor(data.data.user.id);
				}
			});
	};

	React.useEffect(() => {
		fetchData();
	}, [item]); // <-- Have to pass in [] here!


	const handleEdit = (slug) => {
		setItem(slug);
	  };

	return (
		<>
			 {/* <Header /> */}
			<DetailBanner images={details.images} />
			<GrandFamily address={details.address} title={details.title} />
			<Discription about={details.about} title={"Discription"} />
			{/* <PropertyMain title={"Property Overview"} /> */}
			{/* <SpecialFeature title={"Special Features"} /> */}
			{/* <SpecialFeature title={"Bedrooms"} /> */}
			<SpecialFeature amenities={details.amenities} title={"Amenities"} />

			<Gallery images={details.images} title={"Gallery"} />
			<Apartment onEdit={handleEdit} title={"Related properties"} detail={details.relatedProperties} />
			{/* <ApartmentCard /> */}
			<Views total_views={details.total_views} appointments={details.appointments}  author={author}  notes={details.notes} property={details.id} />
			<SimpleMap title={details.address} />
			<SaleAgent
				property={details.id}
				title={"For Sale By Agent"}
				detail={details.user}
			/>
			{/* <Footer /> */}
		</>
	);
}
