import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import Alert from "react-bootstrap/Alert";
import axios from 'axios';
import Select from 'react-select';
import {Modal, Button} from 'react-bootstrap';
import CreatableSelect  from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ImageCrop from "../ImageCropFeed/imageCrop";

import EditProfileContent from "../admin/editProfileFirstStep";
import {  toast } from 'react-toastify';

import {
    kitchenImage,
    houseTwoImage,
    houseView,
    parkView,
    diningView,
    locationTick,
    messagesDarkIcon,
    messagesLightIcon,
    User
  } from "../../utils/images";

import { faCoffee , faXmark, faCircleCheck , faBars  , faPlus } from '@fortawesome/free-solid-svg-icons'
async function updateQuestions(credentials) {
  return fetch(process.env.REACT_APP_API_URL+'api/user/save/questions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

async function updateTags(credentials) {
    return fetch(process.env.REACT_APP_API_URL+'api/profile/addtags', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
          'Access-Control-Allow-Credentials' : 'true/',
          'Content-Type': 'application/json' ,
          'Authorization': 'Bearer '+credentials.userToken+'',
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
  }
  
export default function Basicinfo(props) {

  const handleEdit = (item) => {
   
  };

  const user_type = Cookies.get('user_type');
  const  kyc_required =   Cookies.get('kyc_required');
    const [image, setImage] = useState('');
    const user_id =  Cookies.get('user_id');
    const profile_image = Cookies.get('profile_image');
    const [showLeftSideMenu, setShowLeftSideMenu] = useState(false);
  const handleCloseLeftSideMenu = () => setShowLeftSideMenu(false);
  const handleShowLeftSideMenu = () => setShowLeftSideMenu(true);
  
  const userToken = Cookies.get('token');
  const [successMessage, setSuccessMessage] = useState("");

  const [questionsSelected, setQuestionsSelected] = useState();
  const [first, setFirst] = useState(true);
  const [alreadyquestionsSelected, setAlreadyQuestionsSelected] = useState();
  const [inputData, setInputData] = useState([]);
  React.useEffect(() => {


  var newArray = inputData;
  const answerednew = [];

  newArray.map((item, index) => (
              
    (questionsSelected.find(x => x.value === item.id)) ? 
   
    answerednew.push(item)
      :
    
      answerednew.push({id: item.id,  question: item.question , status: item.status , created_at:item.created_at , updated_at:item.updated_at  })
     
     
  ))
  setInputData(answerednew);

}, [questionsSelected ]); // <-- Have to pass in [] here!
  const [options, setOptions] = useState([]);
  const aquaticCreatures = [];
  const selected = [];
  const answered = [];
  const customStyles = {
    container: provided => ({
      ...provided,
      width: 400
    })
  };
  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "api/questions", {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "http://localhost:3000/",
        "Access-Control-Allow-Credentials": "true/",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken + "",
      },
    })
      .then((results) => results.json())
      .then((data) => {
          if(data.questions){
            data.questions.map((item, index) => (
              aquaticCreatures[index] = {label: item.question,  value: item.id , answer:'' }
               

            ))

            data.questions.map((item, index) => (
              
              (data.userquestions.find(x => x.question_id === item.id)) ? 
                selected.push({label: item.question,  value: item.id , answer:data.userquestions.find(x => x.question_id === item.id).answer })
                :null 
               

            ))

          //  console.log("first" , selected);
            setQuestionsSelected(selected);
           // console.log("second", selected);


            data.questions.map((item, index) => (
              
              (data.userquestions.find(x => x.question_id === item.id)) ? 
              answered.push({id: item.id,  question: item.question , status: item.status , created_at:item.created_at , updated_at:item.updated_at , answer:data.userquestions.find(x => x.question_id === item.id).answer })
                :
                answered.push(item)
               

            ))


           


            setOptions(aquaticCreatures);
            setInputData(answered);
           // console.log("setInputData", answered);
          
          }
        
      });
  }, [ ]); // <-- Have to pass in [] here!
  

  const handleSubmit = async e => {
    e.preventDefault();
    setSuccessMessage(null);
    const res = await updateQuestions({
      'inputData' : JSON.stringify(inputData),
      userToken
    });
    if(res.success){
    //  sessionStorage.setItem("token", token.author.api_token);
    setSuccessMessage('Questionnaires has been updated successfully.');

    toast.success("Questionnaires has been updated successfully.");
    }else{
    
      //setErrorPasswordMessage(res.errors);
    }

  }

      const handleInputValue = (value , id) => {


         var newArray = inputData;

        for (let i = 0; i < newArray.length; i++) {
          if(newArray[i].id==id){
            newArray[i].answer = value;
          }
            
        }
        setFirst(false);
        setInputData(newArray);

      }



      //Movie tags start


  const [successMessageMovieTag, setSuccessMessageMovieTags] = useState();
  const [inputValueMovie, setInputValueMovie] = useState('');
  const [selectionMovie, setSelectionMovie] = useState([]);

  const movieTags = [];
  const selectedMovieTags = [];
  const [newSelectedMovie, setNewSelectedMovie] = useState([]);
  
  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "api/get-tags", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "http://localhost:3000/",
        "Content-Type": "application/json",
      },

     
      body: JSON.stringify({'id':props.details.id})
    })
      .then((results) => results.json())
      .then((data) => {
          if(data.movies_tags){
            data.movies_tags.map((item, index) => (
        
              movieTags[index] = {label: item.name,  value: item.id} 
           
               

            ))

            data.movies_tags.map((item, index) => (
              
              (data.usertags.find(x => x.tag_id === item.id )) ? 
              selectedMovieTags.push({label: item.name,  value: item.id })
                :null 
               

            ))

            setNewSelectedMovie(selectedMovieTags);
            setSelectionMovie(movieTags);

            
      
          
          }
        
      });
  }, [ props.details]); // <-- Have to pass in [] here!
  



  const handleInputChange = (value) => {
    setInputValueMovie(value);
  };

  const handleOnChange = (val) => {
    const newOption = { label: inputValueMovie, inputValueMovie };

    inputValueMovie !== '' && setSelectionMovie([...selectionMovie, newOption]);

    setInputValueMovie('');

    setNewSelectedMovie(val); 

  };

  const handleSubmitMovieTags = async e => {
    e.preventDefault();
    setSuccessMessageMovieTags(null);
    const res = await updateTags({
      'tags' : JSON.stringify(newSelectedMovie),
      'tag_type':'movies',
      userToken
    });
    if(res.success){
    //  sessionStorage.setItem("token", token.author.api_token);
    setSuccessMessageMovieTags('Movies has been updated successfully.');
    toast.success("Movies has been updated successfully.");
    }else{
    
      //setErrorPasswordMessage(res.errors);
    }

  }



// moive tags end 


//sports tags start


const [successMessageSportsTag, setSuccessMessageSportsTags] = useState();
const [inputValueSports, setInputValueSports] = useState('');
const [selectionSports, setSelectionSports] = useState([]);

const sportTags = [];
const selectedSportsTags = [];
const [newSelectedSports, setNewSelectedSports] = useState([]);

React.useEffect(() => {
  fetch(process.env.REACT_APP_API_URL + "api/get-tags", {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "http://localhost:3000/",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({'id':props.details.id})
  })
    .then((results) => results.json())
    .then((data) => {
        if(data.sports_tags){
          data.sports_tags.map((item, index) => (
          
            sportTags[index] = {label: item.name,  value: item.id} 
          
             

          ))

          data.sports_tags.map((item, index) => (
            
            (data.usertags.find(x => x.tag_id === item.id  )) ? 
            selectedSportsTags.push({label: item.name,  value: item.id })
              :null 
             

          ))

          setNewSelectedSports(selectedSportsTags);
          setSelectionSports(sportTags);
    
        
        }
      
    });
}, [ props.details]); // <-- Have to pass in [] here!




const handleInputChangeSports = (value) => {
  setInputValueSports(value);
};

const handleOnChangeSports = (val) => {
  const newOption = { label: inputValueSports, inputValueSports };

  inputValueSports !== '' && setSelectionSports([...selectionSports, newOption]);

  setInputValueSports('');

  setNewSelectedSports(val); 

};

const handleSubmitSportsTags = async e => {
  e.preventDefault();
  setSuccessMessageSportsTags(null);
  const res = await updateTags({
    'tags' : JSON.stringify(newSelectedSports),
    'tag_type':'sports',
    userToken
  });
  if(res.success){
  //  sessionStorage.setItem("token", token.author.api_token);
  setSuccessMessageSportsTags('Sports has been updated successfully.');
  toast.success("Sports has been updated successfully.");
  }else{
  
    //setErrorPasswordMessage(res.errors);
  }

}



//sports tags end 


    return (

        
        <section className="edit-profile-content row">
              <ol class="progtrckr">



  {(user_type=='seller')?
<ol class="progtrckr">
<li class="progtrckr-done" value="0">
   <span> Basic Info</span>
  </li>
  <li class="progtrckr-doing" value="1">
   <span> Interests</span>
  </li>
<li class="progtrckr-todo" value="2">
   <span> Add Property</span>
  </li>
  <li class="progtrckr-todo" value="3">
   <span> Phone Verification</span>
  </li>
    {(kyc_required==1)&& 
    <li class="progtrckr-todo" value="4">
     <span>KYC Verification</span>
  </li>
    }
    </ol>
:
<ol class="progtrckr">
<li class="progtrckr-done" value="0">
   <span> Basic Info</span>
  </li>
  <li class="progtrckr-doing" value="1">
   <span> Interests</span>
  </li>
  <li class="progtrckr-todo" value="2">
   <span> Phone Verification</span>
  </li>
    {(kyc_required==1)&& 
    <li class="progtrckr-todo" value="3">
     <span>KYC Verification</span>
  </li>
    }
    </ol>

}


  </ol>


        <div className="edit-container ol-xsm-12 col-sm-12   col-md-12 col-lg-12 col-12">
    

        <h3>Select Questionnaires </h3>
        <div className="row edit-form">
        {successMessage && (
                        <Alert variant="success">
                          {successMessage && <p> {successMessage} </p>}
                        </Alert>
                      )}
        <div className="card w-100 p-3 mt-2 mb-2 shadow-sm">
            
              {(options &&  questionsSelected)&&
                <Select 
                className='w-100'
                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                placeholder="Questions"
                isMulti
                defaultValue={questionsSelected}
                styles={customStyles}
                options={options}
                onChange={opt => setQuestionsSelected(opt)}

              />
               }
        
        
        </div>

      
        
      {(inputData)&& 
     inputData.map((val, key) => (
     
      <div key={key}>
      {
         

        <>
          {(questionsSelected)&& 
               (questionsSelected.find(x => x.value === val.id)) ? 

               <>
                {(first)?
                  <div key={key} className="signup-input col-md-12">
                  <label>{val.question}</label>
                    <input   type="text" value={questionsSelected.find(x => x.value === val.id).answer}   onChange={e => handleInputValue(e.target.value , val.id )}  />
                  </div>
                :
                <div key={key} className="signup-input col-md-12">
                <label>{val.question}</label>
                  <input type="text"   onChange={e => handleInputValue(e.target.value , val.id )}  />
                </div>
                }
               </>
          
            : null

          }
         
          </>
      }

        </div>
     
    
      ))
     
      
      }
        
     
       

        </div>
        <div className="signup-submit">
          <button type="submit"  onClick={handleSubmit} className="edit-btn">Update</button>
        </div>

        <h1 className="profile-about-title mb-2">Movies</h1>


        
{(user_id!=props.details.id )?
    
    <div className="my-2">
         {(newSelectedMovie)&&
           newSelectedMovie.map((val, key) => (
            <span className="tagsclass">{val.label} </span>
           ))
        }
    </div>
:
<>
{successMessageMovieTag && (
                            <Alert variant="success">
                              {successMessageMovieTag && <p> {successMessageMovieTag} </p>}
                            </Alert>
                          )}
                        
<div className="card w-100 p-3 mt-2 mb-2 shadow-sm">
                
                {(newSelectedMovie)&&
                <CreatableSelect 
                options={selectionMovie}
                isMulti={true}
                isClearable={true}
                onChange={(opt)=>handleOnChange(opt)}
                onInputChange={handleInputChange}
                inputValue={inputValueMovie}
                value={newSelectedMovie}
                controlShouldRenderValue={true}
              />
                }
                
                   
                  
            
            
            </div>
            </>
            }

<div className="signup-submit">
              <button type="submit"  onClick={handleSubmitMovieTags} className="edit-btn">Update</button>
            </div>


            <h1 className="profile-about-title mb-2">Sports</h1>
            {(user_id!=props.details.id )?
    
    <div>
         {(newSelectedSports)&&
           newSelectedSports.map((val, key) => (
            <span className="tagsclass">{val.label} </span>
           ))
        }
    </div>
:
<>


{successMessageSportsTag && (
                            <Alert variant="success">
                              {successMessageSportsTag && <p> {successMessageSportsTag} </p>}
                            </Alert>
                          )}
<div className="card w-100 p-3 mt-2 mb-2 shadow-sm">
                
                {(newSelectedSports)&&
                <CreatableSelect 
                options={selectionSports}
                isMulti={true}
                isClearable={true}
                onChange={(opt)=>handleOnChangeSports(opt)}
                onInputChange={handleInputChangeSports}
                inputValue={inputValueSports}
                value={newSelectedSports}
                controlShouldRenderValue={true}
              />
                }
                  
                  
                   
                 
            
            
            </div>
            </>
}

<div className="signup-submit">
              <button type="submit"  onClick={handleSubmitSportsTags} className="edit-btn">Update</button>
            </div>

      </div>
     



      
      </section>

    )

}