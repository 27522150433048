import AddPropertyContent from "../../components/admin/addProperty";
import Header from "../../layouts/header";

import Cookies from 'js-cookie';
import { useHistory } from "react-router-dom";
export default function AddProperty() {
  const user_type = Cookies.get('user_type');
  const history = useHistory();
  {(user_type!='seller' && user_type!='Real_Estate_Professional') &&
  history.push("/");
}

    return (
      <>
        <section className="verification">
        <div className="wrapper">
             {/* <Header /> */}
            <AddPropertyContent />
          </div>
        </section>
      </>
    );
  }