import React, { useState , useEffect } from 'react';
import { Link } from "react-router-dom";
import { MdOutlineSend } from 'react-icons/md';
import SettingSider from "../../layouts/settingSider";
import LeftSidebar from "../../layouts/leftSider";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import useToken from '../App/useToken';
import Alert from "react-bootstrap/Alert";
import Cookies from 'js-cookie';
async function veirifyKyc(credentials) {

    return fetch(process.env.REACT_APP_API_URL+'api/author/kyc/update', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
  }
  async function sendmessage(credentials) {

    return fetch(process.env.REACT_APP_API_URL+'api/chat/send', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin' : 'http://localhost:3000/',
        'Access-Control-Allow-Credentials' : 'true/',
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+credentials.userToken+'',
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
  }

  export default function Kyc(props) {
    
    const { token, setToken } = useToken();
    const history = useHistory();
    const user_type = Cookies.get('user_type');
    const  kyc_required =   Cookies.get('kyc_required');
    if(!token) {
       history.push("/login");
    }
    const userToken = Cookies.get('token');
    const userid = Cookies.get('user_id');
    
  
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
  
    const [first_name, setFirstName] = useState();
    const [last_name, setLastName] = useState();
    const [company, setCompany] = useState();
    const [frontfilename, setFrontfilename] = useState();
    const [backfilename, setBackfilename] = useState();
    const [companyid, setIdCompany] = useState();
    const [document_type, setDoctype] = useState();
    const [image_front, setFrontFiles] = useState();
    const [image_back, setBackFiles] = useState();
    const [board, setBoard] = useState([]);
    const [message, setMessage] = useState();
    const [url, setUrl] = useState();
    const [checkkyc, setCheckKyc] = useState([]);
    const [reloadboard, setReloadBoard] = useState();
    React.useEffect(() => {
      fetch(process.env.REACT_APP_API_URL+'api/chats/messageboard' , {
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin' : 'http://localhost:3000/',
          'Access-Control-Allow-Credentials' : 'true/',
          'Content-Type': 'application/json' ,
          'Authorization': 'Bearer '+userToken+'',
        },
      }
        )
        .then(results => results.json())
        .then(data => {
         
          setBoard(data.chat);
        });
    }, [url]); // <-- Have to pass in [] here!
  
  
    React.useEffect(() => {
      fetch(process.env.REACT_APP_API_URL+'api/author/kyc' , {
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin' : 'http://localhost:3000/',
          'Access-Control-Allow-Credentials' : 'true/',
          'Content-Type': 'application/json' ,
          'Authorization': 'Bearer '+userToken+'',
        },
      }
        )
        .then(results => results.json())
        .then(data => {
         
          setCheckKyc(data);
        });
    }, [reloadboard]); // <-- Have to pass in [] here!
  
  
    const handleChange =  e => {
      //setFiles(e.target.files)
      setErrorMessage(null);
      const url = process.env.REACT_APP_API_URL+'api/uploadFile';
      const formData = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        setFrontfilename(e.target.files[i].name);
        formData.append(`files[${i}]`, e.target.files[i])
        }
          formData.append('directory' , 'kyc');
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
          axios.post(url, formData, config).then((response) => {
          
            setFrontFiles(JSON.stringify(response.data.files))
          });
  
        };
  
        const handleChangeBack =  e => {
          //setFiles(e.target.files)
          setErrorMessage(null);
          const url = process.env.REACT_APP_API_URL+'api/uploadFile';
          const formData = new FormData();
          for (let i = 0; i < e.target.files.length; i++) {
            setBackfilename(e.target.files[i].name);
            formData.append(`files[${i}]`, e.target.files[i])
            }
              formData.append('directory' , 'kyc');
              const config = {
                headers: {
                  'content-type': 'multipart/form-data',
                },
              };
              axios.post(url, formData, config).then((response) => {
            
                setBackFiles(JSON.stringify(response.data.files));
              });
            };
  
            const handleSubmit = async e => {
              e.preventDefault();
              setErrorMessage(null);
              props.setErrorMessage(null);
               
                const res = await veirifyKyc({
                  first_name,
                  last_name,
                  company,
                  companyid,
                  document_type,
                  image_front,
                  image_back,
                  userToken
                } );
                if(res.success){
                  setSuccessMessage('Your details are submitted successfully.');
                  setReloadBoard(Date.now());
                  Cookies.set('kycsubmitted' , 1);
                  //history.push("/congrates");
                }else{
                
                 setErrorMessage(res.errors);
                }
        
              }
  
              const handleSendMessage = async e => {
                e.preventDefault();
                setErrorMessage(null);
             
                 
                  const res = await sendmessage({
                    'is_admin': 'yes',
                    message,
                    userToken
                  } );
                  if(res.status){
                  
                    setMessage('');
                    setUrl(message);
                    setReloadBoard(message)
                  //  sessionStorage.setItem("token", token.author.api_token);
                  //  history.push("/login");
                  
                  }
          
                }
  var     hidekyc = false;
  if(checkkyc.status==true){
    Cookies.set('kycsubmitted' , 1);
       if(checkkyc.data.status=='Accepted' || checkkyc.data.status=='Pending' ){
        hidekyc = true;
      
       }
  }


  const handleSkip = () => {
      
    props.setErrorMessage(null);
    history.push('/edit-profile')
      
     
    }

    return (
    <>
   {(user_type=='seller')?
<ol class="progtrckr">
<li class="progtrckr-done" value="0">
     <span> Basic Info</span>
    </li>
    <li class="progtrckr-done" value="1">
   <span> Interests</span>
  </li>

<li class="progtrckr-done" value="2">
     <span> Add Property</span>
    </li>
    <li class="progtrckr-done" value="3">
     <span> Phone Verification</span>
    </li>
    {(kyc_required==1)&& 
    <li class="progtrckr-todo" value="4">
     <span>KYC Verification</span>
  </li>
    }
    
    </ol>
:
<ol class="progtrckr">
<li class="progtrckr-done" value="0">
     <span> Basic Info</span>
    </li>
    <li class="progtrckr-done" value="1">
   <span> Interests</span>
  </li>
    <li class="progtrckr-done" value="2">
     <span> Phone Verification</span>
    </li>
    {(kyc_required==1)&& 
    <li class="progtrckr-todo" value="3">
     <span>KYC Verification</span>
  </li>
    }
    </ol>

}
  <h3 className="signup-message">KYC Verification </h3>
        <div className="row animatefadein">
        {hidekyc ?
            <Alert variant="success" >
                
                <p>  KYC Verification Submitted </p>
               
                </Alert>
               
           :
          <form onSubmit={handleSubmit}>
           

            {errorMessage && (
            <Alert variant="danger" >
                 {errorMessage.first_name && (
                <p>  {errorMessage.first_name} </p>
                )}

                {errorMessage.last_name && (
                <p>  {errorMessage.last_name} </p>
                )}
              
              {errorMessage.company && (
                <p>  {errorMessage.company} </p>
                )}

                {errorMessage.companyid && (
                <p>  {errorMessage.companyid} </p>
                )}
                 {errorMessage.document_type && (
                <p>  {errorMessage.document_type} </p>
                )}
                 {errorMessage.image_back && (
                <p>  {errorMessage.image_back} </p>
                )}
                 {errorMessage.image_front && (
                <p>  {errorMessage.image_front} </p>
                )}
              
                 
                </Alert>

          )}


            <div className="row edit-form">
              <p>KYC screening of users will help us continue to provide a safe and secure place to send and receive payments online. By completing our KYC process you help improve the quality of the platform and also become a trusted member of our social media platform. </p>
            <div className="  col-md-12 d-flex justify-content-end ">
            <button type='button' className="submit-btn mr-2" onClick={handleSkip}>Skip</button>
              </div>
              <div className="signup-input col-md-6">
                <label>First Name</label>
                <input type="text"   onChange={e => setFirstName(e.target.value)}/>
              </div>
              <div className="signup-input col-md-6">
                  <label>Last Name</label>
                  <input type="text" onChange={e => setLastName(e.target.value)} />
              </div>
              <div className="signup-input col-md-6">
                <label>Company Name</label>
                <input type="text" onChange={e => setCompany(e.target.value)} />
              </div>
              <div className="signup-input col-md-6">
                  <label>Company ID</label>
                  <input type="text" onChange={e => setIdCompany(e.target.value)} />
              </div>
              <div className="signup-input col-md-6">
                <label>Document Type</label>
                <select className="form-select type-select" aria-label="Default select example" onChange={e => setDoctype(e.target.value)}>
                    <option  value="" >Select Document Type</option>
                    <option value="driving_license">Driving License</option>
                    <option value="cnic">Government-issued ID</option>
                </select>
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-6 file-field">
                <input  key="front_file" type="file" onChange={handleChange} />
                <h3>Front Side <br /> Click  to upload</h3>
                <span>{frontfilename}</span>
              </div>
              <div className="col-md-6 file-field">
                <input  key="back_file" type="file"   onChange={handleChangeBack} />
                <h3>Back Side <br /> Click  to upload</h3>
                <span>{backfilename}</span>
              </div>
              <div className="col-md-6">
            

                  {successMessage && (
            <Alert variant="success" >
                 {successMessage && (
                <p>  {successMessage} </p>
                )}
                </Alert>

          )}


              </div>
            </div>
            <div className="signup-submit">
              <button type="submit" className="submit-btn">Submit </button>
            </div>
            </form>
              }
         </div>
        </>
    );
  }
  