import React, { useState } from "react";
import Description from "../../components/investorDetails/description";
import Details from "../../components/investorDetails/details";
import Footer from "../../layouts/footer";
import Header from "../../layouts/header";
import RealtorApartment from "../../components/investorDetails/apartmentSection";
import DetailGallery from "../../components/investorDetails/detailGallery";
import RealtorAddress from "../../components/investorDetails/realtorAddress";
import RealtorForm from "../../components/investorDetails/realtorForm";
import RealtorFeeds from "../../components/investorDetails/realtorFeeds";
import RealtorCallToAction from "../../components/contractors/callToAction";
import { useHistory ,useLocation } from 'react-router-dom';
export default function InvestorDetails() {
	const [details, setDetails] = useState([]);
	const [activity, setActivity] = useState([]);
	const [lastactivity, setLastActivity] = useState();
	const history = useHistory()
	const [gallery, setGellary] = useState(false);
	const slug =history.location.pathname.replace('/investor/','')
	const fetchData = async () => {
		fetch(process.env.REACT_APP_API_URL + `api/user/getDetail`, {
			method: "POST",
			headers: {
				"Access-Control-Allow-Origin": "http://localhost:3000/",
				"Access-Control-Allow-Credentials": "true/",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ slug: slug }),
		})
			.then((results) => results.json())
			.then((data) => {
				if (data) {
					setDetails(data.user);
					let  path = [];
					let  realname = [];
					 
					  if(!data.user.privateBusiness){
						if(data.user.imagesBusinessPhoto!=''){
							JSON.parse(data.user.imagesBusinessPhoto).path.map(function(val, index){
								path = [...path ,val ];
							  })
							  JSON.parse(data.user.imagesBusinessPhoto).realname.map(function(val, index){
							
								realname = [...realname ,val ];
			  
							  })
						}
						
					  }

					  if(!data.user.privateHavingFun){
						if(data.user.imagesPhotoHavingFun!=''){
							JSON.parse(data.user.imagesPhotoHavingFun).path.map(function(val, index){
								path = [...path ,val ];
							  })
							  JSON.parse(data.user.imagesPhotoHavingFun).realname.map(function(val, index){
							
								realname = [...realname ,val ];
			  
							  })
						}
						
					  }

					  if(!data.user.privateFamily){
						if(data.user.imagesFamily!=''){
							JSON.parse(data.user.imagesFamily).path.map(function(val, index){
								path = [...path ,val ];
							  })
							  JSON.parse(data.user.imagesFamily).realname.map(function(val, index){
							
								realname = [...realname ,val ];
			  
							  })
						}
						
					  }


					  if(!data.user.privateHobbies){
						if(data.user.imagesHobbies!=''){
							JSON.parse(data.user.imagesHobbies).path.map(function(val, index){
								path = [...path ,val ];
							  })
							  JSON.parse(data.user.imagesHobbies).realname.map(function(val, index){
							
								realname = [...realname ,val ];
			  
							  })
						}
						
					  }

					  if(path.length>0){
						setGellary({ 'path' : path ,  'realname'  : realname })
					  }
					setLastActivity(data.latestactivity);
				}
			});
	};

	React.useEffect(() => {
		fetchData();
	}, []); // <-- Have to pass in [] here!


	React.useEffect(() => {
		if(details.id){
			fetch(process.env.REACT_APP_API_URL+'api/user/activity/Log/latest' , {
				method: 'POST',
				headers: {
				  'Access-Control-Allow-Origin' : 'http://localhost:3000/',
				  "Access-Control-Allow-Credentials": "true/",
				  "Content-Type": "application/json",
				},
				body: JSON.stringify({ dealer: details.id }),
			  }
				)
				.then(results => results.json())
				.then(data => {
				  setActivity(data.data);
				});
		}
		
	  }, [details]); // <-- Have to pass in [] here!


	return (
		<>
			<section className="realtors">
				 {/* <Header /> */}
				<Details lastactivity={lastactivity} details={details} />
				<Description description={details.about} />
				{(gallery)&&
				<DetailGallery images={gallery} /> 
				}
			 	<RealtorAddress />
				{/* <RealtorForm /> */}
				<RealtorFeeds activity={activity} />
				<RealtorCallToAction />
				{/* <Footer /> */}
			</section>
		</>
	);
}
