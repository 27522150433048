
import {
    Switch,
    Route,
} from "react-router-dom/cjs/react-router-dom.min";
import CallToAction from "../components/home/callToAction";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import AuthRouter from "./routes";
import { useLocation    } from 'react-router-dom'


import Cookies from 'js-cookie';
import { useTour } from '@reactour/tour'

export default function AppRouter(props) {
    const { setIsOpen } = useTour();
    
   
    

   
    const currentstep = Cookies.get('currentstep');

    // console.log('currentstep' , currentstep);
    if(currentstep==3  &&  props.currentUrl=='' ){
        setIsOpen(true);
        props.onNextStep(3);
        props.handleCurrentUrl('/edit-profile');
    }
  
        
     
  

    //console.log('currentstep' , currentstep);
       
     

  

     const location = useLocation();
    return (
       <>
             <Header />
            <Switch>
                {AuthRouter.map((item, index) => {
                    return (
                        <Route
                            key={index}
                            exact
                            path={item.path}
                            component={item.component}
                        />
                    );
                })}
            </Switch>

                {(location.pathname!='/login' &&  location.pathname!='/signup')?
                      <Footer />
                      :null
                }
      
           

            {/* <CallToAction /> */}
            {/* <Footer /> */}
        </>
    );
}