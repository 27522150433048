import {
	Avatarbg,
	clockIcon,
	locationTick,
	saveIcon,
	starIcon,
	universityIcon,
} from "../../utils/images";
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import moment from 'moment';


export default function Details(props) {
	const userToken =  Cookies.get('token');
	const user_id =  Cookies.get('user_id');
	const { details } = props;
	const [follow , setFollow] = useState(false);
	const [followButton , setFollowButton] = useState(false);
    const [selectedSentRequest , setSelectedSentRequest] = useState('');
	const [unfollow , setUnfollow] = useState('');
	const user_package = Cookies.get("package");

	React.useEffect(() => {
		if(unfollow!=''){
		fetch(process.env.REACT_APP_API_URL+'api/friends/unfriend' , {
		  method: 'POST',
		  headers: {
			'Access-Control-Allow-Origin' : 'http://localhost:3000/',
			'Access-Control-Allow-Credentials' : 'true/',
			'Content-Type': 'application/json' ,
			'Authorization': 'Bearer '+userToken+'',
		  },
		  body: JSON.stringify({'target':unfollow})
		}
		  )
		  .then(results => results.json())
		  .then(data => {
			
			setFollow(false);
			
		  });
		}
	  }, [unfollow]); // <-- Have to pass in [] here!


	React.useEffect(() => {
		if(selectedSentRequest!=''){
        fetch(process.env.REACT_APP_API_URL+'api/friends/friendreq' , {
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin' : 'http://localhost:3000/',
            'Access-Control-Allow-Credentials' : 'true/',
            'Content-Type': 'application/json' ,
            'Authorization': 'Bearer '+userToken+'',
          },
          body: JSON.stringify({'id':selectedSentRequest})
        }
          )
          .then(results => results.json())
          .then(data => {
            
			setFollowButton(true);
          });
		}
      }, [selectedSentRequest ]); // <-- Have to pass in [] here!


	React.useEffect(() => {
		if(userToken && details){

        fetch(process.env.REACT_APP_API_URL+'api/friends/users' , {
          method: 'GET',
          headers: {
            'Access-Control-Allow-Origin' : 'http://localhost:3000/',
            'Access-Control-Allow-Credentials' : 'true/',
            'Content-Type': 'application/json' ,
            'Authorization': 'Bearer '+userToken+'',
          },
        } 
          )
          .then(results => results.json())
          .then(data => {
			
			if(data.requestsent){
					data.requestsent.map((friend) => { 
						if(friend.id==details.id){
							setFollow(true);
						}
					 })
			}
			

           
            //setFollowsYou(data.followsyou);
          });

		  fetch(process.env.REACT_APP_API_URL+'api/follows/get' , {
            method: 'GET',
            headers: {
              'Access-Control-Allow-Origin' : 'http://localhost:3000/',
              'Access-Control-Allow-Credentials' : 'true/',
              'Content-Type': 'application/json' ,
              'Authorization': 'Bearer '+userToken+'',
            },
          }
            )
            .then(results => results.json())
            .then(data => {


              if(data.youfollow){
                data.youfollow.map((friend) => { 
                  if(friend.sender_id==props.details.id){
                    setFollow(true);
                  }
                 })
            }

            if(data.followsyou){
              data.followsyou.map((friend) => { 
                if(friend.receiver_id==props.details.id){
                  setFollow(true);
                }
               })
          }

            });

			
		}else{
			setFollow(true);
		}
      }, [details]); // <-- Have to pass in [] here!

	  React.useEffect(()=>{
		setFollowButton(follow);
	  },[follow])
	  React.useEffect(() => {
	}, [follow]);
	return (
		<section className="realtor-details">
			<div className="container">
				<div className="row">
					<div className="col-lg-9">
						<h2 className="main-title-head realtor-title">
							{details.first_name} 
						
							 {/* <span>Featured</span> */}
						</h2>

						<h4 className="realtor-location">
							<img src={locationTick} alt="" />
							{details.location}
						</h4>

						<h2 className="realtor-info-title">Real Estate Professionals info :</h2>
						<ul className="realtor-feats">
							<li>
								<img src={saveIcon} alt="" />
								{details.company}
							</li>
							{(details.years_in_business) && 
							<li>
								<img src={universityIcon} alt="" />
								{details.years_in_business} Years in business
							</li>
}
							<li>
								<img src={starIcon} alt="" />
								Available on our social media platform
							</li>
							{(props.lastactivity)&&
							
							<li>
								<img src={clockIcon} alt="" />
								Last Activity {moment(props.lastactivity).fromNow() } 
							</li>

							
							}
							
						</ul>
					</div>
					<div className="col-lg-3">
						

						{(details.image!='' && details.image!=null)?	
															
							<img   className="details-img" src={process.env.REACT_APP_IMAGES_URL + JSON.parse(details.image).path[0]} alt="" />
							:
							<img className="details-img" src={Avatarbg} alt="" />
								}


						<br />
						{(user_id)?
						<>
						{(user_id!=props.details.id) &&
						<>
						{(followButton)?
							<button   onClick={(e) => { setUnfollow(e.currentTarget.dataset.id) ; }   }  data-id={props.details.id} className="btn details-img-btn">Unfollow</button>
							:
							<>
							{(user_package!='standard') &&
							
							<button onClick={(e) => { setSelectedSentRequest(e.currentTarget.dataset.id) ; }   }  data-id={details.id} className="btn details-img-btn">Follow </button>
							}
								</>
						}
						</>
					}
					</>

					:
					<>  <a href="/login"  className="btn details-img-btn">Follow </a> </>
					}


						
					</div>
				</div>
			</div>
		</section>
	);
}
