import { Link, NavLink } from "react-router-dom";
import {
  facebookIcon,
  linkdinIcon,
  Logo,
  LogoBlue,
  mailIcon,
  phoneIcon,
  twitterIcon,
  Avatar,
} from "../utils/images";
import Navbar from "react-bootstrap/Navbar";
import { home, contractors, investors, listing, realtors, socialMedia, USHXToken  , token} from "../routers/routePath";
import Cookies from 'js-cookie';
import {  Container , Nav , NavDropdown } from 'react-bootstrap';
import { useHistory , useLocation  } from "react-router-dom";

import { useTour } from '@reactour/tour'

export default function Header() {
  const { setIsOpen } = useTour()
  const userToken = Cookies.get('token');
  const userid = Cookies.get('user_id');
  const user_type = Cookies.get('user_type');
  const first_name = Cookies.get('first_name');
  const last_name = Cookies.get('last_name');
  const profile_image = Cookies.get('profile_image');
  console.log(profile_image);
  const isphonevarified = Cookies.get('isphonevarified');
  const kycsubmitted = Cookies.get('kycsubmitted');
  const  kyc_required =   Cookies.get('kyc_required');
  const history = useHistory();
  


//   if(history.location.pathname!='/profile-completion'){
//  if(userToken){
     
//   console.log("isphonevarified" ,isphonevarified);
//       if(isphonevarified==0){
//         history.push('/profile-completion')
//       }
     
      
//       if(kyc_required==1 &&  kycsubmitted==0){
//         history.push('/profile-completion')
//       }
     
//   }
//   }
 

 
  
  function logOut() {
    //sessionStorage.removeItem("token");
    Cookies.remove('currentstep');
    Cookies.remove('token');
    Cookies.remove('user_id');
    Cookies.remove('user_state');
    Cookies.remove('isphonevarified');
    Cookies.remove('kycsubmitted');
    history.push("/login");
  }
  
  

  const currentstep = Cookies.get('currentstep');
 

  return (
    <header className="header">
      <div className="container">
        <Navbar
          className="justify-content-between"
          bg="transparent"
          expand="xl"
        >
          <Link className="navbar-brand" to="/">
            <img src={LogoBlue} alt="Logo" />
          </Link>
          {(!userToken)?
          <div className="login-btn-mobile">
            <div className="login-btn">
            <Link className="auth-link" to='/login'><button>Login</button></Link>
            </div>
            <div className="join-btn">
            <Link className="auth-link" to='/signup'><button>Join</button></Link>
            </div>
          </div>
          :null
          }
          {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-btn"><i className="fas fa-bars"></i></span>
                    </button> */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="custom-nav-links" id="basic-navbar-nav">
            <ul className="navbar-nav">
              <li className="nav-item" data-tut="reactour__iso">
                <NavLink  className={isActive => "nav-link" + (isActive ? " active" : "")} to={home} exact={true}>
                  Home
                </NavLink>
              </li>
              <li className="nav-item" >
                <NavLink data-tut="reactour__browse_socialmedia" className={isActive => "nav-link" + (isActive ? " active" : "")} to={socialMedia}>
                  Social Media
                </NavLink>
              </li>
              <li className="nav-item" >
                <NavLink  data-tut="reactour__browse_properties" className={isActive => "nav-link" + (isActive ? " active" : "")} to={listing}>
                  Properties
                </NavLink>
              </li>
              <li className="nav-item" style={{ display: "none" }}>
                <NavLink className={isActive => "nav-link" + (isActive ? " active" : "")} to={token}>
                  USHX Token{" "}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={isActive => "nav-link" + (isActive ? " active" : "")} to={realtors}>
                Real Estate Professionals
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={isActive => "nav-link" + (isActive ? " active" : "")} to={investors}>
                  Investors{" "}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={isActive => "nav-link" + (isActive ? " active" : "")} to={contractors}>
                  Contractors{" "}
                </NavLink>
              </li>
              {(userToken)&&
              <li className="nav-item mb-4 mb-lg-0">
                
                <NavDropdown 
                        data-tut="reactour__browse_profile"
                          id="nav-dropdown-dark-example-new"
                          className="newdivavatar"
                          title={<div className="headernavbarimage"> {(profile_image!='null')?
                          <img className={`user_${user_type}`} src={process.env.REACT_APP_IMAGES_URL + profile_image} alt="" />
                        :
                        <img className={`user_${user_type}`} src={Avatar} alt="" />
                        } 
                       {first_name} {last_name.substring(0, 1)}. 
                        </div>
                       
                      }
                          menuVariant="dark"
                        >
                         <Link className={'profiledropdownmenu '} to="/socialMedia">Social Media</Link>  
                         <Link  className={'profiledropdownmenu '} to="/edit-profile">My Profile</Link>  
                    
                          <NavDropdown.Item  onClick={logOut} href="/login">Logout</NavDropdown.Item>
                        
                         
                        </NavDropdown>
              </li>
}


            </ul>
          </Navbar.Collapse>

      
         

          {(!userToken)?
           <div className="login-btn-desktop">
           <div className="login-btn">
             <Link className="auth-link" to='/login'><button>Login</button></Link>
           </div>
           <div className="join-btn">
             <Link className="auth-link" to='/signup'><button>Join</button></Link>
           </div>
         </div>
         :
       null
         
                
                // <Navbar   className="customnavbar newdivavatar" variant="white" bg="white" expand="sm" id="navbar-dark-example">
                //   <Container fluid>
                
                //     <Navbar.Toggle  aria-controls="navbar-dark-example" />
               
                //     <Navbar.Collapse  >
                //       <Nav className="ms-auto">
                //         <NavDropdown 
                //         data-tut="reactour__browse_profile"
                //           id="nav-dropdown-dark-example-new"
                //           title={<div className="headernavbarimage"> {(profile_image!='null')?
                //           <img className={`user_${user_type}`} src={process.env.REACT_APP_IMAGES_URL + profile_image} alt="" />
                //         :
                //         <img className={`user_${user_type}`} src={Avatar} alt="" />
                //         } 
                //         {first_name} {last_name.substring(0, 1)}.
                //         </div>
                       
                //       }
                //           menuVariant="dark"
                //         >
                //           <NavDropdown.Item href="/socialMedia">Social Media</NavDropdown.Item>
                //           <NavDropdown.Item   href="/edit-profile">My Profile</NavDropdown.Item>
                //           <NavDropdown.Item  onClick={logOut} href="/login">Logout</NavDropdown.Item>
                        
                         
                //         </NavDropdown>
                //       </Nav>
                //     </Navbar.Collapse>
                //   </Container>
                // </Navbar>
                
          }

          {/* <div className="collapse custom-nav-links navbar-collapse" id="navbarNav">

                    </div> */}
          {/* <div className="nav-social-links">
                        <div className="address-details">
                            <small>
                                <span>
                                    <img src={phoneIcon} alt="Icon" />
                                </span>
                                <span>
                                    (281) 377-0280
                                </span>
                            </small>
                            <small>
                                <span>
                                    <img src={mailIcon} alt="Icon" />
                                </span>
                                <span>
                                    admin@ushousingexchange.com
                                </span>
                            </small>
                        </div>
                        <div className="social-links-icon">
                            <a href="#">
                                <img src={facebookIcon} alt="Icon" />
                            </a>
                            <a href="#">
                                <img src={twitterIcon} alt="Icon" />
                            </a>
                            <a href="#">
                                <img src={linkdinIcon} alt="Icon" />
                            </a>
                        </div>

                    </div> */}
        </Navbar>
       
        {/* <nav className="navbar navbar-expand-xl navbar-light justify-content-between">

                </nav>
                <div className="toggle-btn">

                </div> */}
      </div>
     
    </header>

    
  );
}
