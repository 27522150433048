import Media from "../../components/socialMediaDetails";
import Footer from "../../layouts/footer";
import { useHistory } from "react-router-dom";
import useToken from '../../components/App/useToken';


export default function SocialMedia() {
  const { token, setToken } = useToken();
  const history = useHistory();
  if(!token) {
    history.push("/login");
 }
  return (
    <>
      <section className="social-media">
        <Media />
      </section>
    </>
  );
}