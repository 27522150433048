import AdminHeader from "../../components/admin/header";
import CommunityContent from "../../components/admin/community";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";

export default function Community() {
    return (
      <>
        <section className="community">
            <div className="mt mt-xsm-0 mt-md-0 mt-sm-0">
                 {/* <Header /> */}
                <CommunityContent />
                {/* <Footer /> */}
            </div>
        </section>
      </>
    );
  }