import {
    LogoBlue
  } from "../../utils/images";
  
  export default function LoginLogo() {
    return (
      <section className="login-logo">
        <img src={LogoBlue} alt="" />
      </section>
    );
  }
  