import {
	addedPostImage,
	commentIcon,
	LikeIcon,
	locationIcon,
	mapImage,
	photoIcon,
	postImageOne,
	postImageThree,
	postImageTwo,
	propertiesTypeIcon,
	shareIcon,
	submitCommentIcon,
	User,
	userLikeImage,
} from "../../utils/images";
import LeftSidebar from "../../layouts/leftSider";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { NavDropdown, Form } from "react-bootstrap";
import NotificationPanel from "../../layouts/notificationpanel";
import Post from "../Posts/post";
import Header from "../../layouts/header";
import React, { useState , useCallback } from "react";
import { detail } from "../../routers/routePath";
import Alert from "react-bootstrap/Alert";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import Cookies from "js-cookie";
import {Modal, Button} from 'react-bootstrap';
import { useHistory ,useLocation } from 'react-router-dom';



async function addPost(credentials) {
	return fetch(process.env.REACT_APP_API_URL + "api/posts/store", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "http://localhost:3000/",
			"Access-Control-Allow-Credentials": "true/",
			"Content-Type": "application/json",
			Authorization: "Bearer " + credentials.userToken + "",
		},
		body: JSON.stringify(credentials),
	}).then((data) => data.json());
}

export default function Media() {
	const userToken = Cookies.get("token");
	const [posts, setPosts] = useState([]);
	const [title, setTitle] = useState("sample Title");
	const [category, setCategory] = useState(1);
	const [body, setBody] = useState();
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const [userProperites, setUserProperties] = useState([]);
	const [toggleContents, setToggleContents] = useState("Select a Property");
	const [property, setSelectedProperty] = useState();
  const [location, setLocation] = useState('');
	const [images, setFrontFiles] = useState();
  const [postsreload, setPostsreload]= useState();

  const profile_image = Cookies.get('profile_image');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const history = useHistory()


  const slug =history.location.pathname.replace('/post/','')

 


	const handleChange = (e) => {
		//setFiles(e.target.files)
		setErrorMessage(null);
		setSuccessMessage(null);
		const url = process.env.REACT_APP_API_URL + "api/uploadFile";
		const formData = new FormData();
		for (let i = 0; i < e.target.files.length; i++) {
			formData.append(`files[${i}]`, e.target.files[i]);
		}
		formData.append("directory", "posts");
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};
		axios.post(url, formData, config).then((response) => {
			setFrontFiles(JSON.stringify(response.data.files));
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrorMessage(null);
		setSuccessMessage(null);

		const res = await addPost({
			body,
			title,
			category,
			property,
      location,
			images,
			userToken,
		});
		if (res.success) {
			//  sessionStorage.setItem("token", token.author.api_token);
			document.getElementById("create-post-form").reset();
			setBody("");
			setSuccessMessage("Post has been added successfully.");
      setPostsreload(body);
		} else {
			setErrorMessage(res.errors);
		}
	};

	React.useEffect(() => {
		fetch(process.env.REACT_APP_API_URL + "api/properties/user", {
			method: "GET",
			headers: {
				"Access-Control-Allow-Origin": "http://localhost:3000/",
				"Access-Control-Allow-Credentials": "true/",
				"Content-Type": "application/json",
				Authorization: "Bearer " + userToken + "",
			},
		})
			.then((results) => results.json())
			.then((data) => {
			
				setUserProperties(data.data);
			});
	}, []); // <-- Have to pass in [] here!

	React.useEffect(() => {
		fetch(process.env.REACT_APP_API_URL + `api/posts/${slug}/show`, {
			method: "GET",
			headers: {
				"Access-Control-Allow-Origin": "http://localhost:3000/",
				"Access-Control-Allow-Credentials": "true/",
				"Content-Type": "application/json",
				Authorization: "Bearer " + userToken + "",
			},
		})
			.then((results) => results.json())
			.then((data) => {
				setPosts(data.data);
			});
	}, [postsreload]); // <-- Have to pass in [] here!

	return (
		<div className="wrapper">
			 {/* <Header /> */}
			<div className="side-bar">
				<LeftSidebar />
			</div>
			<div className="content">
				<div className="feed-main">
				
		
          {(posts.id) && 
              <Post postlink = {posts.slug} post={posts}></Post>
          }
       

				
				</div>
				<div className="notification-main">
					<NotificationPanel />
				</div>
			</div>
		</div>
	);
}
