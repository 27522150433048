import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
//import Geocode from "react-geocode";





const AnyReactComponent = ({ text }) => <div>{text}</div>;

class SimpleMap extends Component  {
    static defaultProps = {
      
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };


  



    render() {
    
        // Geocode.setApiKey("AIzaSyALafu6BQ4Rf7Eq26agEGNsna-ugZ5SoUE");

        // // set response language. Defaults to english.
        // Geocode.setLanguage("en");
        // Geocode.fromAddress("Eiffel Tower").then(
        //     (response) => {
        //       const { lat, lng } = response.results[0].geometry.location;

        //     },
        //     (error) => {
       
        //     }
        //   );

          
        return (
            <section className='container'>
                <h2 className="main-title-head">Property Location</h2>
                <div style={{ height: '100vh', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: process.env.REACT_APP_API_GOOGLE_MAP_KEY }}
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                    >
                        <AnyReactComponent
                            lat={59.955413}
                            lng={30.337844}
                            text={this.props.title}
                        />
                    </GoogleMapReact>
                </div>
            </section>
        );
    }
}

export default SimpleMap;